import axios from "axios";

const instance = axios.create({});

instance.defaults.headers.common["Authorization"] = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNWExYmMwNDMtMTM4MS00MjBjLWJmNTctYTA2MjFiYjI4ZjFjIiwiYWNjb3VudF9pZCI6ImJlNDc3NWQ4LTY3ODAtNDU0MS05YTM5LWVhZmZhZTg4MWZiYyIsImV4cCI6MTYwNDE1OTM2OSwiZXhwX21zIjo0NDc5OTF9.FdWg0eFokCtqAG0jR5SMbA68NX7RMYjhzs9pPsRjchU";

export default {
    state: () => ({
        casesData: [
            {
                id: "0",
                case_id: "ORCA-2341234234",
                status: "Red",
                raised_flags: [{tag: "Violent Content"}, {tag: "Illegal Activities"}],
                user: {
                    name: "Json Blackburn",
                    email: "blackburn@gmail.com",
                },
                created_on: "Jan 9",
                completed_on: "Jan 20",
            },
        ],
        caseMaxEntity: {
            person: 1,
            company: 1,
        },
    }),
    mutations: {
        SET_CASES_DATA(state, payload) {
            // state.casesData = payload;
        },
        SET_CASE_MAX_ENTITY(state, payload) {
            state.caseMaxEntity = payload;
        },
    },
    actions: {
        async fetchCasesData({commit}) {
            await instance.get("https://0doku09yt2.execute-api.eu-west-2.amazonaws.com/beta/case/api/v1/cases?product_id=3bd9f235-37cb-423b-a39a-b8c5045f73f1").then((res) => commit("SET_CASES_DATA", res));
        },
    },
    modules: {},
    getters: {
        // getCasesData: (state) => state.casesData,
        getCaseMaxEntity: (state) => state.caseMaxEntity,
        // getCaseData: (state) => (id) => isArray(state.casesData) && state.casesData.find((item) => item.id === id),
    },
};
