import axios from "@/axios/development";

export default {
    async getRegStatusPieChart(payload) {
        return (await axios.post("dashboard/pie/rag-status", payload)).data.rag_status;
    },
    async getRiskCategoriesPieChart(payload) {
        return (await axios.post("dashboard/pie/risk-categories", payload)).data.risk_categories;
    },
    //  async getSourceCategoriesPieChart(payload) {
    //      return (await axios.post("dashboard/pie/source-categories", payload)).data.source_categories;
    //      },
    //  async getDomainsPieChart(payload) {
    //      return (await axios.post("dashboard/pie/domains", payload)).data.domains;
    //  },
    async getCaseStatusPieChart(payload) {
        return (await axios.post("dashboard/pie/case-status", payload)).data.cases;
    },
    async getCaseListPieChart(payload) {
        return (await axios.post("dashboard/pie/case-list", payload)).data.cases;
    },
    //  async getTimelineGraph(payload) {
    //      return (await axios.post("dashboard/timeline-graph", payload)).data.timelines;
    //  },
};
