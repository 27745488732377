export default {
    name: "neo-graph-node",
    props: {
        image: {
            type: String,
        },
        title: String,
        desc: String,
    },
    mounted() {},
};
