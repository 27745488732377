export default {
    name: "neo-tooltip",
    props: {
        label: {
            type: String,
            default: null,
        },

        description: {
            type: String,
            default: null,
        },

        top: {
            type: Number,
            default: 0,
        },

        left: {
            type: Number,
            default: 0,
        },
        hide: {
            type: Boolean,
            default: true,
        },
        width: {
            type: String,
            default: "150px",
        },
        background: {
            type: String,
            default: "#343648;",
        },
    },

    data() {
        return {};
    },
    methods: {},
};
