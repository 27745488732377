import axios from "@/axios";
import userAxios from "@/axios/user.js";
import {COMPONENT_TAB_MAP, REMOVED_ENTITIES} from "@/utils/constants";
import {active} from "d3-transition";
import {functionsIn} from "lodash";
import personalDetails from "../containers/cards/personal-details/personal-details";

async function getSocialPlatformData(caseId, nextPageId) {
    let response = await axios.get(`social-platform-data?case_id=${caseId}`);
    let data = {socialPlatformData: {relevant: [], irrelevant: []}};
    data.socialPlatformData.relevant = response.data.social_platform_data;
    return data;
}

async function getDarkWebContent(caseId) {
    let response = await axios.get(`hibp-data?case_id=${caseId}`);
    let data = {darkWeb: {irrelevant: [], relevant: []}};

    response.data.hibp_data.forEach((elem) => {
        data.darkWeb.relevant.push(
            ...elem.hibp_metadata.map((item) => {
                return {
                    ...item,
                    email: elem._id,
                };
            })
        );
    });
    return data;
}

async function getMedia(caseId) {
    let response = await axios.get(`active-passive-media/${caseId}`);
    return {media: filterIrrelevent(caseId, "media", response?.data?.data)};
}

async function entityIdentifier(caseId) {
    let data = {associatedPeople: {relevant: [], irrelevant: []}, personalDetails: {relevant: [], irrelevant: []}, personalDetailsTabs: {relevant: [], irrelevant: []}, connections: []};
    try {
        await Promise.all([axios.get(`entity-identifier?caseid=${caseId}`), axios.get(`personal-details-tab`)]).then(([entityData, personalDetailsTabsData]) => {
            data.personalDetails = filterIrrelevent(
                caseId,
                "personalDetails",
                entityData.data.entities.filter((el) => !REMOVED_ENTITIES.includes(el.entity_identifier))
            );
            data.associatedPeople = filterIrrelevent(
                caseId,
                "associatedPeople",
                entityData.data.entities.filter((el) => el.type === "entity")
            );
            data.personalDetailsTabs = {relevant: personalDetailsTabsData.data, irrelevant: []};
            data.connections = entityData.data;
        });
    } catch (error) {
        console.error(error);
    }
    // let response = await axios.get(`entity-identifier?caseid=${caseId}&placeholder_only=true`)

    return data;
}

async function getSearchEngineResults(caseId, pageNo, pageSize) {
    let url = `google-search-data/${caseId}`;
    url = `${url}?page_size=${pageSize ? pageSize : 20}&page_number=${pageNo ? pageNo : 1}&all_data=false`;
    let response = await axios.get(url);
    return {searchEngineResults: filterIrrelevent(caseId, "searchEngineResults", response.data.google_search_data)};
}

async function caseData(caseId) {
    let response = await axios.get(`case-data/${caseId}`);
    let data = {greenContent: {relevant: [], irrelevant: []}, websites: {relevant: [], irrelevant: []}, socialProfiles: {relevant: [], irrelevant: []}, extraContent: {relevant: [], irrelevant: []}, invalidCaseId: false};
    if (Object.keys(response.data.case_data).length <= 0) {
        return data;
    }

    let greeContentObjs = [];

    ["volunteering_roles", "wider_evidence_skills", "charitable_work"].forEach((contentType) => {
        response.data.case_data[contentType].forEach((el) => {
            greeContentObjs.push({
                ...el,
                type: contentType,
            });
        });
    });

    data.greenContent = filterIrrelevent(caseId, "greenContent", greeContentObjs);
    data.websites = filterIrrelevent(caseId, "websites", response.data.case_data.websites);
    data.socialProfiles = filterIrrelevent(caseId, "socialProfiles", response.data.case_data.social_profiles);
    data.extraContent = {relevant: response.data.case_data.extra_content, irrelevant: []};
    data.invalidCaseId = Object.keys(response.data.case_data).length <= 0 ? false : true;

    return data;
}

function filterIrrelevent(caseId, tab, cards) {
    let data = {irrelevant: [], relevant: []};
    cards.forEach((el) => {
        if (el.status === "IRRELEVANT") {
            data.irrelevant.push({
                case_id: caseId,
                item: el,
                tab: tab,
                type: "IRRELEVANT",
                component: COMPONENT_TAB_MAP[tab],
            });
        } else {
            data.relevant.push({...el, tab: tab});
        }
    });

    return data;
}

async function casePersonalData(caseId, caseData) {
    let data = {casePersonalData: {}};
    // handle preloaded data if is
    let response = caseData || (await axios.get(`case-personal-data/${caseId}`));

    data.casePersonalData.relevant = response.data.case_data;
    data.casePersonalData.irrelevant = [];
    return data;
}

async function getHistoryData(caseId) {
    let responce = await axios.get(`get-history/${caseId}`);
    return responce?.data;
}

async function fetchNotesData(caseId) {
    let responce = await axios.get(`get-history-notes/${caseId}`);
    return responce?.data;
}

async function getNodeConnections(caseId) {
    let responce = await axios.get(`entity-identifier?caseid=${caseId}`);
    return responce?.data;
}

async function getStaticData() {
    let data = {iconsdata: [], entitymapperdata: [], socialPlatformIcons: [], riskCategories: [], entitiesRelationsAttributes: {}};
    await Promise.allSettled([axios.get(`icons-data`), axios.get(`entity-parent-mapper`), axios.get(`social_platforms`), axios.get(`risk-categories`), axios.get(`entities-relations-attributes`)]).then(([iconsdata, entitymapperdata, socialPlatformIcons, riskCategories, eraData]) => {
        data.iconsdata = iconsdata.value?.data.icons_data ?? [];
        data.entitymapperdata = entitymapperdata?.value?.data ?? [];
        data.socialPlatformIcons = socialPlatformIcons?.value?.data ?? [];
        data.riskCategories = riskCategories?.value?.data ?? [];
        data.entitiesRelationsAttributes = eraData?.value?.data?.data ?? {};
    });
    data.appConfig = await fetchAppConfig();
    return data;
}

async function getFilterParamsData(caseId) {
    try {
        let filterdata = await axios.post(`filter-params`, {case_id: caseId});
        return filterdata?.data;
    } catch {
        return {};
    }
}

async function fetchCaseUsersData(caseId) {
    let audUrl = `audit-log/users/${caseId}`;
    let url = `api/v1/accounts/users-mini`;
    try {
        let auditUrl = await axios.get(audUrl);
        let response = await userAxios.get(url);
        let users = response?.data?.filter((elem) => auditUrl?.data?.data?.find((elem1) => elem1 === elem.id));
        return {users, users_mini: response?.data ?? []};
    } catch (error) {
        console.error(error);
        return [];
    }
}

async function fetchLastReviewed(caseId) {
    try {
        let responce = await axios.put(`continuous-monitoring-items/last-reviewed`, {case_id: caseId});
        return responce?.data;
    } catch (error) {
        console.error(error);
    }
}
async function fetchAppConfig() {
    let url = "config";
    try {
        let responce = await axios.post(url, {key: ["saas:main_entity"]});
        return responce?.data;
    } catch (error) {
        console.error(error);
    }
}

async function fetchKeyFactsData(caseId) {
    let {data} = await axios.post(`output-template/outputs`, {case_id: caseId});
    let keyFacts = [];
    data.forEach((section) => {
        section?.sections?.forEach((keyFact) => {
            if (keyFact?.active) {
                keyFacts.push(...keyFact.outputs);
            }
        });
    });
    let outputTemplates = data.filter((sec) => sec?.sections?.some((card) => card.active));
    const otherElem = outputTemplates.find((outputTemplate) => outputTemplate.category_name === "Others");
    outputTemplates = outputTemplates.filter((outputTemplate) => outputTemplate.category_name !== "Others");
    if (otherElem) {
        outputTemplates.push(otherElem);
    }
    //NEED To remove after
    outputTemplates?.forEach((temp, index) => {
        temp?.sections?.forEach((sec, idx) => {
            sec?.outputs?.forEach((__, ind) => {
                outputTemplates[index].sections[idx].outputs[ind].output_category_id = temp.category_id;
            });
        });
    });
    return {keyFacts: filterIrrelevent(caseId, "keyFacts", keyFacts), outputTemplates};
}

let tabFetcherMap = {
    searchEngineResults: getSearchEngineResults,
    media: getMedia,
    caseData: caseData,
    entityIdentifier: entityIdentifier,
    casePersonalData: casePersonalData,
    darkWeb: getDarkWebContent,
    socialPlatformData: getSocialPlatformData,
    keyFacts: fetchKeyFactsData,
};

export {getSearchEngineResults, caseData, getDarkWebContent, getMedia, getStaticData, getFilterParamsData, entityIdentifier, getHistoryData, fetchNotesData, getNodeConnections, tabFetcherMap, fetchCaseUsersData, fetchLastReviewed, fetchAppConfig};
