<template>
    <div class="bootstrap-styles">
        <div class="tw-grid tw-grid-cols-2 tw-gap-3">
            <div class="">
                <h4 class="tw-mb-4">Research Notes</h4>
                <div class="placeholder-[#282828] scroll-bar tw-rounded-md tw-flex" style="height: 70vh !important">
                    <!-- <FormulateInput ref="file" type="file" v-model="files" class="w-full rounded-xl group cursor-pointer"
                        @input="handleFiles" multiple :add-label="true" :disabled="disabled" /> -->
                    <b-form-textarea class="scroll-bar tw-flex tw-flex-col" style="width: 100%" v-model="entityData.notes" ref="research_QuillEditor" :options="editorOptions" />
                </div>
            </div>
            <div class="">
                <h4 class="tw-mb-4">Customer Notes</h4>
                <div class="placeholder-[#282828] tw-rounded-md tw-flex tw-w-full" style="height: 70vh !important">
                    <b-form-textarea class="scroll-bar tw-flex tw-flex-col" style="width: 100%" v-model="entityData.customer_notes" ref="customer_QuillEditor" :options="editorOptions" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import {quillEditor, Quill} from "@/lib/vue-quill-editor";
import {ImageExtend, QuillWatch} from "quill-image-extend-module";
import QuillBetterTable from "quill-better-table";

Quill.register("modules/ImageExtend", ImageExtend);
Quill.register(
    {
        "modules/better-table": QuillBetterTable,
    },
    true
);

export default {
    name: "Notes",
    components: {
        quillEditor,
    },
    props: {
        entity: Object,
    },
    data() {
        return {
            researchNotes: "",
            customerNotes: "",
            editorOptions: {
                placeholder: "Insert text here",
                readOnly: true,
                theme: "snow",
                modules: {
                    toolbar: {
                        container: [
                            [{font: []}, {size: ["small", false, "large", "huge"]}, "bold", "italic", "underline", {color: []}, {list: "ordered"}, {align: []}, "clean"], // text direction
                        ],
                    },
                },
            },
        };
    },
    methods: {},
    mounted() {},
    computed: {
        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },
    },
    watch: {},
};
</script>
