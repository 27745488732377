import {DATA_TYPES} from "@/utils/content-scripts/mark.decorator";
import {escapeRegExp} from "@/utils/functions";

/**
 * Case Identifier Entity properties
 *  @param {Object} source - object with Case Identifier Entity
 *   @param {String} source.title - Entity title
 *   @param {String} source.word - Entity word
 *
 *   @return {Object};
 */

function CaseIdentifierEntityToMarkEntityAdapter(source) {
    return {
        getEntity() {
            return {
                element: source,
                dataType: DATA_TYPES.caseIdentifiers,
                pattern: new RegExp(`${escapeRegExp(source.word.trim())}`, "gmi"),
            };
        },
        getClasses() {
            const fixedClass = "case-identifiers confirmed-entity";
            return `${fixedClass}`;
        },
        getAttributes() {
            const attributes = [];
            const hoverLabel = this.getHoverLabelAttribute();
            attributes.push(hoverLabel);
            return attributes;
        },
        getHoverLabelAttribute() {
            const hoverLabel = [];
            hoverLabel.push(source.language ? source.language : "English");

            hoverLabel.push(source.word);

            const label = hoverLabel.join(" - ");
            return {key: "title", value: label};
        },
    };
}

export default CaseIdentifierEntityToMarkEntityAdapter;
