// import { baseBlackboxUrl } from "@/main";

// export default {
//     name: "output-clipboard-table",
//     props: {
//         data: Array,
//     },
//     computed: {
//         getOutputData(){
//             const data  = this.data.map(media => {
//                 if(media.screenshot.available){
//                     return {...media, screenshotUrl: `${process.env.VUE_APP_IMAGES_URL}/${media.screenshot.location}`}

//                 }else{
//                     return {...media, screenshotUrl: null}
//                 }
//             })
//             return data
//         }
//     },
// };

import {mapGetters} from "vuex";

export default {
    name: "output-clipboard-table",
    props: {
        data: Array,
    },
    data() {
        return {
            tableHead: ["Category(s)", "RAG Status", "Customer Notes", "Url", "Screenshots"],
        };
    },
    computed: {
        ...mapGetters(["getRiskCategories"]),
        getOutputData() {
            const data = this.data.map((media) => {
                if (media.screenshot.available) {
                    return {...media, screenshotUrl: `${process.env.VUE_APP_IMAGES_URL}/${media.screenshot.location}`};
                } else {
                    return {...media, screenshotUrl: null};
                }
            });
            return data;
        },
    },
    methods: {
        ragStatus(doc) {
            let ragStatus = doc.entity_status;
            const categoriesList = this.getRiskCategories?.find((opt) => opt.group === "Non-Risk Categories");
            if (doc.risk_categories.length <= 0) {
                ragStatus = "Green";
            } else {
                const nonRiskCheck = doc.risk_categories.every((elem1) => categoriesList?.categories.some((elem2) => elem2.category === elem1));
                if (nonRiskCheck) {
                    ragStatus = "Green";
                }
            }
            return ragStatus;
        },
    },
};
