<template>
    <main>
        <div class="tw-absolute tw-top-1 tw-h-8 tw-w-full tw-flex tw-bg-transparent tw-items-center tw-z-500 tw-none" id="service-worker-alert" v-if="updateExists" @click="refreshApp()">
            <div class="tw-p-2 tw-bg-blue-600 tw-items-center tw-mx-auto tw-text-white tw-leading-none lg:tw-rounded-full tw-flex lg:tw-inline-flex tw-cursor-pointer" role="alert">
                <span class="tw-flex tw-items-center tw-rounded-full tw-bg-blue-300 tw-uppercase tw-px-2 tw-py-1 tw-text-xs tw-font-bold tw-mr-3">New</span>
                <span class="tw-font-semibold tw-mr-2 tw-text-left tw-flex-auto">An update is available, Please refresh</span>
            </div>
        </div>
        <Core v-if="logged" />
        <router-view v-else />
    </main>
</template>

<script>
import Core from "./core";
import "keylines";
import rawAxios from "@/axios";
import update from "./mixins/update.js";
import VueCookies from "vue-cookies";
import iamApi from "@/axios/user";
import store from "@/store";
import {fetchRefreshToken, fetchWlpRefreshToken} from "@/utils/functions";
import {decodeToken} from "@/utils/auth";

VueCookies.config("", "", "", true);
export default {
    name: "App",
    mixins: [update],
    data() {
        return {interval: null};
    },
    components: {
        Core,
    },
    computed: {
        logged() {
            return this.$store.getters.loggedIn;
        },
    },
    methods: {
        async validateTokenExpiry() {
            if (this.logged) {
                const token = VueCookies.get("token");
                if (token) {
                    let response = await iamApi.post("/api/v1/service/validate-expiry");
                    if (response.data && response.data.expired) {
                        try {
                            let exstingdecodeToken = decodeToken(token);
                            let _token;
                            if (exstingdecodeToken?.session_src === "neotaswlp") _token = await fetchWlpRefreshToken(token);
                            else _token = await fetchRefreshToken(token);
                            let decodedToken = decodeToken(_token);
                            store.dispatch("setLoginSession", {decodedToken, token: _token});
                        } catch (error) {
                            // if refresh token throws an error logout the user
                            console.error(error);
                            store.dispatch("logoutUser");
                        }
                    }
                } else store.dispatch("logoutUser");
            }
        },
    },
    async mounted() {
        const visitorData = await this.$fpjs.getVisitorData({
            extendedResult: true,
        });
        this.$cookies.set("NFID", visitorData.visitorId, {httpOnly: true});
        await rawAxios.post("/metrics/nfid", {
            data: visitorData,
        });

        $zoho.salesiq.ready = setTimeout(() => {
            if (document.querySelector("#titlediv.zsiq_cnt")) document.querySelector("#titlediv.zsiq_cnt").style.display = "none";
        }, 2000);
    },
    created() {
        this.interval = setInterval(() => {
            this.validateTokenExpiry();
        }, 60000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>

<style>
@import "./assets/fonts/inter.css";
@import "./assets/fonts/poppins.css";
</style>

<style src="./utils/content-scripts/content-script.scss" lang="scss" />

<style lang="scss">
// isolating bootstrap styles for panel component
.bootstrap-styles,
.b-toaster {
    @import "../node_modules/bootstrap/scss/bootstrap";
}

.copy-clipboard * {
    font-family: "Lato", sans-serif !important;
    text-align: justify !important;
}

// import bootstrap styles for common components
body {
    @import "../node_modules/bootstrap/scss/functions";
    @import "../node_modules/bootstrap/scss/variables";
    @import "../node_modules/bootstrap/scss/mixins";

    @import "../node_modules/bootstrap/scss/popover";
    @import "../node_modules/bootstrap/scss/tooltip";
}

// body {
//     font-family: var(--brand-font);
//     height: auto !important;
//     overflow-y: auto !important;
// }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--brand-font);
}

main {
    display: flex !important;
    height: 100vh;
    flex-direction: column;
}

// .accordion-root{
//   width: 100%;
//   .accordion{
//     // padding: 15px;
//     p{
//       margin: 0;
//     }
//   }
//   .list-root{
//     list-style-type: none;
//     .firstLevel{
//       font-weight: bold;
//       display: flex;
//       justify-content: space-between;
//       padding: 15px;
//       cursor: pointer;
//       img{
//         width: 18px;
//         transform: rotate(0deg);
//         transition: 0.2s all;
//       }
//       .open{
//         transform: rotate(180deg);
//         transition: 0.2s all;
//       }
//     }
//     .secondLevel{
//       display: flex;
//       justify-content: space-between;
//       border-bottom: 1px solid #f2f2f2;
//       padding: 15px 25px;
//       img{
//         width: 18px;
//         transform: rotate(0deg);
//         transition: 0.6s all;
//       }
//       .open{
//         transform: rotate(180deg);
//         transition: 0.6s all;
//       }
//     }
//     .thirdLevel{
//       color : #0677bb;
//       font-weight: bold;
//       padding: 15px 30px;
//       background-color: #f9f8f8;
//     }
//   }
//   .expandible{
//     ul{
//       padding-left: 0px;
//       list-style-type: none;
//     }
//   }
// }

/* Common CSS for report builder */

.content_wrap {
    outline: none !important;
}

/*
.content--area > div {
   background: #90e0b1;
 } */

#editor {
    /* border:solid 1px tomato; */
    // background: #fff;
    /* width: 70%; */
    margin: 0 auto;
    /* padding:20px */
    // width: 100%;
}

#editor:read-write:focus {
    outline: none;
}

.neo-page {
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 42cm;
}

.editor-header,
.footer {
    opacity: 0.4;
    border: none;
    height: auto;
    /* display: table; */
}

.activeHeader {
    opacity: 1;
    border-bottom: 1px dashed #000;
}

.activeFooter {
    opacity: 1;
    border-top: 1px dashed #000;
}

* {
    -webkit-print-color-adjust: exact !important;
}

.table-center {
    margin: auto;
}

.center-icon {
    margin-left: 50%;
    transform: translate(-50%);
}

.fixed_footer {
    position: absolute;
    bottom: 0;
    left: 0;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #232222;
    min-height: 100%;
}

#nav {
    padding: 10px;
    position: absolute;
    right: 5px;
    z-index: 1000;
    top: 15px;
}

#nav a {
    font-weight: bold;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    padding-top: 3px;
    color: #6c7ae0;
    border: 1px solid #6c7ae0;
    border-radius: 5px;
    text-decoration: none;
}

#nav a.router-link-exact-active {
    color: #fff;
    background-color: #6c7ae0;
}

.add_row_btn {
    width: 100%;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.page_break {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
    text-align: center;
    background: #252323;
    color: #fff;
}

@media print {
    .editor_parent {
        border: none;
    }
    .navigation,
    .header,
    .toolbar,
    .document--editor--expand,
    #editor--options--sidepanel,
    .b-sidebar,
    .main,
    .page--remover {
        display: none !important;
    }
    #main {
        display: flex !important;
    }
    #view {
        width: 100%;
    }
    .wrapper {
        background-color: transparent !important;
    }
    .main--wrapper {
        overflow: hidden !important;
        background: transparent !important;
        height: 100% !important;
    }

    .main--wrapper .document--area {
        overflow: hidden !important;
        background: transparent !important;
        flex: initial !important;
    }

    .template_page {
        background: transparent !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .neo-page {
        border: none !important;
        // width: 210mm;
        margin: 0 !important;
        height: 1122px !important;
    }
    .editor_parent {
        border: none !important;
        color: #2c3e50 !important;
    }
}

/* editor formate style */

.btn-lg,
.btn-group-lg > .btn {
    padding: 0px !important;
}

.dropdown {
    padding: 0px !important;
}

.dropdown-menu {
    padding: 0px !important;
    background-color: #252323 !important;
    border: 1px solid #3e3d3d !important;
}

.dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
}

.b-dropdown-form {
    padding: 10px !important;
}

.btn-lg,
.btn-group-lg > .btn {
    font-size: 0.875rem !important;
}

.b-dropdown-form input {
    margin-bottom: 10px;
}

.dropdown-item {
    color: #fff !important;
}

.dropdown-item:hover {
    color: #000 !important;
}

.dropdown-item {
    font-size: 12px;
    padding: 5px 5px 5px 7px !important;
}

.y_resize {
    position: fixed;
    top: 0;
    left: 0;
    width: 7px;
    cursor: col-resize;
    opacity: 0.08;
    z-index: 1000;
}

.x_resize {
    position: fixed;
    top: 0;
    left: 0;
    height: 7px;
    cursor: row-resize;
    opacity: 0.08;
    z-index: 1000;
}

.resizable {
    display: inline-block;
    resize: both;
    overflow: hidden;
    line-height: 0;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    z-index: 1000;
}

.iconSelect {
    background: rgb(156, 162, 163) !important;
}

.rotate_text {
    transform: rotate(90deg);
}

.shape-box {
    height: 50px;
    background: #ffffffad;
    text-align: center;
    padding: 10px 10px;
    margin-bottom: 20px;
    border-radius: 3px;
}

.resizable img {
    width: 100%;
    height: 100%;
}

/* Model Css Changes */
.editor-modal .modal-content {
    background-color: #262324 !important;
    border: none !important;
}

.editor-modal .modal-content .modal-header * {
    color: #fff !important;
    font-size: 12px;
}

.editor-modal .modal-content .modal-header {
    padding: 10px 10px;
    border-bottom: 1px solid #3e3d3d;
}

.editor-modal .modal-content .modal-body {
    padding: 10px 10px 10px 10px;
}

.editor-modal .modal-content .modal-body button {
    background: #3e3d3d;
}

// removed textarea and select
.editor-modal .modal-content .modal-body input {
    background: #3e3d3d;
    border: 0;
    outline: none;
    padding: 5px;
    color: #fff;
    width: 100%;
}

/*editable css */
.editor_parent {
    text-decoration: none;
    border-bottom: dashed 1px #0088cc;
    color: #0088cc;
    position: relative;
}

.editor_parent .editor_inline {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    background-color: #fff;
    z-index: 10000;
}

.editor_parent .editor_inline button {
    margin-left: 5px;
}

.editor_inline select {
    padding: 0px 10px;
    font-size: 12px;
    height: 30px;
    width: auto;
}

.editor_inline input {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.editor_inline input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.pagebreak {
    height: 20px;
    background: #242323;
    /* margin-top: 96px;
    margin-bottom: 96px; */
}

#dragger_elem {
    position: fixed;
    background: #fff;
    border: 1px solid #0067ff;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: move;
    transition: none;
    display: none;
}

#dragger_elem svg {
    position: absolute;
    left: 2px;
    top: 2px;
    font-size: 14px;
    color: #0c1fff;
}

td.table-selection {
    position: relative;
}

td.table-selection::after {
    // background-color: rgba(180,215,255,.35);
    border: solid 2px #9d9dff;
    bottom: -1px;
    content: "";
    left: -1px;
    mix-blend-mode: multiply;
    position: absolute;
    right: -1px;
    top: -1px;
}

.placeholder-custom {
    border: 1px dashed #71671c;
    padding: 10px 20px;
}

.custom-class-backColor {
    z-index: 10000000 !important;
}
</style>
