import VueMultiselect from "@/components/vue-multiselect";
import {mapGetters} from "vuex";
import axios from "@/axios/index.js";
import nLoader from "@/components/n-loader";

export default {
    name: "scoreboard",
    components: {
        VueMultiselect,
        nLoader,
    },
    props: {
        allCaseUsers: {
            type: Array,
        },
    },
    data() {
        return {
            selected_user: null,
            scoreBoards: [],
            isLoading: false,
            oldComments: "",
            isLocked: false,
            isUserLoading: true,
            isSaving: false,
            version: "v1",
            total_score: 0,
            allowed_pointers: ["yes", "negligible", "drafted correctly", "0-5", "1", "not applicable"],
        };
    },
    computed: {
        ...mapGetters(["getCaseId", "getIsUserAdmin", "getUserAuthDetails", "getProduct"]),

        allCaseUserNames() {
            return this.allCaseUsers?.length > 0 ? [...this.allCaseUsers.map((e) => ({name: e.name, id: e.id}))] : [];
        },
        total_sum() {
            return this.scoreBoards.reduce((acc, curr) => acc + (curr.selected_pointer?.value || 0), 0);
        },
        isSuperVisor() {
            // let userRoles = this.getUserAuthDetails.userRole.split(",");
            // return userRoles.includes("Supervisor");
            return this.getIsUserAdmin;
        },

        isApplicableCol() {
            return this.scoreBoards.every((score) => score.applicability);
        },
    },
    mounted() {},
    watch: {
        allCaseUserNames(val) {
            if (val.length && this.selected_user === null) {
                this.selected_user = this.allCaseUserNames[0];
            }
        },
    },

    methods: {
        handleShowMore(index) {
            this.scoreBoards[index].showMore = this.scoreBoards[index].showMore === null ? true : !this.scoreBoards[index].showMore;
            this.$forceUpdate();
        },
        beforeOpen() {
            this.isUserLoading = false;
            this.selected_user = this.allCaseUserNames[0];
            this.fetchAnalystRecord(this.selected_user);
        },
        async fetchAnalystRecord(user) {
            this.isLocked = false;
            if (user) {
                this.isLoading = true;
                this.total_score = 0;
                if (this.getProduct && this.getProduct.key) {
                    try {
                        const {data} = await axios.get("score-board/product", {
                            params: {
                                case_id: this.getCaseId,
                                user: user.name,
                                product_id: this.getProduct.key,
                            },
                        });
                        this.total_score = data?.score_percentage?.toFixed(2);
                        let scoreboardsData = data.data;
                        let commentsRequired = [];
                        let commentsNotRequired = [];
                        scoreboardsData.map((el) => {
                            if (el.comment_required) {
                                commentsRequired.push(el);
                            } else {
                                commentsNotRequired.push(el);
                            }
                        });
                        this.scoreBoards = commentsRequired.concat(commentsNotRequired);
                        this.isLoading = false;
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (this.scoreBoards.length) {
                    this.scoreBoards = this.scoreBoards.map((ele) => ({
                        ...ele,
                        ...{
                            isError: false,
                            pointer_error: false,
                            selected_pointer: ele.pointers.find((ele1) => ele1.value === ele.selected_pointer && ele1.label === ele.selected_label) ?? ele.pointers[0],
                        },
                    }));
                    let notLocked = this.scoreBoards.find((x) => x.locked === false);
                    if (!notLocked) this.isLocked = true;

                    setTimeout(() => {
                        this.scoreBoards.forEach((score, idx) => {
                            this.getRowsCount(idx);
                        });
                    }, 10);
                }
                //  analyst can not edit their own pointers
                if (this.getUserAuthDetails.userId === user.id) {
                    this.scoreBoards = this.scoreBoards.map((el) => ({...el, locked: true}));
                    this.isLocked = true;
                }
            } else {
                if (this.allCaseUsers && this.allCaseUsers.length === 0 && this.selected_user === "") {
                    this.isUserLoading = true;
                } else {
                    this.$toast.warning("No analyst selected!");
                    this.scoreBoards = [];
                }
            }
        },

        async fetchAnalystDetail() {
            await this.fetchAnalystRecord(this.selected_user);
        },

        // Verifying and validate score conditons
        verifyScore() {
            this.scoreBoards = this.scoreBoards.map((ele) => {
                let json = ele;
                json.isError = false;
                json.pointer_error = false;
                /// Comments are mandatory for pointers selected 'No'
                if ((ele.selected_pointer && !this.allowed_pointers.includes(ele.selected_pointer.label.toLowerCase()) && ele.comments.trim() === "") || (ele.comment_required && ele.comments.trim() === "")) {
                    json.isError = true;
                }
                if (!ele.selected_pointer) json.pointer_error = true;
                return json;
            });
            // check if any errors exist or no pointers are selected
            const errors = this.scoreBoards.find((score) => score.isError || score.pointer_error);

            if (errors) {
                if (errors.pointer_error && !errors.isError) {
                    this.$toast.error("Please select all pointers before saving!");
                } else this.$toast.error("Please enter comments for required questions before saving!");
                this.$forceUpdate();
                return;
            } else this.handleSaveBtn();
        },
        handlePointer() {},

        showSaving() {
            this.isSaving = true;
            setTimeout(() => {
                this.isSaving = false;
            }, 1250);
        },

        handleSaveBtn() {
            this.isSuperVisor ? this.handleSaveScore(true) : this.$modal.show("confirm_score");
        },
        // save all scoreboards
        async handleSaveScore(locked) {
            let applicability_data = this.scoreBoards.map((el) => ({
                applicability_id: el._id.toString(),
                selected_pointer: el.selected_pointer ? el.selected_pointer.value : 0,
                selected_label: el.selected_pointer ? el.selected_pointer.label : "NA",
                comments: el.comments ? el.comments : "",
                score: el.score ? el.score : 0,
                locked: locked,
            }));
            const params = {
                case_id: this.getCaseId,
                user: this.selected_user.name,
                applicability_data: applicability_data,
                user_id: this.selected_user.id,
                locked: locked,
            };
            try {
                const resp = await axios.post("/score-board", params);
                if (resp.data && resp.data.status) {
                    if (locked) {
                        this.$toast.success("Saved successfully");
                    }
                    await this.fetchAnalystDetail();
                    this.scoreBoards = this.scoreBoards.map((el) => ({...el, locked: locked}));
                    this.isLocked = locked;
                }
                if (!locked) {
                    this.$toast.success("Saved as draft");
                }
            } catch (error) {
                this.$toast.error("Sorry! An error occurred");
                console.error(error);
            }
            this.$modal.hide("confirm_score");

            // }
        },
        // Save textarea notes
        async handleSaveNotes(id, index) {
            const obj = {
                case_id: this.getCaseId,
                user: this.selected_user.name,
                applicability_id: id.toString(),
                comments: this.scoreBoards[index].comments,
                score: this.scoreBoards[index].score ? this.scoreBoards[index].score : 0,
                selected_pointer: this.scoreBoards[index].selected_pointer ? this.scoreBoards[index].selected_pointer.value : 0,
                selected_label: this.scoreBoards[index].selected_pointer ? this.scoreBoards[index].selected_pointer.label : "NA",
            };
            this.scoreBoards = this.scoreBoards.map((ele) => {
                let json = ele;
                json.isError = false;
                json.pointer_error = false;
                /// Comments are mandatory for pointers selected 'No'
                if ((ele.selected_pointer && !this.allowed_pointers.includes(ele.selected_pointer.label.toLowerCase()) && ele.comments.trim() === "") || (ele.comment_required && ele.comments.trim() === "")) {
                    json.isError = true;
                }
                if (!ele.selected_pointer) json.pointer_error = true;
                return json;
            });
            const errors = this.scoreBoards.find((score) => score.isError || score.pointer_error);
            if (errors) this.$modal.hide("confirm_score");
            else {
                if (this.oldComments != this.scoreBoards[index].comments.trim()) {
                    await axios
                        .put(`/score-board`, obj)
                        .then(() => this.showSaving())
                        .catch((err) => this.$toast.error("Comment update failed"));
                    this.oldComments = "";
                }
            }
        },

        // Save Pointer
        async handlePointer(id, index) {
            const obj = {
                case_id: this.getCaseId,
                user: this.selected_user.name,
                applicability_id: id.toString(),
                comments: this.scoreBoards[index].comments,
                score: this.scoreBoards[index].score ? this.scoreBoards[index].score : 0,
                selected_pointer: this.scoreBoards[index].selected_pointer ? this.scoreBoards[index].selected_pointer.value : 0,
                selected_label: this.scoreBoards[index].selected_pointer ? this.scoreBoards[index].selected_pointer.label : "NA",
            };
            await axios
                .put(`/score-board`, obj)
                .then(() => this.showSaving())
                .catch((err) => this.$toast.error("Pointer update failed"));
        },

        getRowsCount(idx) {
            // const textarea = document.getElementById(`comment-${idx}`);
            // if (textarea) {
            //     const rows = Math.round(textarea.parentElement.offsetHeight / 16);
            //     textarea.rows = rows >= 3 ? rows : 3;
            // }
        },
    },
};
