export const RESEARCH_DATA = {
    case_data: {
        _id: "ORCA-8371b816404d",
        run_number: 2,
        emails: [
            {
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: true,
                deleted: false,
                enabled: true,
                analyst_added: false,
                run_number: 1,
                continuous_monitoring: false,
                email: "john@gmail.com",
            },
            {
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: true,
                deleted: false,
                enabled: true,
                analyst_added: false,
                run_number: 1,
                continuous_monitoring: false,
                email: "john@smith.com",
            },
        ],
        search_engine_results: [
            {
                _id: "c516c833-9846-4669-9d95-b8e08b7dd9f1",
                position: 1,
                title: "Kunal Aggarwal - Software Engineer - Google | LinkedIn",
                link: "https://www.linkedin.com/in/kunal-aggarwal-98325030",
                displayed_link: "www.linkedin.com › kunal-aggarwal-98325030",
                snippet: "View Kunal Aggarwal's profile on LinkedIn, the world's largest professional community. Kunal has 8 jobs listed on their profile. See the complete profile on ...",
                related_pages_link: "https://www.google.com/search?q=related:https://www.linkedin.com/in/kunal-aggarwal-98325030+kunal+aggarwal&sa=X&ved=2ahUKEwirl8mztvrrAhVTo54KHRriAbQQHzAAegQIAxAG",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 1,
                continous_monitoring: false,
                status: "POTENTIAL",
                is_profile: true,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 1,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "8b130179-c182-44b3-9840-a3ce40b06802",
                position: 2,
                title: "Kunal Aggarwal - Business Operations Analyst - Episource ...",
                link: "https://www.linkedin.com/in/kunal-aggarwal-715687a2",
                displayed_link: "www.linkedin.com › kunal-aggarwal-715687a2",
                snippet: "Kunal Aggarwal. Director of Clinical Research Programs at The Youth Movement Against Alzheimer's. Episource LLCUniversity of California, Los Angeles.",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 2,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 2,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "6f046c8c-1d66-4112-bef6-ee5d75aa83d9",
                position: 3,
                title: "Kunal Aggarwal - Black Hat USA 2019",
                link: "https://www.blackhat.com/us-19/presenters/Kunal-Aggarwal.html",
                displayed_link: "www.blackhat.com › presenters › Kunal-Aggarwal",
                snippet: "Kunal Aggarwal is Platform Lead at Neotas Limited and has been a Security Researcher and a full stack developer for 6+ years. He also leads the dataShark ...",
                cached_page_link: "https://webcache.googleusercontent.com/search?q=cache:6B3Eg1cpkoQJ:https://www.blackhat.com/us-19/presenters/Kunal-Aggarwal.html+&cd=3&hl=en&ct=clnk&gl=us",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 3,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 3,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "9f2e57fc-e6f7-40b4-ba17-455dbbf581b1",
                position: 4,
                title: "Kunal Aggarwal (@aggkunal) | Twitter",
                link: "https://twitter.com/aggkunal?lang=en",
                displayed_link: "twitter.com › aggkunal",
                snippet: "The latest Tweets from Kunal Aggarwal (@aggkunal). OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor ...",
                cached_page_link: "https://webcache.googleusercontent.com/search?q=cache:UkXNZ3KNMvMJ:https://twitter.com/aggkunal%3Flang%3Den+&cd=4&hl=en&ct=clnk&gl=us",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 4,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 4,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "1f95acec-ebdc-4152-8274-05a19ee2a4de",
                position: 5,
                title: "Kunal Aggarwal - Quora",
                link: "https://www.quora.com/profile/Kunal-Aggarwal",
                displayed_link: "www.quora.com › profile › Kunal-Aggarwal",
                snippet: "Kunal Aggarwal, IRS IT 2019 | IIT Hyderabad | Worked at Goldman Sachs. Computer Science graduate, passionate about bringing an idealistic change in this ...",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 5,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 5,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "be5c06bd-cfed-4176-aa11-9c0b39283262",
                position: 6,
                title: "Kunal Agarwal - CEO & Co-Founder @ Unravel Data ...",
                link: "https://www.crunchbase.com/person/kunal-agarwal-2",
                displayed_link: "www.crunchbase.com › person › kunal-agarwal-2",
                snippet: "Kunal Agarwal · Overview · Jobs · Board and Advisor Roles · Related Hubs · Education · Recent News and Activity · Discover even more companies with Crunchbase ...",
                cached_page_link: "https://webcache.googleusercontent.com/search?q=cache:cCKntz5nUsUJ:https://www.crunchbase.com/person/kunal-agarwal-2+&cd=6&hl=en&ct=clnk&gl=us",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 6,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 6,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "6dc3438e-a377-4777-8386-1784856c15d3",
                position: 7,
                title: "Kunal Aggarwal (@ggrwlknl) • Instagram photos and videos",
                link: "https://www.instagram.com/ggrwlknl/?hl=en",
                displayed_link: "www.instagram.com › ggrwlknl",
                snippet: "Kunal Aggarwal. Indian Revenue Services (IT) 2019 batch. IIT Hyderabad 2009-13 batch. Worked at Goldman Sachs as Tech Analyst Foodie and extrovert 🥗 ...",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 7,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 7,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "f6ad310f-eb70-40ff-bea1-00e55c059423",
                position: 8,
                title: "‪Kunal Aggarwal‬ - ‪Google Scholar‬",
                link: "http://scholar.google.com/citations?user=VmukBeYAAAAJ&hl=en",
                displayed_link: "scholar.google.com › citations",
                snippet: "Quantitative analysis of protein expression using amine‐specific isobaric tags in Escherichia coli cells expressing rhsA elements. K Aggarwal, LH Choe, KH Lee.",
                cached_page_link: "http://webcache.googleusercontent.com/search?q=cache:YAq_oK4iAYcJ:scholar.google.com/citations%3Fuser%3DVmukBeYAAAAJ%26hl%3Den+&cd=24&hl=en&ct=clnk&gl=us",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 8,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "google",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 8,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "276065ee-bda1-4052-a193-897c24e1b388",
                position: 1,
                title: "Kunal Aggarwal - Founder, CEO - Credflow | LinkedIn",
                link: "https://in.linkedin.com/in/kunal-aggarwal",
                displayed_link: "https://in.linkedin.com/in/kunal-aggarwal",
                rich_snippet: {
                    extensions: ["Title: Unlocking Cashflows for SMEs | IIT …", "Location: South Delhi, Delhi, India", "500+ connections"],
                },
                snippet: "View Kunal Aggarwal’s profile on LinkedIn, the world's largest professional community. Kunal has 6 jobs listed on their profile. See the complete profile on LinkedIn and discover Kunal’s connections and jobs at similar companies.",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 3,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "bing",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 1,
                        dt: "2020-09-23 16:41:56",
                    },
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 3,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "59c5b21b-45bb-4c5e-9253-8b0d88ad8f6f",
                position: 2,
                title: "Kunal Aggarwal - Head, Technical R&D Programs - GSK | LinkedIn",
                link: "https://www.linkedin.com/in/kunal-aggarwal-934aa17",
                displayed_link: "https://www.linkedin.com/in/kunal-aggarwal-934aa17",
                rich_snippet: {
                    extensions: ["Title: Head, Technical R&D Programs at …", "Location: Rockville, Maryland", "Connections: 459"],
                },
                snippet: "View Kunal Aggarwal’s profile on LinkedIn, the world's largest professional community. Kunal has 3 jobs listed on their profile. See the complete profile on LinkedIn and discover Kunal’s ...",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 2,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "bing",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 2,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "4a380191-4102-4f98-a8db-2fa58a1cf7e1",
                position: 3,
                title: "Dr. Kunal Agarwal, Family Medicine Doctor in Seaford, DE ...",
                link: "https://health.usnews.com/doctors/kunal-agarwal-48549",
                displayed_link: "https://health.usnews.com/doctors/kunal-agarwal-48549",
                rich_snippet: {
                    extensions: ["Location: 100 Rawlins Drive, Seaford, 19973, DE", "Phone: (302) 629-4240"],
                },
                snippet: "Dr. Kunal Agarwal is a Family Medicine Doctor in Seaford, DE. Find Dr. Agarwal's phone number, address and more.",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 5,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "bing",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 3,
                        dt: "2020-09-23 16:41:56",
                    },
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 5,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "ebf7541f-4803-4ca2-a552-d51636d0d4c9",
                position: 4,
                title: "Kunal Agarwal - Co-founder and CEO - Unravel Data | LinkedIn",
                link: "https://www.linkedin.com/in/kunalkunal",
                displayed_link: "https://www.linkedin.com/in/kunalkunal",
                rich_snippet: {
                    extensions: ["Title: simplifying big data operations", "Location: Palo Alto, California", "500+ connections"],
                },
                snippet: "View Kunal Agarwal’s profile on LinkedIn, the world's largest professional community. Kunal has 1 job listed on their profile. See the complete profile on LinkedIn and discover Kunal’s ...",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 9,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "bing",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 4,
                        dt: "2020-09-23 16:41:56",
                    },
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 9,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "7be98ae6-ff01-4685-b68f-2af6c55d0753",
                position: 1,
                title: "Kunal Aggarwal",
                link: "https://kunalaggarwal.co.in/",
                displayed_link: "kunalaggarwal.co.in",
                snippet: "Kunal Aggarwal. New Delhi, India. web@kunalaggarwal.co.in +91 7703 889 617. Blog; Powered by WordPress | Theme: Astrid by aThemes. This website uses cookies to ...",
                cached_page_link: "https://cc.bingj.com/cache.aspx?q=kunal+aggarwal&d=4812811557349897&mkt=en-US&setlang=en-US&w=R3aJyMNGWGiMnWTDeg3sdCoN2qQauGjf",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 1,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 1,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "2a1d484c-4a84-47a7-be28-badcd385321a",
                position: 2,
                title: "Kunal Agarwal",
                link: "http://kunal-agarwal.com/",
                displayed_link: "kunal-agarwal.com",
                snippet: "I'm Kunal Agarwal, a student at UC Berkeley born and raised in the Bay Area looking for an internship opportunity for next summer. I am double majoring in Computer Science and Applied Mathematics. Last summer I interned at Lawrence Berkeley National Laboratory in the Scientific Data Management Research Group.",
                cached_page_link: "https://cc.bingj.com/cache.aspx?q=kunal+aggarwal&d=4982355392273390&mkt=en-US&setlang=en-US&w=MA2u_Y0qZl3Zttrfxi5jiHE8CZD5ZsHo",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 2,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 2,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "7fdf633e-6ef3-4d80-a2e0-9e3661a415b8",
                position: 4,
                title: "Kunal Aggarwal - Regional Sales Manager - Dell Technologies ...",
                link: "https://in.linkedin.com/in/kunalaggarwal",
                displayed_link: "in.linkedin.com/in/kunalaggarwal",
                snippet: "View Kunal Aggarwal’s profile on LinkedIn, the world's largest professional community. Kunal has 5 jobs listed on their profile. See the complete profile on LinkedIn and discover Kunal’s connections and jobs at similar companies.",
                rich_snippet: {
                    extensions: {
                        location: "Gurgaon, Haryana, India",
                    },
                },
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 4,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 4,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "c4f6c551-f2e2-4923-9e1a-5b98dd10adae",
                position: 6,
                title: '100+ "Kunal Aggarwal" profiles | LinkedIn',
                link: "https://www.linkedin.com/pub/dir/kunal/aggarwal",
                displayed_link: "www.linkedin.com/pub/dir/kunal/aggarwal",
                snippet: 'View the profiles of professionals named "Kunal Aggarwal" on LinkedIn. There are 100+ professionals named "Kunal Aggarwal", who use LinkedIn to exchange information, ideas, and opportunities.',
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 6,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 6,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "68ee5af7-afea-4e82-b380-ac31219f250a",
                position: 7,
                title: "Kunal's Blog",
                link: "http://www.kunalaggarwal.in/",
                displayed_link: "www.kunalaggarwal.in",
                snippet: "Aug 08, 2020 · Shadowbanning is the act of blocking a user’s content on social media sites, in such a way that the user doesn’t know it’s happening. If you’re shadowbanned on Instagram, your content won’t appear on anyone’s feed unless they already follow you and it becomes almost impossible to increase your instagram followers count for 3-4 months atleast.",
                cached_page_link: "https://cc.bingj.com/cache.aspx?q=kunal+aggarwal&d=4915791991348186&mkt=en-US&setlang=en-US&w=OPvhe3HWWiK7cfHCr2TanaH1A3OICooX",
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 7,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 7,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "a041c5a6-701f-4eb8-821b-9ace7a2cba84",
                position: 8,
                title: "Kunal Aggarwal - Executive Assistant Finance - 361 degrees ...",
                link: "https://au.linkedin.com/in/kunal-aggarwal-51079514b",
                displayed_link: "au.linkedin.com/in/kunal-aggarwal-51079514b",
                snippet: "View Kunal Aggarwal’s profile on LinkedIn, the world's largest professional community. Kunal has 5 jobs listed on their profile. See the complete profile on LinkedIn and discover Kunal’s connections and jobs at similar companies.",
                rich_snippet: {
                    extensions: {
                        location: "Melbourne, Australia",
                    },
                },
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 8,
                continous_monitoring: false,
                status: null,
                is_profile: false,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 8,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
            {
                _id: "ef01a0d3-b39d-4410-a041-fa1485ac0dca",
                position: 10,
                title: "Kunal Agarwal - Senior Director of Product Management ...",
                link: "https://www.linkedin.com/in/kunala",
                displayed_link: "www.linkedin.com/in/kunala",
                snippet: "Kunal Agarwal Principal at I Squared Capital, I Squared India Advisors Pvt. Ltd.",
                rich_snippet: {
                    extensions: {
                        location: "San Jose, California",
                    },
                },
                case_id: "ORCA-111111111111",
                editMode: false,
                deleted: false,
                enabled: true,
                last_position: 10,
                continous_monitoring: false,
                status: null,
                is_profile: true,
                search_source: [
                    {
                        source: "yahoo",
                        search_query: "kunal aggarwal",
                        job_id: "038bb939-a5ab-4efb-a213-e9c75073f3d4",
                        position: 10,
                        dt: "2020-09-23 16:41:56",
                    },
                ],
            },
        ],
        personal_details: {
            full_name: "Thomas Blake",
            age: 25,
            dob: "1988-01-01",
            other_names: "John Matt",
            extras: {
                education: [
                    {
                        institution: "Harvard University",
                        subject: "BS Physics",
                        duration: {
                            from: 2002,
                            to: 2005,
                        },
                    },
                    {
                        institution: "Fordham University",
                        subject: "MS Physics",
                        duration: {
                            from: 2005,
                            to: 2007,
                        },
                    },
                    {
                        institution: "University of California, Berkeley",
                        subject: "PhD. Physics",
                        duration: {
                            from: 2007,
                            to: "Present",
                        },
                    },
                ],
                employment: [
                    {
                        institution: "City College NY",
                        role: "Teaching Faculty",
                        duration: {
                            from: 2012,
                            to: 2015,
                        },
                    },
                    {
                        institution: "Oxford University",
                        role: "Research Assistannt",
                        duration: {
                            from: 2015,
                            to: 20017,
                        },
                    },
                    {
                        institution: "Webb Institute",
                        role: "Research Guide",
                        duration: {
                            from: 2017,
                            to: "Present",
                        },
                    },
                ],
            },
        },
        associated_people: [
            {
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: false,
                deleted: false,
                enabled: true,
                analyst_added: true,
                run_number: 1,
                continuous_monitoring: false,
                name: "Evan Johnson",
                relation: "Co-Worker",
                designation: "CEO-Blake FinCorp",
            },
            {
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: false,
                deleted: false,
                enabled: true,
                analyst_added: true,
                run_number: 1,
                continuous_monitoring: false,
                name: "Samantha Smith",
                relation: "Wife",
                designation: "CFO-Blake FinCorp",
            },
        ],
        websites: [
            {
                website: "https://www.linkedin.com/school/university-of-manchester/",
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: false,
                deleted: true,
                enabled: false,
                analyst_added: false,
                run_number: 1,
                continuous_monitoring: false,
                email: "john@gmail.com",
                title: "Facebook",
            },
            {
                website: "https://www.linkedin.com/edu/the-university-of-manchester-12671",
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: false,
                deleted: true,
                enabled: false,
                analyst_added: false,
                run_number: 1,
                continuous_monitoring: false,
                email: "john@gmail.com",
                title: "Linked In",
            },
            {
                website: "https://www.linkedin.com/company/clifford-chance-llp",
                osint_run: false,
                osint_found: true,
                editMode: false,
                client_provided: false,
                deleted: true,
                enabled: false,
                analyst_added: false,
                run_number: 1,
                continuous_monitoring: false,
                email: "john@gmail.com",
                title: "Linked In",
            },
        ],
        dark_web: [
            {
                _id: "690d7e7b-e07c-49e4-b0a3-15795a282780",
                email: "john@gmail.com",
                source: "Facebook",
                editMode: false,
                deleted: false,
                breach_data: ["Username", "Password"],
            },
            {
                _id: "0d5ca57c-ffd8-482b-9ee2-3e4ff615ee38",
                email: "john@gmail.com",
                source: "LinkedIn",
                editMode: false,
                deleted: false,
                breach_data: ["IP Address", "Username"],
            },
        ],

        media: [
            /*
      {
        username: "antonieth",
        nlp_analysis: {
          nlp_model: {},
          watson: {}
        },
        screenshot: {
          available: true,
          file_name: "image.png",
          file_mime_type: "image/png",
          location: "44b702a2-9a1d-4e18-8a8f-c8f6f35e1829.png",
          size: 644426
        },
        language: {},
        platform: "twitter",
        deleted: false,
        processing: false,
        content: "",
        tweet_id: "",
        posting_date: "2015-06-05",
        hidden: false,
        metadata: {
          retweet: false
        },
        image_caption: "Subject shared post",
        categories: ["Inappropriate / undesirable content"],
        editMode: false
      },
      {
        username: "antonieth.c",
        nlp_analysis: {
          nlp_model: {},
          watson: {}
        },
        screenshot: {
          available: true,
          file_name: "MicrosoftTeams-image (1).png",
          file_mime_type: "image/png",
          location: "66afb4c9-a373-4db2-b10d-4b642fd03606.png",
          size: 722631
        },
        language: {},
        tweet_id: "",
        deleted: false,
        processing: false,
        editMode: false,
        content: "",
        platform: "instagram",
        posting_date: "",
        hidden: false,
        image_caption: "Subject shared post",
        categories: ["Sexually explicit content"],
        metadata: {
          retweet: false
        }
      },
      {
        username: "antonieth.c",
        nlp_analysis: {
          nlp_model: {},
          watson: {}
        },
        screenshot: {
          available: true,
          file_name: "MicrosoftTeams-image (2).png",
          file_mime_type: "image/png",
          location: "c6e996ee-7313-47f2-a6f6-e4c4e8af72d8.png",
          size: 840370
        },
        language: {},
        tweet_id: "",
        deleted: false,
        processing: false,
        editMode: false,
        content: "",
        platform: "instagram",
        posting_date: "",
        hidden: false,
        image_caption: "Subject shared post",
        categories: ["Sexually explicit content"],
        metadata: {
          retweet: false
        }
      },
      {
        username: "antonieth.c",
        nlp_analysis: {
          nlp_model: {},
          watson: {}
        },
        screenshot: {
          available: true,
          file_name: "MicrosoftTeams-image (3).png",
          file_mime_type: "image/png",
          location: "1e2de491-2921-4d6a-91cc-a1cf71c012b3.png",
          size: 257054
        },
        language: {},
        tweet_id: "",
        deleted: false,
        processing: false,
        editMode: false,
        content: "",
        platform: "instagram",
        posting_date: "",
        hidden: false,
        image_caption: "Subject shared post",
        categories: ["Violent content"],
        metadata: {
          retweet: false
        }
      }*/
        ],

        volunteering_roles: [
            {
                osint_found: false,
                editMode: false,
                client_provided: false,
                deleted: false,
                enabled: true,
                value: "https://google.cm",
                analyst_added: true,
                continuous_monitoring: false,
                status: null,
            },
        ],
        wider_evidence_skills: [
            {
                osint_found: false,
                editMode: false,
                client_provided: false,
                deleted: false,
                enabled: true,
                value: "https://neotas.com",
                analyst_added: true,
                continuous_monitoring: false,
                status: null,
            },
        ],
        charitable_work: [
            {
                osint_found: false,
                editMode: false,
                client_provided: false,
                deleted: false,
                enabled: true,
                value: "https://google.com",
                analyst_added: true,
                continuous_monitoring: false,
                status: null,
            },
        ],
        social_profiles: [
            {
                username: "aggkunal",
                osint_found: false,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "Values Draft 1.png",
                    file_mime_type: "image/png",
                    location: "d127dd7c-900b-4c77-b74c-fd5c41287a17.png",
                    size: 1150500,
                },
                platform: "twitter",
                deleted: false,
                enabled: true,
                analyst_added: false,
                run_number: 1,
                continuous_monitoring: false,
                client_provided: true,
                nlp_run: false,
                status: null,
            },
            {
                username: "kunalaggarwal92",
                osint_found: true,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-20 at 12.47.11 AM.png",
                    file_mime_type: "image/png",
                    location: "278c5659-1dd3-4971-b59d-b33f1d1ea84f.png",
                    size: 561724,
                },
                platform: "linkedin",
                deleted: false,
                run_number: 1,
                enabled: true,
                analyst_added: false,
                nlp_run: false,
                continuous_monitoring: false,
                client_provided: false,
                email: "aggkunal@gmail.com",
                status: "CONFIRMED",
            },
            {
                username: "aggkunal",
                osint_found: true,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "image.png",
                    file_mime_type: "image/png",
                    location: "3a129ca2-7855-4a11-81d3-d8d4cd4866f6.png",
                    size: 1234,
                },
                platform: "youtube",
                deleted: false,
                run_number: 1,
                enabled: true,
                analyst_added: false,
                nlp_run: false,
                continuous_monitoring: false,
                client_provided: false,
                email: "aggkunal@gmail.com",
                status: "CONFIRMED",
            },
            {
                username: "kunalaggarwal",
                osint_found: true,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "image.png",
                    file_mime_type: "image/png",
                    location: "289012e2-2f94-45d2-9671-33b8262c2717.png",
                    size: 25763,
                },
                platform: "github",
                deleted: false,
                run_number: 1,
                enabled: true,
                analyst_added: false,
                nlp_run: false,
                continuous_monitoring: false,
                client_provided: false,
                email: "aggkunal@gmail.com",
                status: "CONFIRMED",
            },
            {
                username: "188932268@N05",
                osint_found: true,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-20 at 1.07.03 AM.png",
                    file_mime_type: "image/png",
                    location: "fde18651-52c3-4b0a-97ca-7ffdf3993863.png",
                    size: 533243,
                },
                platform: "flickr",
                deleted: false,
                run_number: 1,
                enabled: true,
                analyst_added: false,
                nlp_run: false,
                continuous_monitoring: false,
                client_provided: false,
                email: "aggkunal@gmail.com",
                status: "POTENTIAL",
            },
            {
                username: "kunalaggarwal24",
                osint_found: true,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "image.png",
                    file_mime_type: "image/png",
                    location: "71c3c40b-38d7-44e6-bebb-c673aac72b7f.png",
                    size: 581488,
                },
                platform: "skype",
                deleted: false,
                run_number: 1,
                enabled: true,
                analyst_added: false,
                nlp_run: false,
                continuous_monitoring: false,
                client_provided: false,
                email: "aggkunal@gmail.com",
                status: "POTENTIAL",
            },
            {
                username: "105381510498926953285",
                osint_found: true,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "image.png",
                    file_mime_type: "image/png",
                    location: "b29ea0cf-5c6c-4b6e-8c3c-4a81382bdd38.png",
                    size: 586662,
                },
                platform: "googleid",
                deleted: false,
                run_number: 1,
                enabled: true,
                analyst_added: false,
                nlp_run: false,
                continuous_monitoring: false,
                client_provided: false,
                email: "aggkunal@gmail.com",
                status: "POTENTIAL",
            },
            {
                username: "aggkunal92",
                osint_found: false,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-28 at 12.22.42 PM.png",
                    file_mime_type: "image/png",
                    location: "eb6d5768-2170-40b8-a9cb-ba40733a03ee.png",
                    size: 208317,
                },
                platform: "twitter",
                deleted: false,
                enabled: true,
                analyst_added: true,
                run_number: 2,
                continuous_monitoring: false,
                client_provided: false,
                nlp_run: false,
                status: "POTENTIAL",
            },
            {
                username: "kunalagg",
                run_number: 9999,
                new_item: false,
                client_provided: true,
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-20 at 12.46.27 AM.png",
                    file_mime_type: "image/png",
                    location: "f928427a-7850-4a7a-92a6-34a934e24196.png",
                    size: 234178,
                },
                deleted: false,
                enabled: true,
                analyst_added: true,
                platform: "facebook",
                osint_found: false,
                continuous_monitoring: false,
                nlp_run: false,
                editMode: false,
                status: "POTENTIAL",
            },
            {
                username: "https://google.com",
                run_number: 9999,
                new_item: false,
                client_provided: false,
                screenshot: {
                    available: false,
                },
                deleted: false,
                enabled: true,
                analyst_added: true,
                platform: "other",
                osint_found: false,
                continuous_monitoring: false,
                nlp_run: false,
                editMode: false,
                status: "CONFIRMED",
            },
            {
                username: "https://ngrok.com/docs",
                run_number: 9999,
                new_item: false,
                editMode: false,
                screenshot: {
                    available: false,
                },
                deleted: false,
                enabled: true,
                analyst_added: true,
                platform: "website",
                osint_found: false,
                continuous_monitoring: false,
                client_provided: false,
                nlp_run: false,
                status: null,
            },
        ],
        case_id: "ORCA-8371b816404d",
        includeTranslationInReport: true,
        extra_content: [
            {
                username: "kunalagg",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: true,
                    file_name: "fee18481df61ce4220956dc8f44d09e8.jpg",
                    file_mime_type: "image/jpeg",
                    location: "383b26f6-af56-4c2d-837f-d2c8adc92031.jpg",
                    size: 112273,
                },
                language: {},
                platform: "facebook",
                deleted: false,
                processing: false,
                content: "",
                tweet_id: "",
                posting_date: "2020-07-28",
                hidden: false,
                metadata: {
                    retweet: false,
                },
                image_caption: "Subject shared post",
                categories: ["Potential addiction or substance abuse"],
                editMode: false,
            },
            {
                username: "https://google.com",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-20 at 12.47.11 AM.png",
                    file_mime_type: "image/png",
                    location: "e491b63c-9583-42a4-9ed7-ebf1b7c1d0de.png",
                    size: 561724,
                },
                language: {},
                platform: "other",
                deleted: false,
                processing: false,
                content: "",
                tweet_id: "",
                posting_date: "2016-08-10",
                hidden: false,
                metadata: {
                    retweet: false,
                },
                image_caption: "Subject's bio",
                categories: ["Hate and discriminatory behaviour"],
                editMode: false,
            },
            {
                username: "https://google.com",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: false,
                },
                language: {},
                platform: "other",
                deleted: true,
                processing: false,
                content: "ghjfghj",
                tweet_id: "",
                posting_date: "",
                hidden: false,
                metadata: {
                    retweet: false,
                },
                image_caption: "Subject shared post",
                categories: ["Hate and discriminatory behaviour"],
                editMode: false,
            },
            {
                username: "105381510498926953285",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: false,
                },
                language: {},
                platform: "googleid",
                deleted: true,
                processing: false,
                content: "sdfgsdfgsdgh",
                tweet_id: "",
                posting_date: "2020-08-11",
                hidden: false,
                metadata: {
                    retweet: false,
                },
                image_caption: "Subject shared post",
                categories: ["Inappropriate / Undesirable Content"],
                editMode: false,
            },
            {
                username: "kunalagg",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: false,
                },
                language: {},
                platform: "facebook",
                deleted: true,
                processing: false,
                content: "",
                tweet_id: "",
                posting_date: "",
                hidden: false,
                metadata: {
                    retweet: false,
                },
                image_caption: "Subject shared post",
                categories: [],
                editMode: false,
            },
            {
                username: "aggkunal92",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-07 at 7.13.59 PM.png",
                    file_mime_type: "image/png",
                    location: "06d54194-c50d-40ae-a562-7387caf8c66e.png",
                    size: 1248312,
                },
                language: {},
                platform: "twitter",
                deleted: false,
                processing: false,
                content: "",
                tweet_id: "",
                posting_date: "2020-08-03",
                hidden: false,
                metadata: {
                    retweet: false,
                },
                image_caption: "Subject shared post",
                categories: ["Sexually explicit content"],
                editMode: false,
            },
            {
                username: "kunalagg",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: true,
                    file_name: "image.png",
                    file_mime_type: "image/png",
                    location: "97880a48-65de-446b-8cfc-1d714e0df9ea.png",
                    size: 485995,
                },
                language: {},
                tweet_id: "",
                nlp_data_id: "7f92ed36-a027-4926-8ceb-39c01f3924e8",
                deleted: false,
                editMode: false,
                content: "",
                platform: "facebook",
                posting_date: "2020-02-17",
                hidden: false,
                image_caption: "Subject shared post",
                categories: ["Others"],
                metadata: {
                    retweet: false,
                },
            },
            {
                username: "kunalaggarwal92",
                nlp_analysis: {
                    nlp_model: {},
                    watson: {},
                },
                screenshot: {
                    available: true,
                    file_name: "Screenshot 2020-07-20 at 1.07.15 AM.png",
                    file_mime_type: "image/png",
                    location: "1855352e-38a5-4014-9403-c8cceb0db7d8.png",
                    size: 1765834,
                },
                language: {},
                tweet_id: "",
                deleted: false,
                processing: false,
                editMode: false,
                content: "",
                platform: "linkedin",
                posting_date: "2020-08-04",
                hidden: false,
                image_caption: "The subject appears to be slacking at work",
                categories: ["Illegal activities"],
                metadata: {
                    retweet: false,
                },
            },
        ],
        tweetNLPDataCounter: 2,
        placeholder_data: [],
    },
    profile_data: [
        {
            _id: "facebook||kunalagg",
            username: "kunalagg",
            uid: "facebook||kunalagg",
            platform: "facebook",
            case_id: [
                {
                    case: "ORCA-8371b816404d",
                    fetchdate: "2020-08-17 01:37:32",
                },
                {
                    case: "ORCA-8371b816404d",
                    fetchdate: "2020-08-17 01:44:54",
                },
            ],
            data: [
                {
                    dataid: "7f92ed36-a027-4926-8ceb-39c01f3924e8",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 619,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.741112,\n      "label": "/business and industrial/logistics"\n    }\n  ]\n}',
                    uid: "facebook||3392287894120669",
                    screenshot: {
                        available: true,
                        file_name: "image.png",
                        file_mime_type: "image/png",
                        location: "97880a48-65de-446b-8cfc-1d714e0df9ea.png",
                        size: 485995,
                    },
                    deleted: false,
                    content:
                        "It was only 3 and a half months back that we started our Neotas Limited India office with 8 employees. Back then we thought, we're good to go for the next year with these many people. 3 months later, we're hiring like crazy and now we're 20 people. We expanded our office in a span of 10 days. Special thanks to our current team for helping us with the whole process, we couldn't have done it without you guys! (Shivani Bahuguna, Manisha Choudhary Ekansh Kapoor, Nishant Razdan, Ashu Tosh Garg). These guys helped us with logistics, recruitment and everything else. #growing #newEmployees #excitement #india #team #work",
                    language: {
                        text_translated_edited:
                            "It was only 3 and a half months back that we started our Neotas Limited India office with 8 employees. Back then we thought, we're good to go for the next year with these many people. 3 months later, we're hiring like crazy and now we're 20 people. We expanded our office in a span of 10 days. Special thanks to our current team for helping us with the whole process, we couldn't have done it without you guys! (Shivani Bahuguna, Manisha Choudhary Ekansh Kapoor, Nishant Razdan, Ashu Tosh Garg). These guys helped us with logistics, recruitment and everything else. #growing #newEmployees #excitement #india #team #work",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 84,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1389,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated:
                            "It was only 3 and a half months back that we started our Neotas Limited India office with 8 employees. Back then we thought, we're good to go for the next year with these many people. 3 months later, we're hiring like crazy and now we're 20 people. We expanded our office in a span of 10 days. Special thanks to our current team for helping us with the whole process, we couldn't have done it without you guys! (Shivani Bahuguna, Manisha Choudhary Ekansh Kapoor, Nishant Razdan, Ashu Tosh Garg). These guys helped us with logistics, recruitment and everything else. #growing #newEmployees #excitement #india #team #work",
                    },
                    include_in_report: true,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3392287894120669",
                    flagged: true,
                    categories: {
                        qa: ["Others"],
                        system: ["Others"],
                        analyst: ["Others"],
                    },
                    metadata: {
                        created_at: "2020-02-17 11:54:21",
                        type: "Post",
                        id_str: "3392287894120669",
                    },
                },
                {
                    dataid: "984829d1-5749-4229-ab7a-4bf5b80bf739",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 249,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.893655,\n      "label": "/religion and spirituality/hinduism"\n    }\n  ]\n}',
                    uid: "facebook||3088594924489969",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Last 2 weeks have been a crazy journey, but the effort put in has been completely worth it. That experience of setting up a new office is amazing. After London, we're here now in the heart of India, Delhi. Welcome to Neotas Limited India. #NewOffice",
                    language: {
                        text_translated_edited: "Last 2 weeks have been a crazy journey, but the effort put in has been completely worth it. That experience of setting up a new office is amazing. After London, we're here now in the heart of India, Delhi. Welcome to Neotas Limited India. #NewOffice",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 99,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1228,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Last 2 weeks have been a crazy journey, but the effort put in has been completely worth it. That experience of setting up a new office is amazing. After London, we're here now in the heart of India, Delhi. Welcome to Neotas Limited India. #NewOffice",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3088594924489969",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-10-12 17:56:07",
                        type: "Post",
                        id_str: "3088594924489969",
                    },
                },
                {
                    dataid: "78b80bb5-e22b-40b4-9570-e2c08d68fce8",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 16,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.589331,\n      "label": "/family and parenting/babies and toddlers/baby clothes"\n    },\n    {\n      "score": 0.579774,\n      "label": "/sports/go kart"\n    },\n    {\n      "score": 0.560787,\n      "label": "/art and entertainment/shows and events"\n    }\n  ]\n}',
                    uid: "facebook||2987652044584258",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Time to go home!",
                    language: {
                        text_translated_edited: "Time to go home!",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1152,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Time to go home!",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2987652044584258",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-08-25 00:00:44",
                        type: "Post",
                        id_str: "2987652044584258",
                    },
                },
                {
                    dataid: "e2059438-aa39-4d70-971d-ad38a005af4a",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 17,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.596627,\n      "label": "/society/dating"\n    },\n    {\n      "score": 0.596388,\n      "label": "/sports/go kart"\n    },\n    {\n      "score": 0.561982,\n      "label": "/society/unrest and war"\n    }\n  ]\n}',
                    uid: "facebook||3392287894120669_3393400030676122",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Great going bro 👍",
                    language: {
                        text_translated_edited: "Great going bro 👍",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1152,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Great going bro 👍",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3392287894120669_3393400030676122",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-02-17 23:13:34",
                        type: "Comment",
                        id_str: "3392287894120669_3393400030676122",
                    },
                },
                {
                    dataid: "23e5bb48-ae38-49d8-adef-5e572a3c3f5f",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 18,\n    "features": 1\n  },\n  "language": "fr",\n  "categories": []\n}',
                    uid: "facebook||3392287894120669_3393496833999775",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Congratulations 🎊🎉",
                    language: {
                        text_translated_edited: "Congratulations 🎊🎉",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "zzp",
                                    name: "zzp",
                                    read_bytes: 640,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Congratulations 🎊🎉",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3392287894120669_3393496833999775",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-02-17 23:59:27",
                        type: "Comment",
                        id_str: "3392287894120669_3393496833999775",
                    },
                },
                {
                    dataid: "02162287-cbaa-4035-9007-5f698e5b588e",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 45,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.63105,\n      "label": "/automotive and vehicles/cars/car culture"\n    },\n    {\n      "score": 0.581207,\n      "label": "/automotive and vehicles/auto parts"\n    },\n    {\n      "score": 0.576742,\n      "label": "/art and entertainment/shows and events"\n    }\n  ]\n}',
                    uid: "facebook||3392287894120669_3394332807249511",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Keep Marching Ahead!! Best wishes Ladke !! 👍👏",
                    language: {
                        text_translated_edited: "Keep Marching Ahead!! Best wishes Ladke !! 👍👏",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 97,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 970,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Keep Marching Ahead!! Best wishes Ladke !! 👍👏",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3392287894120669_3394332807249511",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-02-18 06:24:25",
                        type: "Comment",
                        id_str: "3392287894120669_3394332807249511",
                    },
                },
                {
                    dataid: "358538aa-3d3a-4c8e-9e18-0b4d94805b02",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 15,\n    "features": 1\n  },\n  "language": "fr",\n  "categories": [\n    {\n      "score": 0.555925,\n      "label": "/law, govt and politics/politics/elections/presidential elections"\n    },\n    {\n      "score": 0.53549,\n      "label": "/sports/cricket"\n    },\n    {\n      "score": 0.531228,\n      "label": "/society/senior living"\n    }\n  ]\n}',
                    uid: "facebook||3392287894120669_3394798803869578",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Congratulations",
                    language: {
                        text_translated_edited: "Congratulations",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "zzp",
                                    name: "zzp",
                                    read_bytes: 640,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Congratulations",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3392287894120669_3394798803869578",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-02-18 11:53:07",
                        type: "Comment",
                        id_str: "3392287894120669_3394798803869578",
                    },
                },
                {
                    dataid: "c5ffecc9-cd03-48c8-a48c-1b1dcf0a3aeb",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 15,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.642812,\n      "label": "/pets/large animals"\n    },\n    {\n      "score": 0.591355,\n      "label": "/sports/bowling"\n    },\n    {\n      "score": 0.575447,\n      "label": "/sports/cricket"\n    }\n  ]\n}',
                    uid: "facebook||3308408169175309_3310075915675201",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Thanks Monty! 😊",
                    language: {
                        text_translated_edited: "Thanks Monty! 😊",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 92,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 866,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Thanks Monty! 😊",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3308408169175309_3310075915675201",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-01-12 12:31:03",
                        type: "Comment",
                        id_str: "3308408169175309_3310075915675201",
                    },
                },
                {
                    dataid: "f5a39e47-ef01-4348-827b-518018a73ba8",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 17,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.552702,\n      "label": "/sports/bowling"\n    },\n    {\n      "score": 0.552336,\n      "label": "/hobbies and interests/home recording"\n    },\n    {\n      "score": 0.545585,\n      "label": "/sports/cricket"\n    }\n  ]\n}',
                    uid: "facebook||3308197629196363_3310076259008500",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Thanks Abhishek 😊",
                    language: {
                        text_translated_edited: "Thanks Abhishek 😊",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 704,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Thanks Abhishek 😊",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3308197629196363_3310076259008500",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-01-12 12:31:21",
                        type: "Comment",
                        id_str: "3308197629196363_3310076259008500",
                    },
                },
                {
                    dataid: "240ecf4f-65a6-40bc-b2e0-8fa6c6672a68",
                    nlp_analysis: '{\n  "language": "so",\n  "error": "unsupported text language",\n  "code": 400\n}',
                    uid: "facebook||3307855485897244_3310076995675093",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Dhanyawaad janaab! 😃",
                    language: {
                        text_translated_edited: "Dhanyawaad janaab! 4",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "so",
                                    name: "Somali",
                                    read_bytes: 853,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Dhanyawaad janaab! 4",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3307855485897244_3310076995675093",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-01-12 12:31:57",
                        type: "Comment",
                        id_str: "3307855485897244_3310076995675093",
                    },
                },
                {
                    dataid: "c853f5ff-fc51-489a-9eef-24c0a3b35bd8",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 16,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.554359,\n      "label": "/sports/skateboarding"\n    },\n    {\n      "score": 0.535796,\n      "label": "/society/dating"\n    },\n    {\n      "score": 0.515877,\n      "label": "/shopping/gifts"\n    }\n  ]\n}',
                    uid: "facebook||3307762179239908_3310077439008382",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Thank you bro! 😃",
                    language: {
                        text_translated_edited: "Thank you bro! 😃",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 93,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1170,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Thank you bro! 😃",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3307762179239908_3310077439008382",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-01-12 12:32:19",
                        type: "Comment",
                        id_str: "3307762179239908_3310077439008382",
                    },
                },
                {
                    dataid: "8641b3f1-d940-49fe-83bd-ffeb1ca9a7ef",
                    nlp_analysis: '{\n  "language": "so",\n  "error": "unsupported text language",\n  "code": 400\n}',
                    uid: "facebook||3307760972573362_3310079532341506",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Dhanyawaad saheb! 😅",
                    language: {
                        text_translated_edited: "Dhanyawaad saheb! 4",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "so",
                                    name: "Somali",
                                    read_bytes: 722,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Dhanyawaad saheb! 4",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3307760972573362_3310079532341506",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2020-01-12 12:34:02",
                        type: "Comment",
                        id_str: "3307760972573362_3310079532341506",
                    },
                },
                {
                    dataid: "152a9534-02f7-47db-9e9b-61765e62242d",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 15,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.566043,\n      "label": "/society/social institution"\n    },\n    {\n      "score": 0.566043,\n      "label": "/society/social institution/marriage"\n    },\n    {\n      "score": 0.55779,\n      "label": "/travel/hotels"\n    }\n  ]\n}',
                    uid: "facebook||3097715313577930_3097736446909150",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "welcome welcome",
                    language: {
                        text_translated_edited: "welcome welcome",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 704,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "welcome welcome",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3097715313577930_3097736446909150",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-10-16 16:07:26",
                        type: "Comment",
                        id_str: "3097715313577930_3097736446909150",
                    },
                },
                {
                    dataid: "93f8b498-70df-4c51-9799-d8d801d98f92",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 36,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.548985,\n      "label": "/technology and computing/internet technology/social network"\n    },\n    {\n      "score": 0.536146,\n      "label": "/food and drink/food/fast food"\n    },\n    {\n      "score": 0.527563,\n      "label": "/law, govt and politics"\n    }\n  ]\n}',
                    uid: "facebook||3088594924489969_3090187080997420",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Congratulations! 🎉 Koun location ???",
                    language: {
                        text_translated_edited: "Congratulations! 🎉 Koun location ???",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 96,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 614,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Congratulations! 🎉 Koun location ???",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3088594924489969_3090187080997420",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-10-13 10:15:59",
                        type: "Comment",
                        id_str: "3088594924489969_3090187080997420",
                    },
                },
                {
                    dataid: "0444417e-2504-4a89-9101-29382253c63b",
                    nlp_analysis: '{\n  "error": "not enough text for language id",\n  "code": 422\n}',
                    uid: "facebook||3088594924489969_3180634651952662",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Karol Bagh",
                    language: {
                        text_translated_edited: "Karol Bagh",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 91,
                                    code: "tlh",
                                    name: "Klingon",
                                    read_bytes: 465,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Karol Bagh",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3088594924489969_3180634651952662",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-11-20 09:36:56",
                        type: "Comment",
                        id_str: "3088594924489969_3180634651952662",
                    },
                },
                {
                    dataid: "deedd990-b05a-4489-bdf4-d127acf1dfae",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 32,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.681694,\n      "label": "/hobbies and interests/games"\n    }\n  ]\n}',
                    uid: "facebook||3088594924489969_3090483504301111",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Congratulations and all the best",
                    language: {
                        text_translated_edited: "Congratulations and all the best",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 97,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1303,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Congratulations and all the best",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3088594924489969_3090483504301111",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-10-13 13:26:36",
                        type: "Comment",
                        id_str: "3088594924489969_3090483504301111",
                    },
                },
                {
                    dataid: "ada7d2f4-17ba-4e42-b2f1-ce6826da1729",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 19,\n    "features": 1\n  },\n  "language": "fr",\n  "categories": []\n}',
                    uid: "facebook||3088594924489969_3091528457529949",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Congratulations 🍾🎊🎈",
                    language: {
                        text_translated_edited: "Congratulations 🍾🎊🎈",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "zzp",
                                    name: "zzp",
                                    read_bytes: 640,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Congratulations 🍾🎊🎈",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||3088594924489969_3091528457529949",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-10-13 22:49:42",
                        type: "Comment",
                        id_str: "3088594924489969_3091528457529949",
                    },
                },
                {
                    dataid: "bf8f26b3-5e8a-40e9-a73e-3e91928ea2ae",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 14,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.588139,\n      "label": "/art and entertainment/shows and events/concert"\n    },\n    {\n      "score": 0.544064,\n      "label": "/art and entertainment/shows and events/classical concert"\n    },\n    {\n      "score": 0.524562,\n      "label": "/family and parenting/parenting teens"\n    }\n  ]\n}',
                    uid: "facebook||10158718712271124_10158723084881124",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Happy birthday",
                    language: {
                        text_translated_edited: "Happy birthday",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 93,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1228,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Happy birthday",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||10158718712271124_10158723084881124",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-09-02 04:16:17",
                        type: "Comment",
                        id_str: "10158718712271124_10158723084881124",
                    },
                },
                {
                    dataid: "6184e61a-a749-482f-a668-7312c189c573",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 22,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.60858,\n      "label": "/sports/hockey/field hockey"\n    },\n    {\n      "score": 0.600034,\n      "label": "/sports/badminton"\n    },\n    {\n      "score": 0.600006,\n      "label": "/sports/olympics"\n    }\n  ]\n}',
                    uid: "facebook||10158718712271124_10158733800271124",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Happy birthday Abhinav",
                    language: {
                        text_translated_edited: "Happy birthday Abhinav",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 95,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 801,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Happy birthday Abhinav",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||10158718712271124_10158733800271124",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-09-05 18:12:17",
                        type: "Comment",
                        id_str: "10158718712271124_10158733800271124",
                    },
                },
                {
                    dataid: "334ebedc-2fe1-4065-ae16-337b97018539",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 29,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.695441,\n      "label": "/society/social institution/divorce"\n    },\n    {\n      "score": 0.568858,\n      "label": "/travel/traveling with kids"\n    },\n    {\n      "score": 0.567161,\n      "label": "/family and parenting/babies and toddlers"\n    }\n  ]\n}',
                    uid: "facebook||10158718712271124_10158733841046124",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Wish you very happy B-Day sir",
                    language: {
                        text_translated_edited: "Wish you very happy B-Day sir",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 96,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1467,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Wish you very happy B-Day sir",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||10158718712271124_10158733841046124",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-09-05 18:32:22",
                        type: "Comment",
                        id_str: "10158718712271124_10158733841046124",
                    },
                },
                {
                    dataid: "e04e2f7c-a353-405c-97d2-b8321b3fc747",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 17,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.63355,\n      "label": "/art and entertainment/movies"\n    },\n    {\n      "score": 0.625893,\n      "label": "/art and entertainment/movies and tv/movies/reviews"\n    },\n    {\n      "score": 0.596671,\n      "label": "/art and entertainment/movies/film festivals and awards"\n    }\n  ]\n}',
                    uid: "facebook||10158718712271124_10158733861886124",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "All the very best",
                    language: {
                        text_translated_edited: "All the very best",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1763,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "All the very best",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||10158718712271124_10158733861886124",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-09-05 18:41:47",
                        type: "Comment",
                        id_str: "10158718712271124_10158733861886124",
                    },
                },
                {
                    dataid: "10fd326a-69e5-4ab2-8422-1795815724ff",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 28,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.632553,\n      "label": "/real estate/buying and selling homes"\n    }\n  ]\n}',
                    uid: "facebook||2987652044584258_2989370337745762",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "aao thakur!! Finally home :D",
                    language: {
                        text_translated_edited: "aao thakur!! Finally home :D",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 96,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 748,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "aao thakur!! Finally home :D",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2987652044584258_2989370337745762",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-08-25 20:41:24",
                        type: "Comment",
                        id_str: "2987652044584258_2989370337745762",
                    },
                },
                {
                    dataid: "82201b81-68d8-4bb1-9b17-59f571237cd5",
                    nlp_analysis: '{\n  "error": "not enough text for language id",\n  "code": 422\n}',
                    uid: "facebook||2949943485021781_2950007248348738",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Nyc kb?",
                    language: {
                        text_translated_edited: "Nyc kb?",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 87,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 877,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Nyc kb?",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2949943485021781_2950007248348738",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-08-05 19:13:00",
                        type: "Comment",
                        id_str: "2949943485021781_2950007248348738",
                    },
                },
                {
                    dataid: "2edfc1f2-a726-4108-a06b-f3c0dc4b96c2",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 19,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.726003,\n      "label": "/art and entertainment/comics and animation"\n    },\n    {\n      "score": 0.703674,\n      "label": "/art and entertainment/comics and animation/comics"\n    },\n    {\n      "score": 0.599205,\n      "label": "/art and entertainment/comics and animation/cartoons"\n    }\n  ]\n}',
                    uid: "facebook||2949943485021781_2950149711667825",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Vegas ... The Strip",
                    language: {
                        text_translated_edited: "Vegas ... The Strip",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 94,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1216,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Vegas ... The Strip",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2949943485021781_2950149711667825",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-08-05 20:42:00",
                        type: "Comment",
                        id_str: "2949943485021781_2950149711667825",
                    },
                },
                {
                    dataid: "f0f3d4aa-a853-43af-ba4e-e7f1568c57f5",
                    nlp_analysis: '{\n  "error": "not enough text for language id",\n  "code": 422\n}',
                    uid: "facebook||2949943485021781_2950259408323522",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Texas kab?",
                    language: {
                        text_translated_edited: "Texas kab?",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 90,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 512,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Texas kab?",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2949943485021781_2950259408323522",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-08-05 21:56:29",
                        type: "Comment",
                        id_str: "2949943485021781_2950259408323522",
                    },
                },
                {
                    dataid: "07d84fbf-41b9-475b-ba96-7907f8ce3e94",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 20,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.561069,\n      "label": "/society/sex"\n    },\n    {\n      "score": 0.546166,\n      "label": "/food and drink/food"\n    },\n    {\n      "score": 0.535546,\n      "label": "/law, govt and politics"\n    }\n  ]\n}',
                    uid: "facebook||2949943485021781_2952324244783705",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Enjoy kario full too",
                    language: {
                        text_translated_edited: "Enjoy kario full too",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 95,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 975,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Enjoy kario full too",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2949943485021781_2952324244783705",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-08-07 00:08:17",
                        type: "Comment",
                        id_str: "2949943485021781_2952324244783705",
                    },
                },
                {
                    dataid: "6da01155-66df-4b2a-af24-776eb9f8d08c",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 33,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.550245,\n      "label": "/travel/transports"\n    },\n    {\n      "score": 0.544151,\n      "label": "/sports/tennis"\n    },\n    {\n      "score": 0.543849,\n      "label": "/automotive and vehicles/electric vehicles"\n    }\n  ]\n}',
                    uid: "facebook||2919187001430763_2919231578092972",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Batao kahan milun, just reached 😀",
                    language: {
                        text_translated_edited: "Batao kahan milun, just reached 😀",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 96,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 627,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Batao kahan milun, just reached 😀",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2919187001430763_2919231578092972",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-07-20 18:48:19",
                        type: "Comment",
                        id_str: "2919187001430763_2919231578092972",
                    },
                },
                {
                    dataid: "0ee54ef1-5ad7-4b4b-afc3-a09c426f6cb6",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 26,\n    "features": 1\n  },\n  "language": "it",\n  "categories": [\n    {\n      "score": 0.535217,\n      "label": "/society/social institution"\n    },\n    {\n      "score": 0.535217,\n      "label": "/society/social institution/marriage"\n    },\n    {\n      "score": 0.528207,\n      "label": "/art and entertainment/movies"\n    }\n  ]\n}',
                    uid: "facebook||2919187001430763_2919237494759047",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Apni pic bhi lagao dono..",
                    language: {
                        text_translated_edited: "Apni pic bhi lagao dono ..",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 96,
                                    code: "gd",
                                    name: "Scottish Gaelic",
                                    read_bytes: 341,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Apni pic bhi lagao dono ..",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2919187001430763_2919237494759047",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-07-20 18:51:44",
                        type: "Comment",
                        id_str: "2919187001430763_2919237494759047",
                    },
                },
                {
                    dataid: "27d25594-96c7-46d3-b17e-6c45e5cbd1c0",
                    nlp_analysis: '{\n  "usage": {\n    "text_units": 1,\n    "text_characters": 63,\n    "features": 1\n  },\n  "language": "en",\n  "categories": [\n    {\n      "score": 0.578908,\n      "label": "/travel/traveling with kids"\n    },\n    {\n      "score": 0.567647,\n      "label": "/real estate/buying and selling homes"\n    },\n    {\n      "score": 0.550839,\n      "label": "/art and entertainment/shows and events"\n    }\n  ]\n}',
                    uid: "facebook||2919187001430763_2919551514727645",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Bromance at its best.. are you guys coming to Vegas next month?",
                    language: {
                        text_translated_edited: "Bromance at its best.. are you guys coming to Vegas next month?",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 98,
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1158,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Bromance at its best.. are you guys coming to Vegas next month?",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2919187001430763_2919551514727645",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-07-20 21:36:02",
                        type: "Comment",
                        id_str: "2919187001430763_2919551514727645",
                    },
                },
                {
                    dataid: "364ef957-22ec-41b4-b979-cb7ece85cccc",
                    nlp_analysis: '{\n  "language": "sr",\n  "error": "unsupported text language",\n  "code": 400\n}',
                    uid: "facebook||2919187001430763_2919988048017325",
                    screenshot: {
                        available: false,
                    },
                    deleted: false,
                    content: "Pura pro pic liye ho bhai. Kadak",
                    language: {
                        text_translated_edited: "Pura pro pic liye ho bhai. Kadak",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: 96,
                                    code: "cs",
                                    name: "Czech",
                                    read_bytes: 416,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: 0,
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Pura pro pic liye ho bhai. Kadak",
                    },
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "facebook||2919187001430763_2919988048017325",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "2019-07-21 02:35:49",
                        type: "Comment",
                        id_str: "2919187001430763_2919988048017325",
                    },
                },
            ],
        },
        {
            _id: "flickr||188932268@N05",
            username: "188932268@N05",
            uid: "flickr||188932268@N05",
            platform: "flickr",
            case_id: [
                {
                    case: "ORCA-9371b816404c",
                    startTweetID: "50187778101",
                    fetchdate: "2020-08-07 10:06:09",
                    endTweetID: "50188031687",
                },
                {
                    case: "ORCA-9371b816404c",
                    startTweetID: "50187778101",
                    fetchdate: "2020-08-07 12:21:28",
                    endTweetID: "50188031687",
                },
                {
                    case: "ORCA-8371b816404d",
                    startTweetID: "50187778101",
                    fetchdate: "2020-08-07 12:48:42",
                    endTweetID: "50188031687",
                },
                {
                    case: "ORCA-8371b816404d",
                    startTweetID: "50187778101",
                    fetchdate: "2020-08-10 05:38:48",
                    endTweetID: "50188031687",
                },
            ],
            data: [
                {
                    dataid: "982a89c56b9447dcb742a",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    screenshot: {
                        available: false,
                    },
                    uid: "flickr||1292393052661616641",
                    language: {
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "96.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 682,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated_edited: "@monzo No! 🤣 https://t.co/8pcqTU2IQa",
                        text_translated: "@monzo No No! 🤣 https://t.co/8pcqTU2IQa",
                    },
                    deleted: false,
                    content: "@monzo No! 🤣 https://t.co/8pcqTU2IQa",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "flickr||1292393052661616641",
                    flagged: true,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        created_at: "Sun Aug 09 09:31:29 +0000 2020",
                        type: "Post",
                        id_str: "1292393052661616641",
                    },
                },
            ],
        },
        {
            _id: "twitter||aggkunal",
            username: "aggkunal",
            uid: "twitter||aggkunal",
            platform: "twitter",
            case_id: [
                {
                    case: "ORCA-9371b816404c",
                    startTweetID: {
                        $numberLong: "258279052811579400",
                    },
                    fetchdate: "2020-08-07 10:06:24",
                    endTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                },
                {
                    case: "ORCA-9371b816404c",
                    startTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                    fetchdate: "2020-08-07 10:06:24",
                    endTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                },
                {
                    case: "ORCA-9371b816404c",
                    startTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                    fetchdate: "2020-08-07 12:21:40",
                    endTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                },
                {
                    case: "ORCA-9371b816404c",
                    startTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                    fetchdate: "2020-08-07 12:21:40",
                    endTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                },
                {
                    case: "ORCA-8371b816404d",
                    startTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                    fetchdate: "2020-08-07 12:48:42",
                    endTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                },
                {
                    case: "ORCA-8371b816404d",
                    startTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                    fetchdate: "2020-08-07 12:48:42",
                    endTweetID: {
                        $numberLong: "1278734743543021600",
                    },
                },
                {
                    case: "ORCA-8371b816404d",
                    startTweetID: {
                        $numberLong: "1291789256898183200",
                    },
                    fetchdate: "2020-08-10 05:38:49",
                    endTweetID: {
                        $numberLong: "1292393052661616600",
                    },
                },
            ],
            data: [
                {
                    dataid: "982a89c56b9447dcb742a8e8da68a098",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1292393052661616641",
                    language: {
                        text_translated_edited: "@monzo No! 🤣 https://t.co/8pcqTU2IQa",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "96.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 682,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "@monzo No! No 🤣 https://t.co/8pcqTU2IQa",
                    },
                    deleted: false,
                    content: "@monzo No! 🤣 https://t.co/8pcqTU2IQa",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1292393052661616641",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "@monzo No! 🤣 https://t.co/8pcqTU2IQa",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: {
                            $numberLong: "1292392703183982600",
                        },
                        id: {
                            $numberLong: "1292393052661616600",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [0, 6],
                                    id: {
                                        $numberLong: "3224409977",
                                    },
                                    id_str: "3224409977",
                                    name: "Monzo",
                                    screen_name: "monzo",
                                },
                            ],
                            hashtags: [],
                            urls: [],
                            media: [
                                {
                                    expanded_url: "https://twitter.com/aggkunal/status/1292393052661616641/photo/1",
                                    display_url: "pic.twitter.com/8pcqTU2IQa",
                                    url: "https://t.co/8pcqTU2IQa",
                                    media_url_https: "https://pbs.twimg.com/tweet_video_thumb/Ee-AoCnU4AA1FIf.jpg",
                                    id_str: "1292393043463561216",
                                    sizes: {
                                        small: {
                                            h: 126,
                                            w: 218,
                                            resize: "fit",
                                        },
                                        large: {
                                            h: 126,
                                            w: 218,
                                            resize: "fit",
                                        },
                                        medium: {
                                            h: 126,
                                            w: 218,
                                            resize: "fit",
                                        },
                                        thumb: {
                                            h: 126,
                                            w: 126,
                                            resize: "crop",
                                        },
                                    },
                                    indices: [13, 36],
                                    type: "photo",
                                    id: {
                                        $numberLong: "1292393043463561200",
                                    },
                                    media_url: "http://pbs.twimg.com/tweet_video_thumb/Ee-AoCnU4AA1FIf.jpg",
                                },
                            ],
                        },
                        in_reply_to_screen_name: "monzo",
                        id_str: "1292393052661616641",
                        display_text_range: [7, 12],
                        retweet_count: 0,
                        in_reply_to_user_id: {
                            $numberLong: "3224409977",
                        },
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: false,
                            id: 792155010,
                            default_profile: false,
                            verified: false,
                            profile_text_color: "000000",
                            profile_image_url_https: "https://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            profile_sidebar_fill_color: "000000",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 194,
                            protected: false,
                            location: "New Delhi, Delhi",
                            default_profile_image: false,
                            id_str: "792155010",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 455,
                            description: "OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor to @datasploit, Core Team member @ReconVillage.",
                            friends_count: 172,
                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                            profile_link_color: "19CF86",
                            profile_image_url: "http://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            notifications: false,
                            geo_enabled: true,
                            profile_background_color: "000000",
                            profile_banner_url: "https://pbs.twimg.com/profile_banners/792155010/1534467285",
                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 99,
                            screen_name: "aggkunal",
                            url: null,
                            created_at: "Thu Aug 30 18:38:46 +0000 2012",
                            contributors_enabled: false,
                            time_zone: null,
                            profile_sidebar_border_color: "000000",
                            translator_type: "none",
                            following: false,
                            listed_count: 5,
                        },
                        geo: null,
                        in_reply_to_user_id_str: "3224409977",
                        possibly_sensitive: false,
                        lang: "und",
                        created_at: "Sun Aug 09 09:31:29 +0000 2020",
                        in_reply_to_status_id_str: "1292392703183982593",
                        place: null,
                        extended_entities: {
                            media: [
                                {
                                    expanded_url: "https://twitter.com/aggkunal/status/1292393052661616641/photo/1",
                                    display_url: "pic.twitter.com/8pcqTU2IQa",
                                    url: "https://t.co/8pcqTU2IQa",
                                    media_url_https: "https://pbs.twimg.com/tweet_video_thumb/Ee-AoCnU4AA1FIf.jpg",
                                    video_info: {
                                        aspect_ratio: [109, 63],
                                        variants: [
                                            {
                                                url: "https://video.twimg.com/tweet_video/Ee-AoCnU4AA1FIf.mp4",
                                                bitrate: 0,
                                                content_type: "video/mp4",
                                            },
                                        ],
                                    },
                                    id_str: "1292393043463561216",
                                    sizes: {
                                        small: {
                                            h: 126,
                                            w: 218,
                                            resize: "fit",
                                        },
                                        large: {
                                            h: 126,
                                            w: 218,
                                            resize: "fit",
                                        },
                                        medium: {
                                            h: 126,
                                            w: 218,
                                            resize: "fit",
                                        },
                                        thumb: {
                                            h: 126,
                                            w: 126,
                                            resize: "crop",
                                        },
                                    },
                                    indices: [13, 36],
                                    type: "animated_gif",
                                    id: {
                                        $numberLong: "1292393043463561200",
                                    },
                                    media_url: "http://pbs.twimg.com/tweet_video_thumb/Ee-AoCnU4AA1FIf.jpg",
                                },
                            ],
                        },
                    },
                },
                {
                    dataid: "72aa8c28b1434af6ba05c11f1bc40ca1",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1292370958938828800",
                    language: {
                        text_translated_edited: "RT @ReconVillage: The #ReconVillage CTF is now over after 38 grueling hours. We have the winners here.\n\nThe competition was super close and…",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1274,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "RT @ReconVillage: The #ReconVillage CTF is now over after 38 grueling hours. We have the winners here.\n\nThe competition was super close and…",
                    },
                    deleted: false,
                    content: "RT @ReconVillage: The #ReconVillage CTF is now over after 38 grueling hours. We have the winners here.\n\nThe competition was super close and…",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1292370958938828800",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "RT @ReconVillage: The #ReconVillage CTF is now over after 38 grueling hours. We have the winners here.\n\nThe competition was super close and…",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292370958938828800",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                        retweeted: true,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [3, 16],
                                    id: {
                                        $numberLong: "857306591036448800",
                                    },
                                    id_str: "857306591036448768",
                                    name: "Recon Village",
                                    screen_name: "ReconVillage",
                                },
                            ],
                            hashtags: [
                                {
                                    indices: [22, 35],
                                    text: "ReconVillage",
                                },
                            ],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292370958938828800",
                        display_text_range: [0, 140],
                        retweet_count: 18,
                        in_reply_to_user_id: null,
                        favorited: true,
                        retweeted_status: {
                            full_text: "The #ReconVillage CTF is now over after 38 grueling hours. We have the winners here.\n\nThe competition was super close and nail-biting as we can see from the scoreboard and its a tie.\n\n1st Winner: GSArecon2 &amp; Aloha_Cannon\n2nd Winner: fett\n3rd Winner: Vetsec\n\n#osint #recon @defcon https://t.co/HfFgEPsvms",
                            truncated: false,
                            is_quote_status: false,
                            in_reply_to_status_id: null,
                            id: {
                                $numberLong: "1292364332261548000",
                            },
                            favorite_count: 38,
                            contributors: null,
                            source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                            retweeted: true,
                            coordinates: null,
                            entities: {
                                symbols: [],
                                user_mentions: [
                                    {
                                        indices: [276, 283],
                                        id: 14924965,
                                        id_str: "14924965",
                                        name: "DEF CON",
                                        screen_name: "defcon",
                                    },
                                ],
                                hashtags: [
                                    {
                                        indices: [4, 17],
                                        text: "ReconVillage",
                                    },
                                    {
                                        indices: [262, 268],
                                        text: "osint",
                                    },
                                    {
                                        indices: [269, 275],
                                        text: "recon",
                                    },
                                ],
                                urls: [],
                                media: [
                                    {
                                        expanded_url: "https://twitter.com/ReconVillage/status/1292364332261548033/photo/1",
                                        display_url: "pic.twitter.com/HfFgEPsvms",
                                        url: "https://t.co/HfFgEPsvms",
                                        media_url_https: "https://pbs.twimg.com/media/Ee9lKw1UYAIE32a.jpg",
                                        id_str: "1292362853660254210",
                                        sizes: {
                                            large: {
                                                h: 885,
                                                w: 1204,
                                                resize: "fit",
                                            },
                                            small: {
                                                h: 500,
                                                w: 680,
                                                resize: "fit",
                                            },
                                            medium: {
                                                h: 882,
                                                w: 1200,
                                                resize: "fit",
                                            },
                                            thumb: {
                                                h: 150,
                                                w: 150,
                                                resize: "crop",
                                            },
                                        },
                                        indices: [284, 307],
                                        type: "photo",
                                        id: {
                                            $numberLong: "1292362853660254200",
                                        },
                                        media_url: "http://pbs.twimg.com/media/Ee9lKw1UYAIE32a.jpg",
                                    },
                                ],
                            },
                            in_reply_to_screen_name: null,
                            id_str: "1292364332261548033",
                            display_text_range: [0, 283],
                            retweet_count: 18,
                            in_reply_to_user_id: null,
                            favorited: true,
                            user: {
                                follow_request_sent: false,
                                has_extended_profile: false,
                                profile_use_background_image: false,
                                id: {
                                    $numberLong: "857306591036448800",
                                },
                                default_profile: false,
                                verified: false,
                                profile_text_color: "000000",
                                profile_image_url_https: "https://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                profile_sidebar_fill_color: "000000",
                                is_translator: false,
                                entities: {
                                    description: {
                                        urls: [
                                            {
                                                url: "https://t.co/adB2RSmjkR",
                                                indices: [94, 117],
                                                expanded_url: "https://www.youtube.com/c/ReconVillage/live",
                                                display_url: "youtube.com/c/ReconVillage…",
                                            },
                                        ],
                                    },
                                },
                                followers_count: 4393,
                                protected: false,
                                location: "Las Vegas, NV",
                                default_profile_image: false,
                                id_str: "857306591036448768",
                                lang: null,
                                utc_offset: null,
                                statuses_count: 420,
                                description: "A #DEFCON #Village focused on Open Source Intelligence and #Recon. #OSINT\n\nTalks streaming at https://t.co/adB2RSmjkR",
                                friends_count: 60,
                                profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                                profile_link_color: "ABB8C2",
                                profile_image_url: "http://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                notifications: false,
                                geo_enabled: false,
                                profile_background_color: "000000",
                                profile_banner_url: "https://pbs.twimg.com/profile_banners/857306591036448768/1494530150",
                                profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                                name: "Recon Village",
                                is_translation_enabled: false,
                                profile_background_tile: false,
                                favourites_count: 37,
                                screen_name: "ReconVillage",
                                url: null,
                                created_at: "Wed Apr 26 18:53:14 +0000 2017",
                                contributors_enabled: false,
                                time_zone: null,
                                profile_sidebar_border_color: "000000",
                                translator_type: "none",
                                following: true,
                                listed_count: 88,
                            },
                            geo: null,
                            in_reply_to_user_id_str: null,
                            possibly_sensitive: false,
                            lang: "en",
                            created_at: "Sun Aug 09 07:37:22 +0000 2020",
                            in_reply_to_status_id_str: null,
                            place: null,
                            extended_entities: {
                                media: [
                                    {
                                        expanded_url: "https://twitter.com/ReconVillage/status/1292364332261548033/photo/1",
                                        display_url: "pic.twitter.com/HfFgEPsvms",
                                        url: "https://t.co/HfFgEPsvms",
                                        media_url_https: "https://pbs.twimg.com/media/Ee9lKw1UYAIE32a.jpg",
                                        id_str: "1292362853660254210",
                                        sizes: {
                                            large: {
                                                h: 885,
                                                w: 1204,
                                                resize: "fit",
                                            },
                                            small: {
                                                h: 500,
                                                w: 680,
                                                resize: "fit",
                                            },
                                            medium: {
                                                h: 882,
                                                w: 1200,
                                                resize: "fit",
                                            },
                                            thumb: {
                                                h: 150,
                                                w: 150,
                                                resize: "crop",
                                            },
                                        },
                                        indices: [284, 307],
                                        type: "photo",
                                        id: {
                                            $numberLong: "1292362853660254200",
                                        },
                                        media_url: "http://pbs.twimg.com/media/Ee9lKw1UYAIE32a.jpg",
                                    },
                                ],
                            },
                        },
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: false,
                            id: 792155010,
                            default_profile: false,
                            verified: false,
                            profile_text_color: "000000",
                            profile_image_url_https: "https://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            profile_sidebar_fill_color: "000000",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 194,
                            protected: false,
                            location: "New Delhi, Delhi",
                            default_profile_image: false,
                            id_str: "792155010",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 455,
                            description: "OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor to @datasploit, Core Team member @ReconVillage.",
                            friends_count: 172,
                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                            profile_link_color: "19CF86",
                            profile_image_url: "http://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            notifications: false,
                            geo_enabled: true,
                            profile_background_color: "000000",
                            profile_banner_url: "https://pbs.twimg.com/profile_banners/792155010/1534467285",
                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 99,
                            screen_name: "aggkunal",
                            url: null,
                            created_at: "Thu Aug 30 18:38:46 +0000 2012",
                            contributors_enabled: false,
                            time_zone: null,
                            profile_sidebar_border_color: "000000",
                            translator_type: "none",
                            following: false,
                            listed_count: 5,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Sun Aug 09 08:03:42 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "8f8c6b209d0640728b93f7083828bd95",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1292183695109976065",
                    language: {
                        text_translated_edited: "RT @ReconVillage: Developers are strange people\n\nSometimes things are plain and simple, but people tend to make it complex.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1290,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "RT @ReconVillage: Developers are strange people\n\nSometimes things are plain and simple, but people tend to make it complex.",
                    },
                    deleted: false,
                    content: "RT @ReconVillage: Developers are strange people\n\nSometimes things are plain and simple, but people tend to make it complex.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1292183695109976065",
                    flagged: true,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "RT @ReconVillage: Developers are strange people\n\nSometimes things are plain and simple, but people tend to make it complex.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292183695109976000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: true,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [3, 16],
                                    id: {
                                        $numberLong: "857306591036448800",
                                    },
                                    id_str: "857306591036448768",
                                    name: "Recon Village",
                                    screen_name: "ReconVillage",
                                },
                            ],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292183695109976065",
                        display_text_range: [0, 123],
                        retweet_count: 1,
                        in_reply_to_user_id: null,
                        favorited: false,
                        retweeted_status: {
                            full_text: "Developers are strange people\n\nSometimes things are plain and simple, but people tend to make it complex.",
                            truncated: false,
                            is_quote_status: false,
                            in_reply_to_status_id: null,
                            id: {
                                $numberLong: "1292164016169603000",
                            },
                            favorite_count: 4,
                            contributors: null,
                            source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                            retweeted: true,
                            coordinates: null,
                            entities: {
                                symbols: [],
                                user_mentions: [],
                                hashtags: [],
                                urls: [],
                            },
                            in_reply_to_screen_name: null,
                            id_str: "1292164016169603073",
                            display_text_range: [0, 105],
                            retweet_count: 1,
                            in_reply_to_user_id: null,
                            favorited: false,
                            user: {
                                follow_request_sent: false,
                                has_extended_profile: false,
                                profile_use_background_image: false,
                                id: {
                                    $numberLong: "857306591036448800",
                                },
                                default_profile: false,
                                verified: false,
                                profile_text_color: "000000",
                                profile_image_url_https: "https://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                profile_sidebar_fill_color: "000000",
                                is_translator: false,
                                entities: {
                                    description: {
                                        urls: [
                                            {
                                                url: "https://t.co/adB2RSmjkR",
                                                indices: [94, 117],
                                                expanded_url: "https://www.youtube.com/c/ReconVillage/live",
                                                display_url: "youtube.com/c/ReconVillage…",
                                            },
                                        ],
                                    },
                                },
                                followers_count: 4393,
                                protected: false,
                                location: "Las Vegas, NV",
                                default_profile_image: false,
                                id_str: "857306591036448768",
                                lang: null,
                                utc_offset: null,
                                statuses_count: 420,
                                description: "A #DEFCON #Village focused on Open Source Intelligence and #Recon. #OSINT\n\nTalks streaming at https://t.co/adB2RSmjkR",
                                friends_count: 60,
                                profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                                profile_link_color: "ABB8C2",
                                profile_image_url: "http://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                notifications: false,
                                geo_enabled: false,
                                profile_background_color: "000000",
                                profile_banner_url: "https://pbs.twimg.com/profile_banners/857306591036448768/1494530150",
                                profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                                name: "Recon Village",
                                is_translation_enabled: false,
                                profile_background_tile: false,
                                favourites_count: 37,
                                screen_name: "ReconVillage",
                                url: null,
                                created_at: "Wed Apr 26 18:53:14 +0000 2017",
                                contributors_enabled: false,
                                time_zone: null,
                                profile_sidebar_border_color: "000000",
                                translator_type: "none",
                                following: true,
                                listed_count: 88,
                            },
                            geo: null,
                            in_reply_to_user_id_str: null,
                            lang: "en",
                            created_at: "Sat Aug 08 18:21:23 +0000 2020",
                            in_reply_to_status_id_str: null,
                            place: null,
                        },
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: false,
                            id: 792155010,
                            default_profile: false,
                            verified: false,
                            profile_text_color: "000000",
                            profile_image_url_https: "https://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            profile_sidebar_fill_color: "000000",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 194,
                            protected: false,
                            location: "New Delhi, Delhi",
                            default_profile_image: false,
                            id_str: "792155010",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 455,
                            description: "OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor to @datasploit, Core Team member @ReconVillage.",
                            friends_count: 172,
                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                            profile_link_color: "19CF86",
                            profile_image_url: "http://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            notifications: false,
                            geo_enabled: true,
                            profile_background_color: "000000",
                            profile_banner_url: "https://pbs.twimg.com/profile_banners/792155010/1534467285",
                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 99,
                            screen_name: "aggkunal",
                            url: null,
                            created_at: "Thu Aug 30 18:38:46 +0000 2012",
                            contributors_enabled: false,
                            time_zone: null,
                            profile_sidebar_border_color: "000000",
                            translator_type: "none",
                            following: false,
                            listed_count: 5,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Sat Aug 08 19:39:35 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "3f6d7e8b929d4c46a77399321be6ae62",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1292183658753712128",
                    language: {
                        text_translated_edited: "RT @ReconVillage: Recon Village #CTF HINT #reconvillage @defcon @aggkunal \n\nMy Resume\n\nPeople are easy to predict. Their resume can tell a…",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 722,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "RT @ReconVillage: Recon Village #CTF HINT #reconvillage @defcon @aggkunal \n\nMy Resume\n\nPeople are easy to predict. Their resume can tell a…",
                    },
                    deleted: false,
                    content: "RT @ReconVillage: Recon Village #CTF HINT #reconvillage @defcon @aggkunal \n\nMy Resume\n\nPeople are easy to predict. Their resume can tell a…",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1292183658753712128",
                    flagged: true,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "RT @ReconVillage: Recon Village #CTF HINT #reconvillage @defcon @aggkunal \n\nMy Resume\n\nPeople are easy to predict. Their resume can tell a…",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292183658753712000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: true,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [3, 16],
                                    id: {
                                        $numberLong: "857306591036448800",
                                    },
                                    id_str: "857306591036448768",
                                    name: "Recon Village",
                                    screen_name: "ReconVillage",
                                },
                                {
                                    indices: [56, 63],
                                    id: 14924965,
                                    id_str: "14924965",
                                    name: "DEF CON",
                                    screen_name: "defcon",
                                },
                                {
                                    indices: [64, 73],
                                    id: 792155010,
                                    id_str: "792155010",
                                    name: "Kunal Aggarwal",
                                    screen_name: "aggkunal",
                                },
                            ],
                            hashtags: [
                                {
                                    indices: [32, 36],
                                    text: "CTF",
                                },
                                {
                                    indices: [42, 55],
                                    text: "reconvillage",
                                },
                            ],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292183658753712128",
                        display_text_range: [0, 139],
                        retweet_count: 1,
                        in_reply_to_user_id: null,
                        favorited: false,
                        retweeted_status: {
                            full_text: "Recon Village #CTF HINT #reconvillage @defcon @aggkunal \n\nMy Resume\n\nPeople are easy to predict. Their resume can tell a lot about them.",
                            truncated: false,
                            is_quote_status: false,
                            in_reply_to_status_id: null,
                            id: {
                                $numberLong: "1292183547839516700",
                            },
                            favorite_count: 5,
                            contributors: null,
                            source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                            retweeted: true,
                            coordinates: null,
                            entities: {
                                symbols: [],
                                user_mentions: [
                                    {
                                        indices: [38, 45],
                                        id: 14924965,
                                        id_str: "14924965",
                                        name: "DEF CON",
                                        screen_name: "defcon",
                                    },
                                    {
                                        indices: [46, 55],
                                        id: 792155010,
                                        id_str: "792155010",
                                        name: "Kunal Aggarwal",
                                        screen_name: "aggkunal",
                                    },
                                ],
                                hashtags: [
                                    {
                                        indices: [14, 18],
                                        text: "CTF",
                                    },
                                    {
                                        indices: [24, 37],
                                        text: "reconvillage",
                                    },
                                ],
                                urls: [],
                            },
                            in_reply_to_screen_name: null,
                            id_str: "1292183547839516672",
                            display_text_range: [0, 136],
                            retweet_count: 1,
                            in_reply_to_user_id: null,
                            favorited: false,
                            user: {
                                follow_request_sent: false,
                                has_extended_profile: false,
                                profile_use_background_image: false,
                                id: {
                                    $numberLong: "857306591036448800",
                                },
                                default_profile: false,
                                verified: false,
                                profile_text_color: "000000",
                                profile_image_url_https: "https://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                profile_sidebar_fill_color: "000000",
                                is_translator: false,
                                entities: {
                                    description: {
                                        urls: [
                                            {
                                                url: "https://t.co/adB2RSmjkR",
                                                indices: [94, 117],
                                                expanded_url: "https://www.youtube.com/c/ReconVillage/live",
                                                display_url: "youtube.com/c/ReconVillage…",
                                            },
                                        ],
                                    },
                                },
                                followers_count: 4393,
                                protected: false,
                                location: "Las Vegas, NV",
                                default_profile_image: false,
                                id_str: "857306591036448768",
                                lang: null,
                                utc_offset: null,
                                statuses_count: 420,
                                description: "A #DEFCON #Village focused on Open Source Intelligence and #Recon. #OSINT\n\nTalks streaming at https://t.co/adB2RSmjkR",
                                friends_count: 60,
                                profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                                profile_link_color: "ABB8C2",
                                profile_image_url: "http://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                notifications: false,
                                geo_enabled: false,
                                profile_background_color: "000000",
                                profile_banner_url: "https://pbs.twimg.com/profile_banners/857306591036448768/1494530150",
                                profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                                name: "Recon Village",
                                is_translation_enabled: false,
                                profile_background_tile: false,
                                favourites_count: 37,
                                screen_name: "ReconVillage",
                                url: null,
                                created_at: "Wed Apr 26 18:53:14 +0000 2017",
                                contributors_enabled: false,
                                time_zone: null,
                                profile_sidebar_border_color: "000000",
                                translator_type: "none",
                                following: true,
                                listed_count: 88,
                            },
                            geo: null,
                            in_reply_to_user_id_str: null,
                            lang: "en",
                            created_at: "Sat Aug 08 19:39:00 +0000 2020",
                            in_reply_to_status_id_str: null,
                            place: null,
                        },
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: false,
                            id: 792155010,
                            default_profile: false,
                            verified: false,
                            profile_text_color: "000000",
                            profile_image_url_https: "https://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            profile_sidebar_fill_color: "000000",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 194,
                            protected: false,
                            location: "New Delhi, Delhi",
                            default_profile_image: false,
                            id_str: "792155010",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 455,
                            description: "OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor to @datasploit, Core Team member @ReconVillage.",
                            friends_count: 172,
                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                            profile_link_color: "19CF86",
                            profile_image_url: "http://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            notifications: false,
                            geo_enabled: true,
                            profile_background_color: "000000",
                            profile_banner_url: "https://pbs.twimg.com/profile_banners/792155010/1534467285",
                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 99,
                            screen_name: "aggkunal",
                            url: null,
                            created_at: "Thu Aug 30 18:38:46 +0000 2012",
                            contributors_enabled: false,
                            time_zone: null,
                            profile_sidebar_border_color: "000000",
                            translator_type: "none",
                            following: false,
                            listed_count: 5,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Sat Aug 08 19:39:26 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "68bc147070f7450bbcd9302198312cc4",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1291883741455872000",
                    language: {
                        text_translated_edited: "RT @ReconVillage: Looks like folks are struggling a lot with challenges. \n\nWe have released Hints for the challenges. \n\nBut remember, every…",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1195,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "RT @ReconVillage: Looks like folks are struggling a lot with challenges. \n\nWe have released Hints for the challenges. \n\nBut remember, every…",
                    },
                    deleted: false,
                    content: "RT @ReconVillage: Looks like folks are struggling a lot with challenges. \n\nWe have released Hints for the challenges. \n\nBut remember, every…",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1291883741455872000",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "RT @ReconVillage: Looks like folks are struggling a lot with challenges. \n\nWe have released Hints for the challenges. \n\nBut remember, every…",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1291883741455872000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: true,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [3, 16],
                                    id: {
                                        $numberLong: "857306591036448800",
                                    },
                                    id_str: "857306591036448768",
                                    name: "Recon Village",
                                    screen_name: "ReconVillage",
                                },
                            ],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1291883741455872000",
                        display_text_range: [0, 140],
                        retweet_count: 11,
                        in_reply_to_user_id: null,
                        favorited: true,
                        retweeted_status: {
                            full_text: "Looks like folks are struggling a lot with challenges. \n\nWe have released Hints for the challenges. \n\nBut remember, everything comes with a cost. \n\n#osint #recon @defcon \n\nhttps://t.co/Vqbt93RrLO (Join if you are missing the party).",
                            truncated: false,
                            is_quote_status: false,
                            in_reply_to_status_id: null,
                            id: {
                                $numberLong: "1291879610242613200",
                            },
                            favorite_count: 7,
                            contributors: null,
                            source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                            retweeted: true,
                            coordinates: null,
                            entities: {
                                symbols: [],
                                user_mentions: [
                                    {
                                        indices: [162, 169],
                                        id: 14924965,
                                        id_str: "14924965",
                                        name: "DEF CON",
                                        screen_name: "defcon",
                                    },
                                ],
                                hashtags: [
                                    {
                                        indices: [148, 154],
                                        text: "osint",
                                    },
                                    {
                                        indices: [155, 161],
                                        text: "recon",
                                    },
                                ],
                                urls: [
                                    {
                                        url: "https://t.co/Vqbt93RrLO",
                                        indices: [172, 195],
                                        expanded_url: "https://ctf.reconvillage.org",
                                        display_url: "ctf.reconvillage.org",
                                    },
                                ],
                            },
                            in_reply_to_screen_name: null,
                            id_str: "1291879610242613248",
                            display_text_range: [0, 232],
                            retweet_count: 11,
                            in_reply_to_user_id: null,
                            favorited: true,
                            user: {
                                follow_request_sent: false,
                                has_extended_profile: false,
                                profile_use_background_image: false,
                                id: {
                                    $numberLong: "857306591036448800",
                                },
                                default_profile: false,
                                verified: false,
                                profile_text_color: "000000",
                                profile_image_url_https: "https://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                profile_sidebar_fill_color: "000000",
                                is_translator: false,
                                entities: {
                                    description: {
                                        urls: [
                                            {
                                                url: "https://t.co/adB2RSmjkR",
                                                indices: [94, 117],
                                                expanded_url: "https://www.youtube.com/c/ReconVillage/live",
                                                display_url: "youtube.com/c/ReconVillage…",
                                            },
                                        ],
                                    },
                                },
                                followers_count: 4393,
                                protected: false,
                                location: "Las Vegas, NV",
                                default_profile_image: false,
                                id_str: "857306591036448768",
                                lang: null,
                                utc_offset: null,
                                statuses_count: 420,
                                description: "A #DEFCON #Village focused on Open Source Intelligence and #Recon. #OSINT\n\nTalks streaming at https://t.co/adB2RSmjkR",
                                friends_count: 60,
                                profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                                profile_link_color: "ABB8C2",
                                profile_image_url: "http://pbs.twimg.com/profile_images/862748265598509056/5DtDrTEf_normal.jpg",
                                notifications: false,
                                geo_enabled: false,
                                profile_background_color: "000000",
                                profile_banner_url: "https://pbs.twimg.com/profile_banners/857306591036448768/1494530150",
                                profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                                name: "Recon Village",
                                is_translation_enabled: false,
                                profile_background_tile: false,
                                favourites_count: 37,
                                screen_name: "ReconVillage",
                                url: null,
                                created_at: "Wed Apr 26 18:53:14 +0000 2017",
                                contributors_enabled: false,
                                time_zone: null,
                                profile_sidebar_border_color: "000000",
                                translator_type: "none",
                                following: true,
                                listed_count: 88,
                            },
                            geo: null,
                            in_reply_to_user_id_str: null,
                            possibly_sensitive: false,
                            lang: "en",
                            created_at: "Fri Aug 07 23:31:15 +0000 2020",
                            in_reply_to_status_id_str: null,
                            place: null,
                        },
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: false,
                            id: 792155010,
                            default_profile: false,
                            verified: false,
                            profile_text_color: "000000",
                            profile_image_url_https: "https://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            profile_sidebar_fill_color: "000000",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 194,
                            protected: false,
                            location: "New Delhi, Delhi",
                            default_profile_image: false,
                            id_str: "792155010",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 455,
                            description: "OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor to @datasploit, Core Team member @ReconVillage.",
                            friends_count: 172,
                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                            profile_link_color: "19CF86",
                            profile_image_url: "http://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            notifications: false,
                            geo_enabled: true,
                            profile_background_color: "000000",
                            profile_banner_url: "https://pbs.twimg.com/profile_banners/792155010/1534467285",
                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 99,
                            screen_name: "aggkunal",
                            url: null,
                            created_at: "Thu Aug 30 18:38:46 +0000 2012",
                            contributors_enabled: false,
                            time_zone: null,
                            profile_sidebar_border_color: "000000",
                            translator_type: "none",
                            following: false,
                            listed_count: 5,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Fri Aug 07 23:47:40 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "7e725bfcc6ac404980832115a11d4d0f",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1291789256898183168",
                    language: {
                        text_translated_edited: "@ReconVillage CTF is live. We're back this year for the 4th time. The CTF has #osint #reconnaissance challenges. So time to gear up and bring your arsenal and get cracking. https://t.co/yzdJB6KKEC",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1128,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "@ReconVillage CTF is live. We're back this year for the 4th time. The CTF has #osint #reconnaissance challenges. So time to gear up and bring your arsenal and get cracking. https://t.co/yzdJB6KKEC",
                    },
                    deleted: false,
                    content: "@ReconVillage CTF is live. We're back this year for the 4th time. The CTF has #osint #reconnaissance challenges. So time to gear up and bring your arsenal and get cracking. https://t.co/yzdJB6KKEC",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1291789256898183168",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "@ReconVillage CTF is live. We're back this year for the 4th time. The CTF has #osint #reconnaissance challenges. So time to gear up and bring your arsenal and get cracking. https://t.co/yzdJB6KKEC",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1291789256898183200",
                        },
                        favorite_count: 3,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [0, 13],
                                    id: {
                                        $numberLong: "857306591036448800",
                                    },
                                    id_str: "857306591036448768",
                                    name: "Recon Village",
                                    screen_name: "ReconVillage",
                                },
                            ],
                            hashtags: [
                                {
                                    indices: [78, 84],
                                    text: "osint",
                                },
                                {
                                    indices: [85, 100],
                                    text: "reconnaissance",
                                },
                            ],
                            urls: [
                                {
                                    url: "https://t.co/yzdJB6KKEC",
                                    indices: [173, 196],
                                    expanded_url: "https://ctf.reconvillage.org",
                                    display_url: "ctf.reconvillage.org",
                                },
                            ],
                        },
                        in_reply_to_screen_name: "ReconVillage",
                        id_str: "1291789256898183168",
                        display_text_range: [0, 196],
                        retweet_count: 1,
                        in_reply_to_user_id: {
                            $numberLong: "857306591036448800",
                        },
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: false,
                            id: 792155010,
                            default_profile: false,
                            verified: false,
                            profile_text_color: "000000",
                            profile_image_url_https: "https://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            profile_sidebar_fill_color: "000000",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 194,
                            protected: false,
                            location: "New Delhi, Delhi",
                            default_profile_image: false,
                            id_str: "792155010",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 455,
                            description: "OSINT Researcher, Ethical Hacker & Python Developer. CTO & Director @NeotasLtd, London. Contributor to @datasploit, Core Team member @ReconVillage.",
                            friends_count: 172,
                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                            profile_link_color: "19CF86",
                            profile_image_url: "http://pbs.twimg.com/profile_images/655097035582930944/Tkd5Jf3r_normal.jpg",
                            notifications: false,
                            geo_enabled: true,
                            profile_background_color: "000000",
                            profile_banner_url: "https://pbs.twimg.com/profile_banners/792155010/1534467285",
                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 99,
                            screen_name: "aggkunal",
                            url: null,
                            created_at: "Thu Aug 30 18:38:46 +0000 2012",
                            contributors_enabled: false,
                            time_zone: null,
                            profile_sidebar_border_color: "000000",
                            translator_type: "none",
                            following: false,
                            listed_count: 5,
                        },
                        geo: null,
                        in_reply_to_user_id_str: "857306591036448768",
                        possibly_sensitive: false,
                        lang: "en",
                        created_at: "Fri Aug 07 17:32:13 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
            ],
        },
        {
            _id: "twitter||aggkunal92",
            username: "aggkunal92",
            uid: "twitter||aggkunal92",
            platform: "twitter",
            case_id: [
                {
                    case: "ORCA-8371b816404d",
                    startTweetID: {
                        $numberLong: "1126559270206500900",
                    },
                    fetchdate: "2020-08-10 05:38:49",
                    endTweetID: {
                        $numberLong: "1292696424082100200",
                    },
                },
            ],
            data: [
                {
                    dataid: "709e991db974453d9981f1dcba049183",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Illegal activities",
                            },
                        ],
                    },
                    uid: "twitter||1292696424082100224",
                    language: {
                        text_translated_edited: "OSINT is the practice of gathering intelligence from publicly available sources to support intelligence needs. In the cyber security field, OSINT is widely used to find vulnerabilities in IT systems and is commonly named technical foot-printing.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "97.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 880,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "OSINT is the practice of gathering intelligence from publicly available sources to support intelligence needs. In the cyber security field, OSINT is widely used to find vulnerabilities in IT systems and is commonly named technical foot-printing.",
                    },
                    deleted: false,
                    content: "OSINT खुफिया जरूरतों का समर्थन करने के लिए सार्वजनिक रूप से उपलब्ध स्रोतों से खुफिया जानकारी जुटाने का अभ्यास है। साइबर सुरक्षा क्षेत्र में, OSINT का आईटी प्रणालियों में कमजोरियों को खोजने के लिए व्यापक रूप से उपयोग किया जाता है और इसे आमतौर पर तकनीकी फुट-प्रिंटिंग का नाम दिया है",
                    include_in_report: true,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1292696424082100224",
                    flagged: true,
                    categories: {
                        qa: ["Illegal activities"],
                        system: ["Illegal activities"],
                        analyst: ["Illegal activities", "Hate and discriminatory behaviour"],
                    },
                    metadata: {
                        full_text: "OSINT खुफिया जरूरतों का समर्थन करने के लिए सार्वजनिक रूप से उपलब्ध स्रोतों से खुफिया जानकारी जुटाने का अभ्यास है। साइबर सुरक्षा क्षेत्र में, OSINT का आईटी प्रणालियों में कमजोरियों को खोजने के लिए व्यापक रूप से उपयोग किया जाता है और इसे आमतौर पर तकनीकी फुट-प्रिंटिंग का नाम दिया है",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292696424082100200",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292696424082100224",
                        display_text_range: [0, 280],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "hi",
                        created_at: "Mon Aug 10 05:36:59 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "ab46efbfa8b54d6ea61f3d0f49d27dce",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1292696176647524353",
                    language: {
                        text_translated_edited: "OSINT is the practice of collecting information from publicly available sources to support intelligence needs.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "it",
                                    name: "Italian",
                                    read_bytes: 479,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "OSINT is the practice of collecting information from publicly available sources to support intelligence needs.",
                    },
                    deleted: true,
                    content: "OSINT è la pratica di raccogliere informazioni da fonti pubblicamente disponibili per supportare le esigenze di intelligence.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1292696176647524353",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "OSINT è la pratica di raccogliere informazioni da fonti pubblicamente disponibili per supportare le esigenze di intelligence.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292696176647524400",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292696176647524353",
                        display_text_range: [0, 125],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "it",
                        created_at: "Mon Aug 10 05:36:00 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "e5567d4cddea4fecbc42a88474ee1d5d",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1292696083269746688",
                    language: {
                        text_translated_edited: "OSINT is the practice of collecting intelligence from publicly available sources to support intelligence needs.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "es",
                                    name: "Spanish",
                                    read_bytes: 527,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "OSINT is the practice of collecting intelligence from publicly available sources to support intelligence needs.",
                    },
                    deleted: true,
                    content: "OSINT es la práctica de recopilar inteligencia de fuentes disponibles públicamente para respaldar las necesidades de inteligencia.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1292696083269746688",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "OSINT es la práctica de recopilar inteligencia de fuentes disponibles públicamente para respaldar las necesidades de inteligencia.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292696083269746700",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292696083269746688",
                        display_text_range: [0, 130],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "es",
                        created_at: "Mon Aug 10 05:35:38 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "32cd04e2e0c440ddb3338edcf471c5c0",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Illegal activities",
                            },
                        ],
                    },
                    uid: "twitter||1292695980446380032",
                    language: {
                        text_translated_edited: "OSINT is the practice of gathering intelligence from publicly available sources to support intelligence needs. In the cybersecurity arena, OSINT is used widely to discover vulnerabilities in IT systems and is commonly named Technical foot-printing.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1103,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "OSINT is the practice of gathering intelligence from publicly available sources to support intelligence needs. In the cybersecurity arena, OSINT is used widely to discover vulnerabilities in IT systems and is commonly named Technical foot-printing.",
                    },
                    deleted: false,
                    content: "OSINT is the practice of gathering intelligence from publicly available sources to support intelligence needs. In the cybersecurity arena, OSINT is used widely to discover vulnerabilities in IT systems and is commonly named Technical foot-printing.",
                    include_in_report: true,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1292695980446380032",
                    flagged: true,
                    categories: {
                        qa: ["Illegal activities"],
                        system: ["Illegal activities"],
                        analyst: ["Illegal activities"],
                    },
                    metadata: {
                        full_text: "OSINT is the practice of gathering intelligence from publicly available sources to support intelligence needs. In the cybersecurity arena, OSINT is used widely to discover vulnerabilities in IT systems and is commonly named Technical foot-printing.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1292695980446380000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1292695980446380032",
                        display_text_range: [0, 248],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Mon Aug 10 05:35:13 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "df5fe871a4204dbcaca5829c7de0cd27",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1268480350461677568",
                    language: {
                        text_translated_edited: "This is an awesome tweet",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "96.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1392,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "This is an awesome tweet",
                    },
                    deleted: true,
                    content: "This is an awesome tweet",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1268480350461677568",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "This is an awesome tweet",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1268480350461677600",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1268480350461677568",
                        display_text_range: [0, 24],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Thu Jun 04 09:50:57 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "ad926fbe439842ca964a06d77f72a520",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1268480312796798977",
                    language: {
                        text_translated_edited: "Hello Word 1",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "91.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 837,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Hello Word 1",
                    },
                    deleted: true,
                    content: "Hello Word 1",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1268480312796798977",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Hello Word 1",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1268480312796799000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1268480312796798977",
                        display_text_range: [0, 12],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Thu Jun 04 09:50:48 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "5f5a9d80b9fc429e9b964cd7811a0a65",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1268479720724684800",
                    language: {
                        text_translated_edited: "Hello World",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "92.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1194,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Hello World",
                    },
                    deleted: false,
                    analyst_added: true,
                    include_in_report: true,
                    nlp_run: true,
                    content: "Hello World",
                    hidden: false,
                    _id: "twitter||1268479720724684800",
                    nlpSortCounter: 1,
                    flagged: true,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: ["Illegal activities"],
                    },
                    metadata: {
                        full_text: "Hello World",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1268479720724684800",
                        },
                        favorite_count: 1,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1268479720724684800",
                        display_text_range: [0, 11],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Thu Jun 04 09:48:27 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "7925099bc7124dfcb222fd1547d0efbd",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1255074178488610816",
                    language: {
                        text_translated_edited: "ਕੋਰੋਨਾ ਟੂ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਗੂਗਲ ਦੀ ਮੁਫਤ\nకరోనా టు వార్: చైనా నుండి ఇండియా గూగుల్ యొక్క ఉచిత సేవ Corona to War: China to India Google's free service English Corona to War: China to India Google's free service English and Google's free service instantly translates words, phrases",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "30.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 564,
                                },
                                {
                                    confidence: "29.0",
                                    code: "te",
                                    name: "Telugu",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "21.0",
                                    code: "pa",
                                    name: "Punjabi",
                                    read_bytes: 1024,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "ਕੋਰੋਨਾ ਟੂ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਗੂਗਲ ਦੀ ਮੁਫਤ\nకరోనా టు వార్: చైనా నుండి ఇండియా గూగుల్ యొక్క ఉచిత సేవ Corona to War: China to India Google's free service English Corona to War: China to India Google's free service English and Google's free service instantly translates words, phrases",
                    },
                    deleted: true,
                    content: "ਕੋਰੋਨਾ ਟੂ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਗੂਗਲ ਦੀ ਮੁਫਤ\nకరోనా టు వార్: చైనా నుండి ఇండియా గూగుల్ యొక్క ఉచిత సేవ कोरोना टू वॉर: चाइना टू इंडिया गूगल की फ्री सर्विस इंग्लिश Corona to War: China to India Google's free service English and Google's free service instantly translates words, phrases",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1255074178488610816",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "ਕੋਰੋਨਾ ਟੂ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਗੂਗਲ ਦੀ ਮੁਫਤ\nకరోనా టు వార్: చైనా నుండి ఇండియా గూగుల్ యొక్క ఉచిత సేవ कोरोना टू वॉर: चाइना टू इंडिया गूगल की फ्री सर्विस इंग्लिश Corona to War: China to India Google's free service English and Google's free service instantly translates words, phrases",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1255074178488610800",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1255074178488610816",
                        display_text_range: [0, 276],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "und",
                        created_at: "Tue Apr 28 09:59:36 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "5c376e8416b64fd6a17fd32bbab440c9",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1255072374623367169",
                    language: {
                        text_translated_edited: "Corona to War: China to India Google's free service English and Google's ਵਾਰ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ ਗੂਗਲ ਦੀ ਮੁਫਤ ਅੰਗਰੇਜ਼ੀ ras phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "47.0",
                                    code: "te",
                                    name: "Telugu",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "38.0",
                                    code: "pa",
                                    name: "Punjabi",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "14.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1576,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Corona to War: China to India Google's free service English and Google's ਵਾਰ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ ਗੂਗਲ ਦੀ ਮੁਫਤ ਅੰਗਰੇਜ਼ੀ ras phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "కరోనా టు వార్: చైనా నుండి ఇండియా గూగుల్ యొక్క ఉచిత సేవ ఇంగ్లీష్ మరియు గూగుల్ యొక్క ਕੋਰੋਨਾ ਟੂ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ phrases, and web pages between English and over 100 other languages.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1255072374623367169",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "కరోనా టు వార్: చైనా నుండి ఇండియా గూగుల్ యొక్క ఉచిత సేవ ఇంగ్లీష్ మరియు గూగుల్ యొక్క ਕੋਰੋਨਾ ਟੂ ਵਾਰ: ਚੀਨ ਟੂ ਇੰਡੀਆ ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ phrases, and web pages between English and over 100 other languages.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1255072374623367200",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="http://twitter.com/download/iphone" rel="nofollow">Twitter for iPhone</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1255072374623367169",
                        display_text_range: [0, 237],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "und",
                        created_at: "Tue Apr 28 09:52:26 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "94e7162ae77447288237ed0885ef2873",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1255055352267042816",
                    language: {
                        text_translated_edited: "Corona to Jung: China to India, NASA's page for updates from the International Space Station. ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "53.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 789,
                                },
                                {
                                    confidence: "26.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1161,
                                },
                                {
                                    confidence: "20.0",
                                    code: "pa",
                                    name: "Punjabi",
                                    read_bytes: 1024,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Corona to Jung: China to India, NASA's page for updates from the International Space Station. ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "कोरोना से जंग : भारत को चीन, अंतर्राष्ट्रीय अंतरिक्ष स्टेशन से अपडेट के लिए नासा का पेज. ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1255055352267042816",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना से जंग : भारत को चीन, अंतर्राष्ट्रीय अंतरिक्ष स्टेशन से अपडेट के लिए नासा का पेज. ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1255055352267042800",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1255055352267042816",
                        display_text_range: [0, 238],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "und",
                        created_at: "Tue Apr 28 08:44:48 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "4c81d7678f1d4818bddc50c49ac01215",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1255054100489662465",
                    language: {
                        text_translated_edited: "Corona to War: China to India Google's free service English and Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "42.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1161,
                                },
                                {
                                    confidence: "32.0",
                                    code: "pa",
                                    name: "Punjabi",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "25.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 729,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Corona to War: China to India Google's free service English and Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "कोरोना से जंग : भारत को चीन ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1255054100489662465",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना से जंग : भारत को चीन ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1255054100489662500",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1255054100489662465",
                        display_text_range: [0, 177],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Tue Apr 28 08:39:49 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "0d39994555754055a1aa83184a73b051",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254715026515976197",
                    language: {
                        text_translated_edited: "RT @JeffBezos: Hey, Alexa — show everyone our upcoming Super Bowl ad. Thanks @TheEllenShow and @PortiaDeRossi! https://t.co/U8Qua3dhwg",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 792,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "RT @JeffBezos: Hey, Alexa — show everyone our upcoming Super Bowl ad. Thanks @TheEllenShow and @PortiaDeRossi! https://t.co/U8Qua3dhwg",
                    },
                    deleted: true,
                    content: "RT @JeffBezos: Hey, Alexa — show everyone our upcoming Super Bowl ad. Thanks @TheEllenShow and @PortiaDeRossi! https://t.co/U8Qua3dhwg",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254715026515976197",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "RT @JeffBezos: Hey, Alexa — show everyone our upcoming Super Bowl ad. Thanks @TheEllenShow and @PortiaDeRossi! https://t.co/U8Qua3dhwg",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254715026515976200",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [
                                {
                                    indices: [3, 13],
                                    id_str: "15506669",
                                    screen_name: "JeffBezos",
                                    name: "Jeff Bezos",
                                    id: 15506669,
                                },
                                {
                                    indices: [77, 90],
                                    id_str: "15846407",
                                    screen_name: "TheEllenShow",
                                    name: "Ellen DeGeneres",
                                    id: 15846407,
                                },
                                {
                                    indices: [95, 109],
                                    id_str: "2830872505",
                                    screen_name: "portiaderossi",
                                    name: "Portia de Rossi",
                                    id: {
                                        $numberLong: "2830872505",
                                    },
                                },
                            ],
                            hashtags: [],
                            urls: [],
                            media: [
                                {
                                    source_user_id: 15506669,
                                    source_status_id_str: "1222572705066536961",
                                    expanded_url: "https://twitter.com/JeffBezos/status/1222572705066536961/video/1",
                                    display_url: "pic.twitter.com/U8Qua3dhwg",
                                    url: "https://t.co/U8Qua3dhwg",
                                    media_url_https: "https://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                    source_user_id_str: "15506669",
                                    source_status_id: {
                                        $numberLong: "1222572705066537000",
                                    },
                                    id_str: "1222572618252840962",
                                    sizes: {
                                        small: {
                                            h: 383,
                                            w: 680,
                                            resize: "fit",
                                        },
                                        large: {
                                            h: 720,
                                            w: 1280,
                                            resize: "fit",
                                        },
                                        medium: {
                                            h: 675,
                                            w: 1200,
                                            resize: "fit",
                                        },
                                        thumb: {
                                            h: 150,
                                            w: 150,
                                            resize: "crop",
                                        },
                                    },
                                    indices: [111, 134],
                                    type: "photo",
                                    id: {
                                        $numberLong: "1222572618252841000",
                                    },
                                    media_url: "http://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                },
                            ],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254715026515976197",
                        display_text_range: [0, 134],
                        retweet_count: 1856,
                        in_reply_to_user_id: null,
                        favorited: false,
                        retweeted_status: {
                            full_text: "Hey, Alexa — show everyone our upcoming Super Bowl ad. Thanks @TheEllenShow and @PortiaDeRossi! https://t.co/U8Qua3dhwg",
                            truncated: false,
                            is_quote_status: false,
                            in_reply_to_status_id: null,
                            id: {
                                $numberLong: "1222572705066537000",
                            },
                            favorite_count: 12224,
                            contributors: null,
                            source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                            retweeted: false,
                            coordinates: null,
                            entities: {
                                symbols: [],
                                user_mentions: [
                                    {
                                        indices: [62, 75],
                                        id_str: "15846407",
                                        screen_name: "TheEllenShow",
                                        name: "Ellen DeGeneres",
                                        id: 15846407,
                                    },
                                    {
                                        indices: [80, 94],
                                        id_str: "2830872505",
                                        screen_name: "portiaderossi",
                                        name: "Portia de Rossi",
                                        id: {
                                            $numberLong: "2830872505",
                                        },
                                    },
                                ],
                                hashtags: [],
                                urls: [],
                                media: [
                                    {
                                        expanded_url: "https://twitter.com/JeffBezos/status/1222572705066536961/video/1",
                                        sizes: {
                                            small: {
                                                h: 383,
                                                w: 680,
                                                resize: "fit",
                                            },
                                            large: {
                                                h: 720,
                                                w: 1280,
                                                resize: "fit",
                                            },
                                            medium: {
                                                h: 675,
                                                w: 1200,
                                                resize: "fit",
                                            },
                                            thumb: {
                                                h: 150,
                                                w: 150,
                                                resize: "crop",
                                            },
                                        },
                                        url: "https://t.co/U8Qua3dhwg",
                                        media_url_https: "https://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                        display_url: "pic.twitter.com/U8Qua3dhwg",
                                        id_str: "1222572618252840962",
                                        indices: [96, 119],
                                        type: "photo",
                                        id: {
                                            $numberLong: "1222572618252841000",
                                        },
                                        media_url: "http://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                    },
                                ],
                            },
                            in_reply_to_screen_name: null,
                            id_str: "1222572705066536961",
                            display_text_range: [0, 95],
                            retweet_count: 1856,
                            in_reply_to_user_id: null,
                            favorited: false,
                            user: {
                                follow_request_sent: false,
                                has_extended_profile: false,
                                profile_use_background_image: true,
                                contributors_enabled: false,
                                id: 15506669,
                                default_profile: true,
                                verified: true,
                                profile_text_color: "333333",
                                profile_image_url_https: "https://pbs.twimg.com/profile_images/669103856106668033/UF3cgUk4_normal.jpg",
                                profile_sidebar_fill_color: "DDEEF6",
                                is_translator: false,
                                entities: {
                                    description: {
                                        urls: [],
                                    },
                                },
                                followers_count: 1623743,
                                protected: false,
                                location: "",
                                default_profile_image: false,
                                id_str: "15506669",
                                lang: null,
                                utc_offset: null,
                                statuses_count: 242,
                                description: "Amazon, Blue Origin, Washington Post",
                                friends_count: 1,
                                profile_link_color: "1DA1F2",
                                profile_image_url: "http://pbs.twimg.com/profile_images/669103856106668033/UF3cgUk4_normal.jpg",
                                notifications: false,
                                geo_enabled: false,
                                profile_background_color: "C0DEED",
                                profile_banner_url: "https://pbs.twimg.com/profile_banners/15506669/1448361938",
                                profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                                name: "Jeff Bezos",
                                is_translation_enabled: false,
                                profile_background_tile: false,
                                favourites_count: 1,
                                screen_name: "JeffBezos",
                                url: null,
                                created_at: "Sun Jul 20 22:38:36 +0000 2008",
                                profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                                time_zone: null,
                                profile_sidebar_border_color: "C0DEED",
                                translator_type: "none",
                                following: false,
                                listed_count: 7088,
                            },
                            geo: null,
                            in_reply_to_user_id_str: null,
                            possibly_sensitive: false,
                            lang: "en",
                            created_at: "Wed Jan 29 17:30:21 +0000 2020",
                            in_reply_to_status_id_str: null,
                            place: null,
                            extended_entities: {
                                media: [
                                    {
                                        additional_media_info: {
                                            monetizable: false,
                                        },
                                        expanded_url: "https://twitter.com/JeffBezos/status/1222572705066536961/video/1",
                                        display_url: "pic.twitter.com/U8Qua3dhwg",
                                        url: "https://t.co/U8Qua3dhwg",
                                        media_url_https: "https://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                        video_info: {
                                            aspect_ratio: [16, 9],
                                            duration_millis: 90091,
                                            variants: [
                                                {
                                                    url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/vid/1280x720/svTemHNSY69k3HSM.mp4?tag=10",
                                                    bitrate: 2176000,
                                                    content_type: "video/mp4",
                                                },
                                                {
                                                    url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/pl/dSdwYDFfAdzZMF-Y.m3u8?tag=10",
                                                    content_type: "application/x-mpegURL",
                                                },
                                                {
                                                    url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/vid/640x360/oECvwXeWmqhwkLVo.mp4?tag=10",
                                                    bitrate: 832000,
                                                    content_type: "video/mp4",
                                                },
                                                {
                                                    url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/vid/480x270/EFqJi0jzpb2s4l-D.mp4?tag=10",
                                                    bitrate: 256000,
                                                    content_type: "video/mp4",
                                                },
                                            ],
                                        },
                                        id_str: "1222572618252840962",
                                        sizes: {
                                            small: {
                                                h: 383,
                                                w: 680,
                                                resize: "fit",
                                            },
                                            large: {
                                                h: 720,
                                                w: 1280,
                                                resize: "fit",
                                            },
                                            medium: {
                                                h: 675,
                                                w: 1200,
                                                resize: "fit",
                                            },
                                            thumb: {
                                                h: 150,
                                                w: 150,
                                                resize: "crop",
                                            },
                                        },
                                        indices: [96, 119],
                                        type: "video",
                                        id: {
                                            $numberLong: "1222572618252841000",
                                        },
                                        media_url: "http://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                    },
                                ],
                            },
                        },
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        possibly_sensitive: false,
                        lang: "en",
                        created_at: "Mon Apr 27 10:12:28 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                        extended_entities: {
                            media: [
                                {
                                    additional_media_info: {
                                        source_user: {
                                            follow_request_sent: false,
                                            has_extended_profile: false,
                                            profile_use_background_image: true,
                                            contributors_enabled: false,
                                            id: 15506669,
                                            default_profile: true,
                                            verified: true,
                                            profile_text_color: "333333",
                                            profile_image_url_https: "https://pbs.twimg.com/profile_images/669103856106668033/UF3cgUk4_normal.jpg",
                                            profile_sidebar_fill_color: "DDEEF6",
                                            is_translator: false,
                                            entities: {
                                                description: {
                                                    urls: [],
                                                },
                                            },
                                            followers_count: 1623743,
                                            protected: false,
                                            location: "",
                                            default_profile_image: false,
                                            id_str: "15506669",
                                            lang: null,
                                            utc_offset: null,
                                            statuses_count: 242,
                                            description: "Amazon, Blue Origin, Washington Post",
                                            friends_count: 1,
                                            profile_link_color: "1DA1F2",
                                            profile_image_url: "http://pbs.twimg.com/profile_images/669103856106668033/UF3cgUk4_normal.jpg",
                                            notifications: false,
                                            geo_enabled: false,
                                            profile_background_color: "C0DEED",
                                            profile_banner_url: "https://pbs.twimg.com/profile_banners/15506669/1448361938",
                                            profile_background_image_url: "http://abs.twimg.com/images/themes/theme1/bg.png",
                                            name: "Jeff Bezos",
                                            is_translation_enabled: false,
                                            profile_background_tile: false,
                                            favourites_count: 1,
                                            screen_name: "JeffBezos",
                                            url: null,
                                            created_at: "Sun Jul 20 22:38:36 +0000 2008",
                                            profile_background_image_url_https: "https://abs.twimg.com/images/themes/theme1/bg.png",
                                            time_zone: null,
                                            profile_sidebar_border_color: "C0DEED",
                                            translator_type: "none",
                                            following: false,
                                            listed_count: 7088,
                                        },
                                        monetizable: false,
                                    },
                                    source_user_id: 15506669,
                                    source_status_id_str: "1222572705066536961",
                                    expanded_url: "https://twitter.com/JeffBezos/status/1222572705066536961/video/1",
                                    display_url: "pic.twitter.com/U8Qua3dhwg",
                                    url: "https://t.co/U8Qua3dhwg",
                                    media_url_https: "https://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                    source_user_id_str: "15506669",
                                    source_status_id: {
                                        $numberLong: "1222572705066537000",
                                    },
                                    video_info: {
                                        aspect_ratio: [16, 9],
                                        duration_millis: 90091,
                                        variants: [
                                            {
                                                url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/vid/1280x720/svTemHNSY69k3HSM.mp4?tag=10",
                                                bitrate: 2176000,
                                                content_type: "video/mp4",
                                            },
                                            {
                                                url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/pl/dSdwYDFfAdzZMF-Y.m3u8?tag=10",
                                                content_type: "application/x-mpegURL",
                                            },
                                            {
                                                url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/vid/640x360/oECvwXeWmqhwkLVo.mp4?tag=10",
                                                bitrate: 832000,
                                                content_type: "video/mp4",
                                            },
                                            {
                                                url: "https://video.twimg.com/ext_tw_video/1222572618252840962/pu/vid/480x270/EFqJi0jzpb2s4l-D.mp4?tag=10",
                                                bitrate: 256000,
                                                content_type: "video/mp4",
                                            },
                                        ],
                                    },
                                    id_str: "1222572618252840962",
                                    sizes: {
                                        small: {
                                            h: 383,
                                            w: 680,
                                            resize: "fit",
                                        },
                                        large: {
                                            h: 720,
                                            w: 1280,
                                            resize: "fit",
                                        },
                                        medium: {
                                            h: 675,
                                            w: 1200,
                                            resize: "fit",
                                        },
                                        thumb: {
                                            h: 150,
                                            w: 150,
                                            resize: "crop",
                                        },
                                    },
                                    indices: [111, 134],
                                    type: "video",
                                    id: {
                                        $numberLong: "1222572618252841000",
                                    },
                                    media_url: "http://pbs.twimg.com/ext_tw_video_thumb/1222572618252840962/pu/img/5Buqd-ylJa1o94V5.jpg",
                                },
                            ],
                        },
                    },
                },
                {
                    dataid: "e1febaa472834feea541a25e5e5751b1",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254695044956610561",
                    language: {
                        text_translated_edited: "कोरोना से जंग\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "74.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1161,
                                },
                                {
                                    confidence: "24.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 512,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "कोरोना से जंग\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "कोरोना से जंग\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254695044956610561",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना से जंग\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254695044956610600",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254695044956610561",
                        display_text_range: [0, 132],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Mon Apr 27 08:53:04 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "39cfd08498fd47a1ac1043b5c4ca8f0e",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254694353936642048",
                    language: {
                        text_translated_edited: "कोरोना\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "84.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1161,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "कोरोना\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "कोरोना\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254694353936642048",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254694353936642000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254694353936642048",
                        display_text_range: [0, 125],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Mon Apr 27 08:50:19 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "a1aaeba44e7a4764918b8a8ce3c3b6b0",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254684934557458432",
                    language: {
                        text_translated_edited: "Corona war: India has to pay 'double' price for China's Covid-19 test kit\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "63.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 839,
                                },
                                {
                                    confidence: "35.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1110,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Corona war: India has to pay 'double' price for China's Covid-19 test kit\nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "कोरोना से जंग : भारत को चीन की Covid-19 टेस्ट किट के लिए चुकाने पड़े 'दोगुने' दाम, ऐसे हुआ खुलासा \nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254684934557458432",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना से जंग : भारत को चीन की Covid-19 टेस्ट किट के लिए चुकाने पड़े 'दोगुने' दाम, ऐसे हुआ खुलासा \nGoogle's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254684934557458400",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254684934557458432",
                        display_text_range: [0, 217],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "und",
                        created_at: "Mon Apr 27 08:12:53 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "d2d5d0a2069641cc8527672ea7517f8f",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254684878030794755",
                    language: {
                        text_translated_edited: "War with Corona: India had to pay 'double' price for China's Covid-19 test kit, it was revealed\nGoogle's free service translates words, phrases and web pages instantly into English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "56.0",
                                    code: "pa",
                                    name: "Punjabi",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "42.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 839,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "War with Corona: India had to pay 'double' price for China's Covid-19 test kit, it was revealed\nGoogle's free service translates words, phrases and web pages instantly into English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "कोरोना से जंग : भारत को चीन की Covid-19 टेस्ट किट के लिए चुकाने पड़े 'दोगुने' दाम, ऐसे हुआ खुलासा \nਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ 100 ਤੋਂ ਵੱਧ ਹੋਰ ਭਾਸ਼ਾਵਾਂ ਵਿਚ ਤੁਰੰਤ ਸ਼ਬਦਾਂ, ਵਾਕਾਂਸ਼ਾਂ ਅਤੇ ਵੈਬ ਪੇਜਾਂ ਦਾ ਅਨੁਵਾਦ ਕਰਦੀ ਹੈ.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254684878030794755",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना से जंग : भारत को चीन की Covid-19 टेस्ट किट के लिए चुकाने पड़े 'दोगुने' दाम, ऐसे हुआ खुलासा \nਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ 100 ਤੋਂ ਵੱਧ ਹੋਰ ਭਾਸ਼ਾਵਾਂ ਵਿਚ ਤੁਰੰਤ ਸ਼ਬਦਾਂ, ਵਾਕਾਂਸ਼ਾਂ ਅਤੇ ਵੈਬ ਪੇਜਾਂ ਦਾ ਅਨੁਵਾਦ ਕਰਦੀ ਹੈ.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254684878030794800",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254684878030794755",
                        display_text_range: [0, 215],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "und",
                        created_at: "Mon Apr 27 08:12:40 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "52f5e841528e46dcac686d813d422773",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254684286378102785",
                    language: {
                        text_translated_edited: "Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "100.0",
                                    code: "gu",
                                    name: "Gujarati",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service instantly translates words, phrases, and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "ગૂગલની મફત સેવા ઇંગલિશ અને 100 થી વધુ અન્ય ભાષાઓ વચ્ચે શબ્દો, શબ્દસમૂહો અને વેબ પૃષ્ઠોને તુરંત અનુવાદિત કરે છે.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254684286378102785",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "ગૂગલની મફત સેવા ઇંગલિશ અને 100 થી વધુ અન્ય ભાષાઓ વચ્ચે શબ્દો, શબ્દસમૂહો અને વેબ પૃષ્ઠોને તુરંત અનુવાદિત કરે છે.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254684286378102800",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254684286378102785",
                        display_text_range: [0, 111],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "gu",
                        created_at: "Mon Apr 27 08:10:19 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "7af90eacbedd45e29d44f36aed560a3f",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254684153242501120",
                    language: {
                        text_translated_edited: "Free Google service instantly translates words, phrases and web pages between English and over 100 other languages",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "95.0",
                                    code: "ru",
                                    name: "Russian",
                                    read_bytes: 652,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Free Google service instantly translates words, phrases and web pages between English and over 100 other languages",
                    },
                    deleted: true,
                    content: "Бесплатный сервис Google мгновенно переводит слова, фразы и веб-страницы между английским и более чем 100 другими языками",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254684153242501120",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Бесплатный сервис Google мгновенно переводит слова, фразы и веб-страницы между английским и более чем 100 другими языками",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254684153242501000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254684153242501120",
                        display_text_range: [0, 121],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "ru",
                        created_at: "Mon Apr 27 08:09:47 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "bc7217a4ac894e53942a74240d0de6b6",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254684032530411521",
                    language: {
                        text_translated_edited: "Google's free service instantly translates words, phrases and webpages between English and over 100 languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "100.0",
                                    code: "ta",
                                    name: "Tamil",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service instantly translates words, phrases and webpages between English and over 100 languages.",
                    },
                    deleted: true,
                    content: "கூகிளின் இலவச சேவை ஆங்கிலம் மற்றும் 100 க்கும் மேற்பட்ட மொழிகளுக்கு இடையில் சொற்கள், சொற்றொடர்கள் மற்றும் வலைப்பக்கங்களை உடனடியாக மொழிபெயர்க்கிறது.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254684032530411521",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "கூகிளின் இலவச சேவை ஆங்கிலம் மற்றும் 100 க்கும் மேற்பட்ட மொழிகளுக்கு இடையில் சொற்கள், சொற்றொடர்கள் மற்றும் வலைப்பக்கங்களை உடனடியாக மொழிபெயர்க்கிறது.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254684032530411500",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254684032530411521",
                        display_text_range: [0, 147],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "ta",
                        created_at: "Mon Apr 27 08:09:18 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "d2b0e4269a8a4419bb5f88b5656d7e05",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683968063959041",
                    language: {
                        text_translated_edited: "The free Google service instantly translates words, phrases and web pages between English and 100+ languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "96.0",
                                    code: "th",
                                    name: "Thai",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "The free Google service instantly translates words, phrases and web pages between English and 100+ languages.",
                    },
                    deleted: true,
                    content: "บริการฟรีของ Google แปลคำวลีและหน้าเว็บระหว่างภาษาอังกฤษและอีกกว่า 100 ภาษาทันที",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683968063959041",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "บริการฟรีของ Google แปลคำวลีและหน้าเว็บระหว่างภาษาอังกฤษและอีกกว่า 100 ภาษาทันที",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683968063959000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683968063959041",
                        display_text_range: [0, 80],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "th",
                        created_at: "Mon Apr 27 08:09:03 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "ec13ceaf86e040428b2d244983ab1a50",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683910581059584",
                    language: {
                        text_translated_edited: "Google's free service instantly translates words, phrases and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "100.0",
                                    code: "te",
                                    name: "Telugu",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service instantly translates words, phrases and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "గూగుల్ యొక్క ఉచిత సేవ ఇంగ్లీష్ మరియు 100 కి పైగా ఇతర భాషల మధ్య పదాలు, పదబంధాలు మరియు వెబ్ పేజీలను తక్షణమే అనువదిస్తుంది.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683910581059584",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "గూగుల్ యొక్క ఉచిత సేవ ఇంగ్లీష్ మరియు 100 కి పైగా ఇతర భాషల మధ్య పదాలు, పదబంధాలు మరియు వెబ్ పేజీలను తక్షణమే అనువదిస్తుంది.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683910581059600",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683910581059584",
                        display_text_range: [0, 120],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "te",
                        created_at: "Mon Apr 27 08:08:49 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "912a88b7bab840e19c0841f3e921d32c",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683839101689856",
                    language: {
                        text_translated_edited: "Google's free service translates words, phrases and web pages instantly into English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "100.0",
                                    code: "pa",
                                    name: "Punjabi",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service translates words, phrases and web pages instantly into English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ 100 ਤੋਂ ਵੱਧ ਹੋਰ ਭਾਸ਼ਾਵਾਂ ਵਿਚ ਤੁਰੰਤ ਸ਼ਬਦਾਂ, ਵਾਕਾਂਸ਼ਾਂ ਅਤੇ ਵੈਬ ਪੇਜਾਂ ਦਾ ਅਨੁਵਾਦ ਕਰਦੀ ਹੈ.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683839101689856",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "ਗੂਗਲ ਦੀ ਮੁਫਤ ਸੇਵਾ ਅੰਗਰੇਜ਼ੀ ਅਤੇ 100 ਤੋਂ ਵੱਧ ਹੋਰ ਭਾਸ਼ਾਵਾਂ ਵਿਚ ਤੁਰੰਤ ਸ਼ਬਦਾਂ, ਵਾਕਾਂਸ਼ਾਂ ਅਤੇ ਵੈਬ ਪੇਜਾਂ ਦਾ ਅਨੁਵਾਦ ਕਰਦੀ ਹੈ.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683839101689900",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683839101689856",
                        display_text_range: [0, 116],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "pa",
                        created_at: "Mon Apr 27 08:08:32 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "7a1b0a30a9fe42c1adc00ed846a26b1f",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683782344400896",
                    language: {
                        text_translated_edited: "Google's free service instantly translates words, phrases and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "it",
                                    name: "Italian",
                                    read_bytes: 644,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service instantly translates words, phrases and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "Il servizio gratuito di Google traduce istantaneamente parole, frasi e pagine Web tra l'inglese e oltre 100 altre lingue.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683782344400896",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Il servizio gratuito di Google traduce istantaneamente parole, frasi e pagine Web tra l'inglese e oltre 100 altre lingue.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683782344401000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683782344400896",
                        display_text_range: [0, 121],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "it",
                        created_at: "Mon Apr 27 08:08:19 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "2e13ef4c17fb4f6194eb5ee282c87c7b",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683700375085059",
                    language: {
                        text_translated_edited: "Google's free service instantly translates words, phrases and web pages between English and more than 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "91.0",
                                    code: "ko",
                                    name: "Korean",
                                    read_bytes: 3625,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service instantly translates words, phrases and web pages between English and more than 100 other languages.",
                    },
                    deleted: true,
                    content: "Google의 무료 서비스는 영어와 100 개 이상의 다른 언어간에 단어, 구문 및 웹 페이지를 즉시 번역합니다.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683700375085059",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Google의 무료 서비스는 영어와 100 개 이상의 다른 언어간에 단어, 구문 및 웹 페이지를 즉시 번역합니다.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683700375085000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683700375085059",
                        display_text_range: [0, 63],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "ko",
                        created_at: "Mon Apr 27 08:07:59 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "a43e0047a11d4a63aca499b0c6bba636",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683643399696385",
                    language: {
                        text_translated_edited: "Google's free service instantly translates words, phrases and web pages between English and over 100 other languages.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "93.0",
                                    code: "ja",
                                    name: "Japanese",
                                    read_bytes: 2971,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Google's free service instantly translates words, phrases and web pages between English and over 100 other languages.",
                    },
                    deleted: true,
                    content: "Googleの無料サービスは、単語、フレーズ、ウェブページを英語と他の100以上の言語間で即座に翻訳します。",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683643399696385",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Googleの無料サービスは、単語、フレーズ、ウェブページを英語と他の100以上の言語間で即座に翻訳します。",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683643399696400",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683643399696385",
                        display_text_range: [0, 54],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "ja",
                        created_at: "Mon Apr 27 08:07:46 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "cf2773ca72104860babb0b3bcd687817",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683515020402690",
                    language: {
                        text_translated_edited: "Four thousand years ago, there was a girl named Jiang Chang. One day she felt very empty, so she went to the outskirts to play and saw a giant",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "zh",
                                    name: "Chinese",
                                    read_bytes: 1761,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: false,
                        },
                        text_translated: "Four thousand years ago, there was a girl named Jiang Chang. One day she felt very empty, so she went to the outskirts to play and saw a giant",
                    },
                    deleted: true,
                    content: "四千年前有一个姑娘叫姜嫄，她有一天觉得很空虚，就到郊外玩，看见一只巨人",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683515020402690",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "四千年前有一个姑娘叫姜嫄，她有一天觉得很空虚，就到郊外玩，看见一只巨人",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683515020402700",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683515020402690",
                        display_text_range: [0, 35],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "zh",
                        created_at: "Mon Apr 27 08:07:15 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "8ce26819296049f5bf61d70d4cc13559",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683425736290304",
                    language: {
                        text_translated_edited: "Skip to content Section. Current page, Home · Pakistan · Around",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "ur",
                                    name: "Urdu",
                                    read_bytes: 789,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Skip to content Section. Current page, Home · Pakistan · Around",
                    },
                    deleted: true,
                    content: "اردومواد پر جائیں. سیکشن. موجودہ صفحہ, صفحۂ اول · پاکستان · آس پاس",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683425736290304",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "اردومواد پر جائیں. سیکشن. موجودہ صفحہ, صفحۂ اول · پاکستان · آس پاس",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683425736290300",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683425736290304",
                        display_text_range: [0, 66],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "ur",
                        created_at: "Mon Apr 27 08:06:54 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "92f4bbec1919448983f18728fe8c0777",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683333914578945",
                    language: {
                        text_translated_edited: "BBC Arabia is a network for transmitting news, information and video clips to the world through several media, including the Internet and social media",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "ar",
                                    name: "Arabic",
                                    read_bytes: 1109,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "BBC Arabia is a network for transmitting news, information and video clips to the world through several media, including the Internet and social media",
                    },
                    deleted: true,
                    content: "بي بي سي العربية هي شبكة لنقل الأخبار والمعلومات ومقاطع الفيديو إلى العالم عبر عدة وسائط، تشمل الإنترنت ومواقع التواصل",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683333914578945",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "بي بي سي العربية هي شبكة لنقل الأخبار والمعلومات ومقاطع الفيديو إلى العالم عبر عدة وسائط، تشمل الإنترنت ومواقع التواصل",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683333914579000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683333914578945",
                        display_text_range: [0, 118],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "ar",
                        created_at: "Mon Apr 27 08:06:32 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "8e0ca6116eeb45a18bffa51c02187aca",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1254683186186969089",
                    language: {
                        text_translated_edited: "Corona war: India has to pay 'double' price for China's Covid-19 test kit",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "96.0",
                                    code: "hi",
                                    name: "Hindi",
                                    read_bytes: 839,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Corona war: India has to pay 'double' price for China's Covid-19 test kit",
                    },
                    deleted: true,
                    content: "कोरोना से जंग : भारत को चीन की Covid-19 टेस्ट किट के लिए चुकाने पड़े 'दोगुने' दाम, ऐसे हुआ खुलासा",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1254683186186969089",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "कोरोना से जंग : भारत को चीन की Covid-19 टेस्ट किट के लिए चुकाने पड़े 'दोगुने' दाम, ऐसे हुआ खुलासा",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1254683186186969000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1254683186186969089",
                        display_text_range: [0, 97],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "hi",
                        created_at: "Mon Apr 27 08:05:57 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "e5d75533df104e58aae42ddf14f61c75",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Potential addiction or substance abuse",
                            },
                        ],
                    },
                    uid: "twitter||1237712755974533122",
                    language: {
                        text_translated_edited: "To my future children. If I find weed in your room, I smoke that shit. dfgdfg  dfgdfghdfh dhghgh fghfgfgh fghfghf ",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "98.0",
                                    code: "fr",
                                    name: "French",
                                    read_bytes: 549,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "To my future children. If I find weed in your room, I smoke that shit.",
                    },
                    deleted: false,
                    content: "À mes futurs enfants. Si je trouve de l'herbe dans ta chambre, je fume cette merde.",
                    include_in_report: true,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1237712755974533122",
                    flagged: true,
                    categories: {
                        qa: ["Potential addiction or substance abuse"],
                        system: ["Potential addiction or substance abuse"],
                        analyst: ["Potential addiction or substance abuse"],
                    },
                    metadata: {
                        full_text: "À mes futurs enfants. Si je trouve de l'herbe dans ta chambre, je fume cette merde.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237712755974533000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237712755974533122",
                        display_text_range: [0, 83],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "fr",
                        created_at: "Wed Mar 11 12:11:31 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "bd245c0582734425bcd6d88f3e792118",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237712597090054144",
                    language: {
                        text_translated_edited: "The amount of marijuana injected was too high",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "98.0",
                                    code: "fr",
                                    name: "French",
                                    read_bytes: 844,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "The amount of marijuana injected was too high",
                    },
                    deleted: false,
                    content: "La quantité de marijuana injectée était trop élevée",
                    include_in_report: true,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1237712597090054144",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "La quantité de marijuana injectée était trop élevée",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237712597090054100",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237712597090054144",
                        display_text_range: [0, 51],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "fr",
                        created_at: "Wed Mar 11 12:10:53 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "6a8e5513feae4f09a149afff8b64b1d3",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237712351580680197",
                    language: {
                        text_translated_edited: "Fuck anyone who voted for the Conservatives who gave that option to the British public.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "98.0",
                                    code: "fr",
                                    name: "French",
                                    read_bytes: 608,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Fuck anyone who voted for the Conservatives who gave that option to the British public.",
                    },
                    deleted: true,
                    content: "Fuck quiconque a voté pour les conservateurs qui ont donné cette option au public britannique.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237712351580680197",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "Fuck quiconque a voté pour les conservateurs qui ont donné cette option au public britannique.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237712351580680200",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237712351580680197",
                        display_text_range: [0, 94],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "fr",
                        created_at: "Wed Mar 11 12:09:54 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "6651b8f4056b421183f98f9964a08c97",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237712083296210945",
                    language: {
                        text_translated_edited: "Some people are too beautiful to be a bitch",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "98.0",
                                    code: "fr",
                                    name: "French",
                                    read_bytes: 1394,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Some people are too beautiful to be a bitch",
                    },
                    deleted: true,
                    content: "Certaines personnes sont trop belles pour être une garce",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237712083296210945",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "Certaines personnes sont trop belles pour être une garce",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237712083296211000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237712083296210945",
                        display_text_range: [0, 56],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "fr",
                        created_at: "Wed Mar 11 12:08:50 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "dfa191ed53624cdc9e5f8d149ba0dc0c",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237711738948091904",
                    language: {
                        text_translated_edited: "Where can I find a nigga like that?",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "97.0",
                                    code: "fr",
                                    name: "French",
                                    read_bytes: 1280,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Where can I find a nigga like that?",
                    },
                    deleted: true,
                    content: "Où puis-je trouver un négro comme ça?",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237711738948091904",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "Où puis-je trouver un négro comme ça?",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237711738948092000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237711738948091904",
                        display_text_range: [0, 37],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "fr",
                        created_at: "Wed Mar 11 12:07:28 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "03773ba7c49c4d81b6922d8cf24f5d7d",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237711132296540160",
                    language: {
                        text_translated_edited: "I have dabbled in necrophilia. The wife plays dead and wishes I were dead during intercourse.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "es",
                                    name: "Spanish",
                                    read_bytes: 651,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "I have dabbled in necrophilia. The wife plays dead and wishes I were dead during intercourse.",
                    },
                    deleted: true,
                    content: "He incursionado en la necrofilia. La esposa se hace la muerta y desea que yo estuviera muerto durante el coito.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237711132296540160",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "He incursionado en la necrofilia. La esposa se hace la muerta y desea que yo estuviera muerto durante el coito.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237711132296540200",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237711132296540160",
                        display_text_range: [0, 111],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "es",
                        created_at: "Wed Mar 11 12:05:04 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "6a0996a84fc14442a5a5e648d8fe5955",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237710712060801024",
                    language: {
                        text_translated_edited: "put out your tits who cares",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "97.0",
                                    code: "es",
                                    name: "Spanish",
                                    read_bytes: 1111,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "put out your tits who cares",
                    },
                    deleted: true,
                    content: "saca tus tetas a quién le importa",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237710712060801024",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "saca tus tetas a quién le importa",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237710712060801000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237710712060801024",
                        display_text_range: [0, 33],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "es",
                        created_at: "Wed Mar 11 12:03:23 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "51177ec25a2b47eb8a2fd29c4a779bfc",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Sexually explicit content",
                            },
                        ],
                    },
                    uid: "twitter||1237710272380276738",
                    language: {
                        text_translated_edited: "I miss this pussy",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "95.0",
                                    code: "es",
                                    name: "Spanish",
                                    read_bytes: 1024,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "I miss this pussy",
                    },
                    deleted: true,
                    content: "Extraño este coño",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237710272380276738",
                    flagged: true,
                    categories: {
                        qa: ["Sexually explicit content"],
                        system: ["Sexually explicit content"],
                        analyst: ["Sexually explicit content"],
                    },
                    metadata: {
                        full_text: "Extraño este coño",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237710272380276700",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237710272380276738",
                        display_text_range: [0, 17],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "es",
                        created_at: "Wed Mar 11 12:01:39 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "54dbd296823341e8bfa90c0b128b8139",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Hate and discriminatory behaviour",
                            },
                        ],
                    },
                    uid: "twitter||1237708993595752452",
                    language: {
                        text_translated_edited: "I hate these niggers. Black people are the worst.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "98.0",
                                    code: "nl",
                                    name: "Dutch",
                                    read_bytes: 1274,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "I hate these niggers. Black people are the worst.",
                    },
                    deleted: true,
                    content: "Ik haat deze negers. Zwarte mensen zijn het ergst.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237708993595752452",
                    flagged: true,
                    categories: {
                        qa: ["Hate and discriminatory behaviour"],
                        system: ["Hate and discriminatory behaviour"],
                        analyst: ["Hate and discriminatory behaviour"],
                    },
                    metadata: {
                        full_text: "Ik haat deze negers. Zwarte mensen zijn het ergst.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237708993595752400",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237708993595752452",
                        display_text_range: [0, 50],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "nl",
                        created_at: "Wed Mar 11 11:56:34 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "c49a6248de314443b01836302b4fbd85",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Inappropriate / undesirable content",
                            },
                        ],
                    },
                    uid: "twitter||1237708716738088960",
                    language: {
                        text_translated_edited: "My coworker is an asshole asshole. He spilled coffee all over my desk and didn't have the courtesy to wipe it off.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "nl",
                                    name: "Dutch",
                                    read_bytes: 782,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "My coworker is an asshole asshole. He spilled coffee all over my desk and didn't have the courtesy to wipe it off.",
                    },
                    deleted: true,
                    content: "Mijn collega is een klootzak van een klootzak. Hij morste koffie over mijn bureau en had niet de beleefdheid om het af te vegen.",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237708716738088960",
                    flagged: true,
                    categories: {
                        qa: ["Inappropriate / undesirable content"],
                        system: ["Inappropriate / undesirable content"],
                        analyst: ["Inappropriate / undesirable content"],
                    },
                    metadata: {
                        full_text: "Mijn collega is een klootzak van een klootzak. Hij morste koffie over mijn bureau en had niet de beleefdheid om het af te vegen.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237708716738089000",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237708716738088960",
                        display_text_range: [0, 128],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "nl",
                        created_at: "Wed Mar 11 11:55:28 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "85350660c7bf438b92ef5fe24e2be8dd",
                    nlp_analysis: {
                        nlp_model: [
                            {
                                label: "Violent content",
                            },
                            {
                                label: "Hate and discriminatory behaviour",
                            },
                        ],
                    },
                    uid: "twitter||1237707929362415616",
                    language: {
                        text_translated_edited: "These goddamn politicians know only one thing: rob the country and fill their own pockets. Damn bastards, I hate this damn country.",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "nl",
                                    name: "Dutch",
                                    read_bytes: 775,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "These goddamn politicians know only one thing: rob the country and fill their own pockets. Damn bastards, I hate this damn country.",
                    },
                    deleted: false,
                    content: "Deze verdomde politici weten maar één ding: het land beroven en hun eigen zakken vullen. Verdomde klootzakken, ik haat dit verdomde land.",
                    include_in_report: true,
                    nlp_run: true,
                    hidden: false,
                    _id: "twitter||1237707929362415616",
                    flagged: true,
                    categories: {
                        qa: ["Violent content", "Hate and discriminatory behaviour"],
                        system: ["Violent content", "Hate and discriminatory behaviour"],
                        analyst: ["Violent content", "Hate and discriminatory behaviour"],
                    },
                    metadata: {
                        full_text: "Deze verdomde politici weten maar één ding: het land beroven en hun eigen zakken vullen. Verdomde klootzakken, ik haat dit verdomde land.",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237707929362415600",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237707929362415616",
                        display_text_range: [0, 137],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "nl",
                        created_at: "Wed Mar 11 11:52:20 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "6e10a3177b2b4d1c8b5213cc0180f0a0",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1237707248106762240",
                    language: {
                        text_translated_edited: "Scared about coronavirus affecting hundreds of thousands of people all over the world. Hope some laboratory could come up with a vaccination soon. #health #coronavirusindia",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "99.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 1157,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Scared about coronavirus affecting hundreds of thousands of people all over the world. Hope some laboratory could come up with a vaccination soon. #health #coronavirusindia",
                    },
                    deleted: true,
                    content: "Scared about coronavirus affecting hundreds of thousands of people all over the world. Hope some laboratory could come up with a vaccination soon. #health #coronavirusindia",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1237707248106762240",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Scared about coronavirus affecting hundreds of thousands of people all over the world. Hope some laboratory could come up with a vaccination soon. #health #coronavirusindia",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1237707248106762200",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [
                                {
                                    indices: [147, 154],
                                    text: "health",
                                },
                                {
                                    indices: [155, 172],
                                    text: "coronavirusindia",
                                },
                            ],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1237707248106762240",
                        display_text_range: [0, 172],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Wed Mar 11 11:49:38 +0000 2020",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "51428b763a07473b9ff1965d6f5c1943",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1199282469985349632",
                    language: {
                        text_translated_edited: "I welcome myself to Twitter!",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "96.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 877,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "I welcome myself to Twitter!",
                    },
                    deleted: true,
                    content: "I welcome myself to Twitter!",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1199282469985349632",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "I welcome myself to Twitter!",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1199282469985349600",
                        },
                        favorite_count: 0,
                        contributors: null,
                        source: '<a href="https://mobile.twitter.com" rel="nofollow">Twitter Web App</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1199282469985349632",
                        display_text_range: [0, 28],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Tue Nov 26 11:03:17 +0000 2019",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
                {
                    dataid: "61f78b69115a4a799c38e8a3091d91c7",
                    nlp_analysis: {
                        nlp_model: [],
                    },
                    uid: "twitter||1126559270206500864",
                    language: {
                        text_translated_edited: "Just setting up my Twitter. #myfirstTweet",
                        languages_detected: {
                            languages: [
                                {
                                    confidence: "97.0",
                                    code: "en",
                                    name: "English",
                                    read_bytes: 665,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                                {
                                    confidence: "0.0",
                                    code: "un",
                                    name: "un",
                                    read_bytes: 0,
                                },
                            ],
                            reliable: true,
                        },
                        text_translated: "Just setting up my Twitter. #myfirstTweet",
                    },
                    deleted: true,
                    content: "Just setting up my Twitter. #myfirstTweet",
                    include_in_report: false,
                    nlp_run: true,
                    hidden: true,
                    _id: "twitter||1126559270206500864",
                    flagged: false,
                    categories: {
                        qa: [],
                        system: [],
                        analyst: [],
                    },
                    metadata: {
                        full_text: "Just setting up my Twitter. #myfirstTweet",
                        truncated: false,
                        is_quote_status: false,
                        in_reply_to_status_id: null,
                        id: {
                            $numberLong: "1126559270206500900",
                        },
                        favorite_count: 2,
                        contributors: null,
                        source: '<a href="http://twitter.com" rel="nofollow">Twitter Web Client</a>',
                        retweeted: false,
                        coordinates: null,
                        entities: {
                            symbols: [],
                            user_mentions: [],
                            hashtags: [
                                {
                                    indices: [28, 41],
                                    text: "myfirstTweet",
                                },
                            ],
                            urls: [],
                        },
                        in_reply_to_screen_name: null,
                        id_str: "1126559270206500864",
                        display_text_range: [0, 41],
                        retweet_count: 0,
                        in_reply_to_user_id: null,
                        favorited: false,
                        user: {
                            follow_request_sent: false,
                            has_extended_profile: true,
                            profile_use_background_image: true,
                            contributors_enabled: false,
                            id: {
                                $numberLong: "1126553776779739100",
                            },
                            default_profile: true,
                            verified: false,
                            profile_text_color: "333333",
                            profile_image_url_https: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            profile_sidebar_fill_color: "DDEEF6",
                            is_translator: false,
                            entities: {
                                description: {
                                    urls: [],
                                },
                            },
                            followers_count: 1,
                            protected: false,
                            location: "",
                            default_profile_image: true,
                            id_str: "1126553776779739136",
                            lang: null,
                            utc_offset: null,
                            statuses_count: 43,
                            description: "G33k and travel freak! Love Automation..",
                            friends_count: 8,
                            profile_link_color: "1DA1F2",
                            profile_image_url: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png",
                            notifications: false,
                            geo_enabled: false,
                            profile_background_color: "F5F8FA",
                            profile_background_image_url: null,
                            name: "Kunal Aggarwal",
                            is_translation_enabled: false,
                            profile_background_tile: false,
                            favourites_count: 0,
                            screen_name: "aggkunal92",
                            url: null,
                            created_at: "Thu May 09 18:25:25 +0000 2019",
                            profile_background_image_url_https: null,
                            time_zone: null,
                            profile_sidebar_border_color: "C0DEED",
                            translator_type: "none",
                            following: false,
                            listed_count: 0,
                        },
                        geo: null,
                        in_reply_to_user_id_str: null,
                        lang: "en",
                        created_at: "Thu May 09 18:47:15 +0000 2019",
                        in_reply_to_status_id_str: null,
                        place: null,
                    },
                },
            ],
        },
    ],
};
