import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "keywords-selector-tool-modal",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    methods: {
        closeModal() {
            this.$modal.hide("keywords-selector-tool-modal");
        },
    },
};
