<template>
    <div class="tw-w-96">
        <div class="tw-h-8 tw-mx-2 tw-select-none tw-relative tw-w-full" v-on-click-outside="handleOutsideClick">
            <button class="tw-py-1 tw-rounded tw-flex tw-justify-between tw-ml-auto tw-items-center tw-cursor-pointer tw-text-slate-900 tw-bg-transparent tw-border-none tw-text-opacity-75" @click="toggleMenu">
                <img src="@/assets/icons/credit-card-bold.svg" />

                <div class="tw-flex tw-flex-col tw-gap-1 tw-px-4 tw-text-left" v-if="primaryCreditPack">
                    <p class="tw-mb-0 tw-truncate">{{ primaryCreditPack.name }}</p>
                    <p class="tw-mb-0 tw-text-brand" v-if="!primaryCreditPack.unlimited_credit_limit">
                        (
                        {{ primaryCreditPack.user_available_credits }} /
                        {{ primaryCreditPack.issued_credits }}
                        )
                    </p>
                    <p class="tw-mb-0 tw-text-brand" v-else>
                        (
                        {{ primaryCreditPack.user_available_credits }}
                        )
                    </p>
                    <div class="tw-h-1/2 tw-w-full tw-bg-gray-300" v-if="!primaryCreditPack.unlimited_credit_limit">
                        <div
                            class="tw-h-1 tw-bg-brand tw-rounded-md"
                            :style="{
                                width: (primaryCreditPack.user_available_credits * 100) / primaryCreditPack.issued_credits + '%',
                            }"
                        ></div>
                    </div>
                </div>

                <div class="tw-flex tw-flex-col tw-gap-1 tw-px-4 tw-text-left" v-else>
                    <p class="tw-mb-0 tw-truncate tw-font-bold ">Credit Pack Not Available</p>
                    <p class="tw-mb-0 tw-text-gray-300">
                        (-- / --)
                    </p>
                </div>

                <font-awesome-icon icon="sync" class="tw-cursor-pointer tw-mr-2 tw-outline-none" :class="{'tw-animate-spin': creditDataLoading}" color="black" size="xs" v-tippy content="Refresh credit balance" @click.stop="refreshCreditPacks"> </font-awesome-icon>

                <font-awesome-icon class="tw-ml-auto tw-mr-2 tw-transition-transform tw-transform ease-out duration-300" :class="showMenu ? ' rotate-180' : 'rotate-0'" :icon="['fas', 'chevron-down']" color="black" size="xs" />
            </button>
            <div
                v-if="showMenu"
                class="tw-bg-white tw-rounded-md shadow tw-absolute tw-right-0 
                tw-max-h-60 tw-overflow-y-auto scrollBar
                tw-cursor-pointer tw-shadow-md dark:tw-bg-gray-700
                tw-rounded-md tw-border-solid tw-border tw-border-gray-200"
            >
                <div v-for="item in handleList" :key="item.id" class="tw-h-auto tw-w-full tw-flex tw-flex-col">
                    <div @click.stop="switchCreditPack(item.credit_pack_id)" class="tw-flex tw-justify-between tw-items-center tw-p-2 tw-w-full hover:tw-bg-blue-50" :class="{'tw-bg-blue-100': item.primary}">
                        <div class="tw-flex tw-justify-between tw-items-center tw-truncate">
                            <img src="@/assets/icons/credit-card-bold.svg" class="tw-mx-2" />
                            <div class="tw-flex-col tw-mx-2 tw-truncate">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="tw-flex tw-justify-between tw-gap-2 tw-items-center">
                            <div class="tw-flex-col tw-mx-2" v-if="!item.unlimited_credit_limit">
                                <div class="tw-mx-2">({{ item.user_available_credits }} / {{ item.issued_credits }})</div>
                                <div>
                                    <div class="tw-h-1/2 tw-w-full tw-bg-gray-300">
                                        <div class="tw-h-1 tw-bg-brand tw-rounded-md" :style="{width: item.available_percentage + '%'}"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="tw-flex-col tw-mx-2" v-else>
                                <div class="tw-mx-2">( {{ item.user_available_credits }} )</div>
                            </div>
                            <div class="tw-z-0 tw-w-7 tw-h-7 tw-border-2 tw-border-white tw-rounded-full tw-bg-blue-200 tw-flex tw-justify-center tw-items-center">
                                <img src="@/assets/icons/user.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {setPrimaryCreditPack} from "../../views/credit-packs/services";
import {mapGetters} from "vuex";
import {onlyUnique} from "@/utils/functions.js";

export default {
    data() {
        return {
            showMenu: false,
        };
    },
    components: {},
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },

        handleOutsideClick() {
            this.showMenu = false;
        },

        refreshCreditPacks() {
            this.$emit("refreshCreditPacks");
        },

        async switchCreditPack(id) {
            const data = await setPrimaryCreditPack({
                tenant_id: this.getAccountId,
                credit_pack_id: id,
            });
            if (data && data.status === 200) {
                this.$toast.success("Credit pack updated.");
                this.$emit("refreshCreditPacks");
            } else this.$toast.error("Some error occurred, Please try again");
        },
    },
    props: {
        primaryCreditPack: Object,
        creditPacks: Array,
        title: {
            type: String,
            default: "",
        },
        creditDataLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(["getAccountId"]),
        handleList() {
            let list = [];
            let data = this.creditPacks
                .filter((cp) => cp.active)
                .filter(onlyUnique)
                .map((cp) => ({
                    ...cp,
                    available_percentage: parseInt((cp.user_available_credits / cp.issued_credits) * 100),
                }));

            const primary = data.filter((el) => el.primary);
            const non_primary = data.filter((el) => !el.primary);
            list = primary.concat(non_primary);
            return list;
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.scrollBar {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
.m-width {
    min-width: fit-content;
}

.text-sublabel {
    font-size: 10px;
}

.dd-wrapper {
    background: white;
    padding: 10px;
}

.-blue {
    background: #ce1b22;
}

.-blue-neutral {
    background: #e8e8e8;
}

.cst-label {
    color: var(--text-brand);
}
</style>
