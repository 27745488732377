import iamApi from "@/axios/user.js";
import store from "@/store";

export function getUserFullName(user) {
    return user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) + " " + user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1);
}

export async function getAllUsers(accountId) {
    const url = `/api/v1/accounts/${accountId}/users`;
    try {
        const response = await iamApi.get(url);
        return response.data.map((user) => ({
            ...user,
            ...{full_name: getUserFullName(user)},
        }));
    } catch (error) {
        let message = error.message;
        console.log(message);
    }
}

export async function getCreditFeatures(qParams) {
    const url = `/api/v1/credit-source/tenant/credit-source-details`;
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function getCreditInventory(qParams) {
    const url = "/api/v1/credit-pack/tenant/inventory";
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function getCreditUsage(qParams) {
    const url = "/api/v1/credit-pack/usage-logs";
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function getTenantCreditPack(qParams) {
    const url = "/api/v1/credit-pack/tenant";
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function activateCreditPack(id) {
    const url = `/api/v1/credit-pack/tenant/activate/${id}`;
    return await iamApi.post(url);
}

export async function assignCredits(data) {
    const url = "/api/v1/credit-pack/tenant/assign";
    return await iamApi.patch(url, data);
}

export async function unassignCredits(data) {
    const url = "/api/v1/credit-pack/tenant/assign";
    return await iamApi.delete(url, {
        data: data,
    });
}

export async function setPrimaryCreditPack(data) {
    const url = "/api/v1/credit-pack/user/primary";
    return await iamApi.post(url, data);
}

export async function getUserCreditPack(qParams) {
    const url = "/api/v1/credit-pack/user";
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function getUserActiveCreditPack(qParams) {
    const url = "/api/v1/credit-pack/user/active-packs";
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function getTenantFiltersData(qParams) {
    const url = "/api/v1/credit-pack/tenant/filters-data";
    return await iamApi.get(url, {
        params: qParams,
    });
}

export async function getUserFiltersData(qParams) {
    const url = "/api/v1/credit-pack/user/filters-data";
    return await iamApi.get(url, {
        params: qParams,
    });
}
