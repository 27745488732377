import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import Checkbox from "@/components/checkbox";
import loader from "@/components/loader";
import CheckboxRound from "@/components/checkbox-round";

import {startCase} from "lodash";

export default {
    name: "table",
    components: {
        "neo-checkbox": Checkbox,
        "neo-loader": loader,
        "checkbox-round": CheckboxRound,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["title", "items", "category", "search", "loading"],
    data() {
        return {
            isCheckAll: false,
            selectedItems: [],
            panel: false,
            startCase,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.selectedItems = [];
            if (this.isCheckAll) {
                for (let i = 0; i < this.items.length; i++) {
                    const element = this.items[i];
                    this.selectedItems.push(element[this.itemKey]);
                }
            }
        },
        updateCheckall: function () {
            if (this.selectedItems.length === this.items.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
    },
};
