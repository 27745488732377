import NLPAnalysis from "@/components/nlp-analysis-tool-modal/nlp-analysis";
import {EventBus} from "@/main.js";

export default {
    name: "NLPAnalysisToolModal",
    props: {},
    components: {
        "nlp-analysis": NLPAnalysis,
    },
    data() {
        return {
            showModal: false,
            options: "",
            autoAnalyzer: null,
        };
    },
    methods: {
        beforeClose(event) {
            if (this.autoAnalyzer) {
                let selectedOption = [];
                for (let option of this.options) {
                    if (option.selected) {
                        this.showModal = false;
                        break;
                    } else {
                        selectedOption.push(option);
                        this.showModal = true;
                        if (this.options.length == selectedOption.length) {
                            this.$toast.error("Atleast one option should be selected");
                            event.cancel();
                        }
                    }
                }
            }
            let count = 0;
            this.options.map((el) => {
                if (el.selected) {
                    count++;
                }
            });
            let data = {options: this.options, analyzer: this.autoAnalyzer};
            EventBus.$emit("nlpDone", count);
            EventBus.$emit("AnalyserOptions", data);
        },
    },
    mounted() {
        EventBus.$on("Analyserdata", (data) => {
            this.options = data;
        });
        EventBus.$on("autoAnalyzer", (data) => {
            this.autoAnalyzer = data;
        });
    },
};
