import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import NlpAnalysisLanguage from "@/components/nlp-analysis-tool-modal/nlp-analysis/nlp-analysis-language";
import NlpAnalysisConfiguration from "@/components/nlp-analysis-tool-modal/nlp-analysis/nlp-analysis-configuration";
import {mapGetters, mapActions} from "vuex";
import {FEATURES} from "@/utils/constants";
import {EventBus} from "@/main.js";
import axios from "@/axios";
export default {
    name: "NLPAnalysis",
    components: {
        Multiselect,
        NlpAnalysisLanguage,
        NlpAnalysisConfiguration,
    },
    computed: {
        ...mapGetters(["getAnalyzerOptions", "getPermissions", "getAutoAnalyze", "getProduct"]),
    },
    data() {
        return {
            selectedTab: "configuration",
            analyzerOptions: [],
            openAnalyzerOptions: false,
            caseIdentifiersChecked: false,
            autoAnalyzer: false,
        };
    },
    async created() {
        this.autoAnalyzer = this.getAutoAnalyze;
        this.getAnalyzerOptions.forEach((res) => {
            if (this.permissionExists(res.key)) {
                this.analyzerOptions.push(res);
            }
        });
        await this.getAnalysis();
        EventBus.$on("AnalyserOptions", (data) => {
            this.autoAnalyzer = data.analyzer;
            this.analyzerOptions = data.options;
            this.saveAnalysisDetails();
            this.getAnalysis();
        });
        EventBus.$emit("Analyserdata", this.analyzerOptions);
        EventBus.$emit("autoAnalyzer", this.autoAnalyzer);
    },
    beforeDestroy() {
        this.setAutoAnalyze(this.autoAnalyzer);
        this.setAnalayzerOptions(this.analyzerOptions);
    },
    methods: {
        ...mapActions(["setAnalayzerOptions", "setAutoAnalyze"]),
        permissionExists(key) {
            return this.getPermissions.find((res) => FEATURES.tools[res] == key);
        },
        changeSelectedTab(tabName) {
            this.selectedTab = tabName;
        },
        async saveAnalysisDetails() {
            let payload = {
                auto_analyzer: this.autoAnalyzer,
            };
            this.analyzerOptions?.forEach((el) => {
                if (el.payload_name == "topic_classification") {
                    payload.topic_classification = el.selected;
                }
                if (el.payload_name == "ner") {
                    payload.entity_recognition = el.selected;
                }
                if (el.payload_name == "esg_categorisation") {
                    payload.esg_categorisation = el.selected;
                }
                if (el.payload_name == "risk_categorisation") {
                    payload.core_risk_categorisation = el.selected;
                }
            });
            const response = await axios.post("/case-analysis/config", payload);
        },
        async getAnalysis() {
            const response = await axios.get("/case-analysis/config");
            this.autoAnalyzer = response.data.auto_analyzer;
            this.analyzerOptions?.map((el) => {
                if (el.payload_name == "topic_classification") {
                    console.log(response);
                    el.selected = response.data.topic_classification;
                }
                if (el.payload_name == "ner") {
                    el.selected = response.data.entity_recognition;
                }
                if (el.payload_name == "esg_categorisation") {
                    el.selected = response.data.esg_categorisation;
                }
                if (el.payload_name == "risk_categorisation") {
                    el.selected = response.data.core_risk_categorisation;
                }
            });
        },
        toggle(checked) {
            if (checked) {
                this.$toast.success("Auto Analyzer Turn On Successfully");
                for (let option of this.analyzerOptions) {
                    if (option.selected) {
                        this.openAnalyzerOptions = false;
                        break;
                    } else {
                        this.openAnalyzerOptions = true;
                        this.$modal.show("nlp-analysis-tool-modal");
                        option.selected = true;
                    }
                }
                EventBus.$emit("Analyserdata", this.analyzerOptions);
                EventBus.$emit("autoAnalyzer", this.autoAnalyzer);
            } else {
                this.$toast.success("Auto Analyzer Turn Off Successfully");
                this.autoAnalyzer = false;
                EventBus.$emit("autoAnalyzer", this.autoAnalyzer);
            }
        },
        async fetchCategoryProduct(res) {
            const {data} = await axios.get(`/search-engine/product/${this.getProduct.key}/category`);
            if (data.data.length > 0) {
                this.productCategories = data.data.map((prodCat) => prodCat.category_name);
                this.$forceUpdate();
                this.productCategories.map((el) => {
                    if (el == "ESG") {
                        this.analyzerOptions.push(res);
                    }
                });
            }
        },
    },
};
