import {getSocialProfileIcon, isIntersecting} from "@/utils/functions";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "neo-dropdown",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["label", "values", "width", "height", "bgColor", "borderColor", "margin", "value", "icon", "searcheable", "counts", "placeholder", "borderFull", "update"],
    data() {
        return {
            open: false,
            search: "",
        };
    },
    watch: {
        value() {
            this.search = this.value ? this.value : this.values[0];
        },
    },
    computed: {
        filteredList() {
            return this.values.filter((item) => (item ? item.toString().toLowerCase().startsWith(this.search.toLowerCase()) : ""));
        },
    },
    mounted() {
        if (this.placeholder) return;
        this.search = this.value ? this.value : this.values[0];

        window.addEventListener("click", ({x, y}) => {
            this.$parent.$children["neo-dropdown"];
        });
    },
    methods: {
        setCustomInput() {
            this.$emit("setInput", this.search);
        },
        onSelect(value) {
            this.open = false;
            this.search = value;
            this.$emit("input", value);
        },
        onToggle() {
            this.open = !this.open;
            if (open) this.$refs.input?.focus();
            this.open ? (this.search = "") : !this.open && !this.value ? (this.search = this.values[0]) : (this.search = this.value);
        },
        getSocialProfileIcon,
        getPlatformName(key) {
            if (key == "All") return key;
            let currentPlatform = this.$store.getters.getSocialPlatforms.filter((elem) => elem.key == key);
            if (currentPlatform.length > 0) return currentPlatform[0].name;
            else return key;
        },
        handleOutsideClick() {
            this.open = false;
            this.search = this.value ? this.value : "All";
        },
    },
};
