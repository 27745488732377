import JWT from "jsonwebtoken";
import {SSO_PUBLIC_CERT} from "@/utils/constants";
import iamApi from "@/axios/user";

/*
Once user has provided user credentials & token has been issued,
validate the token both remote & locally using Miniornage cert.

@param {string} token - token issued by Miniornage.
@returns {bool} - Return true if token is valid or else return false.
*/
async function validateTokeOnLogin(token) {
    try {
        let response = await iamApi.post("/api/v1/users", {token: token});
        token = response.data.token;
        let tokenValid = validateTokenLocal(token);
        if (tokenValid) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

/*
Decode token locally on client side using cert provided by Miniornage.

@param  {string} token - Token issued by Miniornage
@returns {Object} decoded token - Token decoded by the cert.
  */
function decodeToken(token) {
    let decoded;
    try {
        decoded = JWT.decode(token);
    } catch (error) {
        if (error.name === "NotBeforeError") {
            let timeSync = alert("Your system time is incorrect. Your system time is skewed by more than 2 minutes. Please make sure your system time is correct as per your time zone and try again.");
            this.$store.dispatch("logoutUser");
        }
    }
    return decoded;
}

/*
Check locally if token has expoired.

@param {string} token_exp_time - Expiry time pulled from token after decoding it.
@returns {bool} - True if the token has expired or return false.
  */
export function isTokenExpired(token_exp_time) {
    // get current time
    let ct = new Date();

    // set current time to one hour later
    ct.setMinutes(ct.getMinutes() + 60); // 60 -> one hour

    // convert string exp format to int
    const expEpoc = Number(token_exp_time);

    // convert epoch to Date object
    const expTime = Date(expEpoc);

    // check token expire time
    if (ct > expTime) return true;
    else return false;
}

/*
Validates token by our remote auth server(iam).

@param {string} token - access token.
@returns {Object|null} - If token is valid return token object or else return null.
  */
async function validateTokenRemote(token) {
    try {
        let response = await iamApi.post("/api/v1/service/verify-token", {
            access_token: token,
            token_type: "Bearer",
        });
        return response.data;
    } catch (err) {
        return null;
    }
}

/*
Validate token locally using Miniornage Cert.

@param {sting} token - access token.
@returns {bool} - If token has been validated successfully locally using Miniornage cert.
*/
function validateTokenLocal(token) {
    let decodedToken;
    try {
        decodeToken(token);
        return true;
    } catch (error) {
        console.warn("verify jwt local error ->", error);
        return false;
    }
}

/*
Logs out user from iam server

@returns {bool} - Returns true if token has been removed from server.
*/
async function logoutFromSaas() {
    const url = `api/v1/logout`;
    try {
        const resp = await iamApi.post(url);
    } catch (error) {
        throw "LogoutFailed";
    }
    return true;
}

async function getAccessToken(payload) {
    const url = "api/v1/code";
    return await iamApi.post(url, payload);
}

export {validateTokenLocal, validateTokenRemote, logoutFromSaas, validateTokeOnLogin, decodeToken, getAccessToken};
