import {STATUS} from "@/utils/constants";
import {toUpper} from "lodash";

export default {
    name: "neo-set-status",
    components: {},
    props: {
        width: String,
        value: String,
        orientation: String,
        borderWidth: {
            type: String,
            default: "1px 0 0 0",
        },
        fontSize: {
            type: String,
            default: "14px",
        },
        pd: {
            // padding around text
            type: String,
            default: "0.5rem 1.25rem",
        },
        borderRadius: {
            type: String,
            default: "0 0 0.7rem 0.7rem",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [...STATUS],
        };
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
        setValue(value) {
            this.$emit("input", value.toUpperCase());
            this.$emit("changes", value.toUpperCase());
        },
    },
};
