import axios from "../../axios/user";
import {mutations} from "./store";

const sendOtpForLogin = async (payload) => {
    try {
        const url = `/api/v1/password-login`;
        const {data} = await axios.post(url, payload);
        const {email} = payload;
        const userName = data?.user_name ? data?.user_name : email.split("@")[0];
        mutations.setUserName(userName);
        mutations.setUserEmail(email);
        mutations.setRequiredAttr(data.required_attributes);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const verifyOtp = async (payload) => {
    try {
        const url = `/api/v1/submit-mfa-challenge`;
        const {data} = await axios.post(url, payload);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const resendOtp = async (payload) => {
    try {
        const url = `/api/v1/request-mfa-challenge`;
        const {data} = await axios.post(url, payload);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const setTempPass = async (payload) => {
    try {
        const url = `/api/v1/request-reset-password`;
        const {data} = await axios.post(url, payload);
        const email = payload.email;
        const userName = data?.user_name ? data?.user_name : email.split("@")[0];
        mutations.setUserName(userName);
        mutations.setUserEmail(email);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const verifyTempPass = async (payload) => {
    try {
        const url = `/api/v1/submit-temporary-password`;
        const {data} = await axios.post(url, payload);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const setNewPass = async (payload) => {
    try {
        const url = `/api/v1/submit-permanent-password`;
        const {data} = await axios.post(url, payload);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getParamsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    urlParams.forEach((value, key) => {
        params[key] = value.trim().replace(/^"(.*)"$/, "$1");
    });

    return params;
};

export {sendOtpForLogin, verifyOtp, resendOtp, setTempPass, verifyTempPass, setNewPass, getParamsFromUrl};
