import {mapGetters} from "vuex";
import RadialProgressBar from "vue-radial-progress";

export default {
    name: "monitoring-timer",
    components: {
        RadialProgressBar,
    },
    props: {
        runtime: 0,
        frequency: 0,
        events: 0,
        timer: true,
    },
    data() {
        return {};
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {},
};
