import {mapActions, mapGetters} from "vuex";
import axios from "@/axios";
import Input from "@/components/input";
import Avatar from "@/components/avatar";
import Button from "@/components/button";
import ChartDonut from "@/containers/chart-donut";
import Extras from "@/containers/extras";
// import Graph from "@/views/map";
import GraphViz from "@/views/graph";
import Tag from "@/components/tag";
import Tags from "@/components/tags";
import {dashedCase, getSocialProfileIcon} from "@/utils/functions";
import DatePicker from "vue2-datepicker";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Dropdown from "@/components/dropdown";
import Toggle from "@/components/toggle";
import AccordionCard from "@/components/accordion";
import {startCase} from "lodash";
import {Object} from "core-js";
import TopicClassificationSVG from "@/assets/icons/topic-classification.svg";
import TreeNode from "@/components/tree-node";
import moreInfo from "@/components/neo-dv-card/more-info/index.vue";

export default {
    name: "neo-subject-preview",
    components: {
        Input,
        "neo-avatar": Avatar,
        "neo-tags": Tags,
        "neo-button": Button,
        "neo-chart-donut": ChartDonut,
        "neo-extras": Extras,
        "neo-tag": Tag,
        GraphViz,
        "neo-datepicker": DatePicker,
        VueMultiselect,
        AccordionCard,
        TreeNode,
        TopicClassificationSVG,
        "neo-dropdown": Dropdown,
        "neo-toggle": Toggle,
        moreInfo,
    },
    props: {
        expanded: Boolean,
        readOnly: Boolean,
        currentTab: String,
        options: Object,
        showGraph: {
            type: Boolean,
            default: true,
        },
        showFilters: {
            type: Boolean,
            default: true,
        },
        automated: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startCase: startCase,
            profiles: null,
            confirmed: this.getConfirmed,
            sliceEnd: 6,
            // expanded: false,
            caseDescription: "",
            // description: '',
            applyFilter: false,
            filterControls: {
                status: [],
                rag_status: [],
                riskCategories: [],
                entityFilter: [],
                selectedCaseUser: [],
                startDate: null,
                endDate: null,
                identifiersCount: [],
                identifiersMatched: [],
                identifiersInputs: [],
                identifiersTools: [],
                persons: [],
                locations: [],
                organisations: [],
                media_type: [],
                social_platform: [],
                categories: [],
                confidenceScore: [],
            },
            showNotes: false,
            activePassiveMediaMonitoringCheckbox: false,
            isCaseDescSaved: false,
            topicClassificationTerm: "",
            isMoreInfo: false,
        };
    },
    watch: {
        getConfirmed() {},
        confirmed() {},
        profiles() {
            this.profiles = this.getConfirmed["socialProfiles"];
        },
    },
    computed: {
        ...mapGetters(["getPanelStatus", "getConfirmed", "getCaseDescription", "getCombinedTags", "getUserDetails", "getCasePersonalData", "getAllConsolidatedFlags", "getReadOnlyMode", "getCaseUsers", "getPermissions", "checkPermissionsFetched", "getSelectedEntityData"]),
        tags() {
            return this.getAllConsolidatedFlags.map((cat) => {
                if (typeof cat === "object" && cat !== null) return cat;
                return {tag: cat};
            });
        },

        allCaseUsers() {
            return [
                {
                    group: "Users",
                    values: this.getCaseUsers,
                },
            ];
        },
        isGraphAuth() {
            return this.getPermissions.includes("case_graph__case_data");
        },

        isRiskCategoriesFilterDisabled() {
            return this.automated ? false : !["socialProfiles", "associatedPeople", "media", "keyFacts"].includes(this.currentTab);
        },

        isAllTopicsChecked() {
            const checkedList = this.isAllchecked(this.options.categories);
            return checkedList.length && checkedList.every((i) => i);
        },
        getTopicClassification() {
            this.options.categories.filter((item) => this.caseInsensitiveSearch(item.key, this.topicClassificationTerm));
            let searchResults = this.filterItemsRecursively(this.options.categories, this.topicClassificationTerm);
            return this.topicClassificationTerm ? searchResults : this.options.categories;
        },
        getEntityType() {
            return this.getSelectedEntityData?.entity?.entity_identifier;
        },
    },
    created() {
        this.confirmed = this.getConfirmed;
        this.profiles = this.getConfirmed["socialProfiles"];
        if (this.getCaseDescription !== "") this.caseDescription = this.getCaseDescription;
    },
    mounted() {
        // this.showNotes = sessionStorage.getItem("show-notes") ? (sessionStorage.getItem("show-notes") == "true" ? true : false) : this.showNotes;
        let data = JSON.parse(sessionStorage.getItem("research-filters"));
        this.$refs?.["topic-classification-accordion"]?.$refs?.["toggle-button"]?.click();

        if (data && data.case_id === this.$store.getters.getCaseId) {
            this.filterControls.startDate = data.st_date ? data.st_date : null;
            this.filterControls.endDate = data.end_date ? data.end_date : null;
            this.filterControls.status = data.status ? data.status : [];
            this.filterControls.rag_status = data.rag_status ? data.rag_status : [];
            this.filterControls.entityFilter = data.entity ? data.entity : [];
            this.filterControls.selectedCaseUser = data.user ? data.user : [];
            this.filterControls.riskCategories = data.rc ? data.rc : [];
            this.filterControls.confidenceScore = data.confidenceScore ? data.confidenceScore : [];
            this.filterControls.identifiersCount = data.identifiers ? data.identifiers : [];
            this.filterControls.identifiersMatched = data.identifiersMatched ? data.identifiersMatched : [];
            this.filterControls.identifiersInputs = data.identifiersInputs ? data.identifiersInputs : [];
            this.filterControls.identifiersTools = data.identifiersTools ? data.identifiersTools : [];
            this.filterControls.persons = data.persons ? data.persons : [];
            this.filterControls.locations = data.locations ? data.locations : [];
            this.filterControls.organisations = data.organisations ? data.organisations : [];
            this.filterControls.media_type = data.media_type ? data.media_type : [];
            this.filterControls.social_platform = data.social_platform ? data.social_platform : [];
            this.filterControls.categories = data.identifiers ? data.categories : [];
            this.showNotes = data.notes;
            this.activePassiveMediaMonitoringCheckbox = data.monitoring;

            if (!this.automated) this.$emit("applyFilter", {filterControls: this.filterControls, showNotes: this.showNotes, activePassiveMediaMonitoringCheckbox: this.activePassiveMediaMonitoringCheckbox});
        }
    },
    methods: {
        ...mapActions(["updateById"]),
        getSocialProfileIcon,
        dashed(string) {
            return dashedCase(string);
        },

        navigateGraph() {
            // if (this.getReadOnlyMode) {
            //     this.$router.push({ path: "/ro/" + this.$store.getters.getCaseId + "/map" }).catch(() => null);
            // } else {
            //     this.$router.push({ path: "/research/" + this.$store.getters.getCaseId + "/map" }).catch(() => null);
            // }
            // this.expanded = true;
            this.$emit("navigateGraph");
        },
        refreshGraph() {
            this.$refs["research-graph"].refresh();
        },
        compressGraph() {
            // this.expanded = false;
            this.$emit("compressGraph");
        },
        filterOnLastSendDate() {
            this.filterControls.startDate = this.getCasePersonalData.job_update.timestamp;
        },
        onTagRemoved(item) {
            this.updateById(item);
        },

        async onUserTypeChange(userType) {
            this.$store.dispatch("setUserDetails", {...this.getUserDetails, type: userType});
            try {
                let response = await axios.put("/case-personal-data", {case_id: this.$store.getters.getCaseId, case_subtype: userType});
            } catch (error) {
                this.$toast.error("Subject updated");
            }
        },
        handleApplyFilters() {
            let data = {
                case_id: this.$store.getters.getCaseId,
                st_date: this.filterControls.startDate,
                end_date: this.filterControls.endDate,
                status: this.filterControls.status,
                rag_status: this.filterControls.rag_status,
                rc: this.filterControls.riskCategories,
                confidenceScore: this.filterControls.confidenceScore,
                identifiers: this.filterControls.identifiersCount,
                identifiersMatched: this.filterControls.identifiersMatched,
                identifiersInputs: this.filterControls.identifiersInputs,
                identifiersTools: this.filterControls.identifiersTools,
                persons: this.filterControls.persons,
                locations: this.filterControls.locations,
                organisations: this.filterControls.organisations,
                media_type: this.filterControls.media_type,
                social_platform: this.filterControls.social_platform,
                categories: this.options.categories ? this.getSelectedTopics(this.options.categories) || [] : [],
                entity: this.filterControls.entityFilter,
                user: this.filterControls.selectedCaseUser,
                notes: this.showNotes,
                monitoring: this.activePassiveMediaMonitoringCheckbox,
            };
            this.$emit("applyFilter", {
                filterControls: {
                    ...this.filterControls,
                    categories: this.options.categories ? this.getSelectedTopics(this.options.categories) || [] : [],
                },
                showNotes: this.showNotes,
                activePassiveMediaMonitoringCheckbox: this.activePassiveMediaMonitoringCheckbox,
            });
            sessionStorage.setItem("research-filters", JSON.stringify(data));
        },
        clearFilters() {
            this.filterControls = {
                status: [],
                rag_status: [],
                riskCategories: [],
                confidenceScore: [],
                identifiersCount: [],
                identifiersMatched: [],
                identifiersInputs: [],
                identifiersTools: [],
                persons: [],
                locations: [],
                organisations: [],
                media_type: [],
                social_platform: [],
                categories: [],
                entityFilter: [],
                selectedCaseUser: [],
                startDate: null,
                endDate: null,
            };
            this.showNotes = false;
            this.activePassiveMediaMonitoringCheckbox = false;
            this.$emit("toggleAllTopics", false);
            this.handleApplyFilters();
        },
        filterItemsRecursively(items, query, parent) {
            let arr = [];
            for (const item of items) {
                if (this.caseInsensitiveSearch(item.category, query)) {
                    if (item.level == 1) arr.push(item);
                    else arr.push(parent);
                } else {
                    if (item?.children?.length) {
                        arr.push(...this.filterItemsRecursively(item.children, query, parent || item));
                    }
                }
            }
            return arr;
        },
        caseInsensitiveSearch(targetString, searchString) {
            const regex = new RegExp(searchString, "i");
            return regex.test(targetString);
        },

        isAllchecked(data) {
            let arr = [];
            for (let item of data) {
                arr.push(item.checked);
                if (item?.children?.length) {
                    arr.push(...this.isAllchecked(item.children));
                }
            }
            return arr;
        },

        getSelectedTopics(nodes) {
            let arr = [];
            for (let child of nodes) {
                if (child.checked) arr.push(child.category);
                if (child.children && Array.isArray(child.children) && child.children.length > 0) arr = [...arr, ...this.getSelectedTopics(child.children)];
            }
            return arr;
        },

        moreInfo() {
            this.isMoreInfo = true;
        },

        closeMoreInfo() {
            this.isMoreInfo = false;
        },

        async dataChanges(card) {
            await this.$emit("dataChanges", {
                card,
                ref: this,
            });
        },
    },
};
