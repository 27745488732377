const state = {
    filters_data: null,
};

export default {
    state,
    mutations: {
        SET_FILTERS_DATA(state, filters_data) {
            state.filters_data = filters_data;
        },
    },
    actions: {
        setFiltersData({commit}, payload) {
            commit("SET_FILTERS_DATA", payload);
        },
    },
    getters: {
        getFiltersData: (state) => state.filters_data,
    },
};
