import dashboardDataProvider from "../../services/end-points/dashboard.data-provider";

import {SET_CASE_LIST_PIE_CHART, SET_CASE_STATUS_PIE_CHART, SET_DOMAINS_PIE_CHART, SET_REG_STATUS_PIE_CHART, SET_RISK_CATEGORIES_PIE_CHART, SET_SOURCE_CATEGORIES_PIE_CHART, SET_TIME_LINE} from "./mutations-types";

export default {
    async getCaseListPieChart(ctx, payload) {
        const caseListPieChart = await dashboardDataProvider.getCaseListPieChart(payload);
        ctx.commit(SET_CASE_LIST_PIE_CHART, caseListPieChart);
    },
    async getCaseStatusPieChart(ctx, payload) {
        const caseStatusPieChart = await dashboardDataProvider.getCaseStatusPieChart(payload);
        ctx.commit(SET_CASE_STATUS_PIE_CHART, caseStatusPieChart);
    },
    async getDomainsPieChart(ctx, payload) {
        const domainsPieChart = await dashboardDataProvider.getDomainsPieChart(payload);
        ctx.commit(SET_DOMAINS_PIE_CHART, domainsPieChart);
    },
    async getRegStatusPieChart(ctx, payload) {
        const regStatusPieChart = await dashboardDataProvider.getRegStatusPieChart(payload);
        ctx.commit(SET_REG_STATUS_PIE_CHART, regStatusPieChart);
    },
    async getRiskCategoriesPieChart(ctx, payload) {
        const riskCategoriesPieChart = await dashboardDataProvider.getRiskCategoriesPieChart(payload);
        ctx.commit(SET_RISK_CATEGORIES_PIE_CHART, riskCategoriesPieChart);
    },
    async getSourceCategoriesPieChart(ctx, payload) {
        const sourceCategoriesPieChart = await dashboardDataProvider.getSourceCategoriesPieChart(payload);
        ctx.commit(SET_SOURCE_CATEGORIES_PIE_CHART, sourceCategoriesPieChart);
    },
    async getTimelineGraph(ctx, payload) {
        const timelineGraph = await dashboardDataProvider.getTimelineGraph(payload);
        ctx.commit(SET_TIME_LINE, timelineGraph);
    },
};
