let optionIcons = {
    delete: `<?xml version="1.0" encoding="UTF-8"?>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25pt" height="25pt" viewBox="0 0 25 25" version="1.1">
    <defs>
    <clipPath id="clip1">
      <path d="M 7 6.683594 L 18 6.683594 L 18 18.3125 L 7 18.3125 Z M 7 6.683594 "/>
    </clipPath>
    </defs>
    <g id="surface1">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,40%,60%);fill-opacity:1;" d="M 23.957031 12.5 C 23.957031 18.828125 18.828125 23.957031 12.5 23.957031 C 6.171875 23.957031 1.042969 18.828125 1.042969 12.5 C 1.042969 6.171875 6.171875 1.042969 12.5 1.042969 C 18.828125 1.042969 23.957031 6.171875 23.957031 12.5 Z M 23.957031 12.5 "/>
    <g clip-path="url(#clip1)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 16.976562 8.921875 L 14.734375 8.921875 C 14.734375 7.683594 13.734375 6.683594 12.5 6.683594 C 11.265625 6.683594 10.265625 7.683594 10.265625 8.921875 L 8.023438 8.921875 C 7.714844 8.921875 7.464844 9.171875 7.464844 9.480469 C 7.464844 9.789062 7.714844 10.039062 8.023438 10.039062 L 8.253906 10.039062 L 8.253906 16.859375 C 8.253906 17.664062 8.902344 18.316406 9.707031 18.316406 L 15.292969 18.316406 C 16.097656 18.316406 16.746094 17.664062 16.746094 16.859375 L 16.746094 10.039062 L 16.976562 10.039062 C 17.285156 10.039062 17.535156 9.789062 17.535156 9.480469 C 17.535156 9.171875 17.285156 8.921875 16.976562 8.921875 Z M 12.5 7.800781 C 13.117188 7.800781 13.617188 8.300781 13.617188 8.921875 L 11.382812 8.921875 C 11.382812 8.300781 11.882812 7.800781 12.5 7.800781 Z M 15.628906 16.859375 C 15.628906 17.046875 15.480469 17.199219 15.292969 17.199219 L 9.707031 17.199219 C 9.519531 17.199219 9.371094 17.046875 9.371094 16.859375 L 9.371094 10.039062 L 15.628906 10.039062 Z M 15.628906 16.859375 "/>
    </g>
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,73.333333%,75.294118%);fill-opacity:1;" d="M 11.269531 15.292969 C 10.960938 15.292969 10.710938 15.042969 10.710938 14.734375 L 10.710938 12.5 C 10.710938 12.191406 10.960938 11.941406 11.269531 11.941406 C 11.578125 11.941406 11.828125 12.191406 11.828125 12.5 L 11.828125 14.734375 C 11.828125 15.042969 11.578125 15.292969 11.269531 15.292969 Z M 13.730469 15.292969 C 13.421875 15.292969 13.171875 15.042969 13.171875 14.734375 L 13.171875 12.5 C 13.171875 12.191406 13.421875 11.941406 13.730469 11.941406 C 14.039062 11.941406 14.289062 12.191406 14.289062 12.5 L 14.289062 14.734375 C 14.289062 15.042969 14.039062 15.292969 13.730469 15.292969 Z M 13.730469 15.292969 "/>
    </g>
    </svg>         
    `,

    link: `<?xml version="1.0" encoding="UTF-8"?>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25pt" height="25pt" viewBox="0 0 25 25" version="1.1">
    <defs>
    <clipPath id="clip1">
      <path d="M 1.042969 1.042969 L 23.960938 1.042969 L 23.960938 23.960938 L 1.042969 23.960938 Z M 1.042969 1.042969 "/>
    </clipPath>
    <clipPath id="clip2">
      <path d="M 7.171875 7.164062 L 17.832031 7.164062 L 17.832031 17.824219 L 7.171875 17.824219 Z M 7.171875 7.164062 "/>
    </clipPath>
    <clipPath id="clip3">
      <path d="M 11.125 7.164062 L 17.832031 7.164062 L 17.832031 13.875 L 11.125 13.875 Z M 11.125 7.164062 "/>
    </clipPath>
    </defs>
    <g id="surface1">
    <g clip-path="url(#clip1)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(52.941176%,74.509804%,99.215686%);fill-opacity:1;" d="M 23.003906 12.5 C 23.003906 18.300781 18.300781 23.003906 12.5 23.003906 C 6.699219 23.003906 1.996094 18.300781 1.996094 12.5 C 1.996094 6.699219 6.699219 1.996094 12.5 1.996094 C 18.300781 1.996094 23.003906 6.699219 23.003906 12.5 Z M 23.003906 12.5 "/>
    </g>
    <g clip-path="url(#clip2)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 7.171875 8.171875 C 7.21875 8.070312 7.265625 7.972656 7.3125 7.878906 C 7.535156 7.441406 7.878906 7.175781 8.390625 7.171875 C 9.375 7.171875 10.367188 7.167969 11.351562 7.171875 C 11.734375 7.171875 11.972656 7.394531 11.964844 7.714844 C 11.960938 8.027344 11.726562 8.226562 11.355469 8.230469 C 10.480469 8.234375 9.601562 8.230469 8.722656 8.230469 C 8.324219 8.230469 8.238281 8.320312 8.238281 8.722656 C 8.238281 11.230469 8.238281 13.734375 8.238281 16.246094 C 8.238281 16.667969 8.324219 16.753906 8.742188 16.753906 C 11.253906 16.753906 13.757812 16.753906 16.265625 16.753906 C 16.679688 16.753906 16.765625 16.667969 16.765625 16.238281 C 16.765625 15.382812 16.765625 14.53125 16.765625 13.675781 C 16.765625 13.257812 16.972656 13.015625 17.308594 13.023438 C 17.632812 13.03125 17.824219 13.265625 17.828125 13.667969 C 17.832031 14.53125 17.8125 15.398438 17.839844 16.261719 C 17.855469 16.996094 17.59375 17.527344 16.898438 17.816406 C 13.96875 17.816406 11.039062 17.816406 8.105469 17.816406 C 7.625 17.644531 7.351562 17.285156 7.171875 16.824219 C 7.171875 13.941406 7.171875 11.054688 7.171875 8.171875 Z M 7.171875 8.171875 "/>
    </g>
    <g clip-path="url(#clip3)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 15.917969 8.234375 C 15.480469 8.234375 15.105469 8.242188 14.734375 8.234375 C 14.34375 8.230469 14.105469 8.03125 14.105469 7.707031 C 14.101562 7.382812 14.335938 7.175781 14.726562 7.171875 C 15.5625 7.167969 16.390625 7.167969 17.222656 7.171875 C 17.628906 7.171875 17.824219 7.371094 17.824219 7.792969 C 17.828125 8.621094 17.828125 9.457031 17.824219 10.285156 C 17.824219 10.65625 17.621094 10.886719 17.308594 10.894531 C 16.988281 10.902344 16.769531 10.667969 16.765625 10.28125 C 16.761719 9.90625 16.765625 9.53125 16.765625 9.085938 C 16.632812 9.207031 16.546875 9.28125 16.46875 9.359375 C 15.324219 10.503906 14.179688 11.652344 13.03125 12.792969 C 12.953125 12.871094 12.875 12.957031 12.785156 13.015625 C 12.53125 13.167969 12.277344 13.164062 12.050781 12.953125 C 11.847656 12.757812 11.816406 12.429688 11.984375 12.195312 C 12.0625 12.089844 12.167969 12 12.257812 11.90625 C 13.378906 10.785156 14.5 9.660156 15.621094 8.539062 C 15.707031 8.460938 15.789062 8.375 15.917969 8.234375 Z M 15.917969 8.234375 "/>
    </g>
    </g>
    </svg>    
    `,

    edit: `<?xml version="1.0" encoding="UTF-8"?>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25pt" height="25pt" viewBox="0 0 25 25" version="1.1">
    <defs>
    <clipPath id="clip1">
      <path d="M 6.683594 6.683594 L 18.3125 6.683594 L 18.3125 18.3125 L 6.683594 18.3125 Z M 6.683594 6.683594 "/>
    </clipPath>
    </defs>
    <g id="surface1">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(61.568627%,92.156863%,77.254902%);fill-opacity:1;" d="M 23.957031 12.5 C 23.957031 18.828125 18.828125 23.957031 12.5 23.957031 C 6.171875 23.957031 1.042969 18.828125 1.042969 12.5 C 1.042969 6.171875 6.171875 1.042969 12.5 1.042969 C 18.828125 1.042969 23.957031 6.171875 23.957031 12.5 Z M 23.957031 12.5 "/>
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 8.351562 13.773438 L 13.800781 8.320312 L 16.679688 11.203125 L 11.230469 16.652344 Z M 8.351562 13.773438 "/>
    <g clip-path="url(#clip1)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 18.140625 9.003906 L 16.015625 6.84375 C 15.804688 6.632812 15.472656 6.632812 15.273438 6.832031 C 15.273438 6.832031 15.273438 6.832031 15.261719 6.84375 L 14.324219 7.78125 L 17.207031 10.675781 L 18.140625 9.742188 C 18.351562 9.53125 18.351562 9.199219 18.140625 9.003906 Z M 7.929688 14.402344 L 6.734375 17.601562 C 6.628906 17.875 6.765625 18.175781 7.039062 18.28125 C 7.160156 18.328125 7.292969 18.328125 7.414062 18.28125 L 10.613281 17.074219 Z M 7.929688 14.402344 "/>
    </g>
    </g>
    </svg>
    `,
    expand: `<?xml version="1.0" encoding="UTF-8"?>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25pt" height="25pt" viewBox="0 0 25 25" version="1.1">
    <g id="surface1">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,50.196078%,40%);fill-opacity:1;" d="M 23.957031 12.5 C 23.957031 18.828125 18.828125 23.957031 12.5 23.957031 C 6.171875 23.957031 1.042969 18.828125 1.042969 12.5 C 1.042969 6.171875 6.171875 1.042969 12.5 1.042969 C 18.828125 1.042969 23.957031 6.171875 23.957031 12.5 Z M 23.957031 12.5 "/>
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 11.0625 13.1875 L 7.1875 17.0625 L 7.1875 15.6875 C 7.1875 15.394531 6.949219 15.15625 6.65625 15.15625 C 6.363281 15.15625 6.125 15.394531 6.125 15.6875 L 6.125 18.34375 C 6.125 18.636719 6.363281 18.875 6.65625 18.875 L 9.3125 18.875 C 9.605469 18.875 9.84375 18.636719 9.84375 18.34375 C 9.84375 18.050781 9.605469 17.8125 9.3125 17.8125 L 7.9375 17.8125 L 11.8125 13.9375 C 12.019531 13.730469 12.019531 13.394531 11.8125 13.1875 C 11.605469 12.980469 11.269531 12.980469 11.0625 13.1875 Z M 18.34375 6.125 L 15.6875 6.125 C 15.394531 6.125 15.15625 6.363281 15.15625 6.65625 C 15.15625 6.949219 15.394531 7.1875 15.6875 7.1875 L 17.0625 7.1875 L 13.1875 11.0625 C 12.980469 11.269531 12.980469 11.605469 13.1875 11.8125 C 13.289062 11.917969 13.425781 11.96875 13.5625 11.96875 C 13.699219 11.96875 13.835938 11.917969 13.9375 11.8125 L 17.8125 7.9375 L 17.8125 9.3125 C 17.8125 9.605469 18.050781 9.84375 18.34375 9.84375 C 18.636719 9.84375 18.875 9.605469 18.875 9.3125 L 18.875 6.65625 C 18.875 6.363281 18.636719 6.125 18.34375 6.125 Z M 18.34375 6.125 "/>
    </g>
    </svg>
    `,
    collapse: `<?xml version="1.0" encoding="UTF-8"?>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25pt" height="25pt" viewBox="0 0 25 25" version="1.1">
    <defs>
    <clipPath id="clip1">
      <path d="M 5.5 13 L 12 13 L 12 19.5 L 5.5 19.5 Z M 5.5 13 "/>
    </clipPath>
    <clipPath id="clip2">
      <path d="M 13 13 L 19.5 13 L 19.5 19.5 L 13 19.5 Z M 13 13 "/>
    </clipPath>
    <clipPath id="clip3">
      <path d="M 13 5.5 L 19.5 5.5 L 19.5 12 L 13 12 Z M 13 5.5 "/>
    </clipPath>
    <clipPath id="clip4">
      <path d="M 5.5 5.5 L 12 5.5 L 12 12 L 5.5 12 Z M 5.5 5.5 "/>
    </clipPath>
    </defs>
    <g id="surface1">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,50.196078%,40%);fill-opacity:1;" d="M 23.957031 12.5 C 23.957031 18.828125 18.828125 23.957031 12.5 23.957031 C 6.171875 23.957031 1.042969 18.828125 1.042969 12.5 C 1.042969 6.171875 6.171875 1.042969 12.5 1.042969 C 18.828125 1.042969 23.957031 6.171875 23.957031 12.5 Z M 23.957031 12.5 "/>
    <g clip-path="url(#clip1)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 6.398438 15.199219 L 8.648438 15.199219 L 5.5 18.347656 L 6.554688 19.402344 L 9.703125 16.253906 L 9.703125 18.503906 L 11.195312 18.503906 L 11.195312 13.707031 L 6.398438 13.707031 Z M 6.398438 15.199219 "/>
    </g>
    <g clip-path="url(#clip2)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 18.601562 15.199219 L 18.601562 13.707031 L 13.804688 13.707031 L 13.804688 18.503906 L 15.296875 18.503906 L 15.296875 16.253906 L 18.445312 19.402344 L 19.5 18.347656 L 16.351562 15.199219 Z M 18.601562 15.199219 "/>
    </g>
    <g clip-path="url(#clip3)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 18.601562 9.800781 L 16.351562 9.800781 L 19.5 6.652344 L 18.445312 5.597656 L 15.296875 8.746094 L 15.296875 6.496094 L 13.804688 6.496094 L 13.804688 11.292969 L 18.601562 11.292969 Z M 18.601562 9.800781 "/>
    </g>
    <g clip-path="url(#clip4)" clip-rule="nonzero">
    <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 9.703125 8.746094 L 6.554688 5.597656 L 5.5 6.652344 L 8.648438 9.800781 L 6.398438 9.800781 L 6.398438 11.292969 L 11.195312 11.292969 L 11.195312 6.496094 L 9.703125 6.496094 Z M 9.703125 8.746094 "/>
    </g>
    </g>
    </svg>
    `,
};

export default optionIcons;
