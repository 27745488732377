import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";

export default {
    name: "neo-dropdown",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["label", "values", "width", "height", "bgColor", "borderColor", "margin", "value", "icon", "searchable", "counts", "placeholder", "borderFull", "update", "select", "disabled"],
    data() {
        return {
            open: false,
            search: "",
            popout: false,
            selection: null,
        };
    },
    watch: {
        value() {
            this.search = this.value ? this.value : this.values[0];
        },
    },
    computed: {
        ...mapGetters(["getCaseFreezeDate"]),
        filteredList() {
            return this.values.filter((item) => item || "");
        },
    },
    mounted() {
        if (this.placeholder) return;
        this.search = this.value ? this.value : this.values[0];

        window.addEventListener("click", () => {
            this.$parent.$children["neo-dropdown"];
        });
    },
    methods: {
        onSelect(item) {
            this.open = false;
            this.search = item.text;
            this.$emit("input", item.text);
            this.popout = item.popout;
            this.selection = item;
        },
        onToggle() {
            if (!this.disabled) {
                this.open = !this.open;
                if (open) this.$refs.input?.focus();
                this.open ? (this.search = "") : !this.open && !this.value ? (this.search = this.values[0]) : (this.search = this.value);
            }
        },
        handleOutside() {
            this.open = false;
        },
    },
};
