import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import MyUploadAdapter from "./uploadImg";

export default {
    name: "ck-editor-modal",
    components: {},
    props: [],
    data() {
        return {
            editor: ClassicEditor,
            ed: null,
            // editorData: "",
            // editorConfig: {
            //     extraPlugins: [this.MyCustomUploadAdapterPlugin],
            //     toolbar: {
            //         items: ["heading", "|", "bold", "italic", "|", "link", "|", "outdent", "indent", "|", "bulletedList", "numberedList", "|", "insertTable", "|", "blockQuote", "|", "mediaEmbed", "|", "undo", "redo", "|", "uploadImage"],
            //         shouldNotGroupWhenFull: true,
            //     },
            //     // The configuration of the editor.
            //     // ckfinder: {
            //     //     uploadUrl: '/uploads/reports/images'
            //     // }
            //     // plugins: [SimpleUploadAdapter],
            //     // toolbar: [ ... ],
            //     link: {
            //         decorators: {
            //             openInNewTab: {
            //                 mode: "manual",
            //                 defaultValue: true,
            //                 label: "Open link in a new tab",
            //                 attributes: {
            //                     target: "_blank",
            //                     rel: "noopener noreferrer",
            //                 },
            //             },
            //         },
            //     },
            //     mediaEmbed: {
            //         previewsInData: true,
            //     },
            //     // simpleUpload: {
            //     //     // The URL that the images are uploaded to.
            //     //     uploadUrl: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1/uploads/reports/images`,

            //     //     // Enable the XMLHttpRequest.withCredentials property.
            //     //     withCredentials: true,

            //     //     // Headers sent along with the XMLHttpRequest to the upload server.
            //     //     headers: {
            //     //         Accept: "application/json",
            //     //         Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM="
            //     //     },
            //     // },
            // },
            // key: "",
            // card: null,
            // currentTab: null,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        beforeOpen({params}) {
            this.editorData = params.card[params.key];
            this.key = params.key;
            this.card = params.card;
            this.currentTab = params.currentTab;
        },
        handleSave() {
            this.card[this.key] = this.editorData;
            this.$emit("dataChanges", this.card, this.card, this.currentTab);
            this.$modal.hide("ck-editor-modal");
        },
        MyCustomUploadAdapterPlugin(editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
                return new MyUploadAdapter(loader);
            };
        },
    },
};
