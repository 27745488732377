import { render, staticRenderFns } from "./nlp-analysis-language.html?vue&type=template&id=18e31d28&scoped=true&"
import script from "./nlp-analysis-language.js?vue&type=script&lang=js&"
export * from "./nlp-analysis-language.js?vue&type=script&lang=js&"
import style0 from "./nlp-analysis-language.scss?vue&type=style&index=0&id=18e31d28&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e31d28",
  null
  
)

export default component.exports