import {mapActions} from "vuex";

export default {
    name: "neo-restore-btn",
    components: {},
    props: ["content", "size", "tab"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        ...mapActions(["popIrrelevant", "filterData"]),
        onRestore() {
            // this.popIrrelevant({ item: this.content, tab: this.tab });
            this.$emit("PopIrrelevant");
            // this.filterData({currentTab:this.tab});
        },
    },
};
