export default {
    name: "nlp-analysis-language",
    props: ["changeTab"],
    data() {
        return {
            languageOptions: ["English", "Spanish", "French", "German", "Russian", "Italian", "Swedish", "Danish", "Portuguese", "Dutch", "Polish", "Arabic", "chinese (simplified)", "Chinese (traditional)", "Urdu", "Japanese", "Norwegian", "Hindi", "Bengali", "Turkish"],
        };
    },
    computed: {
        languageSelected() {
            return this.$store.getters.getBadWordsLanguages;
        },
        allSelected() {
            return this.languageSelected === ["All Languages"];
        },
    },
    mounted() {
        // chrome.storage.local.get({ badWordsLanguages: ["All Languages"] }, (store) => {
        //     this.languageSelected = store.badWordsLanguages;
        // });
    },
    methods: {
        selectAllLanguages() {
            this.$store.dispatch("setBadWordsLanguages", ["All Languages"]);
        },
        toggleLanguage(lang) {
            if (this.languageSelected.length === 1 && this.languageSelected[0] === "All Languages") {
                this.$store.dispatch("setBadWordsLanguages", [lang]);
            } else {
                this.$store.dispatch("toggleBadWordsLanguage", lang);
            }
        },
    },
};
