import toggleSwitch from "@/components/toggle-switch";
import {cloneDeepWith, uniq} from "lodash";
import {mapGetters} from "vuex";
import {FEATURES} from "@/utils/constants";

export default {
    name: "smart-analyser",
    components: {
        toggleSwitch,
    },
    props: {
        value: {
            type: String,
        },
        source: {
            type: String,
        },
        fromGraph: {
            type: Boolean,
        },
    },
    data() {
        return {
            selectedTools: [],
            // allToolsCopy: {},
            toolsCopy: {},
            isEdit: false,
            isEdited: false,
            inputValue: "",
            isError: true,
        };
    },
    watch: {},
    computed: {
        ...mapGetters(["getAllToolNames", "getPermissions", "getEntitiesRelationsAttributesData"]),
        selectedToolsLength() {
            if (this.selectedTools.length === 0) {
                return "No tools selected";
            } else if (this.selectedTools.length === 1) {
                return "1 tool selected";
            } else {
                return `${this.selectedTools.length} tools selected`;
            }
        },
        allToolsCopy() {
            return this.getAllToolNames ? cloneDeepWith(this.getAllToolNames) : {};
        },

        toolsMapping() {
            let headings = uniq(this.allToolsCopy?.children?.map((item) => item.heading));
            let tools = [];
            // let permissions = this.getPermissions.map(e => e.split('__')[0].replace('_',' '))
            // && permissions.includes(item.text.toLowerCase())
            headings.forEach((heading) => {
                tools.push({
                    heading: heading,
                    items: {
                        children: this.allToolsCopy?.children?.filter((item) => item.heading === heading && item.sources.includes(this.source)).filter((ele) => this.getPermissions.find((ele1) => FEATURES["tools"][ele1] == ele.activeProcess)),
                    },
                });
            });
            return tools;
        },
    },
    mounted() {
        this.toolsCopy = cloneDeepWith(this.getAllToolNames);
        // this.inputValue = this.value
    },
    methods: {
        handleSelectedTool(tool) {
            if (this.selectedTools.includes(tool.activeProcess)) {
                this.selectedTools = this.selectedTools.filter((e) => e !== tool.activeProcess);
                // tool.active = false
            } else {
                this.selectedTools.push(tool.activeProcess);
                // tool.active = true;
            }
            this.$forceUpdate();
        },
        handleSave() {
            if (this.inputValue.trim() === "") {
                this.isError = true;
            } else {
                this.isEdited = true;
                this.isEdit = false;
            }
        },
        handleEdit() {
            this.isEdit = true;
            if (this.inputValue === "") {
                this.inputValue = this.value;
            }
        },
        handleReset() {
            this.isEdit = false;
            this.isEdited = false;
            if (this.inputValue !== "") {
                this.inputValue = this.value;
            }
        },
        handleToolSelection() {
            if (this.selectedTools.length === 0) {
                // this.selectedTools = [...this.allToolsCopy.children.filter((e) => e.sources.includes(this.source)).map((e) => e.activeProcess)];
                this.selectedTools = [...this.toolsMapping.map((el) => el.items.children.map((chi) => chi.activeProcess))].flat();
                this.$forceUpdate();
            } else {
                this.clearAllTools();
            }
        },
        clearAllTools() {
            this.selectedTools = [];
            this.$forceUpdate();
            if (this.fromGraph) {
                // this.inputValue = "";
                if (this.isEdited) {
                    this.isEdit = false;
                }
            }
        },
        openAllToolGraph() {
            this.selectedTools = [];
            this.inputValue = "";
            this.isEdit = false;
            this.isEdited = false;
        },
        getGridLayout(length) {
            if (length > 4) return "tw-grid-cols-4";
            else return `tw-grid-cols-${length}`;
        },
        handleRunSelected() {
            let analyser = {tools: this.selectedTools, value: this.isEdited ? this.inputValue : this.value};
            this.$emit("runSelectedTool", analyser);
        },
        handleQuickSave() {
            let source = this.source;
            if (source == "url" || source == "facebook_id") {
                source = "website";
            }
            let entityData = null;
            if (source === "company_name") {
                entityData = this.getEntitiesRelationsAttributesData?.entities?.find((n) => n.entity == "New Company");
            } else if (source === "name") {
                entityData = this.getEntitiesRelationsAttributesData?.entities?.find((n) => n.entity == "New Person");
            } else {
                entityData = this.getEntitiesRelationsAttributesData?.entities?.find((n) => n.entity_identifier == source);
            }
            if (entityData) {
                // const payload = {
                //     msg: "popupSidepanel",
                //     value: this.value,
                //     datatype: "entity",
                //     item: entityData,
                //     userDefined: true,
                //     text: this.value,
                //     edit: false,
                // };
                this.$store.dispatch("setSelectedEntityData", {
                    all_data: {entity: this.value, add_graph: true},
                    datatype: "entity",
                    editEntity: true,
                    entity: entityData,
                    text: this.value,
                    userDefined: true,
                });

                this.$store.dispatch("showAddEntityPanel");
                // if (value.doc_type === "relation") payload.relationType = this.getEntitiesRelationsAttributesData.relations.find((n) => n._id == value.mapper[0].map_id);
                // this.openExtension(payload);
                this.$modal.hide("analyzer");
            }
        },
    },
};
