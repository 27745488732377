// import axios from "axios";
import {jwtBlackboxApi} from "../main.js";

const url = {
    adminReportTemplates: "/report-templates-admin",
    productReportTemplates: "/report-templates-admin/product",
    downloadReportTemplates: (file) => `/report-templates-admin/presigned-url/${file}`,
    priorityProductReportTemplates: "report-templates-admin/product/template/priority",
};

export default {
    state: () => ({
        adminReportTemplates: [],
        productReportTemplates: [],
    }),
    getters: {},
    mutations: {
        // For now, there is no need to use setters and save data at store because we use data only in one component
        // SET_ADMIN_REPORT_TEMPLATES(state, payload) {
        //     state.adminReportTemplates = payload;
        // },
        RESET_ADMIN_REPORT_TEMPLATES(state) {
            state.adminReportTemplates = [];
        },
        // SET_PRODUCT_REPORT_TEMPLATES(state, payload) {
        //     state.productReportTemplates = payload;
        // },
        RESET_PRODUCT_REPORT_TEMPLATES(state) {
            state.adminReportTemplates = [];
            state.productReportTemplates = [];
        },
    },
    actions: {
        getAdminReportTemplates: async ({commit}) => {
            let {data} = await jwtBlackboxApi.get(url.adminReportTemplates);
            // commit("SET_ADMIN_REPORT_TEMPLATES", data);
            return data;
        },
        postAdminReportTemplates: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.post(url.adminReportTemplates, payload);
            if (!data.status) throw new Error(data.message);
            return data;
        },
        putAdminReportTemplates: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.put(url.adminReportTemplates, payload);
            if (!data.status) throw new Error(data.message);
            return data;
        },
        deleteAdminReportTemplates: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.delete(url.adminReportTemplates, {params: payload});
            return data;
        },
        getProductReportTemplates: async ({commit}, productId) => {
            let {data: productList} = await jwtBlackboxApi.get(`${url.productReportTemplates}/${productId}`);
            let {data: list} = await jwtBlackboxApi.get(url.adminReportTemplates);
            const result = list.map((item) => {
                const matched = productList.find((el) => el.template_details[0]?.report_templates_id === item.report_templates_id);
                return {
                    ...item,
                    checked: !!matched,
                    product_template_id: matched?._id || null,
                };
            });
            // commit("SET_PRODUCT_REPORT_TEMPLATES", result);
            return result;
        },
        postProductReportTemplates: async ({commit}, payload) => {
            const {data} = await jwtBlackboxApi.post(url.productReportTemplates, payload);
            if (!data.status) throw new Error(data.message);
            return data;
        },
        downloadProductReportTemplates: async ({commit}, payload) => {
            const {data} = await jwtBlackboxApi.get(url.downloadReportTemplates(payload));
            return data.url;
        },
        priorityProductReportTemplates: async ({commit}, payload) => {
            const {data} = await jwtBlackboxApi.put(url.priorityProductReportTemplates, payload);
            if (!data.status) throw new Error(data.message);
            return data;
        },
    },
};
