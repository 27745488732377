export const SAMPLE_NODES = {
    entities: [
        {
            _id: "5f2bc6a1bf16562e2f9c62b8",
            index: 0,
            isActive: true,
            value: "Tomas Blake",
            type: "subject",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1bf16562e2f9c620ol",
            index: 0,
            isActive: false,
            value: "John Blake",
            type: "relation",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1bf16562e2f9c620wer",
            index: 0,
            isActive: false,
            value: "John Blake",
            type: "main",
            color: "#811DBF",
            group: 1,
        },
        {
            _id: "5f2bc6a1bf16562e2f9c620iil",
            index: 0,
            isActive: false,
            value: "JP Morgan",
            type: "bank",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1bf16562e2f9c620pok",
            index: 0,
            isActive: false,
            value: "Emily Wills",
            type: "relation",
            color: "#811DBF",
            group: 1,
        },
        {
            _id: "5f2bc6a1bf16562e2f9c620yyh",
            index: 0,
            isActive: false,
            value: "Oxford University College",
            type: "education",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1bf16562e2f9c620pere",
            index: 0,
            isActive: false,
            value: "Yane Jor",
            type: "relation",
            color: "#811DBF",
            group: 1,
        },
        {
            _id: "5f2bc6a1bf16562e2f9c620iiie",
            index: 0,
            isActive: false,
            value: "Gambling",
            type: "activity",
            color: "#811DBF",
            group: 1,
        },
        {
            _id: "5f2bc6a1bf16562e2f9c620oou",
            index: 0,
            isActive: false,
            value: "YC United ",
            type: "work",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1bf16562e2f9c62oil",
            index: 0,
            isActive: false,
            value: "Wills Fashion",
            type: "company",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1bf16562e2f9c62uyt",
            index: 0,
            isActive: false,
            value: "Arrested",
            type: "crime",
            color: "#811DBF",
            group: 1,
        },

        {
            _id: "5f2bc6a1c39c851d3d7b6fww",
            index: 25,
            isActive: false,
            value: "457 748 8759",
            type: "phone",
            color: "#20BAA6",
            group: 1,
        },
        {
            _id: "5f2bc6a1c39c851d3d7b6opljw",
            index: 25,
            isActive: false,
            value: "Harvard Edu.",
            type: "education",
            color: "#20BAA6",
            group: 1,
        },

        {
            _id: "5f2bc6a1c39c851d3d7b6op0olll",
            index: 25,
            isActive: false,
            value: "Research Paper",
            type: "google",
            color: "#20BAA6",
            group: 1,
        },

        {
            _id: "5f2bc6a1a35824e75d251e4c",
            index: 2,
            isActive: false,
            value: "tommblk@gmail.com",
            type: "email",
            color: "#D0246B",
            group: 2,
        },
        {
            _id: "5f2bc6a1bf16562e2f9c62plo",
            index: 0,
            isActive: false,
            value: "Data Breach",
            type: "security",
            color: "#811DBF",
            group: 1,
        },
        {
            _id: "5f2bc6a1bf16562e2f9c62itr",
            index: 0,
            isActive: false,
            value: "Hack Anonymous",
            type: "cyber",
            color: "#811DBF",
            group: 1,
        },
        {
            _id: "5f2bc6a1bf60c7f57e5ce1d6",
            index: 3,
            isActive: false,
            value: "facebook",
            type: "facebook",
            color: "#F76042",
            group: 2,
        },

        {
            _id: "5f2bc6a164dc3a9717ff7857",
            index: 4,
            isActive: false,
            picture: "https://www.finance-watch.org/wp-content/uploads/2018/08/money-supply-1600x1067.jpg",
            value: "Arrested for Finance Fraud",
            source: "Hindustan Times",
            url: "https://google.com",
            type: "media",
            color: "#EA9026",
            group: 2,
        },

        {
            _id: "4rfdfg5tvgtc7f57e5ce1d6",
            index: 8,
            isActive: false,
            value: "Linked In",
            type: "linkedin",
            color: "#F76042",
            group: 2,
        },
        {
            _id: "4rfdfg5tvgtc7f57e5ce19gggt",
            index: 8,
            isActive: false,
            value: "mattcorplisted.com",
            type: "web",
            color: "#F76042",
            group: 2,
        },
        {
            _id: "4rfdfg5tvgtc7f57e5ce19piiyyt",
            index: 8,
            isActive: false,
            value: "hackerunited.com",
            type: "web",
            color: "#F76042",
            group: 2,
        },
        {
            _id: "4rfdfgghnjtc7fpoi5ce1d6",
            index: 8,
            isActive: false,
            value: "Instagram",
            type: "instagram",
            color: "#F76042",
            group: 1,
        },
    ],
    connections: [
        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "5f2bc6a1c39c851d3d7b6fww",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "5f2bc6a1bf16562e2f9c620ol",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "5f2bc6a1bf16562e2f9c620wer",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "5f2bc6a1bf16562e2f9c620iil",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620ol",
            to: "5f2bc6a1bf16562e2f9c620pok",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620pok",
            to: "5f2bc6a1bf16562e2f9c620pere",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620pok",
            to: "5f2bc6a1bf16562e2f9c620yyh",
            tags: [
                {
                    value: "ADVERSE",
                    rounded: false,
                    bgColor: "rgb(228, 79, 103)",
                    textColor: "#FFFFFF",
                },
            ],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620pere",
            to: "5f2bc6a1bf16562e2f9c620iiie",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620ol",
            to: "5f2bc6a1bf16562e2f9c620oou",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620ol",
            to: "5f2bc6a1bf16562e2f9c62oil",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620ol",
            to: "5f2bc6a1c39c851d3d7b6opljw",
            tags: [],
        },
        {
            from: "5f2bc6a1c39c851d3d7b6opljw",
            to: "5f2bc6a1c39c851d3d7b6op0olll",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620ol",
            to: "5f2bc6a1bf16562e2f9c62uyt",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62uyt",
            to: "4rfdfg5tvgtc7f57e5ce19gggt",
            tags: [],
        },
        {
            from: "4rfdfg5tvgtc7f57e5ce19gggt",
            to: "4rfdfg5tvgtc7f57e5ce19piiyyt",
            tags: [],
        },

        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "5f2bc6a1a35824e75d251e4c",
            tags: [
                {
                    value: "ADVERSE",
                    rounded: false,
                    bgColor: "rgb(228, 79, 103)",
                    textColor: "#FFFFFF",
                },
            ],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "5f2bc6a1bf16562e2f9c62uyt",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62b8",
            to: "4rfdfgghnjtc7fpoi5ce1d6",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c62oil",
            to: "5f2bc6a1bf60c7f57e5ce1d6",
            tags: [],
        },
        {
            from: "5f2bc6a1c39c851d3d7b6opljw",
            to: "5f2bc6a1bf16562e2f9c62itr",
            tags: [],
        },
        {
            from: "5f2bc6a1a35824e75d251e4c",
            to: "5f2bc6a1bf16562e2f9c62plo",
            tags: [],
        },
        {
            from: "5f2bc6a1c39c851d3d7b6op0olll",
            to: "5f2bc6a164dc3a9717ff7857",
            tags: [],
        },
        {
            from: "5f2bc6a1bf16562e2f9c620wer",
            to: "4rfdfg5tvgtc7f57e5ce1d6",
            tags: [],
        },
    ],
};
