<template>
    <div class="filters-list">
        <div v-for="(values, category) in filters" :key="category">
            <neo-filters :title="startCase(category)" :items="values" :category="category" ref="filters" @updateFilters="updateFilters" />
        </div>
        <div class="tw-align-middle" v-if="loading">
            <neo-loader class="tw-mt-40 tw-mr-2.5" :loading="loading" height="40px" width="40px"></neo-loader>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import NeoFilters from "@/components/filters";
import Loader from "@/components/loader";
import {startCase} from "lodash";

export default {
    name: "CaseFilters",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        "neo-filters": NeoFilters,
        "neo-loader": Loader,
    },
    props: ["filters", "loading"],
    data() {
        return {
            startCase,
            selectedFilters: {},
        };
    },

    async mounted() {},

    methods: {
        updateFilters(selectedItems, category) {
            this.selectedFilters[category] = selectedItems;
            this.$emit("updateFilters", this.selectedFilters);
        },
    },
};
</script>
<style scoped></style>
