import axios from "@/axios";

export default {
    async getDashboardBookmarks(dashboardId) {
        const url = `/dashboard/bookmarks/${dashboardId}`;
        const {data} = await axios.get(url);
        return data;
    },

    async saveDashboardViews(payload) {
        const url = "/dashboard/bookmarks";
        const {data} = await axios.post(url, payload);
        return data;
    },

    async addDashboardSchedule(payload) {
        const url = "/dashboard/add-scheduler";
        const {data} = await axios.post(url, payload);
        return data;
    },

    async getDashboardSchedule(viewId) {
        const url = `/dashboard/get-scheduler/${viewId}`;
        const {data} = await axios.get(url);
        return data;
    },
};
