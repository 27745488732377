import rawAxios from "axios";

import Input from "@/components/input";
import Button from "@/components/button";
import iamAxios from "@/axios/user";

export default {
    name: "onboarding",
    components: {
        "neo-input": Input,
        "neo-button": Button,
    },
    props: ["accountName"],
    data() {
        return {
            onboarding: [
                {
                    title: "Terms and Conditions",
                    description: "Detailed Terms and Conditions for the user",
                },
                {
                    title: "Company Profile",
                    description: "Company Profile",
                },
            ],
            currentStep: 1,
            tncAccepted: false,
            showTncError: false,
            validated: true,
            companyPrefix: "",
            companyName: "",
            address: "",
            phone: "",
            spocEmail: "",
            user_tnc: `Terms of service (also known as terms of use and terms and conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service.[1] Terms of service can also be merely a disclaimer, especially regarding the use of websites. Vague language and lengthy sentences used in the terms of use have brought concerns on customer privacy and raised public awareness in many ways.
      The Terms of Service Agreement is mainly used for legal purposes by companies which provide software or services, such as web browsers, e-commerce, web search engines, social media, and transport services.
      A legitimate terms-of-service agreement is legally binding and may be subject to change.[2] Companies can enforce the terms by refusing service. Customers can enforce by filing a lawsuit or arbitration case if they can show they were actually harmed by a breach of the terms. There is a heightened risk of data going astray during corporate changes, including mergers, divestitures, buyouts, downsizing, etc., when data can be transferred improperly.[3],
      Terms of service (also known as terms of use and terms and conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service.[1] Terms of service can also be merely a disclaimer, especially regarding the use of websites. Vague language and lengthy sentences used in the terms of use have brought concerns on customer privacy and raised public awareness in many ways.
      The Terms of Service Agreement is mainly used for legal purposes by companies which provide software or services, such as web browsers, e-commerce, web search engines, social media, and transport services.
      A legitimate terms-of-service agreement is legally binding and may be subject to change.[2] Companies can enforce the terms by refusing service. Customers can enforce by filing a lawsuit or arbitration case if they can show they were actually harmed by a breach of the terms. There is a heightened risk of data going astray during corporate changes, including mergers, divestitures, buyouts, downsizing, etc., when data can be transferred improperly.[3]`,
        };
    },
    computed: {
        validateEmail() {
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            return emailRegex.test(this.spocEmail);
        },
        validateCompanyPrefix() {
            const prefixRegex = /^[A-Z]\w{2,5}$/g;
            return prefixRegex.test(this.companyPrefix);
        },
        // capitalCompanyPrefix() {
        //     return this.companyPrefix.toUpperCase() ?? ''
        // }
    },
    mounted() {
        this.companyName = this.accountName;
    },
    methods: {
        acceptTerms() {
            if (!this.tncAccepted) this.showTncError = true;
            else {
                this.currentStep = 2;
                this.showTncError = false;
            }
        },
        async submitProfile() {
            if (!this.validateCompanyPrefix || !this.companyName || !this.spocEmail || !this.validateEmail) this.validated = false;
            else {
                this.validated = true;
                await this.saveProfileData();
            }
        },
        async saveProfileData() {
            let data = {
                // name: this.companyName,
                address: this.address,
                phone_number: this.phone,
                account_prefix: this.companyPrefix,
                spoc_email: this.spocEmail,
            };

            let url = `api/v1/accounts/${this.$store.getters.getAccountId}`;

            try {
                const response = await iamAxios.put(url, data);
                await this.saveProfileDataToPortal(data);
                this.$store.commit("setAccountDetails", {accountDetails: response.data});
                this.$store.commit("setOnBoardingStatus", {status: true});
            } catch (error) {
                this.$toast.error(error.response.data.detail);
            }
        },

        // PREFIX CANNOT BE GREATER THAN  5 CHARACTERS
        handleInputPrefix(event) {
            // if (this.companyPrefix.length < 6) {
            this.companyPrefix = event.target.value.toUpperCase().replace(/[0-9]/, "");
            // }
        },

        async saveProfileDataToPortal(data) {
            let doc = {...data};
            doc["account_id"] = this.$store.getters.getAccountId;
            const url = `${process.env.VUE_APP_PORTAL_URL}/client/internal/set/company/key`;
            try {
                let response = await rawAxios.post(url, (data = doc));
            } catch (error) {
                console.error(error);
                this.$toast.error("Failed to update on portal.");
            }
        },
    },
};
