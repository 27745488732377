import OsintAxios from "@/axios/osint.js";
import axios from "@/axios/index.js";

export default {
    async getAllCountryCodes() {
        const url = "/whatsapp/country-code?conn=keep-alive";
        const result = await OsintAxios.get(url);
        return result.data;
    },

    async searchNumber(number, id, globalInput = false, smartAnalyser = false) {
        const url = "/whatsapp/phone-number";
        const headers = {
            "x-tool-name": "whatsapp-tool",
            "x-visited": !smartAnalyser && !globalInput ? true : false,
        };
        const payload = {
            id: id,
            number: number,
        };
        return await OsintAxios.post(url, payload, {headers: headers});
    },

    async setProfileData(payload, caseId) {
        const url = `research-methodology/${caseId}/tools/profiles`;
        await axios.post(url, payload);
    },
};
