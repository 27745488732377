export default {
    name: "image-preview",
    props: {
        imgSrc: String,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        onClose(event) {
            event.stopPropagation();
            this.$emit("previewClose");
        },
    },
};
