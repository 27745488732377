<template>
    <div class="filters-list tw-oveflow-x-hidden">
        <!-- <div class="tw-sticky tw-top-0 tw-bg-white tw-p-2 tw-z-auto"> -->
        <!-- <neo-input :changeCase="true" :shadow="false" placeholder="Search" bg="var(--brand-area-color)" margin="0" icon="search" v-model="search" @input="$emit('searchedValue', $event)" /> -->
        <!-- </div> -->

        <div class="sidebar-treeview tw-h-full">
            <div class="tw-pb-2 tw-px-2">
                <div class="tw-self-center tw-text-lg tw-my-2">Filters</div>
                <div class="filter-section tw-border-gray-200 tw-rounded tw-border-solid tw-border-2 tw-overflow-y-auto tw-max-h-64" v-show="filterVisible">
                    <treeview :items="filters" :checkboxes="true" @checkbox="$emit('checkbox', $event)"></treeview>
                </div>
            </div>

            <div class="methodology-treeview">
                <div class="tw-flex tw-p-2 tw-justify-between">
                    <div class="tw-self-center tw-text-lg">Process</div>
                    <neo-button :label="expanded ? 'Collapse all' : 'Expand all'" faicon="filter" class="tw-w-30 tw-rounded-lg tw-h-10 tw-self-center" border-radius="0.75rem" :bg="filterVisible ? '#e5effa' : white" :hoverBg="filterVisible ? '#e5effa' : white" hoverText="var(--brand-color)" color="var(--brand-color)" stroke="white" @click="handleExpandAll('multilevel')" v-if="methodologyView"> </neo-button>
                </div>
                <treeview :items="filteredMethodology" :allowClick="true" @clickEvent="clickEvent" ref="multilevel" v-if="methodologyView" :showCountRatio="showCountRatio"></treeview>
                <div class="tw-text-base tw-text-gray-500 tw-px-2" v-else>
                    No Process found!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/button";
import Treeview from "@/components/treeview";
import NeoInput from "@/components/input";

export default {
    text: "CaseFilters",
    components: {
        Treeview,
        "neo-button": Button,
        NeoInput,
    },
    props: {
        filters: {
            type: Object,
            default: {},
        },
        methodology: {
            type: Object,
            default: {},
        },
        showCountRatio: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedFilters: {},
            filterVisible: true,
            expanded: false,
            search: "",
        };
    },

    async mounted() {},

    computed: {
        filteredMethodology() {
            // const methodology = JSON.stringify(this.methodology);
            // const children = JSON.parse(methodology)?.children?.filter((n) => {
            //     return ((n.children = n.children?.filter((n2) => n2.text.toLowerCase().includes(this.search?.toLowerCase()))) && n?.children.find((n1) => n1.text.toLowerCase().includes(this.search?.toLowerCase()))) || n.text?.toLowerCase().includes(this.search?.toLowerCase());
            // });
            // this.handleMaxHeight();
            return this.methodology;
        },
        methodologyView() {
            return this.methodology?.children?.length > 0;
        },
    },

    methods: {
        updateFilters(selectedItems, category) {
            this.selectedFilters[category] = selectedItems;
            this.$emit("updateFilters", this.selectedFilters);
        },
        clickEvent(tree) {
            this.$emit("clickEvent", tree);
        },
        handleExpandAll(reference) {
            let expanded = "";
            if (reference == "multilevel") {
                this.expanded = !this.expanded;
                expanded = this.expanded;
            }
            this.$refs[reference].$children[0].$children.forEach((ele) => {
                ele.expanded = expanded;
                if (ele.$children.length > 0) {
                    ele.$children.forEach((elem) => {
                        elem.expanded = expanded;
                    });
                }
                [...ele.$el.getElementsByClassName("panel expandible panel-transition")]?.forEach((e) => (e.style.maxHeight = expanded ? "fit-content" : "0"));
            });
        },
        handleMaxHeight() {
            //For expanded panel height update
            [...document.getElementsByClassName("panel expandible panel-transition")].filter((ele) => ele?.style?.getPropertyValue("max-height") && ele.style.getPropertyValue("max-height") != "0px")?.forEach((e) => (e.style.maxHeight = "fit-content"));
        },
    },
};
</script>
<!--<style src="vuetify/dist/vuetify.min.css" scoped></style>-->

<style lang="scss">
.filter-section,
.filters-list {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}

.filters-list {
    // height: calc(100% - 150px);
    overflow-y: auto;
    height: 100%;

    .check-container {
        div {
            .checkmark {
                height: 16px !important;
                width: 16px !important;
                top: -7px !important;
                left: -7px !important;
                border-radius: 4px !important;
                border: 1px solid #ccc !important;

                &:after {
                    top: 0px !important;
                    left: 3px !important;
                    width: 5px !important;
                    height: 9px !important;
                    border-width: 0 3px 3px 0;
                }
            }
        }
    }
}

.sidebar-treeview {
    // height: calc(100% - 10px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
</style>
