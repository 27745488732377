import {getSocialProfileIcon} from "@/utils/functions";

export default {
    name: "neo-avatar",
    components: {},
    props: ["type", "profile", "avatar", "uploadable"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        getSocialProfileIcon,
    },
};
