import Button from "@/components/button";
import {sortBy} from "lodash";
import axios from "@/axios";
import NeoInput from "@/components/input";
import {FEATURES} from "@/utils/constants";

export default {
    name: "add-node-modal",
    components: {
        "neo-button": Button,
        NeoInput,
    },
    props: {
        icon: {
            type: Boolean,
            default: false,
        },
        extraIconEntity: {
            type: Boolean,
            default: false,
        },
        icons: {
            type: Array,
            default: () => [],
        },
        bottonPadding: {
            type: String,
            default: "1.2rem 1.25rem",
        },
        buttonLabel: {
            type: String,
            default: "Add",
        },
        buttonBorderRadius: {
            type: String,
            default: "10px",
        },
        buttonBg: {
            type: String,
            default: "var(--brand-color)",
        },
        buttonHoverBg: {
            type: String,
            default: "var(--brand-color)",
        },
        buttonTextColor: {
            type: String,
            default: "white",
        },
        buttonBorderStyle: {
            type: String,
        },
        buttonBorderColor: {
            type: String,
        },
        buttonShadow: {
            type: String,
        },
    },
    data() {
        return {
            addNode: false,
            entityType: [
                {key: "social_profile", name: "Social Profile", icon: "social"},
                // { key: "active_media", name: "Active Media", icon: "active-media" },
                {key: "key_fact", name: "Output", icon: "key-facts"},
                {key: "passive_media", name: "Media", icon: "passive-media"},
                {key: "search_engine", name: "Search Engine Results", icon: "search"},
                {key: "url", name: "URL", icon: "url"},
            ],
            extraData: [{key: "extra_icons", name: "Extra Icons", icon: "icons"}],
            showExtraIcons: false,
            selectedDynamicEntity: null,
            searchIcon: "",
        };
    },
    computed: {
        getDynamicEntity() {
            let data = this.$store.getters.getEntitiesRelationsAttributesData.entities.filter((item) => item.parent_id === null);
            return sortBy(data, ["order"]);
        },

        getSubEntity() {
            if (this.selectedDynamicEntity) {
                let data = this.$store.getters.getEntitiesRelationsAttributesData.entities.filter((item) => item.parent_id === this.selectedDynamicEntity._id && item.enabled);
                return sortBy(data, ["order"]);
            }
        },

        getExtraIcons() {
            let data = this.icons
                .filter((item) => item.icon_type === "extra")
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((ele) => ele.name.toLowerCase().includes(this.searchIcon.toLowerCase()));
            return data;
        },
        permittedEnity() {
            let menu = this.entityType.filter(({key}) => {
                if (key === "url") return true;
                else return this.$store.getters.getPermissions.find((ele1) => FEATURES.add_new_entity[ele1] == key);
            });
            return menu;
        },
    },
    mounted() {},

    methods: {
        onSelectDynamicEntity(entity) {
            this.selectedDynamicEntity = entity;
        },
        onBack() {
            this.selectedDynamicEntity = null;
            this.showExtraIcons = false;
        },
        onAddNode() {
            this.addNode = true;
        },
        onClose() {
            this.selectedDynamicEntity = null;
            this.addNode = false;
            this.searchIcon = "";
        },
        openEntityPanel(datatype, subEntity) {
            this.$store.dispatch("setSelectedEntityData", {
                datatype: datatype,
                editEntity: false,
                entity: subEntity,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");

            this.onClose();
        },
        onEntitySelect(datatype, subEntity) {
            if (datatype !== "extra_icons") {
                this.showExtraIcons = false;
                let payload = {
                    msg: "popupSidepanel",
                    value: "",
                    datatype: datatype,
                };

                if (datatype === "entity") {
                    payload.item = subEntity;
                    payload.edit = false;
                }
                this.openExtension(payload);
            } else {
                if (!this.showExtraIcons) this.showExtraIcons = true;
                else {
                    this.saveExtraIcon(subEntity);
                }
            }
        },
        async saveExtraIcon(icon) {
            this.showExtraIcons = false;
            let data = {
                key: icon.key,
                graph_label: icon.name,
            };
            const response = await axios.post(`/icon-node/${this.$store.getters.getCaseId}`, data);
            try {
                if (response) {
                    this.addNode = false;
                    let node_data = JSON.parse(JSON.stringify(icon));
                    node_data.id = response.data.id;
                    node_data._id = response.data.id;
                    node_data.data_type = "icon";
                    node_data.title = icon.name;
                    node_data.entity_identifier = icon.key;
                    node_data.graph_label = icon.name;
                    node_data.entity = icon.name;
                    this.$emit("pushNode", node_data);
                    this.$toast.success("Icon saved Successfully");
                }
            } catch (error) {
                this.$toast.error("Node can't be added");
            }
        },
        openExtension(payload) {
            if (window.chrome) {
                chrome?.runtime?.sendMessage(this.$store.getters.getExtensionID, payload, (response) => {
                    // this.onClose();
                    setTimeout(this.onClose, 1000);
                });
            }
        },
    },
};
