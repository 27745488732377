import VueCookies from "vue-cookies";
import store from "@/store";
import {fetchRefreshToken, fetchWlpRefreshToken} from "@/utils/functions";
import JWT from "jsonwebtoken";
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};
function decodeToken(token) {
    let decoded;
    try {
        decoded = JWT.decode(token);
    } catch (error) {
        if (error.name === "NotBeforeError") {
            let timeSync = alert("Your system time is incorrect. Your system time is skewed by more than 2 minutes. Please make sure your system time is correct as per your time zone and try again.");
            this.$store.dispatch("logoutUser");
        }
    }
    return decoded;
}
const refreshTokeninterceptor = (axiosInstance) => (error) => {
    const _axios = axiosInstance;
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject});
            })
                .then((token) => {
                    originalRequest.headers["Authorization"] = "Bearer " + token;
                    return _axios.request(originalRequest);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const tokenToRefresh = VueCookies.get("token");
        let exstingdecodeToken = decodeToken(tokenToRefresh);
        if (exstingdecodeToken?.session_src === "neotaswlp") {
            return new Promise((resolve, reject) => {
                fetchWlpRefreshToken(tokenToRefresh)
                    .then((token) => {
                        VueCookies.set("token", token);
                        _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        processQueue(null, token);
                        resolve(_axios(originalRequest));
                    })
                    .catch((err) => {
                        console.log("utils.js", err);
                        processQueue(err, null);
                        reject(err);
                        store.dispatch("logoutUser");
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                fetchRefreshToken(tokenToRefresh)
                    .then((token) => {
                        VueCookies.set("token", token);
                        _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        processQueue(null, token);
                        resolve(_axios(originalRequest));
                        // if (window.chrome) {
                        //     chrome.runtime.sendMessage(
                        //         store.state.research.extensionID,
                        //         {
                        //             msg: "sendAccessToken",
                        //             token: token
                        //         }
                        //     );
                        // }
                    })
                    .catch((err) => {
                        console.log("utils.js", err);
                        processQueue(err, null);
                        reject(err);
                        store.dispatch("logoutUser");
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        }
    }

    return Promise.reject(error);
};

export default refreshTokeninterceptor;
