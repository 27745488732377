import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import NLPAnalysisCollection from "@/utils/content-scripts/nlp-analysis.collection";
import loader from "@/components/loader";

export default {
    name: "NLPAnalysisConfiguration",
    props: ["changeTab"],
    components: {
        Multiselect,
        "neo-loader": loader,
    },
    data() {
        return {
            loading: false,
            case_id: null,
        };
    },
    computed: {
        getBadWordsLanguages() {
            return this.$store.getters.getBadWordsLanguages;
        },
        caseIdentifiersChecked: {
            get: function () {
                return this.$store.getters.getCaseIdentifiersChecked;
            },
            set: function (value) {
                this.$store.dispatch("setCaseIdentifiersChecked", value);
            },
        },
        badWordsChecked: {
            get: function () {
                return this.$store.getters.getBadWordsChecked;
            },
            set: function (value) {
                this.$store.dispatch("setBadWordsChecked", value);
            },
        },
        productKeywordsChecked: {
            get: function () {
                return this.$store.getters.getProductKeywordsChecked;
            },
            set: function (value) {
                this.$store.dispatch("setProductKeywordsChecked", value);
            },
        },
        entities() {
            return this.$store.getters.getCombinedHighlightedEntities;
        },
        countLength() {
            if (this.entities) {
                return Object.keys(this.entities).length;
            }

            return 0;
        },
    },
    methods: {
        getAccordionId(index) {
            const normalizedIndex = index.toLowerCase().replaceAll(" ", "-");
            return `accordion-${normalizedIndex}`;
        },
        async highlightItems(type) {
            if (NLPAnalysisCollection.isEmpty()) {
                this.$bvToast.toast("No media content on the page", {
                    title: "Highlighting warning",
                    variant: "warning",
                    autoHideDelay: 2000,
                    solid: true,
                });

                return;
            }

            if (this.loading) {
                this.$bvToast.toast("The previous operation hasn't been completed yet", {
                    title: "Highlighting warning",
                    variant: "warning",
                    autoHideDelay: 2000,
                    solid: true,
                });

                return;
            }

            try {
                this.loading = true;

                switch (type) {
                    case "all":
                        NLPAnalysisCollection.clear();
                        await Promise.all([NLPAnalysisCollection.highlightNegativeWords(this.getBadWordsLanguages), NLPAnalysisCollection.highlightCaseIdentifiers(), NLPAnalysisCollection.highlightProductWords(), NLPAnalysisCollection.highlightSelectedKeywords(), NLPAnalysisCollection.highlightSearchWords()]);
                        const allCount = NLPAnalysisCollection.getEntitiesCount();
                        this.$store.dispatch("addHighlightedEntities", allCount);
                        break;
                    case "ml":
                        NLPAnalysisCollection.clear();
                        this.$store.dispatch("setHighlightedEntities", []);

                        await NLPAnalysisCollection.highlightMLEntities();
                        const mlCount = NLPAnalysisCollection.getEntitiesCount();
                        this.$store.dispatch("addHighlightedEntities", mlCount);
                        break;
                    case "clear":
                        NLPAnalysisCollection.clear();
                        this.$store.dispatch("setHighlightedEntities", []);
                        break;
                    default:
                        throw new Error(`Type ${type} is not supported`);
                }
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {},
};
