import axios from "@/axios";
import osintAxios from "@/axios/osint.js";
const state = {
    allToolData: [],
};
const mutations = {
    SET_TOOL_MANAGER_DATA(state, value) {
        state.allToolData = value;
    },
};
const getters = {
    getToolData(state) {
        return state.allToolData;
    },
};
const actions = {
    async getToolManagerData({commit}) {
        const resp = await osintAxios.get("key-manager/tools");
        if (resp.data.status == true) {
            commit("SET_TOOL_MANAGER_DATA", resp.data.data);
        }
        return resp;
    },
    async saveToolManagerData({commit}, payload) {
        return payload;
        // const resp = await osintAxios.get("key-manager/tools");
        // if (resp.data.status == true) {
        //     commit("SET_TOOL_MANAGER_DATA", resp.data.data);
        // }
        // return resp;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
