import { render, staticRenderFns } from "./chart-donut.html?vue&type=template&id=67fe5e9b&scoped=true&"
import script from "./chart-donut.js?vue&type=script&lang=js&"
export * from "./chart-donut.js?vue&type=script&lang=js&"
import style0 from "./chart-donut.scss?vue&type=style&index=0&id=67fe5e9b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67fe5e9b",
  null
  
)

export default component.exports