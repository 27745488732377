import axios from "@/axios";
import rawAxios from "axios";
import {cloneDeep, startCase, takeRightWhile} from "lodash";
import supportCenter from "@/components/supportCenter";
import Avatar from "../../components/avatar";
import Button from "../../components/button";
import NeoInput from "@/components/input";
import {DateTime} from "luxon";
import VueMultiselect from "@/components/vue-multiselect";
import moment from "moment";
import VueCookies from "vue-cookies";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {getConfig} from "@/utils/functions";
import iamApi from "@/axios/user";
import neoSwitch from "@/components/toggle-switch";
import {required} from "vuelidate/lib/validators";
import {thresholdScott} from "d3-array";
import CreditPack from "./credit-pack";
import {EventBus, lightrayApi} from "@/main.js";
import dashboardServices from "@/views/bi-dashboard/services.js";
import {getUserActiveCreditPack} from "../../views/credit-packs/services";
import {decodeToken} from "../../utils/auth";

export default {
    name: "neo-header",
    components: {
        "neo-support-center": supportCenter,
        "neo-avatar": Avatar,
        "neo-button": Button,
        NeoInput,
        VueMultiselect,
        neoSwitch,
        CreditPack,
    },
    validations: {
        filterName: {required},
        absoluteMonth: {
            dayNumber: {
                required,
            },
            monthNumber: {
                required,
            },
        },
        relativeMonth: {
            everyMonth: {
                required,
            },
        },
        absoluteYear: {
            dayMonth: {
                required,
            },
        },
        noOfdays: {
            required,
        },
        afterDays: {
            required,
        },
        recurYear: {
            required,
        },
    },

    props: [],
    data() {
        return {
            accounts: [],
            message: "Welcome",
            username: "Vipul Mishra",
            extensionMenuOpen: false,
            extensionVersion: "",
            currentExtensionVersion: null,
            versionChecked: false,
            profileMenuOpen: false,
            savedFiltersOpen: false,
            statusMenuOpen: false,
            supportModalOpen: false,
            allStates: [],
            caseState: null,
            startCase: startCase,
            caseDescription: "",
            review_date: "",
            due_date: "",
            support_center: false,
            companyDashboards: [
                {value: "Case Dashboard", visited: true},
                {value: "Event Dashboard", visited: false},
            ],
            dashboardSelection: {value: "Case Dashboard", visited: true},
            emailFrequencyList: ["Once", "Daily", "Weekly", "Monthly", "Yearly"],
            filterName: "",
            bookmarksList: [],
            openBookmarkList: false,
            hideDelete: false,
            saveFilters: false,
            setEmailFreq: false,
            bookmarkId: null,
            emailvalue: [],
            emailoptions: [{group: "emails", categories: []}],
            everyDay: true,
            everyWeekDay: "",
            enddateAfter: "",
            weekdays: "",
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            months: "",
            repeatWeek: ["First", "Second", "Third", "Fourth", "Last"],
            monthsInYear: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            recurYear: 1,
            day: "",
            frequency: [
                {id: 1, checked: true, type: "daily"},
                {id: 2, checked: false, type: "weekly"},
                {id: 3, checked: false, type: "monthly"},
                {id: 4, checked: false, type: "yearly"},
            ],
            frequencyType: "daily",
            absoluteMonth: {dayNumber: new Date().getDate().toString(), monthNumber: 1},
            relativeMonth: {
                weeklyRepeat: this.getWeekOfMonth(),
                dayRepeat: "",
                everyMonth: 1,
            },
            absoluteYear: {
                dayMonth: new Date().getDate(),
                monthYear: this.getMonthNameFull(),
            },
            relativeYear: {
                week: this.getWeekOfMonth(),
                everyMonthYear: this.getMonthNameFull(),
                yearDay: "",
            },
            afterDays: 1,
            bookmarkExits: false,
            noOfdays: 1,
            pattern: "",
            absoluteMonthly: true,
            relativeMonthly: false,
            absoluteYearly: true,
            relativeYearly: false,
            daysInWeek: [
                {id: 1, dayName: "Monday", checked: false, label: "MO"},
                {id: 2, dayName: "Tuesday", checked: false, label: "TU"},
                {id: 3, dayName: "Wednesday", checked: false, label: "WE"},
                {id: 4, dayName: "Thursday", checked: false, label: "TH"},
                {id: 5, dayName: "Friday", checked: false, label: "FR"},
                {id: 6, dayName: "Saturday", checked: false, label: "SA"},
                {id: 7, dayName: "Sunday", checked: false, label: "SU"},
            ],
            interval: 0,
            index: "first",
            dayOfMonth: "",
            daysOfWeek: [],
            emailRecepient: [],
            emailFreq: true,
            emails: false,
            emailError: "",
            newFilter: false,
            selectedDashboardName: "",
            weekDaysSelected: false,
            shortLiveToken: null,
            creditPacks: [],
            dashboardMapping: {
                Case_Dashboard: "Case Dashboard",
                Event_Dashboard: "Event Dashboard",
                Event_Internal_Dashboard: "Analyst Dashboard",
            },
            viewData: {},
            scheduleId: "",
            filters: null,
            creditDataLoading: false,
        };
    },
    computed: {
        ...mapGetters(["search", "getCasePersonalData", "getLicenses", "getValidCase", "getCaseStates", "getPermissions", "getOnBoardingStatus", "getSocketIO", "getCaseDescription", "getUserAuthDetails", "caseValid", "getPanelStatus", "getReadOnlyMode"]),
        ...mapGetters("dashboard", ["getAssignedDashboards"]),
        getDashboardOptions() {
            const selectedOption = this.getAssignedDashboards.find((res) => res.visited);
            this.selectedDashboard = selectedOption;
            this.companyDashboards = this.getAssignedDashboards;
            return [
                {
                    group: "dashboards",
                    categories: this.companyDashboards,
                },
            ];
        },
        showHeader() {
            return window == window.parent;
        },
        showCreditPack() {
            // return this.$route.name === "research-automated";
            return true;
        },
        primaryCreditPack() {
            return this.creditPacks.find((item) => item.primary === true && item.active === true);
        },
        // showSideBar() {
        //     return this.getLicenses.includes("methodology__methodology") || this.getLicenses.includes("sources__sources") || this.getLicenses.includes("monitoring__monitoring")
        //     || this.getLicenses.includes("social_media_automation__social_media");
        // },
        // isShowResearchTab() {
        //     const researchTabs = ["outputs__case_data", "detail_section__case_data", "social_profiles__case_data", "associated_entities__case_data", "media__case_data"];
        //     return this.getLicenses.some((permission) => researchTabs.includes(permission));
        // },
        isTools() {
            return this.getLicenses.includes("analysis_tools__tools");
        },
        currentRouteName() {
            if (this.getOnBoardingStatus) {
                return this.$route.name?.replace("-ro", "");
            } else {
                return "Onboarding Details";
            }
        },
        showDashboardSelection() {
            const routes = ["case dashboard", "bi-dashboard-view"];
            if (routes.includes(this.$route.name.toLowerCase()) && this.getAssignedDashboards.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        isReadOnlyMode() {
            this.getReadOnlyMode ?? false;
        },

        selectedAccount() {
            return this.$store.getters.getAccountId;
        },
        researchCaseId() {
            if (this.$route.name) {
                if (this.$route.name.toLowerCase() === "research" || this.$route.name.toLowerCase() === "research-automated") return this.$store.getters.getCaseId;
                else if (this.$route.name?.includes("entities")) return this.$store.getters.getCaseId == "NEW" ? "Create Case" : this.$store.getters.getCaseId;
            }
        },
        isResearchScreen() {
            return this.$store.getters.getCaseId;
        },

        getUser() {
            return this.$store.getters.getLoggedInUser;
        },
        userAccounts() {
            let usersAcc = cloneDeep(this.$store.getters.getUserAccounts);
            usersAcc = usersAcc ?? [];
            usersAcc.forEach((item, i) => {
                if (item.id === this.selectedAccount) {
                    usersAcc.splice(i, 1);
                    usersAcc.unshift(item);
                }
            });
            return usersAcc;
        },
        checkVersion() {
            if (this.currentExtensionVersion && this.extensionVersion) {
                if (this.extensionVersion == this.currentExtensionVersion) {
                    return false;
                }

                let [installedMajor, installedMinor, installedPatch] = this.currentExtensionVersion.split(".");
                let [availableMajor, availableMinor, availablePatch] = this.extensionVersion.split(".");

                if (parseInt(availableMajor) > parseInt(installedMajor) || parseInt(availableMinor) > parseInt(installedMinor) || parseInt(availablePatch) > parseInt(installedPatch)) {
                    return true;
                }
            }
            return true;
        },
        isResearchData() {
            if (this.getCasePersonalData) {
                this.caseState = this.allStates.find((state) => state.text === this.getCasePersonalData.case_state) || {
                    text: this.getCasePersonalData.case_state || "To Do",
                    value: this.getCasePersonalData.case_state || "To Do",
                    indicator: "tw-text-default",
                };
                return true;
            } else return false;
        },

        getCaseReviewDate() {
            let dt = this.getCasePersonalData?.upcoming_review_date || "";
            this.review_date = DateTime.fromISO(dt).toFormat("dd-MMM-yyyy");
        },
        getCaseDueDate() {
            let dt = this.getCasePersonalData?.case_due_date || "";
            this.due_date = DateTime.fromISO(dt).toFormat("dd-MMM-yyyy");
        },
        getCurrentCompany() {
            let userAcc = this.userAccounts.find((acc) => acc.id === this.selectedAccount);
            return userAcc?.name ?? this.selectedAccount;
        },
        isCaseMonitoring() {
            return this.getCasePersonalData.case_state === "Monitoring";
        },
        editDateCond() {
            if (this.getReadOnlyMode) {
                return false;
            } else {
                if (this.isCaseMonitoring) {
                    return this.getCasePersonalData;
                } else {
                    let userRoles = this.getUserAuthDetails?.userRole;
                    return userRoles?.includes("case_due_date");
                }
            }
        },

        extensionBaseURL() {
            return process.env.VUE_APP_EXTENSION_URL;
        },

        isNeotasWLPToken() {
            const token = VueCookies.get("token");
            const decodedToken = decodeToken(token);

            if (decodedToken?.session_src === "neotaswlp") return true;
            else return false;
        },
    },
    async created() {
        // EventBus.$on('savedbookmark', async (data) => {
        //     if(data.bookmark_id) {
        //         // this.filterName = "";
        //         // this.$modal.hide('saveFilter');
        //         this.bookmarkExits = false;
        //         this.bookmarkId = data.bookmark_id ;
        //     }
        //     else if(data === "bookmarkExists") {
        //         this.bookmarkExits = true
        //     }
        //     await this.getBookmarks()
        // });
        EventBus.$on("saveDashboardView", async (data) => {
            this.handleModalSaveFilter(data);
        });
        EventBus.$on("refreshBookmarks", () => {
            this.getBookmarks();
        });
        EventBus.$on("handleSelectedDashboard", async (data) => {
            this.selectedDashboardName = data;
            if (this.selectedDashboard) {
                this.companyDashboards.forEach((res) => {
                    if (this.selectedDashboardName == res.dashboardId) {
                        res.visited = true;
                        this.selectedDashboard = res;
                        this.getBookmarks();
                    } else {
                        res.visited = false;
                    }
                });
            }
        });

        EventBus.$on("refreshCreditPacks", () => {
            this.getAvailableCreditPacks();
        });
    },
    async mounted() {
        await this.getCompanyDashboards();
        await this.getBookmarks();
        getConfig(["saas:support_center"]).then((config) => {
            if (config["saas:support_center"].status) {
                this.support_center = config["saas:support_center"].value;
            }
        });

        if (window.chrome) {
            chrome?.runtime?.sendMessage(this.$store.getters.getExtensionID, {msg: "getExtensionVersion"}, (response) => {
                this.currentExtensionVersion = response.version;
            });
        }

        // await this.getDelviumToken();

        let url = `${this.extensionBaseURL}/latest-version.txt`;
        rawAxios.get(url).then((response) => {
            this.extensionVersion = response?.data;
            this.versionChecked = true;
        });
        if (this.caseValid) {
            if (this.caseDescription.trim() === "") this.caseDescription = this.getCaseDescription;
            await this.getAllCaseStates();
            this.allStates = this.getCaseStates;
            // await this.getBookmarks();
            this.resetFilterDetails();
            this.resetEmailFrequency();
        }
        if (this.$route?.query?.company_id) this.switchAccount(this.$route.query.company_id);
        await this.getAvailableCreditPacks();
    },
    methods: {
        ...mapActions(["getAllCaseStates", "createSocketIOConn"]),
        ...mapMutations(["set_search"]),
        ...mapMutations("dashboard", ["SET_ASSIGNED_DASHBOARDS"]),
        async getCompanyDashboards() {
            const {data} = await axios.get(`/dashboard/${this.selectedAccount}/assigned-dashboards`);
            const dashboards = data.map((res, idx) => {
                if (this.$route.params.id) {
                    if (res.dashboard_id == this.$route.params.id) {
                        return {
                            value: this.dashboardMapping[res.name],
                            visited: true,
                            dashboardId: res.quicksight_id ? res.quicksight_id : res.dashboard_id,
                        };
                    }
                }
                if (idx == 0 && !this.$route.params.id) {
                    return {
                        value: this.dashboardMapping[res.name],
                        visited: true,
                        dashboardId: res.quicksight_id ? res.quicksight_id : res.dashboard_id,
                    };
                } else {
                    return {
                        value: this.dashboardMapping[res.name],
                        visited: false,
                        dashboardId: res.quicksight_id ? res.quicksight_id : res.dashboard_id,
                    };
                }
            });
            this.SET_ASSIGNED_DASHBOARDS(dashboards);
        },
        async getDelviumToken() {
            this.shortLiveToken = null;
            try {
                const {data} = await axios.get(`/internal-service/short-lived-token`);
                this.shortLiveToken = data.access_token;
                VueCookies.set("delvium-token", this.shortLiveToken);
            } catch (e) {
                console.log(e);
            }
        },

        async getAvailableCreditPacks() {
            // this.creditPacks = [];
            this.creditDataLoading = true;
            const {data} = await getUserActiveCreditPack({
                tenant_id: this.$store.getters.getAccountId,
            });
            this.creditPacks = data?.data || [];
            this.creditDataLoading = false;
        },

        getDayNameFull() {
            const day = new Date().getDay();
            const dayMap = {
                0: "Sunday",
                1: "Monday",
                2: "Tuesday",
                3: "Wednesday",
                4: "Thursday",
                5: "Friday",
                6: "Saturday",
            };
            this.relativeMonth.dayRepeat = dayMap[day];
            return dayMap[day];
        },
        handleAfterDay() {
            if (this.afterDays <= 0) {
                return true;
            }
        },
        logout() {
            VueCookies.set("next-url", this.$route.fullPath);
            this.$store.dispatch("logoutUser");
            // this.$route.push({name: "Login"});
        },
        async saveFrequencyFilter() {
            this.$v.$touch();
            this.newFilter = true;
            this.resetEmailFrequency();
            if (this.filterName.trim() !== "") {
                this.viewData.name = this.filterName;
                this.viewData.dashboard_id = this.$route.params.id;
                this.viewData.company = this.$store.getters.getAccountId;
                dashboardServices
                    .saveDashboardViews(this.viewData)
                    .then(async (res) => {
                        this.bookmarkId = res.bookmark_id;
                        await this.getBookmarks();
                        this.$toast.success("Bookmark added successfully.");
                        this.filters = this.viewData.filters;
                    })
                    .catch((err) => {
                        this.$toast.error(err.response.data.detail.message);
                    });
            }
        },
        editBookMark(view) {
            this.filterName = view.name;
            this.saveFilter = true;
            this.newFilter = false;
            this.bookmarkExits = false;
            this.bookmarkId = view.view_id;
            this.scheduleId = view?.schedule_id;
            this.filters = view.filters;
            this.$modal.show("saveFilter");
        },
        addTag(newTag) {
            this.emailError = "";
            let newTags = [];
            newTags = [
                ...newTags,
                ...newTag
                    ?.split(/\t/)[0]
                    ?.split("\n")
                    ?.filter((el) => el && (el !== " " || el !== "")),
            ];

            let newTagsData = newTags.filter(function(item) {
                return item.trim() != "";
            });
            newTagsData.forEach((tg) => {
                if (tg !== "" && tg !== " ") {
                    tg = tg?.trim();
                    const tag = {
                        name: tg,
                        value: tg?.trim(),
                        code: tg.substring(0, 2) + Math.floor(Math.random() * 10000000),
                    };
                    let index = this.emailvalue?.findIndex((item) => item?.value === tg);
                    let secIndex = this.emailoptions[0]?.categories?.findIndex((item) => item?.value === tg);
                    if (index > -1) {
                    } else {
                        this.emailvalue.push(tag);
                    }
                    if (secIndex > -1) {
                    } else {
                        this.emailoptions[0]?.categories.push(tag);
                    }
                }
            });
        },
        deleteSearch(value) {
            let index = this.emailvalue.findIndex((el) => el.name === value);
            if (index > -1) this.emailvalue.splice(index, 1);
        },
        updateFrequencyFilter() {
            this.$v.$touch();
            if (this.filterName !== "") {
                const accountId = this.$store.getters.getAccountId;
                const result = this.getAssignedDashboards.find((res) => res.visited);
                const url = `/dashboard/bookmarks/${this.bookmarkId}`;
                let payload = {
                    name: this.filterName,
                    company: accountId,
                    dashboard_id: result.dashboardId,
                };
                try {
                    axios
                        .put(url, payload)
                        .then(async (res) => {
                            await this.getBookmarks();
                            this.$toast.success("Bookmark updated successfully.");
                            EventBus.$emit("savedbookmark", response.data);
                        })
                        .catch((err) => {
                            this.$toast.error(err.response.data.detail.message);
                        });
                } catch (error) {
                    this.$toast.error("Something went wrong.");
                }
            }
        },
        deleteFrequencyFilter(freq) {
            this.$modal.show("delete-filter");
            this.filterName = freq.name;
            this.bookmarkId = freq.view_id;
            this.scheduleId = freq?.schedule_id;
        },
        async deleteBookmark() {
            let url = `/dashboard/bookmarks/${this.bookmarkId}`;
            if (this.scheduleId) {
                url = `/dashboard/bookmarks/${this.bookmarkId}?${this.scheduleId}`;
            }
            const response = await axios.delete(url);
            if (response && response.data) {
                this.$toast.success(response.data.message);
                this.$modal.hide("delete-filter");
                this.bookmarkId = null;
                this.filterName = "";
                this.getBookmarks();
            }
        },
        async getBookmarks() {
            try {
                this.bookmarkFilters = [];
                const accountId = this.$store.getters.getAccountId;
                let result;
                if (this.$route.params.id) {
                    result = this.getAssignedDashboards.find((res) => res.dashboardId === this.$route.params.id);
                } else result = this.getAssignedDashboards.find((res) => res.visited);
                if (result) {
                    const {data} = await axios.get(`/dashboard/bookmarks/${accountId}/${result.dashboardId}`);
                    this.bookmarksList = data.views;
                }
            } catch {
                return {};
            }
        },
        openBookMark() {
            this.openBookmarkList = !this.openBookmarkList;
        },
        onClickOutside() {
            this.openBookmarkList = false;
        },
        hideDeleteFilter() {
            this.$modal.hide("delete-filter");
            this.hideDelete = false;
        },
        hideSaveFilter() {
            this.$modal.hide("saveFilter");
            this.saveFilter = false;
            this.filterName = "";
            this.bookmarkId = null;
        },
        getStatusClass(status) {
            let mapper = {
                "To Do": "tw-bg-gray-400",
                "In Progress": "tw-bg-brand",
                Monitoring: "tw-bg-warning",
                "On Hold": "tw-bg-danger",
                Done: "tw-bg-success",
            };
            return mapper[status];
        },
        onSelect(data) {
            this.companyDashboards.forEach((res) => {
                if (data.value == res.value) {
                    res.visited = true;
                } else {
                    res.visited = false;
                }
            });
            // if(this.$route.query.bookmark_id)
            this.$router.push({name: "Bi-Dashboard-View", params: {id: data.dashboardId}});
            // EventBus.$emit("handleDashboardSelection")
            // this.getBookmarks()
        },
        handleModalSaveFilter(data) {
            this.viewData = data;
            this.$modal.show("saveFilter");
            this.saveFilter = true;
            this.filterName = "";
            this.bookmarkId = null;
            this.bookmarkExits = false;
            this.resetEmailFrequency();
        },
        showEmailFrequency() {
            this.setEmailFreq = true;
            EventBus.$emit("showEmailFreq", {name: this.filterName, viewId: this.bookmarkId, scheduleId: this.scheduleId, filters: this.filters});
        },
        async switchAccount(accountId) {
            var body = {id: accountId};
            const {VUE_APP_SOCKET_INTERNAL_URL: url} = process.env;
            try {
                var response = await iamApi.post("/api/v1/switch-account", body);
                this.$store.commit("setAccountId", {accountId: accountId});
                // set user account id
                VueCookies.set("ACCOUNT_ID", accountId);

                if (!this.$route?.query?.company_id && !this.isNeotasWLPToken) {
                    try {
                        const socketResponse = await rawAxios.post(url + "/socket", {roomId: this.$store.getters.getUserAuthDetails.userId, eventName: "switch", eventData: true});
                        this.$router
                            .push({path: "/case-list"})
                            .then(() => this.$router.go())
                            .catch(() => this.$router.go());
                        this.profileMenuOpen = false;
                    } catch (e) {
                        this.$router
                            .push({path: "/case-list"})
                            .then(() => this.$router.go())
                            .catch(() => this.$router.go());
                    }
                }
            } catch (error) {}
        },
        handleChange({target}) {
            this.search[this.$route.name] = target.value;
            this.set_search(this.search);
        },
        handleOutsideClick() {
            this.extensionMenuOpen = false;
        },
        handleOutsideClickProfile() {
            this.profileMenuOpen = false;
        },
        handleOutsideClickStatus() {
            this.statusMenuOpen = false;
        },
        editReviewDate() {
            this.$store.commit("SET_CASE_REVIEW_DATE_POPUP", true);
            EventBus.$emit("editReview", true);
        },
        editDueDate() {
            this.$store.commit("SET_CASE_DUE_DATE_POPUP", true);
        },
        async clickAction(e) {
            let currentState = e.target.innerText;
            this.handleOutsideClickStatus();
            let flag = false;
            if (currentState === "Monitoring" && this.getCasePersonalData.case_state !== currentState) {
                flag = true;
                this.$store.commit("SET_CASE_STATUS_FLAG", true);
                EventBus.$emit("resetMonitoring", true);
                this.$store.commit("SET_CASE_REVIEW_DATE_POPUP", true);
            }
            if (!flag && this.getCasePersonalData.case_state !== currentState) {
                try {
                    let data = await axios.post(`case-info/case-state`, {
                        case_id: this.$store.getters.getCaseId,
                        state_name: currentState,
                    });
                    let caseData = await axios.post("/case-info/upcoming-review-date", {
                        case_id: this.$store.getters.getCaseId,
                        state_name: currentState,
                    });
                    if (caseData.status === 200 && data.status === 200 && data?.data?.status) {
                        this.$store.commit("SET_CASE_STATUS", currentState);
                        EventBus.$emit("resetMonitoring", true);
                        // this.$store.commit("SET_CASE_DUE_DATE_POPUP", true);
                        this.$toast.success("Case state updated successfully !");
                    } else this.$toast.error("Some error occurred ! Please try again later");
                } catch {
                    return {};
                }
            }
            if (currentState != "Monitoring" && this.getCasePersonalData.case_state !== currentState) {
                try {
                    let data = await axios.post(`case-info/case-state`, {
                        case_id: this.$store.getters.getCaseId,
                        state_name: currentState,
                    });
                    let caseData = await axios.post("/case-info/upcoming-review-date", {
                        case_id: this.$store.getters.getCaseId,
                        state_name: currentState,
                    });
                    if (caseData.status === 200 && data.status === 200 && data?.data?.status) {
                        this.$store.commit("SET_CASE_STATUS", currentState);
                        EventBus.$emit("resetMonitoring", true);
                        this.$store.commit("SET_CASE_DUE_DATE_POPUP", true);
                        this.$toast.success("Case due date updated successfully !");
                    } else this.$toast.error("Some error occurred ! Please try again later");
                } catch {
                    return {};
                }
            }
        },
        async handleCaseDescription() {
            if (this.caseDescription.trim() === "") {
                this.$toast.error("Please enter case name...");
                this.$refs["case-desc"].$el.classList.add("tw-border-red-400");
            } else {
                const resp = await axios.post("case-info/case-description", {
                    description: this.caseDescription,
                    case_id: this.$store.getters.getCaseId,
                });
                this.$modal.hide("case-description");
                this.$toast.success("Case Name saved...");
                this.isCaseDescSaved = true;
                this.$store.commit("SET_CASE_DESCRIPTION", this.caseDescription);
            }
        },
        cancelModalHandle() {
            this.$modal.hide("case-description");
            if (!this.isCaseDescSaved && this.getCaseDescription === "") this.caseDescription = "";
            else if (this.caseDescription !== this.getCaseDescription) this.caseDescription = this.getCaseDescription;
        },
        handleDayNumber() {
            if (this.absoluteMonth.dayNumber && (this.absoluteMonth.dayNumber > 31 || this.absoluteMonth.dayNumber < 0)) return true;
        },
        getWeekday(day) {
            if (day === "MO") return "Monday";
            if (day === "TU") return "Tuesday";
            if (day === "WE") return "Wednesday";
            if (day === "TH") return "Thursday";
            if (day === "FR") return "Friday";
            if (day === "SA") return "Saturday";
            if (day === "SU") return "Sunday";
        },
        getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString("en-US", {
                month: "long",
            });
        },
        emailFrequency(id) {
            this.$modal.show("email Frequency");
            this.bookmarkId = id;
            this.emailvalue = [];
            this.resetEmailFrequency();
        },
        handleDayMonth() {
            if (this.absoluteYear.dayMonth !== "") {
                if ((this.absoluteYear.monthYear === "January" || this.absoluteYear.monthYear === "March" || this.absoluteYear.monthYear === "May" || this.absoluteYear.monthYear === "July" || this.absoluteYear.monthYear === "August" || this.absoluteYear.monthYear === "October" || this.absoluteYear.monthYear === "December") && (this.absoluteYear.dayMonth > 31 || this.absoluteYear.dayMonth <= 0)) return true;
                else if ((this.absoluteYear.monthYear === "June" || this.absoluteYear.monthYear === "September" || this.absoluteYear.monthYear === "November") && (this.absoluteYear.dayMonth > 30 || this.absoluteYear.dayMonth <= 0)) return true;
                else if (this.absoluteYear.monthYear === "February" && (this.absoluteYear.dayMonth > 29 || this.absoluteYear.dayMonth <= 0)) return true;
                else return false;
            } else if (this.absoluteYear.dayMonth == "") return false;
        },
        getDayName(day) {
            if (day.toLowerCase() === "monday") return "MO";
            if (day.toLowerCase() === "tuesday") return "TU";
            if (day.toLowerCase() === "wednesday") return "WE";
            if (day.toLowerCase() === "thursday") return "TH";
            if (day.toLowerCase() === "friday") return "FR";
            if (day.toLowerCase() === "saturday") return "SA";
            if (day.toLowerCase() === "sunday") return "SU";
        },
        getWeekIndex(index) {
            if (index.toLowerCase() === "first") return 1;
            if (index.toLowerCase() === "second") return 2;
            if (index.toLowerCase() === "third") return 3;
            if (index.toLowerCase() === "fourth") return 4;
            if (index.toLowerCase() === "last") return -1;
        },
        handleMonthNumber() {
            if (this.absoluteMonthly == true && this.absoluteMonth.monthNumber !== "" && this.absoluteMonth.monthNumber <= 0) return true;
            else return false;
        },

        async setEmailFrequency() {
            this.emailError = "";
            this.emailRecepient = [];
            if (this.emailvalue.length === 0) this.emails = true;
            else this.emails = false;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (this.emailvalue.length > 0) {
                this.emailvalue.map((el) => {
                    if (emailRegex.test(el.name)) this.emailRecepient.push(el.name);
                    else this.emailError = "Enter Valid Email";
                });
            }
            this.$v.$touch();
            if (this.frequencyType === "weekly" && this.daysOfWeek.length === 0) this.weekDaysSelected = true;
            else this.weekDaysSelected = false;
            let dateFormat = moment(new Date())
                .format("YYYY-MM-DD")
                .split("-")
                .join("");
            if (
                ((this.frequencyType === "daily" && this.everyDay && this.noOfdays > 0) ||
                    (this.frequencyType === "daily" && this.everyWeekDay) ||
                    (this.frequencyType === "weekly" && this.daysOfWeek.length > 0 && this.handleAfterDay() !== true) ||
                    (this.frequencyType === "monthly" && this.absoluteMonth.dayNumber && this.absoluteMonthly && this.absoluteMonth.monthNumber && this.handleDayNumber() !== true && this.handleMonthNumber() !== true) ||
                    (this.frequencyType === "monthly" && this.relativeMonth.everyMonth && this.relativeMonthly && this.handleEveryMonth() !== true) ||
                    (this.frequencyType === "yearly" && this.absoluteYearly && this.absoluteYear.dayMonth !== "" && !this.handleDayMonth() && this.recurYear > 0) ||
                    (this.frequencyType === "yearly" && this.relativeYearly && this.recurYear > 0)) &&
                this.emailRecepient.length > 0 &&
                this.emailError === ""
            ) {
                let payload;
                if (this.everyDay && this.frequencyType === "daily") {
                    payload = {
                        pattern: {
                            pattern_type: "daily",
                            interval: this.noOfdays,
                        },
                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=DAILY;INTERVAL=${this.noOfdays}`,
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                    };
                } else if (this.everyWeekDay && this.frequencyType === "daily") {
                    payload = {
                        pattern: {
                            pattern_type: "daily",
                            daysofweek: ["MO", "TU", "WE", "TH", "FR"],
                        },

                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR`,
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                    };
                } else if (this.frequencyType === "weekly") {
                    let weekDays = this.daysOfWeek.join();
                    payload = {
                        pattern: {
                            pattern_type: "weekly",
                            interval: this.afterDays,
                            daysofweek: this.daysOfWeek,
                        },
                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;INTERVAL=${this.afterDays};BYDAY=${weekDays}`,
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                    };
                } else if (this.frequencyType === "monthly" && this.absoluteMonthly) {
                    payload = {
                        pattern: {
                            pattern_type: "absoluteMonthly",
                            interval: this.absoluteMonth.monthNumber,
                            dayofmonth: this.absoluteMonth.dayNumber,
                        },
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.absoluteMonth.monthNumber};BYMONTHDAY=${this.absoluteMonth.dayNumber}`,
                    };
                } else if (this.frequencyType === "monthly" && this.relativeMonthly) {
                    payload = {
                        pattern: {
                            pattern_type: "relativeMonthly",
                            interval: this.relativeMonth.everyMonth,
                            index: this.relativeMonth.weeklyRepeat.toLowerCase(),
                            daysofweek: [this.getDayName(this.relativeMonth.dayRepeat)],
                        },
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.relativeMonth.everyMonth};BYDAY=${this.getWeekIndex(this.relativeMonth.weeklyRepeat)}${this.getDayName(this.relativeMonth.dayRepeat)}`,
                    };
                } else if (this.frequencyType === "yearly" && this.absoluteYearly) {
                    payload = {
                        pattern: {
                            pattern_type: "absoluteYearly",
                            interval: this.recurYear,
                            month: this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1,
                            dayofmonth: this.absoluteYear.dayMonth,
                        },
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1};BYMONTHDAY=${this.absoluteYear.dayMonth}`,
                    };
                } else if (this.frequencyType === "yearly" && this.relativeYearly) {
                    payload = {
                        pattern: {
                            pattern_type: "relativeYearly",
                            interval: this.recurYear,
                            index: this.relativeYear.week.toLowerCase(),
                            daysofweek: [this.getDayName(this.relativeYear.yearDay)],
                            month: this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1,
                        },
                        dashboard_bookmark_id: this.bookmarkId,
                        recipient_emails: this.emailRecepient,
                        send_email: this.emailFreq,
                        rrule_format: `DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1};BYDAY=${this.getWeekIndex(this.relativeYear.week)}${this.getDayName(this.relativeYear.yearDay)}`,
                    };
                }
                try {
                    const res = await axios.post("/dashboard/email-frequency", payload);
                    if (res && res.data.status) {
                        this.$toast.success(res.data.message);
                        this.setEmailFreq = false;
                        this.resetEmailFrequency();
                        this.$modal.hide("email Frequency");
                        this.$modal.hide("saveFilter");
                        this.getBookmarks();
                    } else if (res.data.detail[0].msg === "value is not a valid email address") this.emailError = "Enter vaild email address";
                } catch (e) {
                    console.log(e);
                }
            }
        },
        enableEmailFrequency() {
            this.emailFreq = !this.emailFreq;
        },
        cancelEmailFrequency() {
            this.$modal.hide("email Frequency");
            this.$modal.hide("saveFilter");
            this.setEmailFreq = false;
        },
        handleInputRange(range) {
            if (range > 99) {
                this.noOfdays = 99;
            }
            if (this.noOfdays < 0) {
                this.noOfdays = 1;
            }
        },
        handleCheckbox(id) {
            const day = document.getElementById(id);

            if (id === "everyday" && day.checked) {
                this.everyDay = true;
                this.everyWeekDay = false;
            } else if (id === "everyweekday" && day.checked) {
                this.everyWeekDay = true;
                this.everyDay = false;
            }
        },
        handledaysofweek(checkedDay) {
            this.weekDaysSelected = false;
            this.daysInWeek.find((el) => {
                if (el.id === checkedDay.id) {
                    let check = !this.daysOfWeek.includes(el.label);
                    if (check) {
                        el.checked = true;
                        this.daysOfWeek.push(el.label);
                    } else {
                        const index = this.daysOfWeek.indexOf(el.label);
                        if (index > -1) {
                            this.daysOfWeek.splice(index, 1);
                            this.daysInWeek.find((el) => {
                                if (el.id === checkedDay.id) {
                                    el.checked = false;
                                }
                            });
                        }
                    }
                }
            });
        },
        getMonthNameFull() {
            const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const d = new Date();
            return month[d.getMonth()];
        },
        handleReviewYearly(id) {
            const yearly = document.getElementById(id);
            if (id === "absyear" && yearly.checked) {
                this.absoluteYearly = true;
                this.relativeYearly = false;
                this.absoluteYear.monthYear = this.getMonthNameFull();
                this.absoluteYear.dayMonth = new Date().getDate();
            } else if (id === "relativeyear" && yearly.checked) {
                this.relativeYearly = true;
                this.absoluteYearly = false;
            }
        },
        handleReviewMonthly(month) {
            const monthly = document.getElementById(month);
            if (month === "absmonthly" && monthly.checked) {
                this.absoluteMonthly = true;
                this.relativeMonthly = false;
            } else if (month === "relativeMonthly" && monthly.checked) {
                this.getDayNameFull();
                this.relativeMonthly = true;
                this.absoluteMonthly = false;
            }
        },

        handleFrequency(freq) {
            this.weekDaysSelected = false;
            this.frequencyType = freq;
            this.$nextTick(() => {
                if (freq == "daily") {
                    this.pattern = freq;
                    if (!this.everyWeekDay) {
                        this.everyDay = true;
                        document.getElementById("everyday").checked = true;
                    }
                }
                if (freq == "weekly") {
                    this.pattern = freq;
                    this.noOfdays = 1;
                    if (this.daysOfWeek.length > 0) this.weekDaysSelected = false;
                }
                if (freq == "monthly") {
                    if (this.relativeMonthly == false) {
                        this.absoluteMonthly = true;
                        document.getElementById("absmonthly").checked = true;
                    }
                }
                if (freq == "yearly") {
                    if (this.relativeYearly == false) this.absoluteYearly = true;
                }
            });
        },
        async editEmailFreq(freq) {
            EventBus.$emit("showEmailFreq", {name: freq.name, viewId: freq.view_id, scheduleId: freq?.schedule_id, filters: freq.filters});
        },
        getWeekOfMonth() {
            var date = new Date();
            const startWeekDayIndex = 1; // 1 MonthDay 0 Sundays
            const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
            const firstDay = firstDate.getDay();

            let weekOfMonth = Math.ceil((date.getDate() + firstDay) / 7);
            if (startWeekDayIndex === 1) {
                if (date.getDay() === 0 && date.getDate() > 1) {
                    weekOfMonth -= 1;
                }

                if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
                    weekOfMonth += 1;
                }
            }
            if (weekOfMonth === 1) return "First";
            if (weekOfMonth === 2) return "Second";
            if (weekOfMonth === 3) return "Third";
            if (weekOfMonth === 4) return "Fourth";
            if (weekOfMonth === 5) return "Last";
        },
        handleEveryMonth() {
            if (this.relativeMonth.everyMonth && this.relativeMonth.everyMonth <= 0) return true;
        },
        resetEmailFrequency() {
            this.everyDay = true;
            this.everyWeekDay = "";
            this.enddateAfter = "";
            this.weekday = "";
            this.months = "";
            (this.frequency = [
                {id: 1, checked: true, type: "daily"},
                {id: 2, checked: false, type: "weekly"},
                {id: 3, checked: false, type: "monthly"},
                {id: 4, checked: false, type: "yearly"},
            ]),
                (this.frequencyType = "daily");
            this.relativeYear.week = this.getWeekOfMonth();
            this.relativeYear.everyMonthYear = this.getMonthNameFull();
            this.absoluteYear.monthYear = this.getMonthNameFull();
            this.recurYear = 1;
            this.absoluteYear.dayMonth = new Date().getDate();
            this.absoluteMonth.dayNumber = new Date().getDate().toString();
            this.absoluteMonth.monthNumber = 1;
            this.relativeMonth.weeklyRepeat = this.getWeekOfMonth();
            this.relativeYear.yearDay = this.getDayNameFull();
            this.relativeMonth.dayRepeat = this.getDayNameFull();
            this.relativeMonth.everyMonth = 1;
            this.afterDays = 1;
            this.noOfdays = 1;
            this.absoluteMonthly = true;
            this.relativeMonthly = false;
            this.absoluteYearly = true;
            this.relativeYearly = false;
            this.dayOfMonth = "";
            this.daysOfWeek = [];
            this.daysInWeek.find((el) => (el.checked = false));
            this.weekDaysSelected = false;
            this.emailvalue = [];
            this.emailRecepient = [];
            this.emailoptions = [{group: "emails", categories: []}];
            this.emailFreq = true;
        },
        resetFilterDetails() {
            this.bookmarkId = null;
            this.filterName = "";
            this.newFilter = false;
            this.bookmarkExits = false;
        },
    },
    beforeDestroy() {
        EventBus.$off("saveDashboardView");
        EventBus.$off("refreshBookmarks");
        EventBus.$off("handleSelectedDashboard");
    },
    beforeDestroy() {
        EventBus.$off("saveDashboardView");
        EventBus.$off("refreshBookmarks");
        EventBus.$off("handleSelectedDashboard");
    },
};
