export default {
    name: "checkbox-round",
    model: {
        prop: "checked",
        event: "change",
    },
    props: {
        value: String,
        borderRadius: String,
        checked: {
            type: [Array, String, Boolean],
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    created() {},
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        onChange() {
            if (typeof this.checked === "object") {
                if (this.checked.includes(this.value)) {
                    this.checked.splice(this.checked.indexOf(this.value), 1);
                } else {
                    this.checked.push(this.value);
                }
            }
            this.$emit("change", this.checked);
        },
    },
};
