export default {
    name: "neo-toggle",
    components: {},
    props: ["value", "margin", "label"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
