export default {
    name: "neo-card-notes",
    props: {
        notes: {
            type: String,
            default: "Not provided",
        },
        customerNotes: {
            type: String,
            default: "Not provided",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    mounded() {},
};
