export default {
    name: "neo-multi-select",
    components: {},
    props: ["value", "items", "selected"],
    data() {
        return {
            model: "",
        };
    },
    watch: {
        value() {},
    },
    computed: {
        filtered() {
            return this.items.filter((item) => item.toLocaleLowerCase().includes(this.model.toLocaleLowerCase()));
        },
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        onSelect(item) {
            this.$emit("select", item);
        },
    },
};
