import { render, staticRenderFns } from "./button.html?vue&type=template&id=7a0a9abb&scoped=true&"
import script from "./button.js?vue&type=script&lang=js&"
export * from "./button.js?vue&type=script&lang=js&"
import style0 from "./button.scss?vue&type=style&index=0&id=7a0a9abb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0a9abb",
  null
  
)

export default component.exports