<template>
    <label class="label items-center capitalize max-w-full" :for="context.id" v-if="!showLabel">
        <!-- <span class="" v-if="context.type !== 'consent'" v-text="context.label"></span> -->
        <span class="break-all" v-text="context.label"></span>
        <span class="text-red-500 ml-1 text-lg leading-3 mr-auto" v-if="isRequired">
            *
        </span>
    </label>
</template>

<script>
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
        showRequired: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isRequired() {
            return this.context.rules.some((ele) => ele.ruleName === "required");
        },
    },
};
</script>

<style>
/* styles? sure... */
.label {
    font-family: "Poppins", san-serif;
    padding: 0.25rem 0;
}
</style>
