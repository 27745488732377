import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import analysisToolsFilters from "./analysisToolsFilters";
import titleMixin from "@/mixins/titleMixin";
import "@/assets/css/multiselect.scss";
import Loader from "@/components/loader";

import axios from "@/axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {EventBus} from "@/main.js";

import {mapGetters, mapActions, mapMutations} from "vuex";

const ErrorMessage = () => import("@/components/server-error-message");
const Button = () => import("@/components/button");
const Input = () => import("@/components/input");
const Multiselect = () => import("@/components/vue-multiselect");
const Table = () => import("@/components/table");
const Tab = () => import("@/components/tab");
const NeoTabsSwitch = () => import("@/components/tabs-switch");
const Dropdown = () => import("@/components/dropdown");
const Pagination = () => import("@/components/pagination");

export default {
    title() {
        return `Research Methodology`;
    },
    name: "methodology",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        DatePicker,
        multiselect: Multiselect,
        "neo-loader": Loader,
        "neo-dropdown": Dropdown,
        "analysis-tools-filters": analysisToolsFilters,
        ErrorMessage,
    },
    mixins: [titleMixin],
    data() {
        return {
            case_id: "",
            collapsed: false,
            collapseIconVisible: false,
            allFilters: {},
            methodology: {},
            methodologyData: "",
            guidanceInput: {
                description: "",
                image: "",
                name: "",
            },
            isGuidance: false,
            treeData: null,
            researchMethodology: [],
            researchMethodologyHeading: [
                {name: "Step", label: "Step", field: "step"},
                {name: "Guidance", label: "Guidance", field: "guidance"},
                {name: "Mandatory", label: "Mandatory", field: "mandatory"},
                {name: "Checked?", label: "Checked", field: "checked"},
                {name: "Notes", field: "notes", label: "Notes"},
            ],
            // search: "",
            oldValueNotes: "",
            currentPage: 1,
            pageLimit: 10,
        };
    },
    computed: {
        ...mapGetters(["getAccountDetails", "getReadOnlyMode", "getCurrentAnalysisToolsTab", "getPermissions", "getCaseDataLevel"]),
        researchMethodologyData() {
            return this.researchMethodology.filter((data) => data.step?.toLowerCase().includes(this.search.toLowerCase()) || data.guidance?.description?.toLowerCase().includes(this.search.toLowerCase()));
        },
        methodologyFilteredData() {
            let items = {children: []};
            if (this.methodologyData) {
                let filteredData = this.methodologyData?.filter((data) => data.step?.toLowerCase().includes(this.search.toLowerCase()) || data.module?.toLowerCase().includes(this.search.toLowerCase()) || data.purpose?.toLowerCase().includes(this.search.toLowerCase()) || data.entity_type?.toLowerCase().includes(this.search.toLowerCase()) || data.guidance?.description?.toLowerCase().includes(this.search.toLowerCase()));
                this.getUniqueList(filteredData, "module").forEach((ele) => {
                    let json = this.getOptions(
                        filteredData.filter((n) => n.module == ele.text),
                        ele.text
                    );
                    items.children.push(json);
                });
                this.handleMaxHeight();
            }
            return items;
        },
        search() {
            return this.$store.getters.search[this.$route.name];
        },
        permissionsFetched() {
            return this.checkPermissionsFetched;
        },
        isAuth() {
            return this.getPermissions.includes("methodology__methodology");
        },
        imagesBaseURL() {
            return process.env.VUE_APP_IMAGES_URL;
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        if (!this.getCurrentAnalysisToolsTab) {
            let url = `/research-methodology/list`;
            let payload = {};
            if (this.$route.params.id)
                payload = {
                    case_id: this.$route.params.id,
                };
            if (this.getCaseDataLevel) {
                payload.products = [this.getCaseDataLevel];
            }
            if (this.getAccountDetails?.id) {
                payload.company = [this.getAccountDetails.id];
            }
            // let loader = this.$loading.show();
            EventBus.$emit("topprogress", "start");
            await this.loadOptions(url, payload);
            await this.fetchFilters();
            // loader.hide();
            EventBus.$emit("topprogress", "done");
            // await this.setFilters();
            await this.reassignFilter();
        } else {
            this.case_id = this.getCurrentAnalysisToolsTab.case_id;
            this.allFilters = this.getCurrentAnalysisToolsTab.allFilters;
            this.methodology = this.getCurrentAnalysisToolsTab.methodology;
            this.methodologyData = this.getCurrentAnalysisToolsTab.methodologyData;
            this.guidanceInput = this.getCurrentAnalysisToolsTab.guidanceInput;
            this.isGuidance = this.getCurrentAnalysisToolsTab.isGuidance;
            this.researchMethodology = this.getCurrentAnalysisToolsTab.researchMethodology;
            this.treeData = this.getCurrentAnalysisToolsTab.treeData;
            this.researchMethodologyHeading = this.getCurrentAnalysisToolsTab.researchMethodologyHeading;
            this.search = this.getCurrentAnalysisToolsTab.search;
            this.oldValueNotes = this.getCurrentAnalysisToolsTab.oldValueNotes;
            if (this.treeData) {
                this.clickEvent(this.treeData);
            }
        }
        document.getElementById("view").style.overflow = "hidden";
    },
    async created() {
        // await this.fetchPermissions();
    },
    updated() {
        if (this.treeData)
            this.$refs.filters.$children.forEach((ele) => {
                if (ele?.setSelected && {}.toString.call(ele.setSelected) === "[object Function]") ele.setSelected(this.treeData.id ? this.treeData.id : this.treeData.text);
            });
    },
    beforeDestroy() {
        document.getElementById("view").style.overflow = "auto";
        this.SET_CURRENT_ANALYSIS_TOOL({
            ...this._data,
        });
    },
    methods: {
        ...mapActions(["fetchPermissions"]),
        ...mapMutations(["SET_CURRENT_ANALYSIS_TOOL"]),

        clickEvent(tree) {
            if (tree.leaf) {
                this.treeData = tree;
                this.isGuidance = true;
                if (tree.dataset === "purpose") {
                    this.researchMethodology = this.methodologyData.filter((n) => n[tree.dataset] == tree.text && n["module"] === tree.module);
                } else {
                    this.researchMethodology = this.methodologyData.filter((n) => n[tree.dataset] == tree.text);
                }
            }
        },

        showCollapseIcon() {
            this.collapseIconVisible = true;
        },

        hideCollapseIcon() {
            this.collapseIconVisible = false;
        },

        handleGuidanceClosed() {
            this.guidanceInput = {
                description: "",
                image: "",
            };
        },

        async loadOptions(url, payload) {
            const {data} = await axios.post(url, payload);
            this.methodologyData = data?.data;
            let items = {children: []};
            this.getUniqueList(data.data, "module").forEach((ele) => {
                let json = this.getOptions(
                    data.data.filter((n) => n.module == ele.text),
                    ele.text
                );
                items.children.push(json);
            });
            this.methodology = items;
        },
        getOptions(data, entity_type) {
            let json = {
                text: entity_type,
                children: this.getUniqueList(data, "purpose", entity_type),
                allChecked: false,
            };
            let checkedCount = 0;
            let totalCount = 0;
            json.children.forEach((child) => {
                checkedCount += child.checkedCount;
                totalCount += child.totalCount;
            });
            json.checkedCount = checkedCount;
            json.totalCount = totalCount;
            json.allChecked = json.children.findIndex((n) => n.checkedCount !== n.totalCount) > -1 ? false : true;

            // json.children.forEach((n, i) => {
            //     const values = data.filter((n) => n.purpose).filter((ele) => ele.purpose == n.text);
            //     json.children[i] = {
            //         text: n.text,
            //         children: values.filter((n1) => n1.step).map((n2) => ({ text: n2.step, id: n2.id, dataset: "step" })),
            //     };
            // });
            return json;
        },
        getUniqueList(array, dataset, module) {
            return [
                ...new Set(
                    [].concat.apply(
                        [],
                        array.filter((n) => n[dataset]).map((n1) => n1[dataset])
                    )
                ),
            ]
                .map((n2, i) => ({
                    text: n2,
                    checkedCount:
                        dataset === "purpose"
                            ? array.filter((item) => {
                                  return item.checked && item.purpose === n2;
                              }).length
                            : 0,
                    totalCount:
                        dataset === "purpose"
                            ? array.filter((item) => {
                                  return item.purpose === n2;
                              }).length
                            : 0,
                    dataset,
                    module,
                }))
                .sort((a, b) => a.text?.localeCompare(b.text));
        },
        fetchFilters() {
            let filters = {children: []};
            if (this.getUniqueList(this.methodologyData, "entity_type").length > 0) {
                let json = {
                    text: "Entity Type",
                    // value: "entity_type_type",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "entity_type").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "entity_type",
                    })),
                };
                filters.children.push(json);
            }
            const produts_list = [
                ...new Set(
                    [].concat
                        .apply(
                            [],
                            this.methodologyData.filter((n) => n["products"]).map((n) => n.products)
                        )
                        .map((n) => n.product_name.trim())
                ),
            ]
                .map((n2, i) => ({text: n2, dataset: "products"}))
                .sort((a, b) => a.text?.localeCompare(b.text));
            // if (produts_list.length > 0) {
            //     let json = {
            //         text: "Products",
            //         // value: "entity_type_type",
            //         header: true,
            //         children: produts_list.map((ele) => ({
            //             text: ele.text,
            //             value: false,
            //             disabled: false,
            //             filter: "products",
            //         })),
            //     };
            //     filters.children.push(json);
            // }

            if (this.getUniqueList(this.methodologyData, "module").length > 0) {
                let json = {
                    text: "Module",
                    // value: "module_type",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "module").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "module",
                    })),
                };
                filters.children.push(json);
            }

            if (this.getUniqueList(this.methodologyData, "input").length > 0) {
                let json = {
                    text: "Input",
                    // value: "input_type",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "input").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "input",
                    })),
                };
                filters.children.push(json);
            }
            if (this.getUniqueList(this.methodologyData, "output").length > 0) {
                let json = {
                    text: "Output",
                    // value: "output_type",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "output").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "output",
                    })),
                };
                filters.children.push(json);
            }

            if (this.getUniqueList(this.methodologyData, "report_type").length > 0) {
                let json = {
                    text: "Type",
                    // value: "report_type_type",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "report_type").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "report_type",
                    })),
                };
                filters.children.push(json);
            }
            const mandatoryOptions = {
                text: "Mandatory",
                header: true,
                children: [],
            };
            if (this.methodologyData.find((n) => n.mandatory) && this.methodologyData.find((n) => !n.mandatory)) {
                mandatoryOptions.children = [
                    {text: "Yes", value: false, disabled: false, filter: "mandatory"},
                    {text: "No", value: false, disabled: false, filter: "mandatory"},
                ];
            } else if (this.methodologyData.find((n) => !n.mandatory)) {
                mandatoryOptions.children = [{text: "No", value: false, disabled: false, filter: "mandatory"}];
            } else if (this.methodologyData.find((n) => n.mandatory)) {
                mandatoryOptions.children = [{text: "Yes", value: false, disabled: false, filter: "mandatory"}];
            }
            filters.children.push(mandatoryOptions);
            const checkedOptions = {
                text: "Checked",
                header: true,
                children: [],
            };
            if (this.methodologyData.find((n) => n.checked) && this.methodologyData.find((n) => !n.checked)) {
                checkedOptions.children = [
                    {text: "Yes", value: false, disabled: false, filter: "checked"},
                    {text: "No", value: false, disabled: false, filter: "checked"},
                ];
            } else if (this.methodologyData.find((n) => !n.checked)) {
                checkedOptions.children = [{text: "No", value: false, disabled: false, filter: "checked"}];
            } else if (this.methodologyData.find((n) => n.checked)) {
                checkedOptions.children = [{text: "Yes", value: false, disabled: false, filter: "checked"}];
            }
            filters.children.push(checkedOptions);

            if (this.getUniqueList(this.methodologyData, "region").length > 0) {
                let json = {
                    text: "Region",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "region").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "region",
                    })),
                };
                filters.children.push(json);
            }

            if (this.getUniqueList(this.methodologyData, "country").length > 0) {
                let json = {
                    text: "Country",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "country").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "country",
                    })),
                };
                filters.children.push(json);
            }

            if (this.getUniqueList(this.methodologyData, "location").length > 0) {
                let json = {
                    text: "Location",
                    header: true,
                    children: this.getUniqueList(this.methodologyData, "location").map((ele) => ({
                        text: ele.text,
                        value: false,
                        disabled: false,
                        filter: "location",
                    })),
                };
                filters.children.push(json);
            }

            this.allFilters = filters;
            if (this.treeData) {
                this.clickEvent(this.treeData);
            }
        },
        async handleChange(event) {
            let url = `/research-methodology/list`;
            // if (event.filter == "entity_type") {
            let payload = {};
            if (this.$route.params.id)
                payload = {
                    case_id: this.$route.params.id,
                };
            // if (this.getCaseDataLevel) {
            //     payload.products = [this.getCaseDataLevel];
            // }
            const entity_type = this.allFilters.children?.find((n) => n.text === "Entity Type")?.children.filter((n) => n.value) || [];
            payload.entity_type = entity_type.map((n) => n.text);
            // }
            // if (event.filter == "input") {
            const input = this.allFilters.children?.find((n) => n.text === "Input")?.children.filter((n) => n.value) || [];
            payload.input = input.map((n) => n.text);
            // }
            // if (event.filter == "output") {
            const output = this.allFilters.children?.find((n) => n.text === "Output")?.children.filter((n) => n.value) || [];
            payload.output = output.map((n) => n.text);
            // }
            // if (event.filter == "module") {
            const module = this.allFilters.children?.find((n) => n.text === "Module")?.children.filter((n) => n.value) || [];
            payload.module = module.map((n) => n.text);
            // }
            // if (event.filter == "report_type") {
            const report_type = this.allFilters.children?.find((n) => n.text === "Type")?.children.filter((n) => n.value) || [];
            payload.report_type = report_type.map((n) => n.text);
            // }
            const products = this.allFilters.children?.find((n) => n.text === "Products")?.children.filter((n) => n.value) || [];
            payload.products = [...products.map((n) => n.text), this.getCaseDataLevel];

            const region = this.allFilters.children?.find((n) => n.text === "Region")?.children.filter((n) => n.value) || [];
            payload.region = region.map((n) => n.text);

            // Country
            const country = this.allFilters.children?.find((n) => n.text === "Country")?.children.filter((n) => n.value) || [];
            payload.country = country.map((n) => n.text);

            const location = this.allFilters.children?.find((n) => n.text === "Location")?.children.filter((n) => n.value) || [];
            payload.location = location.map((n) => n.text);

            const mandatory = this.allFilters.children?.find((n) => n.text === "Mandatory")?.children.filter((n) => n.value) || [];
            payload.mandatory = mandatory?.length > 0 && mandatory?.length < 2 ? (mandatory[0].text == "Yes" ? true : false) : undefined;

            const checked = this.allFilters.children?.find((n) => n.text === "Checked")?.children.filter((n) => n.value) || [];
            payload.checked = checked?.length > 0 && checked.length < 2 ? (checked[0].text == "Yes" ? true : false) : undefined;

            if (this.getAccountDetails?.id) {
                payload.company = [this.getAccountDetails.id];
            }
            const loading = this.$loading.show();
            await this.loadOptions(url, payload);
            sessionStorage.setItem("analysis-tools-filters", JSON.stringify({...this.allFilters, case_id: this.case_id}));
            await this.fetchFilters();
            this.refetchFilters();
            loading.hide();
        },

        refetchFilters() {
            const obj = JSON.parse(sessionStorage.getItem("analysis-tools-filters"));
            //For expanded panel height update
            [...document.getElementsByClassName("panel expandible panel-transition")].filter((ele) => ele?.style?.getPropertyValue("max-height") && ele?.style?.getPropertyValue("max-height") != "0px")?.forEach((e) => (e.style.maxHeight = "fit-content"));

            const currEntityType = this.allFilters.children.find((ele) => ele.text == "Entity Type")?.children;
            const prevEntityType = obj.children.find((ele) => ele.text == "Entity Type")?.children;
            if (currEntityType?.length > 0 && prevEntityType?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Entity Type")].children = this.attachFilters(prevEntityType, currEntityType);
            }

            const currProduct = this.allFilters.children.find((ele) => ele.text == "Products")?.children;
            const prevProduct = obj.children.find((ele) => ele.text == "Products")?.children;
            if (currProduct?.length > 0 && prevProduct?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Products")].children = this.attachFilters(prevProduct, currProduct);
            }

            const currOutput = this.allFilters.children.find((ele) => ele.text == "Output")?.children;
            const prevOutput = obj.children.find((ele) => ele.text == "Output")?.children;
            if (currOutput?.length > 0 && prevOutput?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Output")].children = this.attachFilters(prevOutput, currOutput);
            }

            const currInput = this.allFilters.children.find((ele) => ele.text == "Input")?.children;
            const prevInput = obj.children.find((ele) => ele.text == "Input")?.children;
            if (currInput?.length > 0 && prevInput?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Input")].children = this.attachFilters(prevInput, currInput);
            }

            const currModule = this.allFilters.children.find((ele) => ele.text == "Module")?.children;
            const prevModule = obj.children.find((ele) => ele.text == "Module")?.children;
            if (currModule?.length > 0 && prevModule?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Module")].children = this.attachFilters(prevModule, currModule);
            }

            const currType = this.allFilters.children.find((ele) => ele.text == "Type")?.children;
            const prevType = obj.children.find((ele) => ele.text == "Type")?.children;
            if (currType?.length > 0 && prevType?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Type")].children = this.attachFilters(prevType, currType);
            }

            const currRegion = this.allFilters.children.find((ele) => ele.text == "Region")?.children;
            const prevRegion = obj.children.find((ele) => ele.text == "Region")?.children;
            if (currRegion?.length > 0 && prevRegion?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Region")].children = this.attachFilters(prevRegion, currRegion);
            }

            const currCountry = this.allFilters.children.find((ele) => ele.text == "Country")?.children;
            const prevCountry = obj.children.find((ele) => ele.text == "Country")?.children;
            if (currCountry?.length > 0 && prevCountry?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Country")].children = this.attachFilters(prevCountry, currCountry);
            }

            // Location
            const currLocation = this.allFilters.children.find((ele) => ele.text == "Location")?.children;
            const prevLocation = obj.children.find((ele) => ele.text == "Location")?.children;
            if (currLocation?.length > 0 && prevLocation?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Location")].children = this.attachFilters(prevLocation, currLocation);
            }

            // Mandatory
            const currMandatory = this.allFilters.children.find((ele) => ele.text == "Mandatory")?.children;
            const prevMandatory = obj.children.find((ele) => ele.text == "Mandatory")?.children;
            if (currMandatory?.length > 0 && prevMandatory?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Mandatory")].children = this.attachFilters(prevMandatory, currMandatory);
            }

            // Checked
            const currChecked = this.allFilters.children.find((ele) => ele.text == "Checked")?.children;
            const prevChecked = obj.children.find((ele) => ele.text == "Checked")?.children;
            if (currChecked?.length > 0 && prevChecked?.length > 0) {
                this.allFilters.children[this.allFilters.children.findIndex((n) => n.text == "Checked")].children = this.attachFilters(prevChecked, currChecked);
            }
            sessionStorage.setItem("analysis-tools-filters", JSON.stringify({...this.allFilters, case_id: this.case_id}));
        },
        attachFilters(prevValue, currValue) {
            const array = prevValue.map((ele) => {
                if (!ele.value) {
                    let refs = currValue.find((n) => n.text == ele.text);
                    if (refs) {
                        ele.disabled = false;
                    } else {
                        ele.disabled = true;
                    }
                }
                return ele;
            });
            return array;
        },
        async handleSaveNotes(id) {
            const index = this.researchMethodology.findIndex((n) => n.id == id);
            const obj = {
                research_methodology_id: id,
                checked: this.researchMethodology[index].checked,
                notes: this.researchMethodology[index].notes,
            };
            if (this.oldValueNotes != this.researchMethodology[index].notes.trim()) {
                await axios
                    .put(`/research-methodology/${this.$route.params.id}`, obj)
                    .then(() => this.$toast.success("Note saved successfully!"))
                    .catch((err) => this.$toast.error("Status update failed"));
                this.oldValueNotes = "";
            }
        },
        handleUpdateNotes(event, id) {
            const index = this.researchMethodology.findIndex((n) => n.id == id);
            this.researchMethodology[index].notes = event.target.value;
        },
        async handleChecked(id, module, purpose) {
            let index = this.researchMethodology.findIndex((n) => n.id == id);
            this.researchMethodology[index].checked = !this.researchMethodology[index].checked;
            const obj = {
                research_methodology_id: id,
                checked: this.researchMethodology[index].checked,
            };
            await axios.put(`/research-methodology/${this.$route.params.id}`, obj).catch((err) => this.$toast.error("Status update failed"));
            // if (data.status) {
            // }

            let status = this.researchMethodology[index].checked ? 1 : -1;
            this.methodology?.children.forEach((mod) => {
                if (mod.text === module) {
                    mod.children.forEach((purp) => {
                        if (purp.text === purpose) {
                            purp.checkedCount = purp.checkedCount || purp.checkedCount === 0 ? purp.checkedCount + status : undefined;
                        }
                    });

                    mod.checkedCount = mod.checkedCount || mod.checkedCount === 0 ? mod.checkedCount + status : undefined;
                    mod.allChecked = mod.children.findIndex((n) => n.checkedCount !== n.totalCount) > -1 ? false : true;
                }
            });

            this.$forceUpdate();
        },
        async setFilters() {
            const {data} = await axios.get(`/case-personal-data/${this.$route.params.id}`);
            if (data?.case_data?.case_subtype) {
                let case_subtype = data.case_data.case_subtype == "company" ? "company" : "individual";
                const entityType = this.allFilters.children.find((n) => n.text === "Entity Type");
                let res = entityType.children.find((ele) => ele.text.toLowerCase() === case_subtype);
                res.value = true;
                this.handleChange();
            }
        },
        reassignFilter() {
            const obj = JSON.parse(sessionStorage.getItem("analysis-tools-filters"));
            if (obj && obj.case_id == this.case_id) {
                this.allFilters = obj;
                this.handleChange();
            }
        },
        filtersView(name) {
            return this.allFilters?.children?.find((n) => n.text === name)?.children?.filter((n) => n.value) ?? [];
        },
        handleGuidance(id) {
            const data = this.researchMethodology.find((n) => n.id == id);
            this.guidanceInput.description = data.guidance?.description;
            this.guidanceInput.image = data.guidance?.image;
            this.$modal.show("guidance-modal");
        },
        searchedValue(event) {
            this.search = event;
        },
        handleMaxHeight() {
            //For expanded panel height update
            [...document.getElementsByClassName("panel expandible panel-transition")].filter((ele) => ele?.style?.getPropertyValue("max-height") && ele?.style?.getPropertyValue("max-height") != "0px")?.forEach((e) => (e.style.maxHeight = "fit-content"));
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
        },

        onPerPageChange(params) {
            this.currentPage = 1;
            this.pageLimit = params.currentPerPage;
        },
    },
};
