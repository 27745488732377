import Button from "@/components/button";
import Input from "@/components/input";
import GenericModal from "@/components/genericModal";
import nLoader from "@/components/n-loader";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapGetters} from "vuex";
import axios from "@/axios";

export default {
    name: "supportCenter",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-generic-modal": GenericModal,
        nLoader,
    },
    props: [],
    data() {
        return {
            isHeader: true,
            loading: true,
        };
    },
    async created() {},
    computed: {
        ...mapGetters(["getUserAuthDetails"]),
        isNeotasUser() {
            return this.getUserAuthDetails.email.includes("@neotas.com");
        },
    },
    async mounted() {},
    methods: {
        close() {
            this.$emit("close");
        },
        loadChat() {
            if (document.getElementById("zsiq_float")) document.getElementById("zsiq_float").click();
        },
    },
};
