import { render, staticRenderFns } from "./attributes.html?vue&type=template&id=3e0d1517&scoped=true&"
import script from "./attributes.js?vue&type=script&lang=js&"
export * from "./attributes.js?vue&type=script&lang=js&"
import style0 from "./attributes.scss?vue&type=style&index=0&id=3e0d1517&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e0d1517",
  null
  
)

export default component.exports