var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'formulate-input-element',
        ( _obj = {}, _obj[("formulate-input-element--" + (_vm.context.class))] = _vm.context.class, _obj ) ]},[_c('div',{staticClass:"editor field th-full tw-relative",class:{
            'editor--disabled': _vm.disabled,
            'editor--invalid field--invalid': _vm.invalid,
        },style:(_vm.styles)},[_c('div',{staticClass:"qeditor-wrapper"},[_c('quill-editor',{ref:"myQuillEditor",attrs:{"disabled":_vm.disabled,"id":_vm.editorId,"options":_vm.editorConfig},on:{"blur":function($event){return _vm.onEditorBlur($event)},"focus":function($event){return _vm.onEditorFocus($event)},"ready":function($event){return _vm.onEditorReady($event)}},model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }