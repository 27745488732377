<template>
    <div>
        <div class="card-container">
            <div class="fields-parent mb-0">
                <h2 class="tw-mb-4">Attachments</h2>
                <div class="fields-container multiselect-holder tw-flex tw-flex-col tw-gap-4">
                    <div class="uploadfile__wrapper tw-flex">
                        <div class="uploadfile__container">
                            <file-pond name="file" ref="pond" label-idle="Drag and drop or click here to upload a screenshot or PDF..." credits="false" accepted-file-types="image/jpeg, image/png, application/pdf" @removefile="removeFile" :server="imageUploadServer" :files="myFiles" v-bind:allow-multiple="false" />
                            <div v-if="showExpand" class="image__expand__icon" @click="expandImagePreview">
                                <img id="caret" src="@/assets/icons/ico-expand-white.svg" height="12px" />
                            </div>
                        </div>
                    </div>
                    <multiselect v-model="entityData.screenshot_caption" :options="imageCaptionOptions" :searchable="true" :close-on-select="true" :show-labels="false" tag-placeholder="Add this a new caption" :taggable="true" @tag="addCaption" placeholder="Select or add an image caption"> </multiselect>
                </div>
            </div>
        </div>

        <image-preview v-if="openImagePreview" :imgSrc="openImageURL" @previewClose="openImagePreview = false"> </image-preview>
    </div>
</template>
<script>
import "@/plugins/formulate";
import ImagePreview from "@/containers/image-preview";

// filepond imports
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";

import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginPdfPreview);

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {downloadGraphUrl} from "../../../../main";
import axios from "@/axios";
import rawAxios from "axios";

export default {
    name: "Attachments",
    components: {
        ImagePreview,
        FilePond,
        multiselect: Multiselect,
    },
    props: {
        entity: Object,
    },
    data() {
        return {
            imageUploading: false,
            openImageURL: "",
            openImagePreview: false,
            showExpand: false,
            imageUploadServer: {
                process: this.uploadScreenShot,
                load: this.loadScreenshot,
                revert: this.revertImage,
            },
            myFiles: [],
            imageCaptionOptions: ["Subject's comment on post", "Subject's comments on post", "Subject shared post", "Friend's comment on post", "Friend's comments on post", "Profile username", "Subject tagged in post", "Subject's response to other user's question", "Other user's question to subject", "Subject's profile picture", "Subject's bio", "Liked page"],
        };
    },
    methods: {
        removeFile() {
            this.showExpand = false;
            this.entityData.screenshot = {
                available: false,
            };
        },
        addCaption(newTag) {
            this.imageCaptionOptions.push(newTag);
            this.entityData.screenshot_caption = newTag;
        },

        async uploadScreenShot(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
            try {
                let response = await axios({
                    url: "/uploads/reports/images",
                    method: "post",
                    data: formData,
                    onUploadProgress: (e) => {
                        // updating progress indicator
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                });
                load(response.data.file);
                this.showExpand = true;
                // const caseData = { ...this.entityData };
                this.entityData.is_collapse = "is_collapse" in this.entityData ? this.entityData.is_collapse : false;
                this.entityData.screenshot = {
                    available: true,
                    location: response.data.file,
                    file_name: response.data.file,
                    file_mime_type: file.type,
                    size: file.size,
                };
                this.openImageURL = `/images/${this.entityData.screenshot.location}`;
            } catch (error) {
                console.error("Request canceled", error);
            }
        },

        async loadScreenshot(source, load) {
            try {
                this.openImageURL = `https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/${source}`;
                let response = await rawAxios.get(this.openImageURL, {responseType: "blob"});
                load(response.data);
                this.showExpand = true;
            } catch (error) {
                this.openImageURL = "";
                load();
            }
        },

        revertImage() {
            this.showExpand = false;
            this.openImagePreview = false;
        },
        async expandImagePreview() {
            if (this.entityData.screenshot.file_mime_type === "application/pdf") {
                window.open(`${process.env.VUE_APP_IMAGES_URL}/${this.entityData.screenshot.location}`);
            } else {
                const url = this.imgUrl;
                this.previewFile = `${downloadGraphUrl}/${this.entityData.screenshot.location}`;
                this.openImagePreview = true;
            }
        },
    },
    mounted() {
        if (this.entityData.screenshot !== "" && !this.imageCaptionOptions.includes(this.entityData.screenshot_caption)) {
            this.imageCaptionOptions.push(this.entityData.screenshot_caption);
        }

        if (this.entityData.screenshot && this.entityData.screenshot.available) {
            this.myFiles = [{source: this.entityData.screenshot.location, options: {type: "local"}}];
            this.showExpand = true;
        }
    },
    computed: {
        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep {
    .formulate-input .formulate-input-element {
        min-width: 65vw !important;
    }

    .formulate-input-upload-area {
        height: 10rem;
        min-width: 65vw !important;
    }

    .formulate-input-upload-area-mask {
        height: 10rem;
        min-width: 65vw !important;
    }

    .uploadfile__container {
        .filepond--wrapper {
            height: 100%;

            .filepond--root {
                height: 100% !important;

                .filepond--drop-label {
                    height: 100%;
                    background: transparent;
                }

                .filepond--panel-root {
                    background-color: transparent;
                }
            }
        }
    }
}

.uploadfile__wrapper {
    height: 260px;
    border-radius: var(--container-border);
    margin: 10px;
    border: 0.12rem dashed var(--brand-accent-neutral-alt);

    .uploadfile__container {
        position: relative;
        width: 100%;
        height: 100%;
        .image__expand__icon {
            position: absolute;
            right: 25px;
            bottom: 25px;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 7px;
            border-radius: 50%;
            background: black;
            transition: 0.3s all;
            border: 2px solid transparent;

            &:hover {
                border-color: #ffffff;
            }
        }
    }
}

.multiselect-holder {
    ::v-deep {
        .multiselect__placeholder {
            margin: 0;
        }

        .multiselect__tag {
            margin-bottom: 0;
            background-color: var(--brand-color);
            .multiselect__tag-icon:focus,
            .multiselect__tag-icon:hover {
                background-color: var(--brand-hover-on);
            }
            .multiselect__tag-icon:after {
                color: #ffffff;
            }
        }

        .multiselect__placeholder {
            margin-bottom: 0;
        }
    }
}

.fields-container {
    .font-bold {
        font-weight: bold;
    }

    .manual-screenshot {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
        cursor: pointer;

        .screenshot-title {
            margin-left: 0.5rem;
            color: var(--brand-color);
        }

        img {
            height: 30px;
            filter: hue-rotate(50);
        }
    }
}
</style>
