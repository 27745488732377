import {DATA_TYPES} from "@/utils/content-scripts/mark.decorator";

/**
 * Selected Keyword Entity properties
 *  @param {String} source - string with Selected Keyword Entity
 *
 *   @return {Object};
 */

function SelectedKeywordToMarkEntityAdapter(source) {
    return {
        getEntity() {
            const elementForRender = {
                word: source,
            };
            return {
                element: elementForRender,
                dataType: DATA_TYPES.selectedKeywords,
                pattern: new RegExp(`${this.escapeRegExpWithAllowed(source)}`, "gmi"),
            };
        },
        getClasses() {
            const fixedClass = "user-search-list selected-keywords";
            return `${fixedClass}`;
        },
        getAttributes() {
            const attributes = [];
            attributes.push({key: "title", value: `${DATA_TYPES.selectedKeywords} - ${source}`});
            return attributes;
        },
        escapeRegExpWithAllowed(exprString) {
            // escape not allowed
            const notAllowed = "[.+?^${}()|[\\]\\\\]";
            let escaped = exprString.replace(new RegExp(notAllowed, "g"), "\\$&");
            // process * to pass to regexp like '\S* to select all non-space between
            escaped = escaped.replace(/[*]/g, "\\S$&");
            // select only withing one word
            return `\\b\\S*${escaped}\\S*\\b`;
        },
    };
}

export default SelectedKeywordToMarkEntityAdapter;
