<template>
    <div>
        <modal name="search-engine-cheat-sheet" height="600px" width="800px">
            <div class="tw-flex tw-mt-4 tw-justify-end tw-mr-4 hover:tw-text-brand-hover tw-cursor-pointer" @click="hideModal('search-engine-cheat-sheet')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>

            <div class="tw-p-5 tw-pt-2 tw-space-y-2 tw-h-full tw-overflow-y-auto scroll-bar">
                <div class="tw-pb-3 tw-font-bold">The Search Engine Tool uses Boolean Logic.</div>
                <div class="tw-text-yellow-600">
                    <span class="tw-font-bold">Quotation Marks</span> <span class="tw-underline tw-mr-2"> " "</span> <span class="tw-text-yellow-600"><span class="tw-italic">used for </span> searching an exact phrase</span>
                </div>
                <div><span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span><span class="tw-text-purple-600"> "John Oliver Doe"</span></div>
                <div class="tw-text-yellow-600"><span class="tw-font-bold">OR </span><span class="tw-italic">used for </span> searching for multiple terms</div>
                <div><span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span><span class="tw-text-purple-600"> "John Doe" OR "Jonathon Smith" </span></div>
                <div><span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span> <span class="tw-text-purple-600">"(John OR Jonathon) Doe" "(London OR Manchester OR Rome) </span></div>
                <div class="tw-leading-normal">
                    <span class="tw-font-serif">The word OR will pick up the word or quotation either side of it. Phrases with multiple words must be in quotation marks. E.g., </span>
                    <span class="tw-text-purple-600 tw-font-serif">"United States of <span class="tw-font-bold">America</span> OR <span class="tw-font-bold"> New </span> Zealand"</span>
                    will choose between the emboldened words, and search for <span class="tw-text-purple-600"> "United States of America Zealand" </span> and <span class="tw-text-purple-600">"United States of New Zealand".</span> Therefore, the search should be <span class="tw-text-purple-600 tw-font-sans">"United States of America" OR "New Zealand"</span>
                </div>
                <div class="tw-text-yellow-600"><span class="tw-font-bold">Hyphen — </span> used for removing a word from the search results</div>
                <div>
                    <span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span> <span class="tw-text-purple-600 tw-font-sans">"Bill Gates" -<span class="tw-text-sm">Microsoft</span></span>
                </div>
                <div>
                    <span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span> <span class="tw-text-purple-600 tw-font-sans">"Bill Gates" -"<span class="tw-text-sm">Melinda Gates"</span></span>
                </div>
                <div>The hyphen must be connected to the word or quotation with no space between them</div>
                <div class="tw-text-yellow-600">
                    <span class="tw-font-bold">Wildcard <span style="position: relative; top: -0.3em">*</span></span> used for searching phrases with unknown words
                </div>
                <div>
                    <span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span><span class="tw-text-purple-600 tw-font-sans"> "John <span style="position: relative; top: -0.3em">*</span> Doe"</span>
                </div>
                <div class="tw-text-yellow-600"><span class="tw-font-bold">{Search Lists}</span> used for adding lists of words/phrases to your search</div>
                <div><span class="tw-text-sm tw-font-bold tw-italic">e.g.,</span><span class="tw-text-purple-600 tw-font-sans"> "John OR Jonathan Doe" {English Criminal}</span></div>
                <div class="tw-leading-normal tw-font-serif">These lists must include the language and the list name from the Platform Library, in curly brackets with capital letters. They only work with languages available in the Pre-Built Queries language dropdown.</div>
                <div class="tw-leading-normal tw-mb-5">
                    <ul>
                        Examples include:
                        <li class="tw-ml-10">{English Criminal}</li>
                        <li class="tw-ml-10">{Italian Scandal}</li>
                        <li class="tw-ml-10">{Russian Harassment}</li>
                        <li class="tw-ml-10">{Chinese-simplified Sexual}</li>
                        <li class="tw-ml-10">{English Climate Change} — <span class="tw-italic">not available to all users</span></li>
                        <li class="tw-ml-10 tw-appearance-none"></li>
                    </ul>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
export default {
    methods: {
        hideModal(modalname) {
            this.$modal.hide(modalname);
        },
    },
};
</script>

<style>
/* .vm--modal{
        width: 800px!important;
        top:100px !important
} */
</style>
