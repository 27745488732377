import { render, staticRenderFns } from "./attribute-holder.html?vue&type=template&id=2cc175be&scoped=true&"
import script from "./attribute-holder.js?vue&type=script&lang=js&"
export * from "./attribute-holder.js?vue&type=script&lang=js&"
import style0 from "./attribute-holder.scss?vue&type=style&index=0&id=2cc175be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc175be",
  null
  
)

export default component.exports