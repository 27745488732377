import Button from "@/components/button";

export default {
    name: "graph-preview",
    props: {
        imageSrc: {
            type: String,
            default: ``,
        },
        imageBlob: {
            type: String,
        },
    },
    components: {
        "neo-button": Button,
    },
    data() {
        return {};
    },
    mounted() {
        const image = document.getElementById("graphImage");

        image.zoom = 1;

        image.onwheel = function _image__onwheel(event) {
            event.preventDefault();
            this[event.deltaY < 0 ? "onscrollforeward" : "onscrollbackward"]();
        };

        image.onscrollforeward = function _image__onscrollforeward() {
            this.style.transform = `scale(${this.zoom + 0.1})`;
        };

        image.onscrollbackward = function _image__onscrollbackward() {
            // if (this.zoom > 1){
            //     this.style.transform = `scale(${ --this.zoom })`;
            // }
            this.style.transform = `scale(${this.zoom - 0.1})`;
        };
    },
    methods: {
        onclose() {
            this.$emit("close");
        },
        async printImage() {
            await navigator.clipboard.write([new ClipboardItem({"image/png": this.imageBlob})]); // eslint-disable-line
        },
    },
};
