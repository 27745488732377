import axios from "axios";
import VueCookies from "vue-cookies";
import refreshTokeninterceptor from "./utils.js";

const keywordsApi = axios.create({
    baseURL: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1`,
});

// Add a request interceptor
keywordsApi.interceptors.request.use(
    function(config) {
        let token = VueCookies.get("token");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

keywordsApi.interceptors.response.use(undefined, refreshTokeninterceptor(keywordsApi));
export default keywordsApi;
