// import axios from "axios";
import {jwtBlackboxApi} from "../main.js";
import {getOutputTemplateProductCategory} from "../services/end-points/output-template.data-provider";

const url = {
    adminCategory: `output-template/category`, // get, post, put
    adminSections: `output-template/sections`, // get
    productCategory: (product_id) => `output-template/product/${product_id}`, // get, delete
    deleteCategory: (category_id) => `output-template/category/${category_id}`, // delete
    section: (category_id, section_id) => `output-template/category/${category_id}/section/${section_id}`, // get, put, delete
    addSection: (category_id) => `output-template/category/${category_id}/section`, // post
    toggleSection: `output-template/product/section`, // post
    priorityCategory: `output-template/categories/priority`, // post
    prioritySection: `output-template/categories/section/priority`, // post
};

const MUTATIONS_ENUM = Object.freeze({
    SET_CACHED_PRODUCTS_CATEGORIES_MAP_ITEM: "SET_CACHED_PRODUCTS_CATEGORIES_MAP_ITEM",
});

export default {
    state: () => ({
        cachedProductsCategoriesMap: {},
    }),
    getters: {
        cachedProductsCategoriesMap: ({cachedProductsCategoriesMap}) => cachedProductsCategoriesMap,
    },
    mutations: {
        [MUTATIONS_ENUM.SET_CACHED_PRODUCTS_CATEGORIES_MAP_ITEM](state, {productId, categories}) {
            if (typeof productId !== "string") throw new TypeError("productId must be a string");
            if (!Array.isArray(categories)) throw new TypeError("categories must be an array");
            state.cachedProductsCategoriesMap[productId] = categories;
        },
    },
    actions: {
        getOutputTemplateAdminCategories: async ({commit}, params) => {
            let {data} = await jwtBlackboxApi.get(url.adminCategory, {params});
            return data;
        },
        getOutputTemplateAdminSections: async ({commit}, params) => {
            let {data} = await jwtBlackboxApi.get(url.adminSections, {params});
            return data;
        },
        postOutputTemplateCategory: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.post(url.adminCategory, payload);
            return data;
        },
        putOutputTemplateCategory: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.put(url.adminCategory, payload);
            return data;
        },
        deleteOutputTemplateCategory: async ({commit}, payload) => {
            const {category_id, ...params} = payload;
            let {data} = await jwtBlackboxApi.delete(url.deleteCategory(category_id), {params});
            return data;
        },
        putOutputTemplateSection: async ({commit}, payload) => {
            const {section_id, category_id, updateData} = payload;
            let {data} = await jwtBlackboxApi.put(url.section(category_id, section_id), updateData);
            return data;
        },
        addOutputTemplateSection: async ({commit}, payload) => {
            const {category_id, updateData} = payload;
            let {data} = await jwtBlackboxApi.post(url.addSection(category_id), updateData);
            return data;
        },
        deleteOutputTemplateSection: async ({commit}, payload) => {
            const {section_id, category_id, ...params} = payload;
            let {data} = await jwtBlackboxApi.delete(url.section(category_id, section_id), {params});
            return data;
        },
        getOutputTemplateSectionData: async ({commit}, payload) => {
            const {section_id, category_id, ...params} = payload;
            let {data} = await jwtBlackboxApi.get(url.section(category_id, section_id), {params});
            return data;
        },
        getOutputTemplateProductCategories: async ({commit}, product_id) => {
            const categories = await getOutputTemplateProductCategory(product_id);
            commit(MUTATIONS_ENUM.SET_CACHED_PRODUCTS_CATEGORIES_MAP_ITEM, {productId: product_id, categories});
            return categories;
        },
        swapOutputTemplateProductCategories: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.post(url.priorityCategory, payload);
            return data;
        },
        swapOutputTemplateProductSections: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.post(url.prioritySection, payload);
            return data;
        },
        toggleOutputTemplateProductSection: async ({commit}, payload) => {
            let {data} = await jwtBlackboxApi.post(url.toggleSection, payload);
            return data;
        },
    },
    namespaced: true,
};
