import Graph from "./graph/graph";
import Research from "./research";
import Vue from "vue";
import Vuex from "vuex";
import Templates from "./templates";
import VueCookies from "vue-cookies";
import Cases from "./cases";
import Commons from "./commons";
import Auth from "./auth";
import NLPAnalysis from "./nlp-analysis";
import Comment from "./comment";
import Keywords from "./keywords";
import jwt_decode from "jwt-decode";
import ToolManager from "./tool-manager";
import ReportTemplates from "./report-templates";
import SearchEngine from "./search-engine";
import OutputTemplates from "./output-templates";
import dashboard from "./dashboard";
import CreditAdmin from "./credit-admin";

Vue.use(Vuex);

export default new Vuex.Store({
    state: () => ({
        token: VueCookies.get("token") || "",
        accountId: null,
        user_name: "",
        nextUrl: null,
        accountDetails: {},
        search: {
            research: "",
            "research-automated": "",
            sources: "",
            methodology: "",
            tools: "",
            "Research Methodology": "",
            Sources: "",
            monitoring: "",
            "sources-ro": "",
            "methodology-ro": "",
            "tools-ro": "",
            "monitoring-ro": "",
        },
        tokenExpirationTimer: null,
        onBoardingStatus: false,
    }),
    mutations: {
        setCase(state, payload) {
            state.activeCaseId = payload.caseId;
        },
        setAccountId(state, payload) {
            state.accountId = payload.accountId;
        },

        setAccountDetails(state, payload) {
            state.accountDetails = payload.accountDetails;
        },
        setUserAccounts(state, payload) {
            state.userAccounts = payload.accounts;
        },
        logout(state) {
            state.token = "";
        },
        auth_request(state, token) {
            state.token = token;
            VueCookies.set("token", token);
        },
        set_user(state, user) {
            state.user_name = user;
        },
        set_search: (state, payload) => (state.search = payload),
        set_tokenExpirationTimer: (state, payload) => (state.tokenExpirationTimer = payload),
        setOnBoardingStatus: (state, payload) => {
            state.onBoardingStatus = payload.status;
        },
    },
    actions: {},
    getters: {
        getActiveCaseId: (state) => {
            return state.activeCaseId;
        },
        getAccountDetails: (state) => {
            return state.accountDetails;
        },

        getOriginalLoggedInAccount: (state) => {
            return state.accountId;
        },
        getUserAccounts: (state) => {
            return state.userAccounts;
        },
        getAccountId: (state) => {
            return state.accountId;
        },
        isLoggedIn: (state) => (state.token ? true : false),
        getJWTToken: (state) => state.token,
        getDecodedToken: (state) => {
            return jwt_decode(state.token);
        },
        authStatus: (state) => state.status,
        getLoggedInUser: (state) => state.user_name,
        search: (state) => state.search,
        tokenExpirationTimer: (state) => state.expirationTimer,
        getOnBoardingStatus: (state) => state.onBoardingStatus,
    },
    modules: {
        research: Research,
        graph: Graph,
        commons: Commons,
        comment: Comment,
        cases: Cases,
        templates: Templates,
        auth: Auth,
        toolmanager: ToolManager,
        keywords: Keywords,
        nlp: NLPAnalysis,
        reportTemplates: ReportTemplates,
        searchEngine: SearchEngine,
        outputTemplates: OutputTemplates,
        dashboard,
        CreditAdmin,
    },
});
