// import axios from "axios";
import {jwtBlackboxApi} from "../main.js";

const url = {
    adminSearchEngineCategories: "search-engine/company/category-queries-map",
    productSearchEngineCategories: `search-engine/product/product-category-map`,
    productCategorySwitchOn: `search-engine/product/category`, // use only to switch on
    productCategorySwitchOff: (product_id, category_id) => `search-engine/product/${product_id}/category/${category_id}`,
};

export default {
    state: () => ({
        adminSearchEngineCategories: [],
        productSearchEngineCategories: [],
    }),
    getters: {},
    mutations: {
        // For now, there is no need to use setters and save data at store because we use data only in one component
        // SET_ADMIN_SEARCH_ENGINE_QUERIES(state, payload) {
        //     state.adminSearchEngineCategories = payload;
        // },
        RESET_ADMIN_SEARCH_ENGINE_QUERIES(state) {
            state.adminSearchEngineCategories = [];
        },
        // SET_PRODUCT_SEARCH_ENGINE_QUERIES(state, payload) {
        //     state.productSearchEngineCategories = payload;
        // },
        RESET_PRODUCT_SEARCH_ENGINE_QUERIES(state) {
            state.adminSearchEngineCategories = [];
            state.productSearchEngineCategories = [];
        },
    },
    actions: {
        getAdminSearchEngineCategories: async ({commit}, params) => {
            let {data} = await jwtBlackboxApi.get(url.adminSearchEngineCategories, {params});
            // commit("SET_ADMIN_SEARCH_ENGINE_QUERIES", data);
            return data;
        },
        getProductSearchEngineCategories: async ({commit}, payload) => {
            const {product_id, params} = payload;
            let {data: response} = await jwtBlackboxApi.get(url.productSearchEngineCategories, {params});
            // commit("SET_PRODUCT_SEARCH_ENGINE_QUERIES", data);
            const list = response.data.map((el) => ({
                ...el,
                checked: !!el.products.find((pr) => pr.id === payload.product_id),
            }));
            return {...response, list};
        },
        postProductSearchEngineCategories: async ({commit}, payload) => {
            const {value, ...rest} = payload;
            if (value) {
                let {data} = await jwtBlackboxApi.post(url.productCategorySwitchOn, rest);
                if (!data.status) throw new Error(data.message);
                return data;
            } else {
                let {data} = await jwtBlackboxApi.delete(url.productCategorySwitchOff(rest.product_id, rest.id));
                if (!data.status) throw new Error(data.message);
                return data;
            }
        },
    },
};
