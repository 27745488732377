<template>
    <div class="">
        <h2 class="tw-mb-4">Monitoring</h2>
        <div class="tw-flex tw-items-center tw-w-full">
            <input v-model="entityData.monitoring" type="checkbox" id="monitoring" class="" :disabled="isProductMonitoringEnabled(entityData.entity_type)" />
            <label for="monitoring" class="label tw-mx-2">Save for Ongoing Monitoring</label>
        </div>
        <div class="bootstrap-styles">
            <b-alert v-if="isProductMonitoringEnabled(entityData.entity_type)" variant="warning" class="tw-mt-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
                <span class="tw-ml-2 tw-text-sm"> Please enable monitoring from Product Admin </span>
            </b-alert>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "Monitoring",
    components: {
        multiselect: Multiselect,
    },
    props: {
        entity: Object,
    },
    data() {
        return {};
    },
    methods: {
        isProductMonitoringEnabled(type) {
            if (type === "entity") return false;
            const mapper = {
                passive_media: "active-passive-media",
                social_profile: "social-media",
                search_engine: "search-engine-tool",
                url: "url",
            };
            let monitoring_enabled_tools = this.$store.getters.productMonitoring;
            return !monitoring_enabled_tools.some((el) => el.tool === mapper[type] && el.status === "enabled");
        },
    },
    mounted() {
        this.$store.getters.productMonitoring.length > 0 ? "" : this.$store.dispatch("getProductMonitoring");
    },
    computed: {
        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },
    },
};
</script>

<style lang="scss">
.offcanvas-content {
    .bootstrap-styles {
        label {
            margin-bottom: 0px !important;
            padding: 0px !important;
        }
    }
}
</style>
