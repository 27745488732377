import {baseBlackboxUrl} from "../main";
import axios from "@/axios";

/**
 *
 */
async function getOsintSources() {
    let url = `/osint-sources`;

    let headers = {
        Accept: "application/json",
        Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
    };

    let osintSources = await axios.get(url, {headers});
    return osintSources?.data;
}

function titleCase(s) {
    let title = "";
    s = s.split("-");
    s[0] = s[0][0].toUpperCase() + s[0].slice(1);
    s.forEach((e) => (title = title + e + " "));
    return title;
}

async function getOsintFilterValues(osintSources) {
    var country = [],
        region = [],
        type = [],
        tags = [];

    osintSources?.forEach((source) => {
        if (source.original_source === "mind_map" || source.original_source === "id_occrp") {
            if (source.region) {
                region = region.concat(source.region);
            }
            if (source.country) {
                country = country.concat(source.country);
            }
            if (source.type) {
                type = type.concat(source.type);
            }
            if (source.tags) {
                tags = tags.concat(source.tags);
            }
        }
    });

    country = [...new Set(["All", ...country])].map((e) => {
        return {label: titleCase(e), value: e};
    });

    region = [...new Set(["All", ...region])].map((e) => {
        return {label: titleCase(e), value: e};
    });

    type = [...new Set(["All", ...type])].map((e) => {
        return {label: titleCase(e), value: e};
    });

    tags = [...new Set(["All", ...tags])].map((e) => {
        return {label: e, value: e};
    });

    return {country: country, region: region, type: type, tags: tags};
}

async function getOsintSourcesAggregatedByCountry(osintSources) {
    let osintData = {
        children: [],
    };

    osintSources.forEach((source) => {
        source.country?.forEach(function(country) {
            let findIndex = osintData.children.findIndex((val) => {
                return val.text.toLowerCase() == country;
            });
            if (findIndex >= 0) {
                osintData.children[findIndex].children.push({text: source.source});
            } else {
                osintData.children.push({
                    text: country,
                    children: [{text: source.source}],
                });
            }
        });
    });
    return osintData;
}

export {getOsintSources, getOsintFilterValues, getOsintSourcesAggregatedByCountry};
