<template>
    <div>
        <h3 style="color: var(--brand-accent-neutral-alt)">Case ID:</h3>
        <h3>{{ id }}</h3>
        <h3 style="color: var(--brand-accent-neutral-alt)">Name:</h3>
        <h3>{{ name }}</h3>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "CaseProfile",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["id", "name"],
    data() {
        return {};
    },

    async mounted() {},

    methods: {},
};
</script>

<style scoped></style>
