import AttributeHolder from "@/containers/add-entity-panel/panel/attribute-holder";
import DropdownDatepicker from "@/containers/add-entity-panel/panel/attribute-holder/form-elements/dropdown-datepicker.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapGetters} from "vuex";
import neoToggle from "@/components/toggle";

export default {
    name: "Attributes",
    components: {
        "attribute-holder": AttributeHolder,
        multiselect: Multiselect,
        DropdownDatepicker,
        "neo-toggle": neoToggle,
    },
    props: {
        entity: Object,
        data: Object,
    },
    data() {
        return {
            type: "user",
            selectedSocialPlatform: null,
            date: new Date().getFullYear(),
        };
    },
    methods: {
        tempChange(e) {
            let temp = [];
            temp.push(e.year ?? "");
            temp.push(e.month ?? "");
            temp.push(e.day ?? "");
            const val = temp.join("-");
            this.entityData.published_at = val;
        },

        updateAttributes(value) {
            this.entityData.attributes = value;
        },

        updateSeletedPlatform(value) {
            this.entityData.platform = value.key;
        },
    },
    mounted() {
        this.selectedSocialPlatform = this.getSocialPlatforms.find((platform) => platform.key === this.entityData.platform);
    },
    computed: {
        ...mapGetters(["getSocialPlatforms"]),

        panel_type() {
            return this.$store.getters.getSelectedEntityData.datatype;
        },

        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },
    },
};
