<template>
    <div>
        <ul>
            <li class="tw-list-none" v-for="(item, index) in treeData" :key="index">
                <tree-item :data="item" @toggleTopics="$emit('toggleTopics', $event)"></tree-item>
            </li>
        </ul>
    </div>
</template>

<script>
import TreeItem from "./tree-item"; // Import the TreeItem component

export default {
    components: {
        TreeItem,
    },
    props: {
        treeData: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
ul {
    li {
        list-style-type: none !important;
    }
}
</style>
