import { render, staticRenderFns } from "./tag.html?vue&type=template&id=ce0607ae&scoped=true&"
import script from "./tag.js?vue&type=script&lang=js&"
export * from "./tag.js?vue&type=script&lang=js&"
import style0 from "./tag.scss?vue&type=style&index=0&id=ce0607ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce0607ae",
  null
  
)

export default component.exports