import {rgbToHex} from "@/utils/functions";

export default {
    name: "neo-color-picker",
    components: {},
    props: {
        value: {
            type: String,
        },
        label: String,
        margin: String,
        rect: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 32,
        },
        width: {
            type: Number,
            default: 32,
        },
    },
    // props: ["value", "label", "margin", "rect"],
    data() {
        return {rgbToHex: rgbToHex};
    },
    computed: {},
    mounted() {},
    methods: {},
};
