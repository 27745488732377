export default {
    name: "Details",
    components: {},
    props: {
        entity: Object,
    },
    data() {
        return {};
    },
    computed: {
        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },
    },
    methods: {},
    async mounted() {},
};
