export default {
    name: "toggle-switch",
    components: {},
    props: ["status", "disabled"],
    data() {
        return {};
    },
    computed: {
        optionalClass() {
            if (this.disabled) {
                if (this.status) {
                    return "tw-bg-blue-400 tw-bg-gray-100   tw-cursor-not-allowed tw-pointer-events-none";
                }
                return "tw-bg-gray-100 tw-cursor-not-allowed tw-pointer-events-none  tw-opacity-50";
            } else if (this.status) return "tw-bg-brand ";
            else return;
        },
    },
    mounted() {},
    methods: {},
};
