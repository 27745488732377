import axios from "@/axios/development";

export default {
    async getLanguages() {
        return (await axios.get("/keywords/master/languages")).data.data;
    },
    async getEntities(limit = 10, offset = 0) {
        return (await axios.get("/keywords", {params: {limit, offset}})).data.data;
    },
    async getKeywordProductMap(productId) {
        return (await axios.get(`/keywords/keyword-product-map/${productId}`)).data.data;
    },
    async setKeywordSetsToProduct({productId, keywordIds} = payload) {
        if (productId === undefined) {
            throw new Error(`Product id is not defined`);
        }

        if (keywordIds === undefined) {
            throw new Error(`Keyword ids list is not defined`);
        }

        return (
            await axios.post(`/keywords/keyword-product-map/`, {
                product_id: productId,
                keyword_id: keywordIds,
            })
        ).data.data;
    },
    async removeKeywordSetsFromProduct({productId, keywordIds} = payload) {
        if (productId === undefined) {
            throw new Error(`Product id is not defined`);
        }

        if (keywordIds === undefined) {
            throw new Error(`Keyword ids list is not defined`);
        }

        return (
            await axios.delete(`/keywords/keyword-product-map/delete`, {
                product_id: productId,
                keyword_id: keywordIds,
            })
        ).data.data;
    },
    async createEntity(entity) {
        if (entity === undefined) {
            throw new Error(`Entity isn't defined`);
        }

        return (await axios.post("/keywords", entity)).data;
    },
    async getEntity(entityId) {
        if (entityId === undefined) {
            throw new Error(`Id isn't defined`);
        }

        return (await axios.get(`/keywords/${entityId}`)).data;
    },
    async updateEntity(entityId, entity) {
        if (entityId === undefined) {
            throw new Error(`Id isn't defined`);
        }

        if (entity === undefined) {
            throw new Error(`Entity isn't defined`);
        }

        return (await axios.put(`/keywords/${entityId}`, entity)).data;
    },
    async deleteEntity(entityId) {
        if (entityId === undefined) {
            throw new Error(`Id isn't defined`);
        }

        return (await axios.delete(`/keywords/${entityId}`)).data;
    },
    async filterEntities(
        filters = {},
        limit = 10,
        offset = 0,
        order = {
            ordering_key: "keyword_set_name",
            ordering_value: "ASC",
        }
    ) {
        const filterResponse = (await axios.post("/keywords/filter", {...filters, limit, offset, ...order})).data;

        return [filterResponse.data, filterResponse.total_records];
    },
};
