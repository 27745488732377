import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import caseProfile from "./caseProfile";
import searchEngineFilters from "./searchEngineFilters";

import Button from "@/components/button";
import Input from "@/components/input";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Table from "@/components/table";
import Tab from "@/components/tab";
import Loader from "@/components/loader";
import Dropdown from "@/components/dropdown";
import Pagination from "@/components/pagination";
import axios from "../../../axios";
import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(Toast);

export default {
    name: "neo-users",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-pagination": Pagination,
        DatePicker,
        multiselect: Multiselect,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown": Dropdown,
        "case-profile": caseProfile,
        "search-engine-filters": searchEngineFilters,
    },
    props: {},
    data() {
        return {
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            alertMessage: {
                success: null,
                error: null,
            },
            tabs: [
                {key: "unmarked", name: "Unmarked Results", enabled: true},
                {key: "done", name: "Done Results", enabled: true},
            ],
            currentTab: "unmarked",
            searchCaseFilter: "",
            case_id: "",
            allFilters: [],
            allDatapoints: [],
            caseProfile: null,
            newDatapoints: [],
            filters: {},
            headers: [
                {
                    text: "Title & Snippet",
                    value: "snippet",
                },
                {
                    text: "Domain Category",
                    value: "domain_category",
                },
                {
                    text: "Domain Reputation",
                    value: "domain_reputation",
                },
                {
                    text: "Sentiment",
                    value: "sentiment",
                },
                {
                    text: "Topics",
                    value: "topics",
                },
                {
                    text: "Matching Keywords",
                    value: "keywords",
                },
            ],
            itemKey: "_id",
            selectedFilters: {},
            selectable: false,
            hasAction: true,
            languages: [
                "",
                "Afrikaans",
                "Albanian",
                "Amharic",
                "Arabic",
                "Armenian",
                "Azerbaijani",
                "Basque",
                "Belarusian",
                "Bengali",
                "Bosnian",
                "Bulgarian",
                "Catalan",
                "Cebuano",
                "Chinese",
                "Corsican",
                "Croatian",
                "Czech",
                "Danish",
                "Dutch",
                "English",
                "Esperanto",
                "Estonian",
                "Finnish",
                "French",
                "Frisian",
                "Galician",
                "Georgian",
                "German",
                "Greek",
                "Gujarati",
                "Haitian Creole",
                "Hausa",
                "Hawaiian",
                "Hebrew",
                "Hindi",
                "Hmong",
                "Hungarian",
                "Icelandic",
                "Igbo",
                "Indonesian",
                "Irish",
                "Italian",
                "Japanese",
                "Javanese",
                "Kannada",
                "Kazakh",
                "Khmer",
                "Kinyarwanda",
                "Korean",
                "Kurdish",
                "Kyrgyz",
                "Lao",
                "Latin",
                "Latvian",
                "Lithuanian",
                "Luxembourgish",
                "Macedonian",
                "Malagasy",
                "Malay",
                "Malayalam",
                "Maltese",
                "Maori",
                "Marathi",
                "Mongolian",
                "Burmese",
                "Nepali",
                "Norwegian",
                "Nyanja (Chichewa)",
                "Odia (Oriya)",
                "Pashto",
                "Persian",
                "Polish",
                "Portuguese",
                "Punjabi",
                "Romanian",
                "Russian",
                "Samoan",
                "Scots Gaelic",
                "Serbian",
                "Sesotho",
                "Shona",
                "Sindhi",
                "Sinhalese",
                "Sinhala",
                "Slovak",
                "Slovenian",
                "Somali",
                "Spanish",
                "Sundanese",
                "Swahili",
                "Swedish",
                "Tagalog (Filipino)",
                "Tajik",
                "Tamil",
                "Tatar",
                "Telugu",
                "Thai",
                "Turkish",
                "Turkmen",
                "Ukrainian",
                "Urdu",
                "Uyghur",
                "Uzbek",
                "Vietnamese",
                "Welsh",
                "Xhosa",
                "Yiddish",
                "Yoruba",
                "Zulu",
            ],
            searchOptions: [],
            sentimentOptions: [
                {
                    text: "Positive",
                    value: 0.5,
                },
                {
                    text: "Neutral",
                    value: 0,
                },
                {
                    text: "Negative",
                    value: -0.5,
                },
            ],
            domainCategories: [
                "Arts & Entertainment",
                "Books & Literature",
                "Celebrity Fan/Gossip",
                "Fine Art",
                "Humor",
                "Movies",
                "Music & Audio",
                "Television & Video",
                "Automotive",
                "Auto Parts",
                "Auto Repair",
                "Buying/Selling Cars",
                "Car Culture",
                "Certified Pre-Owned",
                "Convertible",
                "Coupe",
                "Crossover",
                "Diesel",
                "Electric Vehicle",
                "Hatchback",
                "Hybrid",
                "Luxury",
                "Minivan",
                "Motorcycles",
                "Off-Road Vehicles",
                "Performance Vehicles",
                "Pickup",
                "Road-Side Assistance",
                "Sedan",
                "Trucks & Accessories",
                "Vintage Cars",
                "Wagon",
                "Business",
                "Advertising",
                "Agriculture",
                "Biotech/Biomedical",
                "Business Software",
                "Construction",
                "Forestry",
                "Government",
                "Green Solutions",
                "Human Resources",
                "Logistics",
                "Marketing",
                "Metals",
                "Careers",
                "Career Planning",
                "College",
                "Financial Aid",
                "Job Fairs",
                "Job Search",
                "Resume Writing/Advice",
                "Nursing",
                "Scholarships",
                "Telecommuting",
                "Military",
                "Career Advice",
                "Education",
                "7-12 Education",
                "Adult Education",
                "Art History",
                "College Administration",
                "College Life",
                "Distance Learning",
                "English as a 2nd Language",
                "Language Learning",
                "College / Graduate School",
                "Homeschooling",
                "Homework/Study Tips",
                "K-6 Education",
                "Private School",
                "Special Education",
                "Studying Business",
                "Family & Parenting",
                "Adoption",
                "Babies & Toddlers",
                "Daycare/Pre School",
                "Family Internet",
                "Parenting - K-6 Kids",
                "Parenting teens",
                "Pregnancy",
                "Special Needs Kids",
                "Eldercare",
                "Health & Fitness",
                "Exercise / Weight Loss",
                "ADD",
                "AIDS / HIV",
                "Allergies",
                "Alternative Medicine / Holistic Healing",
                "Arthritis",
                "Asthma",
                "Autism / PDD / Asperger’s Syndrome",
                "Bipolar Disorder",
                "Brain Tumor",
                "Cancer",
                "Cholesterol",
                "Chronic Fatigue Syndrome",
                "Chronic Pain",
                "Cold & Flu",
                "Deafness",
                "Dental Care",
                "Depression",
                "Dermatology",
                "Diabetes",
                "Epilepsy / Seizures",
                "GERD / Acid Reflux",
                "Headaches / Migraines / Fevers / Pain",
                "Heart Disease / Stroke",
                "Herbs for Health / Supplements / Vitamins",
                "Holistic Healing (see: IAB7-5)",
                "IBS / Crohn’s / Celiac Disease",
                "Incest / Abuse Support",
                "Incontinence",
                "Infertility",
                "Men’s Health",
                "Nutrition",
                "Orthopedics",
                "Panic / Anxiety Disorders",
                "Pediatrics",
                "Physical Therapy",
                "Psychology / Psychiatry / Therapy",
                "Senior Health",
                "Sexuality",
                "Sleep Disorders",
                "Smoking Cessation",
                "Substance Abuse",
                "Thyroid Disease / Endocrinology",
                "Weight Loss (see: IAB7-1)",
                "Women’s Health",
                "Abortion",
                "Food & Drink",
                "American Cuisine",
                "Barbecues & Grilling",
                "Cajun / Creole",
                "Chinese Cuisine",
                "Cocktails / Beer",
                "Coffee / Tea",
                "Cuisine-Specific",
                "Desserts & Baking",
                "Dining Out",
                "Food Allergies",
                "French Cuisine",
                "Health / Low-Fat Cooking",
                "Italian Cuisine",
                "Japanese Cuisine",
                "Mexican Cuisine",
                "Vegan",
                "Vegetarian",
                "Wine",
                "Hobbies & Interests",
                "Art / Technology",
                "Arts & Crafts",
                "Beadwork",
                "Bird-Watching",
                "Board Games / Puzzles",
                "Candle & Soap Making",
                "Card Games",
                "Chess",
                "Cigars / Vaping / Tobacco & Accessories",
                "Collecting",
                "Comic Books / Anime / Manga",
                "Drawing / Sketching",
                "Freelance Writing / Getting Published",
                "Genealogy",
                "Getting Published (see: IAB9-13)",
                "Guitar / Keyboard / Drums",
                "Home Recording",
                "Investors / Inventors / Patents / Copyright",
                "Jewelry Making",
                "Magic & Illusion",
                "Needlework",
                "Painting",
                "Photography",
                "Radio",
                "Roleplaying Games",
                "Sci-Fi & Fantasy",
                "Scrapbooking",
                "Screenwriting",
                "Stamps & Coins",
                "Video & Computer Games",
                "Woodworking",
                "Gambling",
                "Weapons",
                "Home & Garden",
                "Appliances",
                "Entertaining",
                "Environmental Safety",
                "Gardening",
                "Home Repair",
                "Home Theater",
                "Interior Decorating",
                "Landscaping",
                "Remodeling & Construction",
                "Law, Government, & Politics",
                "Immigration",
                "Legal Issues",
                "Government Resources",
                "Politics",
                "Commentary",
                "News / Weather / Information",
                "Personal Finance",
                "Beginning Investing",
                "Credit / Debit & Loans",
                "Financial News",
                "Financial Planning",
                "Hedge Fund",
                "Insurance",
                "Investing",
                "Mutual Funds / ETFs",
                "Options",
                "Retirement Planning",
                "Stocks",
                "Tax Planning / Accounting",
                "Society",
                "Dating / Personals",
                "Divorce Support",
                "LGBTQ+",
                "Marriage",
                "Senior Living",
                "Teens",
                "Weddings",
                "Ethnic Specific",
                "Social Networking",
                "Science",
                "Astrology",
                "Biology",
                "Chemistry",
                "Geology",
                "Paranormal Phenomena",
                "Physics",
                "Space / Astronomy",
                "Geography",
                "Botany",
                "Weather",
                "Pets",
                "Aquariums",
                "Birds",
                "Cats",
                "Dogs",
                "Large Animals",
                "Reptiles",
                "Veterinary Medicine",
                "Sports",
                "Auto Racing",
                "Baseball / Softball",
                "Bicycling",
                "Bodybuilding",
                "Boxing",
                "Canoeing / Kayaking",
                "Cheerleading",
                "Climbing",
                "Cricket",
                "Figure Skating",
                "Fly Fishing",
                "American Football",
                "Freshwater Fishing",
                "Game & Fish",
                "Golf",
                "Horse Racing",
                "Horses",
                "Hunting / Shooting",
                "Inline Skating",
                "Martial Arts",
                "Mountain Biking",
                "NASCAR Racing",
                "Olympics",
                "Paintball",
                "Power & Motorcycles",
                "Basketball",
                "Ice Hockey",
                "Rodeo",
                "Rugby",
                "Running / Jogging",
                "Sailing / Boating",
                "Saltwater Fishing",
                "Scuba Diving",
                "Skateboarding",
                "Skiing",
                "Snowboarding",
                "Surfing / Body-Boarding",
                "Swimming",
                "Table Tennis / Ping-Pong",
                "Tennis",
                "Volleyball",
                "Waterski / Wakeboard",
                "World Football / Soccer",
                "Style & Fashion",
                "Beauty",
                "Body Art",
                "Fashion",
                "Jewelry",
                "Clothing",
                "Accessories",
                "Technology & Computing",
                "3-D Graphics",
                "Animation",
                "Antivirus Software",
                "C / C++",
                "Cameras & Camcorders",
                "Cell Phones",
                "Computer Certification",
                "Computer Networking",
                "Computer Peripherals",
                "Computer Reviews",
                "Data Centers",
                "Databases",
                "Desktop Publishing",
                "Desktop Video",
                "Email / Chat / Messaging",
                "Graphics Software",
                "Home Video / DVD",
                "Internet Technology",
                "Java",
                "JavaScript",
                "Mac Support",
                "MP3 / MIDI",
                "Net Conferencing",
                "Net for Beginners",
                "Network Security",
                "Palmtops / PDAs / Tablets",
                "PC Support",
                "Portable",
                "Entertainment / Virtual & Augmented Reality",
                "Shareware / Freeware / Open Source",
                "Unix / Linux",
                "Visual Basic",
                "Web Clip Art",
                "Web Design / HTML",
                "Web Search",
                "Windows",
                "Hacking / Cracking",
                "VPNs / Proxies & Filter Avoidance",
                "Language Translation",
                "File Sharing",
                "Travel",
                "Adventure Travel",
                "Africa",
                "Air Travel",
                "Australia & New Zealand",
                "Bed & Breakfast",
                "Budget Travel",
                "Business Travel",
                "By US Locale",
                "Camping",
                "Canada",
                "Caribbean",
                "Cruises",
                "Eastern Europe",
                "Europe",
                "France",
                "Greece",
                "Honeymoons / Getaways",
                "Hotels",
                "Italy",
                "Japan / China",
                "Mexico & Central America",
                "National Parks",
                "South America",
                "Spas",
                "Theme Parks",
                "Traveling with Kids",
                "United Kingdom",
                "Real Estate",
                "Apartments",
                "Architects",
                "Buying / Selling Homes",
                "Shopping",
                "Contests & Freebies",
                "Couponing",
                "Comparison",
                "Engines",
                "Religion & Spirituality",
                "Alternative Religions",
                "Atheism / Agnosticism",
                "Buddhism",
                "Catholicism",
                "Christianity",
                "Hinduism",
                "Islam",
                "Judaism",
                "Latter-Day Saints",
                "Pagan / Wiccan",
                "Uncategorized",
                "Non-Standard Content",
                "Unmoderated UGC / Message Boards",
                "Extreme Graphic / Explicit Violence",
                "Adult Content",
                "Profane Content",
                "Hate Content",
                "Under Construction",
                "Incentivized",
                "Content Server",
                "Streaming Media",
                "Trackers",
                "Illegal Content",
                "Illegal Content",
                "Warez",
                "Spyware / Malware / Malicious",
                "Copyright Infringement",
                "Illegal Drugs & Paraphernalia",
                "Deceptive / Phishing",
            ],
            topics: [
                "Adult",
                "Arts & Entertainment",
                "Celebrities & Entertainment News",
                "Comics & Animation",
                "Anime & Manga",
                "Cartoons",
                "Comics",
                "Entertainment Industry",
                "Film & TV Industry",
                "Recording Industry",
                "Events & Listings",
                "Bars, Clubs & Nightlife",
                "Concerts & Music Festivals",
                "Expos & Conventions",
                "Film Festivals",
                "Movie Listings & Theater Showtimes",
                "Fun & Trivia",
                "Flash-Based Entertainment",
                "Fun Tests & Silly Surveys",
                "Humor",
                "Funny Pictures & Videos",
                "Political Humor",
                "Movies",
                "Music & Audio",
                "CD & Audio Shopping",
                "Classical Music",
                "Country Music",
                "Dance & Electronic Music",
                "Experimental & Industrial Music",
                "Jazz & Blues",
                "Music Education & Instruction",
                "Music Equipment & Technology",
                "Music Reference",
                "Music Streams & Downloads",
                "Music Videos",
                "Pop Music",
                "Radio",
                "Religious Music",
                "Rock Music",
                "Soundtracks",
                "Urban & Hip-Hop",
                "World Music",
                "Offbeat",
                "Occult & Paranormal",
                "Online Media",
                "Online Image Galleries",
                "Performing Arts",
                "Acting & Theater",
                "Circus",
                "Dance",
                "Magic",
                "Opera",
                "TV & Video",
                "Online Video",
                "TV Commercials",
                "TV Shows & Programs",
                "Visual Art & Design",
                "Architecture",
                "Art Museums & Galleries",
                "Design",
                "Painting",
                "Photographic & Digital Arts",
                "Autos & Vehicles",
                "Bicycles & Accessories",
                "Bike Parts & Repair",
                "BMX Bikes",
                "Boats & Watercraft",
                "Campers & RVs",
                "Classic Vehicles",
                "Cargo Trucks & Trailers",
                "Motor Vehicles (By Type)",
                "Hybrid & Alternative Vehicles",
                "Motorcycles",
                "Off-Road Vehicles",
                "Trucks & SUVs",
                "Vehicle Codes & Driving Laws",
                "Vehicle Licensing & Registration",
                "Vehicle Parts & Services",
                "Gas Prices & Vehicle Fueling",
                "Vehicle Parts & Accessories",
                "Vehicle Repair & Maintenance",
                "Vehicle Shopping",
                "Used Vehicles",
                "Vehicle Shows",
                "Beauty & Fitness",
                "Beauty Pageants",
                "Body Art",
                "Cosmetic Procedures",
                "Cosmetic Surgery",
                "Cosmetology & Beauty Professionals",
                "Face & Body Care",
                "Hygiene & Toiletries",
                "Make-Up & Cosmetics",
                "Perfumes & Fragrances",
                "Skin & Nail Care",
                "Unwanted Body & Facial Hair Removal",
                "Fashion & Style",
                "Fashion Designers & Collections",
                "Fitness",
                "Hair Care",
                "Hair Loss",
                "Spas & Beauty Services",
                "Massage Therapy",
                "Weight Loss",
                "Books & Literature",
                "Children's Literature",
                "E-Books",
                "Fan Fiction",
                "Literary Classics",
                "Poetry",
                "Writers Resources",
                "Business & Industrial",
                "Public Relations",
                "Space Technology",
                "Agriculture & Forestry",
                "Agricultural Equipment",
                "Forestry",
                "Livestock",
                "Automotive Industry",
                "Business Education",
                "Business Finance",
                "Venture Capital",
                "Business Operations",
                "Business Plans & Presentations",
                "Management",
                "Business Services",
                "Consulting",
                "Corporate Events",
                "E-Commerce Services",
                "Fire & Security Services",
                "Office Services",
                "Office Supplies",
                "Writing & Editing Services",
                "Chemicals Industry",
                "Cleaning Agents",
                "Plastics & Polymers",
                "Construction & Maintenance",
                "Building Materials & Supplies",
                "Energy & Utilities",
                "Electricity",
                "Oil & Gas",
                "Renewable & Alternative Energy",
                "Hospitality Industry",
                "Event Planning",
                "Food Service",
                "Industrial Materials & Equipment",
                "Heavy Machinery",
                "Manufacturing",
                "Metals & Mining",
                "Precious Metals",
                "Pharmaceuticals & Biotech",
                "Printing & Publishing",
                "Retail Trade",
                "Retail Equipment & Technology",
                "MLM & Business Opportunities",
                "Textiles & Nonwovens",
                "Transportation & Logistics",
                "Freight & Trucking",
                "Mail & Package Delivery",
                "Maritime Transport",
                "Moving & Relocation",
                "Packaging",
                "Parking",
                "Rail Transport",
                "Urban Transport",
                "Computers & Electronics",
                "CAD & CAM",
                "Computer Hardware",
                "Computer Components",
                "Computer Drives & Storage",
                "Computer Peripherals",
                "Desktop Computers",
                "Laptops & Notebooks",
                "Computer Security",
                "Hacking & Cracking",
                "Consumer Electronics",
                "Audio Equipment",
                "Camera & Photo Equipment",
                "Car Electronics",
                "Drones & RC Aircraft",
                "Game Systems & Consoles",
                "GPS & Navigation",
                "TV & Video Equipment",
                "Electronics & Electrical",
                "Electronic Components",
                "Power Supplies",
                "Enterprise Technology",
                "Data Management",
                "Networking",
                "Data Formats & Protocols",
                "Network Monitoring & Management",
                "VPN & Remote Access",
                "Programming",
                "Java (Programming Language)",
                "Software",
                "Business & Productivity Software",
                "Device Drivers",
                "Internet Software",
                "Multimedia Software",
                "Operating Systems",
                "Software Utilities",
                "Finance",
                "Accounting & Auditing",
                "Billing & Invoicing",
                "Tax Preparation & Planning",
                "Banking",
                "Credit & Lending",
                "Credit Cards",
                "Credit Reporting & Monitoring",
                "Loans",
                "Financial Planning & Management",
                "Retirement & Pension",
                "Grants, Scholarships & Financial Aid",
                "Study Grants & Scholarships",
                "Insurance",
                "Health Insurance",
                "Investing",
                "Commodities & Futures Trading",
                "Currencies & Foreign Exchange",
                "Stocks & Bonds",
                "Food & Drink",
                "Beverages",
                "Alcoholic Beverages",
                "Coffee & Tea",
                "Juice",
                "Soft Drinks",
                "Cooking & Recipes",
                "BBQ & Grilling",
                "Desserts",
                "Soups & Stews",
                "Food",
                "Food & Grocery Retailers",
                "Baked Goods",
                "Breakfast Foods",
                "Candy & Sweets",
                "Grains & Pasta",
                "Meat & Seafood",
                "Snack Foods",
                "Restaurants",
                "Fast Food",
                "Pizzerias",
                "Restaurant Reviews & Reservations",
                "Games",
                "Arcade & Coin-Op Games",
                "Board Games",
                "Chess & Abstract Strategy Games",
                "Miniatures & Wargaming",
                "Card Games",
                "Collectible Card Games",
                "Poker & Casino Games",
                "Computer & Video Games",
                "Casual Games",
                "Driving & Racing Games",
                "Fighting Games",
                "Music & Dance Games",
                "Sandbox Games",
                "Shooter Games",
                "Simulation Games",
                "Sports Games",
                "Strategy Games",
                "Video Game Emulation",
                "Family-Oriented Games & Activities",
                "Drawing & Coloring",
                "Dress-Up & Fashion Games",
                "Gambling",
                "Lottery",
                "Massively Multiplayer Games",
                "Puzzles & Brainteasers",
                "Roleplaying Games",
                "Table Games",
                "Billiards",
                "Word Games",
                "Health",
                "Aging & Geriatrics",
                "Health Conditions",
                "AIDS & HIV",
                "Allergies",
                "Arthritis",
                "Cancer",
                "Diabetes",
                "Ear Nose & Throat",
                "Eating Disorders",
                "Endocrine Conditions",
                "Genetic Disorders",
                "Heart & Hypertension",
                "Infectious Diseases",
                "Neurological Conditions",
                "Obesity",
                "Pain Management",
                "Respiratory Conditions",
                "Skin Conditions",
                "Sleep Disorders",
                "Health Education & Medical Training",
                "Health Foundations & Medical Research",
                "Medical Devices & Equipment",
                "Medical Facilities & Services",
                "Doctors' Offices",
                "Hospitals & Treatment Centers",
                "Medical Procedures",
                "Physical Therapy",
                "Men's Health",
                "Mental Health",
                "Anxiety & Stress",
                "Depression",
                "Nursing",
                "Assisted Living & Long Term Care",
                "Nutrition",
                "Special & Restricted Diets",
                "Vitamins & Supplements",
                "Oral & Dental Care",
                "Pharmacy",
                "Drugs & Medications",
                "Public Health",
                "Occupational Health & Safety",
                "Reproductive Health",
                "Substance Abuse",
                "Drug & Alcohol Testing",
                "Drug & Alcohol Treatment",
                "Smoking & Smoking Cessation",
                "Steroids & Performance-Enhancing Drugs",
                "Vision Care",
                "Eyeglasses & Contacts",
                "Women's Health",
                "Hobbies & Leisure",
                "Clubs & Organizations",
                "Youth Organizations & Resources",
                "Crafts",
                "Fiber & Textile Arts",
                "Merit Prizes & Contests",
                "Outdoors",
                "Fishing",
                "Hiking & Camping",
                "Paintball",
                "Radio Control & Modeling",
                "Model Trains & Railroads",
                "Special Occasions",
                "Holidays & Seasonal Events",
                "Weddings",
                "Water Activities",
                "Boating",
                "Surf & Swim",
                "Home & Garden",
                "Bed & Bath",
                "Bathroom",
                "Domestic Services",
                "Cleaning Services",
                "Gardening & Landscaping",
                "Home & Interior Decor",
                "Home Appliances",
                "Home Furnishings",
                "Curtains & Window Treatments",
                "Kitchen & Dining Furniture",
                "Lamps & Lighting",
                "Living Room Furniture",
                "Rugs & Carpets",
                "Home Improvement",
                "Construction & Power Tools",
                "Doors & Windows",
                "Flooring",
                "House Painting & Finishing",
                "Plumbing",
                "Home Safety & Security",
                "Home Storage & Shelving",
                "Home Swimming Pools, Saunas & Spas",
                "HVAC & Climate Control",
                "Fireplaces & Stoves",
                "Kitchen & Dining",
                "Cookware & Diningware",
                "Major Kitchen Appliances",
                "Small Kitchen Appliances",
                "Laundry",
                "Washers & Dryers",
                "Nursery & Playroom",
                "Pest Control",
                "Yard & Patio",
                "Lawn Mowers",
                "Internet & Telecom",
                "Communications Equipment",
                "Radio Equipment",
                "Email & Messaging",
                "Text & Instant Messaging",
                "Voice & Video Chat",
                "Mobile & Wireless",
                "Mobile & Wireless Accessories",
                "Mobile Apps & Add-Ons",
                "Mobile Phones",
                "Service Providers",
                "Cable & Satellite Providers",
                "Web Services",
                "Affiliate Programs",
                "Web Design & Development",
                "Jobs & Education",
                "Education",
                "Colleges & Universities",
                "Distance Learning",
                "Homeschooling",
                "Primary & Secondary Schooling (K-12)",
                "Standardized & Admissions Tests",
                "Teaching & Classroom Resources",
                "Training & Certification",
                "Vocational & Continuing Education",
                "Jobs",
                "Career Resources & Planning",
                "Job Listings",
                "Resumes & Portfolios",
                "Law & Government",
                "Government",
                "Courts & Judiciary",
                "Visa & Immigration",
                "Legal",
                "Bankruptcy",
                "Legal Education",
                "Legal Services",
                "Military",
                "Public Safety",
                "Crime & Justice",
                "Emergency Services",
                "Law Enforcement",
                "Security Products & Services",
                "Social Services",
                "News",
                "Business News",
                "Company News",
                "Financial Markets News",
                "Scandals & Investigations",
                "Health News",
                "Politics",
                "Sports News",
                "Weather",
                "Online Communities",
                "Blogging Resources & Services",
                "Dating & Personals",
                "Matrimonial Services",
                "Personals",
                "Photo Rating Sites",
                "File Sharing & Hosting",
                "Online Goodies",
                "Clip Art & Animated GIFs",
                "Skins, Themes & Wallpapers",
                "Social Network Apps & Add-Ons",
                "Photo & Video Sharing",
                "Photo & Image Sharing",
                "Social Networks",
                "Virtual Worlds",
                "People & Society",
                "Family & Relationships",
                "Family",
                "Marriage",
                "Troubled Relationships",
                "Kids & Teens",
                "Children's Interests",
                "Teen Interests",
                "Religion & Belief",
                "Seniors & Retirement",
                "Social Issues & Advocacy",
                "Charity & Philanthropy",
                "Discrimination & Identity Relations",
                "Green Living & Environmental Issues",
                "Human Rights & Liberties",
                "Poverty & Hunger",
                "Work & Labor Issues",
                "Social Sciences",
                "Economics",
                "Political Science",
                "Psychology",
                "Subcultures & Niche Interests",
                "Pets & Animals",
                "Pet Food & Supplies",
                "Veterinarians",
                "Pets",
                "Birds",
                "Cats",
                "Dogs",
                "Exotic Pets",
                "Fish & Aquaria",
                "Horses",
                "Rabbits & Rodents",
                "Reptiles & Amphibians",
                "Wildlife",
                "Real Estate",
                "Real Estate Listings",
                "Bank-Owned & Foreclosed Properties",
                "Commercial Properties",
                "Lots & Land",
                "Residential Rentals",
                "Residential Sales",
                "Timeshares & Vacation Properties",
                "Real Estate Services",
                "Reference",
                "Directories & Listings",
                "Business & Personal Listings",
                "General Reference",
                "Biographies & Quotations",
                "Calculators & Reference Tools",
                "Dictionaries & Encyclopedias",
                "Forms Guides & Templates",
                "Public Records",
                "Time & Calendars",
                "Geographic Reference",
                "Maps",
                "Humanities",
                "History",
                "Myth & Folklore",
                "Philosophy",
                "Language Resources",
                "Foreign Language Resources",
                "Libraries & Museums",
                "Museums",
                "Science",
                "Astronomy",
                "Biological Sciences",
                "Neuroscience",
                "Chemistry",
                "Computer Science",
                "Earth Sciences",
                "Atmospheric Science",
                "Geology",
                "Ecology & Environment",
                "Climate Change & Global Warming",
                "Engineering & Technology",
                "Robotics",
                "Mathematics",
                "Statistics",
                "Physics",
                "Scientific Institutions",
                "Sensitive Subjects",
                "Shopping",
                "Antiques & Collectibles",
                "Apparel",
                "Athletic Apparel",
                "Casual Apparel",
                "Children's Clothing",
                "Clothing Accessories",
                "Costumes",
                "Eyewear",
                "Footwear",
                "Formal Wear",
                "Headwear",
                "Men's Clothing",
                "Swimwear",
                "Undergarments",
                "Women's Clothing",
                "Auctions",
                "Classifieds",
                "Consumer Resources",
                "Consumer Advocacy & Protection",
                "Coupons & Discount Offers",
                "Product Reviews & Price Comparisons",
                "Entertainment Media",
                "Entertainment Media Rentals",
                "Gifts & Special Event Items",
                "Cards & Greetings",
                "Flowers",
                "Gifts",
                "Luxury Goods",
                "Mass Merchants & Department Stores",
                "Photo & Video Services",
                "Tobacco Products",
                "Toys",
                "Building Toys",
                "Die-cast & Toy Vehicles",
                "Dolls & Accessories",
                "Ride-On Toys & Wagons",
                "Stuffed Toys",
                "Sports",
                "Animal Sports",
                "College Sports",
                "Combat Sports",
                "Boxing",
                "Martial Arts",
                "Wrestling",
                "Extreme Sports",
                "Drag & Street Racing",
                "Fantasy Sports",
                "Individual Sports",
                "Cycling",
                "Golf",
                "Gymnastics",
                "Racquet Sports",
                "Skate Sports",
                "Track & Field",
                "International Sports Competitions",
                "Olympics",
                "Motor Sports",
                "Sporting Goods",
                "Sports Memorabilia",
                "Winter Sports Equipment",
                "Sports Coaching & Training",
                "Team Sports",
                "American Football",
                "Australian Football",
                "Baseball",
                "Basketball",
                "Cheerleading",
                "Cricket",
                "Hockey",
                "Rugby",
                "Soccer",
                "Volleyball",
                "Water Sports",
                "Surfing",
                "Swimming",
                "Winter Sports",
                "Ice Skating",
                "Skiing & Snowboarding",
                "Travel",
                "Air Travel",
                "Airport Parking & Transportation",
                "Bus & Rail",
                "Car Rental & Taxi Services",
                "Cruises & Charters",
                "Hotels & Accommodations",
                "Vacation Rentals & Short-Term Stays",
                "Specialty Travel",
                "Tourist Destinations",
                "Beaches & Islands",
                "Mountain & Ski Resorts",
                "Regional Parks & Gardens",
                "Theme Parks",
                "Zoos-Aquariums-Preserves",
            ],
            pagination: {
                page: 1,
                perPage: 10,
                total_page: 1,
            },
            searchKeywords: [],
            date_range: null,
            status: null,
        };
    },
    computed: {},
    async mounted() {
        this.case_id = this.$route.params.case_id;
        this.getCaseProfileDetails();
        this.getSearchEngineFilters();
        this.getCaseDataPoints();
    },
    methods: {
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },

        changetab(tab) {
            this.currentTab = tab;
            this.status = tab === "done" ? "CONFIRMED" : null;
            this.getCaseDataPoints();
        },

        updateFilters(filters) {
            this.selectedFilters = filters;
        },

        resetFilters() {
            this.selectedFilters = {};
            this.$refs.filters.selectedFilters = {};
            for (let i = 0; i < this.$refs.filters.$refs.filters.length; i++) {
                this.$refs.filters.$refs.filters[i].selectedItems = [];
            }
        },

        markDone(item, isDone) {
            let match = -1;
            const status = isDone ? "CONFIRMED" : "";
            for (const ndp of this.newDatapoints) {
                if (ndp.id === item._id) {
                    match = ndp.index;
                    break;
                }
            }
            if (match > -1) {
                this.newDatapoints[match].status = status;
            } else {
                this.newDatapoints.push({
                    id: item._id,
                    status: status,
                    index: this.newDatapoints.length,
                });
            }
            item.status = status;
        },

        markAllDone() {
            for (const dp of this.allDatapoints) {
                let match = -1;
                for (const ndp of this.newDatapoints) {
                    if (ndp.id === dp._id) {
                        match = ndp.index;
                        break;
                    }
                }
                if (match > -1) {
                    this.newDatapoints[match].status = "CONFIRMED";
                } else {
                    this.newDatapoints.push({
                        id: dp._id,
                        status: "CONFIRMED",
                        index: this.newDatapoints.length,
                    });
                }
                dp.status = "CONFIRMED";
            }
        },

        paginate(pageNo) {
            this.pagination.page = pageNo;
            this.getCaseDataPoints();
        },

        async saveDatapoints() {
            this.startLoader();
            const response = await axios.put("api/v1/datapoints", this.newDatapoints);
            try {
                if (response) {
                    this.getCaseDataPoints();
                }
                this.resetLoader();
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },

        async getSearchEngineFilters() {
            this.loaderController.filterLoading = true;
            this.allFilters = {};
            const response = await axios.post("api/v1/filter-params", {
                case_id: this.case_id,
                country: [],
            });
            try {
                this.allFilters = response.data;
                this.loaderController.filterSuccess = true;
                this.loaderController.filterLoading = false;
            } catch (error) {
                this.loaderController.filterSuccess = true;
                this.loaderController.filterLoading = false;
                let message = error.message;
                this.$toast.error(message);
            }
        },

        async getCaseDataPoints() {
            this.startLoader();
            this.allDatapoints = [];
            let data = {
                case_id: this.case_id,
                doc_count: this.pagination.perPage,
                page: this.pagination.page,
                start_date: this.date_range ? this.date_range[0] : null,
                end_date: this.date_range ? this.date_range[1] : null,
                status: this.status,
            };
            const datapointsPayload = {
                ...data,
                ...this.selectedFilters,
            };

            const response = await axios.post("api/v1/datapoints", datapointsPayload);
            try {
                if (response && response.data && response.data.data) this.allDatapoints = response.data.data;

                for (const result of this.allDatapoints) {
                    let src = [];
                    let qry = [];
                    for (const element of result.search_source) {
                        const matched = src.findIndex(function () {
                            return element.source;
                        });
                        if (matched <= -1) {
                            src.push(element.source);
                        }
                        const match = qry.findIndex(function () {
                            return element.search_query;
                        });
                        if (match <= -1) qry.push(element.search_query);
                    }
                    result.sources = src;
                    result.queries = qry;
                }

                this.pagination.total_page = response.data.total_page;
                this.resetLoader();
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },

        async getCaseProfileDetails() {
            this.startLoader();
            const response = await axios.get(`api/v1/case-personal-data/${this.case_id}`);
            try {
                if (response && response.data && response.data.case_data) {
                    this.caseProfile = response.data.case_data.data;
                }
                this.resetLoader();
            } catch (error) {
                this.resetLoader();
                let message = error.message;
                this.$toast.error(message);
            }
        },
    },
};
