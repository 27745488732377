import Button from "@/components/button";
import Input from "@/components/input";
import GenericModal from "@/components/genericModal";
import ConfirmDelete from "@/components/confirm-delete";
import nLoader from "@/components/n-loader";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapGetters} from "vuex";
import "@/assets/css/multiselect.scss";
import axios from "@/axios";
import {data} from "browserslist";

export default {
    name: "caseSettings",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-generic-modal": GenericModal,
        VueMultiselect,
        ConfirmDelete,
        nLoader,
    },
    props: [],
    data() {
        return {
            isHeader: true,
            isNewUser: false,
            permissionData: null,
            allUsers: [],
            casePermissions: [],
            assignee: [],
            accessType: {
                label: "write",
            },
            searchQuery: "",
            permissionFilter: "",
            isPermissionFilterOpen: false,
            message: {
                success: "",
                error: "",
            },
            loading: true,
        };
    },
    async created() {},
    computed: {
        ...mapGetters(["getCaseId", "getUserAuthDetails"]),
        isAuth() {
            return this.getPermissions.includes("case_settings__case_data");
        },

        assigneeList() {
            let users = this.allUsers
                ?.filter((user) => !(user.permissions && user.permissions.permission_id) && !user.is_admin)
                .map((user) => {
                    user.username = `${user.first_name} ${user.last_name}`;
                    if (user.id === this.getUserAuthDetails?.userId) {
                        user.$isDisabled = true;
                    }
                    return user;
                });
            return [
                {
                    group: "Users",
                    values: users,
                },
            ];
        },

        selectedUsers() {
            return this.allUsers?.filter((user) => !user.is_admin && user.permissions && user.permissions.permission_id && (!this.permisionFilter || user.permissions.permission_name === this.permisionFilter) && user.email.includes(this.searchQuery));
        },

        adminUsers() {
            return this.allUsers?.filter((user) => user.is_admin && user.email.includes(this.searchQuery) && (!this.permisionFilter || this.permisionFilter === "write"));
        },
    },
    async mounted() {
        this.fetchCasePermissions(); // Function to fetch list of all users along with allocated permissions

        let dropdowns = document.querySelectorAll(".case-settings-dropdown .multiselect__content-wrapper");
        dropdowns.forEach((element) => {
            element.style.width = `${element.parentElement.clientWidth.toString()}px`;
        });
    },
    methods: {
        // Function to get case users and permissions
        async fetchCasePermissions() {
            this.loading = true;
            let url = `/permissions/case/allocatable-permissions/${this.getCaseId}`;
            try {
                this.permissionData = await axios.get(url);
                this.allUsers = this.permissionData?.data?.all_users.sort((a, b) => {
                    if (a.email > b.email) return 1;
                    else return -1;
                });
                this.casePermissions = this.permissionData?.data?.allocatable_permissions
                    ?.filter((perm) => perm !== "admin")
                    ?.map((perm) => ({
                        label: perm,
                        disabled: perm === "read" ? true : false,
                    }));
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.loading = false;
            }
        },

        // Function to update users and permissions on case
        async updatePermissions() {
            if (this.assignee?.length) {
                let url = `/permissions/case/multiuser`;
                let data = {
                    permission_name: this.accessType?.label,
                    user_ids: this.assignee?.length ? this.assignee.map((el) => el.id) : [],
                    case_id: this.getCaseId,
                };
                try {
                    let permissions = await axios.post(url, data);
                    if (permissions?.status === 200) {
                        this.assignee = [];
                        this.accessType = {label: "write"};
                        this.isNewUser = false;
                        // this.$toast.success("Permissions updated !");
                        this.message = {
                            success: "Permissions updated !",
                            error: "",
                        };
                        setTimeout(() => {
                            this.message = null;
                        }, 5000);
                        this.fetchCasePermissions();
                    } else {
                        let message = "Some error occurred";
                        // this.$toast.error(message);
                        this.message = {
                            success: "",
                            error: message,
                        };
                        setTimeout(() => {
                            this.message = null;
                        }, 5000);
                    }
                } catch (error) {
                    let message = "Some error occurred";
                    if (error?.response?.data?.detail) message = error?.response?.data?.detail;
                    // this.$toast.error(message);
                    this.message = {
                        success: "",
                        error: message,
                    };
                    setTimeout(() => {
                        this.message = null;
                    }, 5000);
                }
            }
        },

        /** Function to remove users and permissions on case
         * @param {user} - user to be removed
         *        {index} - index of selected user to be removed
         */
        async removeAssignee(user) {
            let url = `/permissions/${user}`;
            try {
                let permissions = await axios.delete(url);
                if (permissions?.status === 200) {
                    // this.$toast.success("Permissions updated !");
                    this.message = {
                        success: "Permissions updated !",
                        error: "",
                    };
                    setTimeout(() => {
                        this.message = null;
                    }, 5000);
                    this.fetchCasePermissions();
                } else {
                    let message = "Some error occurred";
                    // this.$toast.error(message);
                    this.message = {
                        success: "",
                        error: message,
                    };
                    setTimeout(() => {
                        this.message = null;
                    }, 5000);
                }
            } catch (error) {
                let message = "Some error occurred";
                if (error?.response?.data?.detail) message = error?.response?.data?.detail;
                // this.$toast.error(message);
                this.message = {
                    success: "",
                    error: message,
                };
                setTimeout(() => {
                    this.message = null;
                }, 5000);
            }
        },

        close() {
            this.$emit("close");
        },

        /** Function to add new users
         */
        addNewUser() {
            this.isNewUser = true;
            setTimeout(() => {
                let dropdowns = document.querySelectorAll(".case-settings-dropdown .multiselect__content-wrapper");
                dropdowns.forEach((element) => {
                    element.style.width = `${element.parentElement.clientWidth.toString()}px`;
                });
            }, 100);
        },

        handleOutsideClick() {
            this.isPermissionFilterOpen = false;
        },

        clickAction(e) {
            this.permissionFilter = e.target.innerText?.toLowerCase();
            this.isPermissionFilterOpen = false;
        },
    },
};
