import { render, staticRenderFns } from "./badge.html?vue&type=template&id=46651777&scoped=true&"
import script from "./badge.js?vue&type=script&lang=js&"
export * from "./badge.js?vue&type=script&lang=js&"
import style0 from "./badge.scss?vue&type=style&index=0&id=46651777&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46651777",
  null
  
)

export default component.exports