export default {
    caseListPieChart: [],
    caseStatusPieChart: [],
    domainsPieChart: [],
    ragStatusPieChart: [],
    riskCategoriesPieChart: [],
    sourceCategoriesPieChart: [],
    timeline: [],
    assignedDashboards: [],
};
