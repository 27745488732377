// {
//     datatype: "key_facts";
//     editEntity: false;
//     entity: Object;
//     text: "";
//     userDefined: true;
// }

export default {
    state: () => ({
        panelOpen: false,
        isAddEntityPanelOpen: false,
        // selectedEntityKey: "associated_person",
        selectedEntityData: null,
    }),
    mutations: {
        TOGGLE_PANEL(state) {
            state.panelOpen = !state.panelOpen;
        },
        SET_IS_ADD_ENTITY_PANEL_OPEN(state, isAddEntityPanelOpen) {
            state.isAddEntityPanelOpen = isAddEntityPanelOpen;
        },
        // SET_SELECTED_ENTITY_KEY(state, selectedEntityKey) {
        //     state.selectedEntityKey = selectedEntityKey;
        // },
        SET_SELECTED_ENTITY_DATA(state, selectedEntityData) {
            state.selectedEntityData = selectedEntityData;
        },
    },
    actions: {
        togglePanel({commit}) {
            commit("TOGGLE_PANEL");
        },
        showAddEntityPanel({commit}) {
            commit("SET_IS_ADD_ENTITY_PANEL_OPEN", true);
        },
        hideAddEntityPanel({commit}) {
            commit("SET_IS_ADD_ENTITY_PANEL_OPEN", false);
        },
        // setSelectedEntityKey({ commit }, { entityKey }) {
        //     commit("SET_SELECTED_ENTITY_KEY", entityKey);
        // },
        setSelectedEntityData({commit}, selectedEntityData) {
            commit("SET_SELECTED_ENTITY_DATA", selectedEntityData);
        },
    },
    modules: {},
    getters: {
        getPanelStatus: (state) => state.panelOpen,
        isAddEntityPanelOpen: (state) => state.isAddEntityPanelOpen,
        // getSelectedEntityKey: state => state.selectedEntityKey,
        getSelectedEntityData: (state) => state.selectedEntityData,
    },
};
