<template>
    <div class="relationship-main-container">
        <h2 class="tw-mb-4">Relationships</h2>
        <div class="relationship-container tw-bg-gray-100 tw-p-4 tw-flex tw-flex-col tw-gap-4" id="middle">
            <div class="tw-flex tw-items-end tw-gap-4">
                <div class="tw-flex tw-flex-col tw-w-1/3">
                    <label for="first_name" class="r_label">
                        Related to
                        <span class="tw-text-red-600 tw--top-2"> * </span>
                    </label>
                    <multiselect v-model="data.entity" :options="getFilteredSavedEntities" class="relations-box" label="entity" track-by="_id" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Entity/Identifier" :internal-search="false" @search-change="searchEntities">
                        <template slot="singleLabel" slot-scope="props">
                            <div class="options__box">
                                <div class="options__image__label">
                                    <img class="tw-w-6 tw-h-6 option__image" :src="getOptionIcon(props.option)" />
                                </div>
                                <div class="options__data">
                                    <div class="options__title">
                                        {{ props.option.entity }}
                                        <span v-if="props.option.title"> ({{ props.option.title }}) </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="options__box">
                                <div class="options__image">
                                    <img class="tw-w-6 tw-h-6 option__image" :src="getOptionIcon(props.option)" />
                                </div>
                                <div class="options__data">
                                    <div class="options__title">{{ props.option.entity }}</div>
                                    <div class="options__subtitle">{{ props.option.title }}</div>
                                </div>
                            </div>
                        </template>
                    </multiselect>
                </div>
                <div class="tw-flex tw-flex-col tw-w-1/3">
                    <label for="first_name" class="r_label">Relation type</label>
                    <multiselect @input="relationTypeChange($event)" v-model="data.relation_type" :options="relation_types" :searchable="true" :clear-on-select="true" :close-on-select="true" :show-labels="false" :maxHeight="140" placeholder="Relation Type" label="label" track-by="_id"> </multiselect>
                </div>
                <button @click="addRelation" :disabled="loadingBtn" class="tw-border tw-border-blue-600 tw-rounded-lg tw-cursor-pointer tw-py-2 tw-px-4 tw-text-base tw-bg-white tw-text-brand hover:tw-bg-brand hover:tw-text-white tw-h-8 tw-flex tw-items-center">
                    Add
                </button>

                <label for="toogle" class="tw-flex tw-items-center tw-cursor-pointer">
                    <neo-toggle id="toggle" class="tw-w-full" @input="data.show_on_graph = !data.show_on_graph" label="Show on Graph" :value="data.show_on_graph"></neo-toggle>
                </label>
            </div>

            <div class="tw-flex tw-flex-col tw-gap-4 tw-max-w-screen-lg" v-if="data.type && data.type.attributes">
                <span v-if="loadingNewAttributes" class="tw-py-2">
                    <div class="spinner-4" style=""></div>
                    <span class="">Please wait... Updating attributes</span>
                </span>
                <attribute-holder :indent="true" title="Relationship Attributes" v-else :attributes="data.type.attributes" @onChange="onValueChange($event)"> </attribute-holder>
            </div>

            <div class="tw-flex tw-flex-row tw-gap-4" v-if="data.show_on_graph">
                <div class="tw-flex tw-flex-col tw-w-1/3">
                    <label for="first_name" class="r_label">Line type</label>
                    <div class="tw-flex tw-flex-row tw-items-center tw-w-full">
                        <div class="svg-wrapper" :id="type.value" :class="{selected: data.line_type === type.value}" v-for="(type, index) in line_options" :key="index" @click="data.line_type = type.value" v-b-tooltip.hover :title="type.description">
                            <img class="tw-w-6 tw-h-6" :src="require(`./../assets/${type.value}-line.svg`)" />
                        </div>
                    </div>
                </div>
                <div class="tw-flex tw-flex-col tw-w-1/3">
                    <label for="first_name" class="r_label">Arrow type</label>
                    <div class="tw-flex tw-flex-row tw-items-center tw-w-full">
                        <div class="svg-wrapper" :id="type.value" :class="{selected: data.arrow_type === type.value}" v-for="(type, index) in line_direction" :key="index" @click="data.arrow_type = type.value" v-b-tooltip.hover :title="type.description">
                            <img class="tw-w-6 tw-h-6" :src="require(`./../assets/${type.value}.svg`)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="divider">
            <img src="./../assets/divider.svg" />
        </div> -->
        <h2 class="tw-my-4">Previous Relationships</h2>
        <div class="relationship-container" id="middle">
            <!-- Loader : relationships -->
            <template v-if="loadingBtn">
                <span class="tw-absolute tw-px-4 tw-py-2 tw-text-center" style="left: calc(50% - 128px)">
                    <div class="spinner-4" style="margin-left: calc(50% - 15px)"></div>
                    <span class="tw-ml-2">Please wait... Fetching relationships</span>
                </span>
            </template>

            <!-- Relationships cards -->
            <template v-else>
                <div class="tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-flex tw-flex-col tw-gap-4 tw-mb-4" v-for="(relation, index) in relationships" :key="index" v-if="!relation.deleted && index < relationsCounter">
                    <!-- Relations - entity data -->
                    <div class="tw-flex tw-items-end tw-gap-4">
                        <div class="tw-flex tw-flex-col tw-w-1/3">
                            <label for="first_name" class="r_label">
                                Related to
                                <span class="tw-text-red-600 tw--top-2"> * </span>
                            </label>
                            <multiselect v-model="relation.entity" :options="getFilteredSavedEntities" class="relations-box" label="entity" track-by="_id" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Entity/Identifier" :internal-search="false" @search-change="searchEntities">
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="options__box">
                                        <div class="options__image__label">
                                            <img class="tw-w-6 tw-h-6 option__image" :src="getOptionIcon(props.option)" />
                                        </div>
                                        <div class="options__data">
                                            <div class="options__title">
                                                {{ props.option.entity }}
                                                <span v-if="props.option.title"> ({{ props.option.title }}) </span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template slot="option" slot-scope="props">
                                    <div class="options__box">
                                        <div class="options__image">
                                            <img class="tw-w-6 tw-h-6 option__image" :src="getOptionIcon(props.option)" />
                                        </div>
                                        <div class="options__data">
                                            <div class="options__title">{{ props.option.entity }}</div>
                                            <div class="options__subtitle">{{ props.option.title }}</div>
                                        </div>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="tw-flex tw-flex-col tw-w-1/3">
                            <label for="first_name" class="r_label">Relation type</label>
                            <multiselect @input="relationTypeChange($event, relation)" v-model="relation.relation_type" :options="relation_types" :searchable="true" :clear-on-select="true" :close-on-select="true" :show-labels="false" :maxHeight="140" placeholder="Relation Type" label="label" track-by="_id"> </multiselect>
                        </div>

                        <div class="delete btn-label danger" @click="deleteRelation(index)">
                            <img src="./../assets/trash-red.svg" />
                            <span class="">Delete</span>
                        </div>

                        <label for="toogle" class="tw-flex tw-items-center tw-cursor-pointer">
                            <neo-toggle id="toggle" class="tw-w-full" @input="relation.show_on_graph = !relation.show_on_graph" label="Show on Graph" :value="relation.show_on_graph"> </neo-toggle>
                        </label>
                    </div>

                    <!-- Relation attributes -->
                    <div class="tw-flex tw-flex-col tw-gap-4 tw-max-w-screen-lg" v-if="relation.type && relation.type.attributes">
                        <attribute-holder :indent="true" title="Relationship Attributes" :attributes="relation.type.attributes" @onChange="onValueChange($event, relation)"> </attribute-holder>
                    </div>

                    <!-- Relations - Line and arrow type -->
                    <div class="tw-flex tw-flex-row tw-gap-4" v-if="relation.show_on_graph">
                        <div class="tw-flex tw-flex-col tw-w-1/3">
                            <label for="first_name" class="r_label">Line type</label>
                            <div class="tw-flex tw-flex-row tw-items-center tw-w-full">
                                <div class="svg-wrapper" :id="type.value" :class="{selected: relation.line_type === type.value}" v-for="(type, index) in line_options" :key="index" @click="setLineType(type.value, relation)" v-b-tooltip.hover :title="type.description">
                                    <img class="tw-w-6 tw-h-6" :src="require(`./../assets/${type.value}-line.svg`)" />
                                </div>
                            </div>
                        </div>
                        <div class="tw-flex tw-flex-col tw-w-1/3">
                            <label for="first_name" class="r_label">Arrow type</label>
                            <div class="tw-flex tw-flex-row tw-items-center tw-w-full">
                                <div class="svg-wrapper" :id="type.value" :class="{selected: relation.arrow_type === type.value}" v-for="(type, index) in line_direction" :key="index" @click="setArrowType(type.value, relation)" v-b-tooltip.hover :title="type.description">
                                    <img class="tw-w-6 tw-h-6" :src="require(`./../assets/${type.value}.svg`)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="!relationships || !relationships.length">
                    <div id="message" class="tw-pt-4 tw-w-full tw-flex tw-flex-col tw-items-center">
                        <div class="tw-text-base tw-text-gray-500">No relationships...</div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
import AttributeHolder from "@/containers/add-entity-panel/panel/attribute-holder";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import neoToggle from "@/components/toggle";

export default {
    name: "Relationships",
    components: {
        "attribute-holder": AttributeHolder,
        multiselect: Multiselect,
        "neo-toggle": neoToggle,
    },
    props: {
        entity: Object,
        relationshipTypes: Array,
    },
    data() {
        return {
            edit_id: null,
            loadingBtn: false,
            loadingNewAttributes: false,
            entity_attributes: [],
            selectionData: {},
            data: {
                entity: null,
                relation_type: null,
                type: null,
                show_on_graph: true,
                line_type: "solid",
                arrow_type: "arrow_from",
                attributes_data: [],
                deleted: false,
                _id: null,
                entity_name: "",
                relation_label: "",
            },
            line_options: [
                {
                    value: "solid",
                    icon: "icons_solid.svg",
                    description: "Solid",
                },
                {
                    value: "dashed",
                    icon: "icons_dotted.svg",
                    description: "Dashed",
                },
                {
                    value: "dotted",
                    icon: "icons_dotted.svg",
                    description: "Dotted",
                },
            ],
            line_direction: [
                {
                    value: "arrow_from",
                    icon: "icons_from.svg",
                    description: "Arrow from related entity to new entity",
                },
                {
                    value: "arrow_to",
                    icon: "icons_to.svg",
                    description: "Arrow from new entity to related entity",
                },
                {
                    value: "arrow_bi",
                    icon: "icons_bi-dir.svg",
                    description: "Bi-Directional arrow between new and related entity",
                },
                {
                    value: "arrow_none",
                    icon: "icons_non-dir.svg",
                    description: "Non-Directional line between new and related entity",
                },
            ],
            relationships: [],
            relationsCounter: 10,
        };
    },
    beforeDestroy() {
        this.syncRelations();
    },
    watch: {
        relationships: {
            handler: function(val) {
                this.syncRelations();
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["getFilteredSavedEntities", "getSocialPlatforms"]),
        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },

        relation_types: {
            get() {
                return this.relationshipTypes;
            },
            set(val) {
                this.relationshipTypes = val;
            },
        },

        getSelectedEntityData() {
            return this.$store.getters.getSelectedEntityData;
        },
        getEntitiesRelationsAttributes() {
            return this.$store.getters.getEntitiesRelationsAttributesData;
        },

        panel_type() {
            return this.getSelectedEntityData.datatype;
        },
    },
    methods: {
        searchEntities(query) {
            this.$store.dispatch("setEntitiesSearchQuery", query);
        },
        getOptionIcon(option) {
            if (option.entity_type == "social_profile") {
                return this.$store.getters.getSocialPlatforms.find((e) => e.key == option.platform).icon_data;
            } else {
                try {
                    return this.$store.getters.getIconsData.find((e) => e.key == option.entity_identifier).icon_data;
                } catch {
                    return require("@/assets/add.svg");
                }
            }
        },

        relationTypeChange(value, relation) {
            if (relation) {
                this.loadingBtn = true;
                if (!relation.type) relation.type = {};
                relation.attributes_data = value?.attributes || [];
                relation.type.attributes = value?.attributes || [];
                this.relationships = this.relationships.map((el, i) => ({
                    ...el,
                    type: {
                        attributes: el.attributes_data || [],
                    },
                }));
                setTimeout(() => {
                    this.loadingBtn = false;
                });
            } else {
                this.loadingNewAttributes = true;
                if (!this.data.type) this.data.type = {};
                this.data.attributes_data = value?.attributes || [];
                this.data.type.attributes = value?.attributes || [];
                setTimeout(() => {
                    this.loadingNewAttributes = false;
                });
            }
        },

        onValueChange(value, relation) {
            if (relation) relation.attributes_data = value;
            else this.data.attributes_data = value;
        },

        setLineType(value, relation) {
            relation.line_type = value;
        },

        setArrowType(value, relation) {
            relation.arrow_type = value;
        },

        deleteRelation(index) {
            this.loadingBtn = true;
            this.relationships.splice(index, 1);
            this.relationships = this.relationships.map((el, i) => ({
                ...el,
                type: {
                    attributes: el.attributes_data || [],
                },
            }));
            setTimeout(() => {
                this.loadingBtn = false;
            });
        },

        resetInitialRelationState() {
            this.data = {
                entity: null,
                relation_type: null,
                type: null,
                show_on_graph: true,
                line_type: "solid",
                arrow_type: "arrow_from",
                attributes_data: [],
                deleted: false,
                _id: null,
                entity_name: "",
                relation_label: "",
            };
        },

        async addRelation() {
            if (this.data.entity) {
                this.loadingBtn = true;
                let relation = cloneDeep({
                    ...this.data,
                    type: {
                        attributes: this.data.attributes_data || [],
                    },
                });
                this.resetInitialRelationState();
                this.relationships.unshift(relation);
                setTimeout(() => {
                    this.loadingBtn = false;
                });
                this.$toast.success("Added sucessfully");
            } else {
                this.$toast.error("Please select an entity.");
            }
        },

        addRelationshipType(newTag, relation) {
            let temp = {
                label: newTag,
                _id: uuidv4(),
            };
            this.relation_types.push(temp);
            relation.type = temp;
        },

        syncRelations() {
            this.entityData.relation_data = this.relationships;
        },

        async onTabScroll() {
            if (document.getElementById("more-info-container")?.scrollTop > document.getElementById("more-info-container")?.scrollHeight - document.getElementById("more-info-container")?.offsetHeight - 900) {
                if (this.relationships.length > this.relationsCounter + 10) this.relationsCounter += 10;
                else if (this.relationships.length > this.relationsCounter) this.relationsCounter = this.relationships.length;
            }
        },
    },
    mounted() {
        this.relationships = this.entityData.relation_data;
        if (document.getElementById("more-info-container")) document.getElementById("more-info-container").addEventListener("scroll", this.onTabScroll);
    },
};
</script>
<style lang="scss" scoped>
@import "./../assets/color.scss";

.relationship-container {
    align-self: stretch;
    border-radius: 8px;
}

.r_label {
    color: var(--blue-shade, #1a2036) !important;
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 4px;
    /* 109.091% */
}

.svg-wrapper {
    border-radius: 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    background: white;
    padding: 4px;
    cursor: pointer;
}

.svg-wrapper.selected {
    border: 3px solid var(--brand-color);
}

.parent {
    input[type="radio"] {
        height: 15px;
        appearance: auto;
    }
}

.dot {
    background-color: #e4e1e1;
}

input:checked ~ .dot {
    transform: translateX(100%);
    background-color: var(--brand-color);
}

.label {
    color: var(--blue-shade, #1a2036);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.divider {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.input {
    height: 2.3rem !important;
    border-radius: 8px !important;
    border: 1px solid var(--grey, #aeb1ba) !important;
    background: var(--white, #fff) !important;
    width: 100%;
}

.edit {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    color: #42b85c;
    width: 100px;
    border: 0.5px solid var(--light-ray-action-red, #42b85c);
    border-radius: 8px;
}

.delete {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 0.5px solid var(--light-ray-action-red, #ce1b22);
    background: var(--white, #fff);
    color: #eb3131;
    width: 100px;
}

.btn-label {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    padding: 4px 16px;
    height: 32px;
    cursor: pointer;
}

.btn-label.danger {
    color: #eb3131;
}

.btn-label.success {
    color: #42b85c;
}

.relationship-main-container {
    .relations-box {
        .options__box {
            display: flex;
            align-items: center;

            .options__image {
                height: 25px;
                width: 25px;
                display: flex;
                align-items: center;
                margin-right: 0.75rem;

                .option__image {
                    height: 100%;
                }
            }

            .options__image__label {
                height: 22px;
                width: 22px;
                display: flex;
                align-items: center;
                margin-right: 0.75rem;

                .option__image {
                    height: 100%;
                }
            }

            .options__data {
                display: flex;
                flex-direction: column;

                .options__title {
                    font-size: 1rem;
                }

                .options__subtitle {
                    margin-top: 5px;
                    font-size: 0.75rem;
                }
            }
        }
    }

    .line-config {
        display: flex;
        flex-direction: column;

        .line-config-buttons {
            border: 1px solid #e4e4e4;
            background-color: white;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            img {
                width: 26px;
            }

            &:hover {
                background-color: var(--brand-color);

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }

        .line-config-buttons.active {
            background-color: var(--brand-color);

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}
.spinner-4 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 1px;
    margin-right: 1rem;
    background: conic-gradient(#0000 10%, #0d69d5) content-box;
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s4 1s infinite steps(10);
}
</style>
