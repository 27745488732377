import { render, staticRenderFns } from "./restore-btn.html?vue&type=template&id=113411fa&scoped=true&"
import script from "./restore-btn.js?vue&type=script&lang=js&"
export * from "./restore-btn.js?vue&type=script&lang=js&"
import style0 from "./restore-btn.scss?vue&type=style&index=0&id=113411fa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113411fa",
  null
  
)

export default component.exports