import {mapActions, mapGetters} from "vuex";
import {Network, DataSet} from "vis-network/standalone";
import axios from "@/axios";
import rawAaxios from "axios";

import {EDGE_BIDIRECTIONAL_KEY, OPTION_NODE_COLOR, EDGE_DIRECTIONS_KEY} from "@/utils/constants";
import {generateRandomString, breakText} from "@/utils/functions";

import NodesContainer from "@/containers/nodes";
import GraphPreview from "@/containers/graph-preview";

import Checkbox from "@/components/checkbox";
import Button from "@/components/button";
import Dropdown from "@/components/dropdown";
import Tooltip from "@/components/tooltip";
import GraphNode from "@/components/graph-node";
import ColorPicker from "@/components/color-picker";

import optionIcons from "./icons/optionicons";
import CENTER_ICON from "./icons/centericon";
import {baseBlackboxUrl, blackboxApi, downloadGraphUrl} from "../../main";
import caseApi from "@/axios";
import AddNodeModal from "@/containers/add-node-modal";
import {escape, unescape} from "html-escaper";
import GraphToSvg from "@/containers/graph-to-svg";
import Multiselect from "@/components/multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import AddEntityPanel from "@/containers/add-entity-panel";

import {isEqual, cloneDeep} from "lodash";
import {timeHours} from "d3-time";

export default {
    name: "GraphViz",
    svg: "",
    components: {
        "neo-nodes-container": NodesContainer,
        "neo-button": Button,
        "neo-checkbox": Checkbox,
        AddEntityPanel,
        // "neo-dropdown": Dropdown,
        "neo-multiselect": Multiselect,
        "neo-tooltip": Tooltip,
        "graph-preview": GraphPreview,
        "neo-graph-node": GraphNode,
        "neo-color-picker": ColorPicker,
        "add-node": AddNodeModal,
        "graph-to-svg": GraphToSvg,
    },
    data: () => {
        return {
            convertToSvg: false,
            editExtraIcon: false,
            extraIcon: null,
            connectionCollapsedNodes: [],
            manipulationMessage: "",
            isAdverse: false,
            newAddedNodeCords: {x: 0, y: 0, count: 0},
            selectedEdge: {},
            selectedEdgeId: [],
            selectedNodes: [],
            loader: null,
            centerNode: {},
            // expanded: false,
            nodeStore: false,
            singleNodes: [],
            graphArea: null,
            graphData: {},
            nodes: [],
            entityMapper: [],
            icons: [],
            otherIcon: [],
            edges: [],
            editMode: false,
            hoverNodeId: null,
            hoverNodeDimension: {},
            optionsNode: [],
            optionNodeTimer: null,
            collapsedNodes: [],
            edgeLabel: "",
            editNodePopUp: false,
            isAddNode: false,
            isAddEdge: false,
            isEditNode: false,
            isEditEdge: false,
            stabilizing: false,
            editEdgeControler: {
                type: "solid",
                bidirectional: false,
                lable: "",
                from: null,
                to: null,
                color: "",
            },
            editNodeControler: {
                highlight: "false",
                iconSelected: "",
                values: null,
                nodeType: "0",
                username_hidden: false,
                mediaType: "",
                attributes: [],
            },
            highlightNodes: [],
            KeyPressedControler: {
                ctrlKey: false,
                shiftDown: false,
                addEdgeKey: "e",
                undoKey: "z",
                redoKey: "z",
            },
            connectionDegree: "",
            degreeOptions: ["1st degree connections", "2nd degree connections", "3rd degree connections"],
            relationshipOptions: [
                {
                    name: "Actual",
                    value: "actual",
                },
                {
                    name: "Visual",
                    value: "visual",
                },
                {
                    name: "Actual but hidden",
                    value: "actual_hidden",
                },
            ],
            relationshipType: [
                {
                    name: "Actual",
                    value: "actual",
                },
                {
                    name: "Visual",
                    value: "visual",
                },
            ],
            entityFilterOptions: [],
            entityFilterValues: [],
            tooltipControler: {
                hide: true,
                top: 0,
                left: 0,
            },
            showLabel: true,
            edgeLabelSize: 14,
            edgesConf: {
                arrowStrikethrough: true,
                smooth: false,
                // smooth: {
                //     type: "continuous",
                //     // forceDirection: "none",
                //     roundness: 0,
                // },
                font: {
                    size: 0,
                    background: "white",
                    align: "bottom",
                    bold: "16px arial black",
                },
            },
            dragedNodes: [],
            redoEvents: [],
            allNodesCollapsed: true,
            graphPeviewImage: null,
            graphImageBlob: "",
            graphNodeControler: {
                image: "",
                title: "",
                desc: "",
            },
            graphNodeSet: false,
            dottedEdgeConf: [1, 3],
            allEdges: [],
            entity_identifier_data: [],
            downloadedIcons: {},
            nodesToDownload: [],
            edgesToDownload: [],
        };
    },
    async mounted() {
        if (!this.getisEmptyConnection) {
            this.loader = this.$loading.show({
                container: document.querySelector("#canvas-area-container"),
                canCancel: false,
                color: "#0d69d5",
                loader: "bars",
                blur: "20px",
                opacity: 0.7,
            });
        }
        // await this.getEntities();
        // await this.getIcons();
        // let lc = location.hash.replace("#", "")
        // if (lc === "map"){
        //     this.expanded = true;
        // } else {
        //     this.expanded = this.showGraph;
        // }
        // await this.getGraphData();
        if (this.checkAllGraphData) {
            await this.getGraphData(this.getNodesInfo);
        }
        this.addKeyboadEvent();
        this.filterOnRelationshipType();
    },
    methods: {
        ...mapActions(["fetchStaticData", "setFilter", "fetchAllResearchScreenData"]),
        fitToCanvas() {
            window.network.fit({maxZoomLevel: 1});
        },
        zoomInGraph() {
            let currentScale = window.network.getScale();
            window.network.moveTo({scale: currentScale + 0.2});
        },
        zoomOutGraph() {
            let currentScale = window.network.getScale();
            window.network.moveTo({scale: currentScale - 0.2});
        },

        exapandCanvasSize(container, canvas) {
            container.style.height = "5000px";
            container.style.width = "3535px";
            window.network.redraw();
            this.fitToCanvas();
        },

        resetCanvasSize(container) {
            container.style.height = "100%";
            container.style.width = "100%";
            window.network.redraw();
            this.fitToCanvas();
        },

        buildCropedCanvas(container, sourceCanvas, destCanvas) {
            this.exapandCanvasSize(container, sourceCanvas);
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    let nodesPos = window.network.getPositions();
                    let posObj = {
                        minX: 9999999,
                        minY: 9999999,
                        maxX: -9999999,
                        maxY: -9999999,
                    };

                    for (let [key, val] of Object.entries(nodesPos)) {
                        val = window.network.canvasToDOM(val);

                        posObj.minX = val.x < posObj.minX ? val.x : posObj.minX;
                        posObj.minY = val.y < posObj.minY ? val.y : posObj.minY;
                        posObj.maxX = posObj.maxX < val.x ? val.x : posObj.maxX;
                        posObj.maxY = posObj.maxY < val.y ? val.y : posObj.maxY;
                    }
                    let imgHeight = posObj.maxY - posObj.minY;
                    let imgWidth = posObj.maxX - posObj.minX;
                    // let destCanvas = document.getElementById("imageCrop");
                    destCanvas.width = imgWidth + 400;
                    destCanvas.height = imgHeight + 300;
                    var contex = destCanvas.getContext("2d");
                    contex.drawImage(sourceCanvas, posObj.minX - 200, posObj.minY - 150, imgWidth + 400, imgHeight + 300, 0, 0, imgWidth + 400, imgHeight + 300);

                    resolve(destCanvas);
                }, 1000);
            });
        },

        buildGraphPreviewImage() {
            // this.canvasToSvg();
            let loader = this.$loading.show({
                container: container,
                canCancel: false,
                color: "#0d69d5",
                loader: "bars",
                blur: "20px",
                opacity: 0.7,
            });
            let container = document.getElementById("canvas-area-container");
            let canvas = container.getElementsByTagName("canvas")[0];
            let destCanvas = document.getElementById("imageCrop");
            destCanvas.style.display = "block";

            this.buildCropedCanvas(container, canvas, destCanvas).then(() => {
                this.graphPeviewImage = destCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
                destCanvas.toBlob((blob) => (this.graphImageBlob = blob));
                this.resetCanvasSize(container, canvas);
                destCanvas.style.display = "none";
                loader.hide();
            });
        },
        // toggleNodesExpantion() {
        //     this.allNodesCollapsed = !this.allNodesCollapsed;
        //     let nodesOnCanvas = this.getfilteredNode(this.nodes)[1];
        //     let nodeImg;
        //     let nodesToUpdate = [];
        //     nodesOnCanvas.forEach((node) => {
        //         if (!(node.id === this.centerNode.id)) {
        //             if (this.allNodesCollapsed) {
        //                 if (!this.collapsedNodes.includes(node.id)) {
        //                     this.collapsedNodes.push(node.id);
        //                 }
        //             } else {
        //                 this.collapsedNodes.splice(this.collapsedNodes.indexOf(node.id), 1);
        //             }
        //             this.graphData[node.id].is_collapse = this.allNodesCollapsed;
        //             nodeImg = this.getNodeImage(this.graphData[node.id]);
        //             nodesToUpdate.push({
        //                 id: node.id,
        //                 image: nodeImg,
        //             });
        //         }
        //     });

        //     window.network?.body.data.nodes.update(nodesToUpdate);
        //     window.network.redraw();
        // },
        shiftNodes(nodes) {
            for (const [key, val] of Object.entries(nodes)) {
                window.network?.body.data.nodes.update({
                    id: key,
                    x: val.x,
                    y: val.y,
                });
            }
        },
        async refresh() {
            window.network?.destroy();
            this.networkselectedEdge = {};
            this.selectedEdgeId = [];
            this.selectedNodes = [];
            this.loader = null;
            this.centerNode = {};
            this.nodeStore = false;
            this.singleNodes = [];
            this.graphArea = null;
            this.graphData = {};
            this.nodes = [];
            this.entityMapper = [];
            this.icons = [];
            this.otherIcon = [];
            this.edges = [];
            this.editMode = false;
            this.hoverNodeId = null;
            document.querySelector("#canvas-area-container").innerHTML = "";
            this.loader = this.$loading.show({
                container: document.querySelector("#canvas-area-container"),
                canCancel: false,
                color: "#0d69d5",
                loader: "bars",
                blur: "20px",
                opacity: 0.7,
            });
            await this.fetchAllResearchScreenData({currentTab: "personalDetails"});

            if (this.checkAllGraphData) {
                this.getGraphData(this.getNodesInfo);
            }
            this.addKeyboadEvent();
            this.setEntityFilters();
            // this.filterOnRelationshipType();
        },

        undoDrag() {
            // let nodesOnCanvas = this.getfilteredNode(this.nodes)[1].map(el => el.id); // 1 is index of connected node
            if (this.dragedNodes.length > 0) {
                let drNodes = this.dragedNodes[this.dragedNodes.length - 1];
                this.registerRedoEvent(drNodes);
                this.shiftNodes(drNodes);
                this.dragedNodes.splice(this.dragedNodes.length - 1, 1);
            }
        },
        registerUndoEvent(nodes) {
            let dragedNodes = JSON.parse(JSON.stringify(nodes));
            for (const [key, val] of Object.entries(dragedNodes)) {
                dragedNodes[key] = window.network.getPosition(key);
            }
            this.dragedNodes.push(dragedNodes);
        },
        registerRedoEvent(nodes) {
            let dragedNodes = JSON.parse(JSON.stringify(nodes));
            for (const [key, val] of Object.entries(dragedNodes)) {
                dragedNodes[key] = window.network.getPosition(key);
            }
            this.redoEvents.push(dragedNodes);
        },

        redoDrag() {
            if (this.redoEvents.length > 0) {
                let lastShiftedNodes = this.redoEvents[this.redoEvents.length - 1];
                this.registerUndoEvent(lastShiftedNodes);
                this.shiftNodes(lastShiftedNodes);
                this.redoEvents.splice(this.redoEvents.length - 1, 1);
            }
        },
        toggleLabel() {
            this.showLabel = !this.showLabel;
            window.network?.body.data.edges.update(
                this.edges.map((ed) => {
                    return {
                        id: ed.id,
                        font: {size: this.showLabel ? 14 : 0},
                    };
                })
            );
        },
        getAllConnectedNodeTillCenter(nodeId, edges) {
            let connectedNodes = [];
            let nodesInQueue = [nodeId];
            while (nodesInQueue.length > 0) {
                let childNodes = [];
                for (edge of edges) {
                    let cnNodeId;
                    if (edge.to === nodeId) {
                        cnNodeId = edge.from;
                    }
                    if (edge.from === nodeId) {
                        cnNodeId = edge.to;
                    }

                    if (cnNodeId) {
                        if (!connectedNodes.includes(cnNodeId)) {
                            childNodes.push(cnNodeId);
                        }
                    }
                }
                connectedNodes = [...connectedNodes, ...childNodes];
                nodesInQueue = childNodes;
            }
        },
        filterOnRelationshipType() {
            let filters = [];
            this.relationshipType.forEach((rt) => {
                filters.push(rt.value);
            });
            let filteredEdges = this.getEdgeOnNetwork();
            filteredEdges.forEach((edge) => {
                if (edge.show_on_graph) {
                    if (edge.relationship_type === "actual" && filters.includes("actual")) {
                        this.addEdgeToNetwork(edge);
                    } else if (edge.relationship_type === "visual" && filters.includes("visual")) {
                        this.addEdgeToNetwork(edge);
                    } else {
                        window.network?.body.data.edges.remove(edge.id);
                    }
                } else if (!edge.show_on_graph) {
                    if (edge.relationship_type === "actual" && filters.includes("actual_hidden")) {
                        this.addEdgeToNetwork(edge);
                    } else {
                        window.network?.body.data.edges.remove(edge.id);
                    }
                } else {
                    window.network?.body.data.edges.remove(edge.id);
                }
            });
        },
        getEdgeOnNetwork() {
            let edges = this.edges.filter((edge) => {
                try {
                    window.network.getPosition(edge.from);
                    window.network.getPosition(edge.to);
                    return true;
                } catch (error) {}
            });
            return edges;
        },
        addEdgeToNetwork(edge) {
            let connectedNodes = window.network.getConnectedNodes(edge.id);
            if (connectedNodes.length === 0) {
                try {
                    window.network?.body.data.edges.add([edge]);
                } catch (error) {}
            }
        },
        filterOnEntity() {
            let nodeIds = this.entityFilterValues.map((el) => {
                return el.id;
            });
            let nodesOnCanvas = this.getfilteredNode(this.nodes)[1];

            if (nodeIds.length == 0) {
                // if no filter selected display all nodes
                for (let nd of nodesOnCanvas) {
                    window.network?.body.data.nodes.update({
                        id: nd.id,
                        hidden: false,
                    });

                    if (this.connectionCollapsedNodes.includes(nd.id)) {
                        this.connectionCollapsedNodes.splice(this.connectionCollapsedNodes.indexOf(nd.id), 1);
                    }
                }
            } else {
                let connectedNodes = [...nodeIds];
                for (let cnNodeId of nodeIds) {
                    connectedNodes = [...connectedNodes, ...this.getDirectConnectedNodes(cnNodeId, true)];
                }

                let currentNodeChilds = [];
                let childNodeInConnection = [];
                for (let cnNode of nodesOnCanvas) {
                    currentNodeChilds = this.getAllConnectedNode(cnNode.id, this.edges);
                    childNodeInConnection = currentNodeChilds.filter((el) => connectedNodes.includes(el));

                    if (connectedNodes.includes(cnNode.id)) {
                        window.network?.body.data.nodes.update({
                            id: cnNode.id,
                            hidden: false,
                        });

                        if (currentNodeChilds.length > childNodeInConnection.length) {
                            if (!this.connectionCollapsedNodes.includes(cnNode.id)) {
                                this.connectionCollapsedNodes.push(cnNode.id);
                            }
                        } else {
                            if (this.connectionCollapsedNodes.includes(cnNode.id)) {
                                this.connectionCollapsedNodes.splice(this.connectionCollapsedNodes.indexOf(cnNode.id), 1);
                            }
                        }
                    } else {
                        window.network?.body.data.nodes.update({
                            id: cnNode.id,
                            hidden: true,
                        });
                        if (!this.connectionCollapsedNodes.includes(cnNode.id)) {
                            this.connectionCollapsedNodes.push(cnNode.id);
                        }
                    }
                }
            }

            let entityNodes = [{entity: "All", _id: "all-filters-applied"}];
            for (let nId of nodeIds) {
                entityNodes.push(this.graphData[nId]);
            }
            this.setFilter({field: "entityFilter", param: entityNodes});
            let nodeToFocus;
            if (nodeIds.length > 0) {
                nodeToFocus = nodeIds[nodeIds.length - 1];
            } else {
                nodeToFocus = this.centerNode.id;
            }
            window.network.moveTo({
                position: window.network.getPosition(nodeToFocus),
            });
            window.network.redraw();
            this.fitToCanvas();
        },
        setEntityFilters() {
            let connectedNodes = this.getfilteredNode(this.nodes)[1];
            this.entityFilterOptions = [];
            for (let node of connectedNodes) {
                if (node.associatedType === "entity") {
                    this.entityFilterOptions.push({id: node.id, name: node.nodeEntity});
                }
            }
            if (this.centerNode.entity)
                this.entityFilterOptions.push({
                    id: this.centerNode._id,
                    name: this.centerNode.entity,
                });
        },
        getNodesOnConnectionDegree(centerNodeId, degreeCount) {
            let parentNodes = new Set();
            parentNodes.add(centerNodeId);
            // let parentNodes = [centerNodeId];
            let nodesOnCanvas = this.getfilteredNode(this.nodes)[1];
            nodesOnCanvas = nodesOnCanvas.map((node) => node.id);
            for (let i = 0; i <= degreeCount; i++) {
                let connectedNodes = new Set();
                for (let prNode of parentNodes) {
                    let cnds = window.network.getConnectedNodes(prNode);
                    for (const nid of cnds) {
                        connectedNodes.add(nid);
                    }
                    // for (let edge of this.edges) {
                    //     let currentNode;
                    //     if (edge.from === prNode && edge.show_on_graph && !edge.deleted && nodesOnCanvas.includes(edge.to)) {
                    //         currentNode = edge.to;
                    //     } else if (edge.to === prNode && edge.show_on_graph && !edge.deleted && nodesOnCanvas.includes(edge.from)) {
                    //         currentNode = edge.from;
                    //     }
                    //     if (currentNode && !connectedNodes.includes(currentNode) && !parentNodes.includes(currentNode)) {
                    //         connectedNodes.push(currentNode);
                    //     }
                    // }
                }
                for (const cnds of connectedNodes) {
                    parentNodes.add(cnds);
                }
                // parentNodes = [...parentNodes, ...connectedNodes];
            }
            return [...parentNodes];
        },

        filterNodeConnections() {
            let degreeCount = this.degreeOptions.indexOf(this.connectionDegree);
            let nodesOnCanvas = this.getfilteredNode(this.nodes)[1];
            let nodesToUpdate = [];

            if (!this.connectionDegree) {
                for (let node of nodesOnCanvas) {
                    nodesToUpdate.push({
                        id: node.id,
                        hidden: false,
                    });
                    // window.network?.body.data.nodes.update({
                    //     id: node.id,
                    //     hidden: false,
                    // });
                }
            } else {
                let parentNodes = this.getNodesOnConnectionDegree(this.centerNode.id, degreeCount);
                for (let node of nodesOnCanvas) {
                    if (parentNodes.includes(node.id)) {
                        nodesToUpdate.push({
                            id: node.id,
                            hidden: false,
                        });
                        // window.network?.body.data.nodes.update({
                        //     id: node.id,
                        //     hidden: false,
                        // });
                    } else {
                        nodesToUpdate.push({
                            id: node.id,
                            hidden: true,
                        });
                        // window.network?.body.data.nodes.update({
                        //     id: node.id,
                        //     hidden: true,
                        // });
                    }
                }
            }
            window.network?.body.data.nodes.update(nodesToUpdate);
        },
        changeEdgeCurve() {
            // if (this.edgesConf.smooth.roundness === 0) {
            //     this.edgesConf.smooth.roundness = 0.5;
            // } else {
            //     this.edgesConf.smooth.roundness = 0;
            // }

            window.network.setOptions({edges: this.edgesConf});
            window.network.redraw();
        },
        resetGraph() {
            for (let node of this.nodes) {
                (node.x = 0), (node.y = 0);
            }
            let container = document.querySelector("#canvas-area-container");

            container.style.width = "210mm";
            this.stabilizing = true;
            window.network.setOptions({physics: {enabled: true}});
            window.network.redraw();
            this.dragedNodes = [];
            container.style.width = "100%";
            this.$modal.hide("confirm_reset_graph");
        },
        addKeyboadEvent() {
            let container = document.getElementById("canvas-area-container");
            container.addEventListener("keydown", (e) => {
                this.handleKeydownEvent(event);
            });

            container.addEventListener("keyup", (e) => {
                this.handleKeyupEvent(event);
            });
        },

        handleKeydownEvent(event) {
            if (event.key === "Control") this.KeyPressedControler.ctrlKey = true;
            else if (event.key === "Shift") this.KeyPressedControler.shiftDown = true;
            else if (event.key === this.KeyPressedControler.addEdgeKey) {
                if (this.KeyPressedControler.ctrlKey) {
                    event.preventDefault();
                    this.toggleAddEdgeMode();
                }
            } else if (event.key === this.KeyPressedControler.undoKey) {
                if (this.KeyPressedControler.ctrlKey) {
                    event.preventDefault();
                    this.undoDrag();
                }
            } else if (event.key.toLowerCase() === this.KeyPressedControler.redoKey) {
                if (this.KeyPressedControler.ctrlKey && this.KeyPressedControler.shiftDown) {
                    event.preventDefault();
                    this.redoDrag();
                }
            } else if (event.key === "Delete") {
                if (this.selectedEdgeId.length > 0) {
                    this.deleteEdge();
                }
            }
        },

        handleKeyupEvent(event) {
            if (event.key === "Control") this.KeyPressedControler.ctrlKey = false;
            else if (event.key === "Shift") {
                this.KeyPressedControler.shiftDown = false;
            }
        },
        addNewEmptyAttribute() {
            this.editNodeControler.attributes.push({
                name: "",
                value: "",
                show_graph: false,
                case_id: this.$store.getters.getCaseId,
                _id: generateRandomString(),
            });
        },
        removeAttribute(index) {
            this.editNodeControler.attributes.splice(index, 1);
        },
        navigateBack() {
            // this.$router.back();
            // this.expanded = false;
            this.$emit("navigateBack");
            this.$router.push({
                name: this.getReadOnlyMode ? "read-only" : this.automated ? "research-automated" : "research",
                params: {casename: localStorage.getItem("currentTabReviewScreen") == "map" ? "personalDetails" : localStorage.getItem("currentTabReviewScreen")},
            });
        },
        destroy() {
            if (window.network !== null) {
                /*** Need to check this ****/
                // window.network.destroy();
                window.network = null;
            }
        },
        fitGraph() {
            let network = window.network;
            let bigBB = {top: Infinity, left: Infinity, right: -Infinity, bottom: -Infinity};

            this.nodes.forEach((node) => {
                let bb = network.getBoundingBox(node.id);
                if (bb && bb.top < bigBB.top) {
                    bigBB.top = bb.top;
                }
                if (bb && bb.left < bigBB.left) {
                    bigBB.left = bb.left;
                }
                if (bb && bb.right > bigBB.right) {
                    bigBB.right = bb.right;
                }
                if (bb && bb.bottom > bigBB.bottom) {
                    bigBB.bottom = bb.bottom;
                }
            });

            bigBB.top -= 150;
            bigBB.right += 150;
            bigBB.bottom += 150;
            bigBB.left -= 150;

            let canvasWidth = network.canvas.body.container.clientWidth;
            let canvasHeight = network.canvas.body.container.clientHeight;

            let scaleX = canvasWidth / (bigBB.right - bigBB.left);
            let scaleY = canvasHeight / (bigBB.bottom - bigBB.top);
            let scale = scaleX;
            if (scale * (bigBB.bottom - bigBB.top) > canvasHeight) scale = scaleY;

            // if (scale > 1) scale = 0.9 * scale;
            this.edges.forEach((elem) => {
                if (elem.relationship_type === "visual") {
                    network.body.data.edges.update({
                        id: elem.id || elem._id,
                        color: {
                            color: "#00000",
                            hover: "#00000",
                            highlight: "#00000",
                        },
                    });
                }
            });

            network.moveTo({
                scale: scale,
                position: {
                    x: (bigBB.right + bigBB.left) / 2,
                    y: (bigBB.bottom + bigBB.top) / 2,
                },
            });
        },
        downloadGraphSvg(blob) {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "graph.svg";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            this.convertToSvg = false;
        },
        async downloadGraph() {
            let canvas = document.querySelector("#canvas-area-container canvas");
            let container = document.querySelector("#canvas-area-container");
            const sizeFactor = 10;
            container.style.height = `${sizeFactor * 1000}px`;
            container.style.width = `${sizeFactor * 1000}px`;

            window.network.redraw();
            this.fitGraph();

            let loader = this.$loading.show({
                container: container,
                canCancel: false,
                color: "#0d69d5",
                loader: "bars",
                blur: "20px",
                opacity: 0.7,
            });
            setTimeout(() => {
                canvas.toBlob((blob) => {
                    container.style.height = "";
                    container.style.width = "";
                    window.network.redraw();
                    this.fitGraph();

                    loader.hide();

                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "graph.png";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();

                    this.edges.forEach((elem) => {
                        if (elem.relationship_type === "visual")
                            window.network?.body.data.edges.update({
                                id: elem.id || elem._id,
                                color: {
                                    color: "#0d69d5",
                                    hover: "#0d69d5",
                                    highlight: "#0d69d5",
                                },
                            });
                    });
                    window.network.redraw();
                });
            }, 500);
        },
        async downloadGraphImage(imageId) {
            let url = `/uploads/presigned-url/${imageId}`;
            try {
                let response = await axios.get(url);
                let el = document.getElementById("download-graph");
                let queryParams = response.data.url.split("?")[1];
                el.href = `${downloadGraphUrl}/${imageId}?${queryParams}`;
                el.click();
            } catch (error) {
                console.error(error);
            }
        },
        toggleAddNodeMode() {
            if (this.isAddNode) {
                this.disableEditMode();
            } else {
                window.network.addNodeMode();
                this.manipulationMessage = "Click in an empty space to place a new node.";
                this.editMode = true;
                this.isAddNode = true;
                this.isAddEdge = false;
                this.isEditEdge = false;
            }
        },
        toggleAddEdgeMode() {
            if (this.isAddEdge) {
                this.disableEditMode();
            } else {
                window.network.addEdgeMode();
                this.editMode = true;
                this.isAddEdge = true;
                this.isAddNode = false;
                this.isEditEdge = false;
                this.manipulationMessage = "Click on a node and drag the edge to another node to connect them.";
            }
        },
        editEdgeMode() {
            if (this.selectedEdgeId.length > 1) {
                this.$toast.error("Select single edge to edit");
            } else {
                window.network.editEdgeMode();
                this.editMode = true;
                this.isAddEdge = false;
                this.isAddNode = false;
                this.isEditEdge = true;
            }
        },

        disableEditMode() {
            window.network.disableEditMode();
            this.editMode = false;
            this.isAddEdge = false;
            this.isAddNode = false;
            this.isEditEdge = false;
            this.manipulationMessage = "";
        },
        deleteEdge() {
            for (const edgeId of this.selectedEdgeId) {
                let payload = {
                    case_id: this.$store.getters.getCaseId,
                    deleted: true,
                    id: edgeId,
                };
                axios
                    .delete("/add-relationship", {data: payload})
                    .then((response) => {
                        this.edges.forEach((edge) => {
                            if (edge.id === edgeId) {
                                edge.deleted = true;
                                edge.show_on_graph = false;
                            }
                        });
                        window.network?.body.data.edges.remove(edgeId);
                        this.$toast.success("Edge deleted");
                    })
                    .catch((error) => {
                        this.$toast.error("Network error");
                    });
            }
            this.selectedEdgeId = [];
        },
        entity_one(e) {
            let data = this.getEntityMapperData.filter((val) => {
                return val.parentid == e.target.value;
            });
            this.renderSelectOptions(data, "entity_list_two");
        },
        entity_two(e) {
            let data = this.getEntityMapperData.filter((val) => {
                return val.parentid == e.target.value;
            });
            this.renderSelectOptions(data, "entity_list_three");
        },
        renderSelectOptions(value, elemId) {
            let child = value;
            let elem = document.getElementById(elemId);
            let option = "<option value=''>Select Child</option>";
            child.forEach(function (childVal) {
                option = option + "<option value=" + childVal["_id"] + ">" + childVal.entity + "</option>";
            });
            elem.innerHTML = option;
            elem.style.display = "block";
        },

        async saveGraph(notificationFlag) {
            let coordinates = window.network.getPositions();
            let finalData = {
                case_id: this.$store.getters.getCaseId,
                nodes: [],
            };
            Object.keys(coordinates).forEach((key) => {
                let obj = {
                    _id: key,
                    x: coordinates[key].x,
                    y: coordinates[key].y,
                };
                finalData.nodes.push(obj);
                this.nodes.forEach((node) => {
                    if (node.id === key) {
                        node.x = obj.x;
                        node.y = obj.y;
                    }
                });
            });
            this.dragedNodes = [];

            try {
                await axios.post("/save-graph", finalData);
                if (notificationFlag) this.$toast.success("Graph saved");
            } catch (error) {
                this.$toast.error("Network error");
            }
        },
        async addNodeFromStore(node) {
            if (!(this.newAddedNodeCords.count % 7)) {
                if (this.newAddedNodeCords.x) {
                    this.newAddedNodeCords.x = this.newAddedNodeCords.x + 200 + 10;
                } else {
                    this.newAddedNodeCords.x = this.newAddedNodeCords.x + 100 + 10;
                }
                this.newAddedNodeCords.y = 0;
            }
            if (["other", "social"].includes(this.graphData[node.id].data_type)) {
                if (this.newAddedNodeCords.y) {
                    this.newAddedNodeCords.y = this.newAddedNodeCords.y + 80; // 80 = height of (social or other) image
                } else this.newAddedNodeCords.y = this.newAddedNodeCords.y + 40 + 5; // 40 = half of (social or other) image height

                // this.newAddedNodeCords.y = this.newAddedNodeCords.y + 10;
            } else {
                this.newAddedNodeCords.y = this.newAddedNodeCords.y + 81;
            }

            if (!(this.newAddedNodeCords.count % 7)) {
                this.newAddedNodeCords.y = this.newAddedNodeCords.y + 50;
            } else this.newAddedNodeCords.y = this.newAddedNodeCords.y + 20;

            let domPos = {
                x: this.newAddedNodeCords.x,
                y: this.newAddedNodeCords.y,
            };
            let canvasPos = window.network.DOMtoCanvas(domPos);
            window.network?.body.data.nodes.getDataSet().add({
                id: node.id,
                shape: "image",
                image: node.image,
                x: canvasPos.x,
                y: canvasPos.y,
            });

            this.newAddedNodeCords.count = this.newAddedNodeCords.count + 1;
            for (let nd of this.singleNodes) {
                if (nd.id === node.id) {
                    this.singleNodes.splice(this.singleNodes.indexOf(node), 1);
                }
            }

            // await this.updateEdge(node.id, false);
            this.addExistingEdgeToCanvas(node.id);
            await this.saveUpdatedNode(node.id, false);
            this.nodes.push(node);
        },

        addExistingEdgeToCanvas(nodeId) {
            this.allEdges.forEach((edge) => {
                if (edge.from === nodeId || edge.to === nodeId) {
                    let edgeOnCanvas = this.edges.find((elem) => elem.id === edge._id);
                    if (!edgeOnCanvas) {
                        let edgeObj = this.getEdgeObject(edge);
                        edgeObj.deleted = false;
                        const showEdge = "show_on_graph" in edgeObj ? edgeObj.show_on_graph : true;
                        if (showEdge) this.addEdgeToNetwork(edgeObj);
                        this.edges.push(edgeObj);
                    } else {
                        try {
                            const showEdge = "show_on_graph" in edgeOnCanvas ? edgeOnCanvas.show_on_graph : true;
                            if (showEdge) this.addEdgeToNetwork(edgeOnCanvas);
                        } catch {
                            // edge allready exists on canvas
                        }
                    }
                }
            });
        },
        deleteNodeFromStore(node) {
            let nodeData = this.graphData[node.id];
            let payload = {
                case_id: this.$store.getters.getCaseId,
                deleted: true,
                id: node.id,
            };
            let url = "";
            let title = this.getNodeTitle(nodeData);
            if (title.key == "social") {
                url = `/add-social-profile`;
            } else {
                if (node.nodeKey === "website") {
                    url = `/add-website`;
                }
                if (["phone", "email", "location", "name"].includes(node.nodeKey)) {
                    url = `/placeholder-data`;
                }
                if (["volunteering_roles", "wider_evidence_skills", "charitable_work"].includes(node.nodeKey)) {
                    url = `/add-green-content`;
                }
            }

            caseApi
                .delete(url, {
                    data: payload,
                })
                .then((response) => response.json())
                .then((data) => {
                    for (let nd of this.singleNodes) {
                        if (nd.id === node.id) {
                            this.singleNodes.splice(this.singleNodes.indexOf(node), 1);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        },
        onNodeTypeChange: function (e) {
            this.renderSelection(e.target.value);
        },
        renderSelection(val) {
            document.getElementById("icons_list").style.display = val == 0 || val == 1 ? "block" : "none";
            document.getElementById("screenshot").style.display = "none";
            document.getElementById("entity_list").style.display = "none";
            document.getElementById("entity_list_two").style.display = "none";
            document.getElementById("entity_list_three").style.display = "none";
            document.getElementById("icons_list").style.display = "none";
            document.getElementById("green_data_type").style.display = "none";
            // this.editNodeControler.nodeType = "";
            if (val == "0") {
                document.getElementById("icons_list").style.display = "block";
            } else if (val == "1") {
                let elemm = document.getElementById("entity_list");
                let option = "<option value=''>Select Parent</option>";
                let parents = this.getEntityMapperData.filter((val) => {
                    return !val["parentid"];
                });
                parents.forEach(function (childVal) {
                    option = option + "<option value=" + childVal["_id"] + ">" + childVal.entity + "</option>";
                });
                elemm.innerHTML = option;
                document.getElementById("entity_list").style.display = "block";
            } else if (val == "2") {
                document.getElementById("green_data_type").style.display = "block";
            } else if (val == "3") {
                // document.getElementById("other_icons_list").style.display = "block";
            } else if (val == "4") {
                this.editNodeControler.nodeType = "4";
                // document.getElementById("screenshot").style.display = "block";
            }
        },

        async toggleEdit(data, callback, prevData) {
            let item = {};
            let entity = {};
            let match = -1;
            let tab = "";
            if (prevData?.entity_type && !(prevData.data_type === "icon" && prevData.icon_type === "extra")) {
                if (prevData?.entity_type?._id) {
                    const eid = prevData?.entity_type?._id;
                    item = this.getEntitiesRelationsAttributesData.entities.find((e) => e._id == eid);
                    prevData.relations = prevData.relations?.filter((relation) => {
                        return relation.relationship_type !== "visual";
                    });

                    this.$store.dispatch("setSelectedEntityData", {
                        datatype: "entity",
                        all_data: prevData,
                        entity: item,
                        editEntity: true,
                        text: "",
                        userDefined: true,
                    });

                    if (this.automated) this.$emit("moreInfo");
                    else this.$store.dispatch("showAddEntityPanel");
                } else {
                    if (prevData.data_type == "social") {
                        if (this.automated) {
                            item = JSON.parse(JSON.stringify(prevData));
                            item.relations = item.relations?.filter((relation) => {
                                return relation.relationship_type !== "visual";
                            });
                        } else {
                            const eid = prevData?._id;
                            item = this.$store.getters.getResearchData.socialProfiles.find((e) => e._id == eid);
                        }

                        this.$store.dispatch("setSelectedEntityData", {
                            datatype: "social_profile",
                            all_data: item,
                            entity: prevData,
                            editEntity: true,
                            text: "",
                            userDefined: true,
                        });

                        if (this.automated) this.$emit("moreInfo");
                        else this.$store.dispatch("showAddEntityPanel");
                    } else if (["passive_media", "active_media", "search"].includes(prevData.entity_identifier)) {
                        if (this.automated) {
                            item = JSON.parse(JSON.stringify(prevData));
                            item.relations = item.relations?.filter((relation) => {
                                return relation.relationship_type !== "visual";
                            });
                        } else {
                            const eid = prevData?._id;
                            item = this.$store.getters.getResearchData.media.find((e) => e._id == eid);
                        }

                        this.$store.dispatch("setSelectedEntityData", {
                            datatype: item.media_type,
                            all_data: item,
                            entity: prevData,
                            editEntity: true,
                            text: "",
                            userDefined: true,
                        });

                        if (this.automated) this.$emit("moreInfo");
                        else this.$store.dispatch("showAddEntityPanel");
                    } else {
                        prevData.relations = prevData.relations?.filter((relation) => {
                            return relation.relationship_type !== "visual";
                        });
                        entity = this.getEntitiesRelationsAttributesData.entities.find((e) => e.entity_key === prevData.entity_type);

                        this.$store.dispatch("setSelectedEntityData", {
                            datatype: prevData.entity_type,
                            all_data: prevData,
                            entity: entity,
                            prevData,
                            editEntity: true,
                            text: "",
                            userDefined: true,
                        });

                        if (this.automated) this.$emit("moreInfo");
                        else this.$store.dispatch("showAddEntityPanel");
                    }
                }
            } else if (prevData.icon_type === "extra") {
                this.extraIcon = prevData;
                this.editExtraIcon = true;
            } else {
                this.$toast.error(`Error: Selected node can't be edited`);
            }
        },

        async pushNode(data) {
            this.collapsedNodes.push(data.id);

            let title = this.getNodeTitle(data);
            let nodeImg = await this.getNodeImage(data);

            let domPos = {
                x: 100,
                y: 100,
            };
            let canvasPos = window.network.DOMtoCanvas(domPos);

            let nodeObj = {
                case_id: this.$store.getters.getCaseId,
                is_collapse: false,
                nodeTitle: title.title,
                nodeKey: title.key,
                value: title.title,
                shape: "image",
                image: nodeImg,
                show_on_graph: true,
                x: canvasPos.x,
                y: canvasPos.y,
            };
            nodeObj = {
                ...data,
                ...nodeObj,
            };

            let nd_data = JSON.parse(JSON.stringify(nodeObj));
            this.nodes.push(nd_data);
            window.network?.body.data.nodes.getDataSet().add(nodeObj);
            this.graphData[data.id] = nodeObj;
            this.saveGraph();
        },

        async saveExtraIcon() {
            let data = {
                key: this.extraIcon.key || this.extraIcon.entity_identifier,
                nodeKey: this.extraIcon.key || this.extraIcon.entity_identifier,
                entity_identifier: this.extraIcon.key || this.extraIcon.entity_identifier,
                _id: this.extraIcon.id || this.extraIcon._id,
                graph_label: this.extraIcon.graph_label,
                nodeTitle: this.extraIcon.graph_label,
                title: this.extraIcon.graph_label,
                entity: this.extraIcon.graph_label,
                value: this.extraIcon.graph_label,
            };
            const response = await axios.put(`/icon-node/${this.$store.getters.getCaseId}`, data);
            try {
                if (response && response.data) {
                    await this.updateNode(this.extraIcon);
                    this.extraIcon = null;
                    this.editExtraIcon = false;
                    this.$toast.success("Icon saved Successfully");
                }
            } catch (error) {
                console.error(error);
                this.$toast.error("Node can't be edited");
            }
        },

        async updateNode(data) {
            if ("_id" in data) {
                data.id = data._id;
                delete data._id;
            }
            data.title = data.graph_label;
            data.entity = data.graph_label;
            data.nodeTitle = data.graph_label;
            data.name = data.graph_label;

            let nodeObj = JSON.parse(JSON.stringify(data));
            this.nodes = this.nodes.filter((nd) => nd.id !== nodeObj.id);
            let nodeImg = await this.getNodeImage({...nodeObj, _id: nodeObj.id});
            nodeObj.image = nodeImg;
            this.nodes.push(nodeObj);
            this.graphData[data.id] = {...nodeObj, _id: nodeObj.id};
            window.network?.body.data.nodes.update({
                id: nodeObj.id,
                image: nodeImg,
            });
        },

        editNode(data, cancelAction, callback, prevData) {
            // document.getElementById("node-label").value = data.label == "new" ? '' : data.label;
            document.getElementById("node-saveButton").onclick = this.saveNodeData.bind(this, data, callback, prevData);
            document.getElementById("node-cancelButton").onclick = cancelAction.bind(this, callback);
            document.getElementById("node-popup-model").style.display = "flex";
            if (prevData) {
                document.getElementById("node-type").disabled = true;
                if (prevData["data_type"] == "social") {
                    this.renderSelection(0);
                    setTimeout(function () {
                        let iconIndex = this.getSocialPlatforms.findIndex((val) => {
                            return val["key"] == prevData.platform;
                        });
                        this.editNodeControler.nodeType = "0";
                        this.editNodeControler.iconSelected = iconIndex;
                        this.editNodeControler.values = prevData["entity"];
                        this.editNodeControler.username_hidden = "username_hidden" in prevData ? prevData.username_hidden : false;
                    });

                    let x = {};
                    x?.y;
                } else if (prevData["data_type"] == "other") {
                    switch (prevData["entity_identifier"]) {
                        case "website":
                            this.renderSelection(3);
                            setTimeout(function () {
                                this.editNodeControler.nodeType = "3";
                                this.editNodeControler.values = prevData["entity"];
                            });
                            break;
                        case "volunteering_roles":
                        case "wider_evidence_skills":
                        case "charitable_work":
                            this.renderSelection(2);
                            setTimeout(function () {
                                this.editNodeControler.nodeType = "2";
                                this.editNodeControler.values = prevData["entity"];
                                document.getElementById("green_data_type").value = prevData["entity_identifier"];
                            });
                            break;
                        case "active_media":
                        case "passive_media":
                        case "search":
                            this.renderSelection(4);
                            setTimeout(function () {
                                this.editNodeControler.nodeType = "4";
                                this.editNodeControler.values = prevData["entity"];
                                this.editNodeControler.mediaType = prevData["entity_identifier"];
                                this.isAdverse = prevData["is_adverse"];
                            });
                            break;
                        default:
                            this.renderSelection(1);
                            setTimeout(function () {
                                this.editNodeControler.nodeType = "1";
                                this.editNodeControler.values = prevData["entity"];
                                this.editNodeControler.attributes = prevData.attributes ? prevData.attributes : [];
                                let selections = prevData["entity_type"].split(".");
                                this.autoSelectEntity(selections);
                            });
                            break;
                    }
                }
            }
            this.resetEditNode();
            this.editMode = false;
            this.isAddNode = false;
        },
        autoSelectEntity(itemArr) {
            let two_elem = document.getElementById("entity_list_two");
            let three_elem = document.getElementById("entity_list_three");
            let id_one = this.getEntityMapperData.find((val) => {
                return val["entity_key"] == itemArr[0];
            })["_id"];
            let id_two = this.getEntityMapperData.find((val) => {
                return val["entity_key"] == itemArr[1];
            })["_id"];
            let id_three = this.getEntityMapperData.find((val) => {
                return val["entity_key"] == itemArr[2];
            })["_id"];

            document.getElementById("entity_list").value = id_one;

            let data_one = this.getEntityMapperData.filter((val) => {
                return val.parentid == id_one;
            });

            this.renderSelectOptions(data_one, "entity_list_two");

            document.getElementById("entity_list_two").value = id_two;

            let data_two = this.getEntityMapperData.filter((val) => {
                return val.parentid == id_two;
            });

            this.renderSelectOptions(data_two, "entity_list_three");
            document.getElementById("entity_list_three").value = id_three;
        },
        clearNodePopUp() {
            document.getElementById("node-saveButton").onclick = null;
            document.getElementById("node-cancelButton").onclick = null;
            document.getElementById("node-popup-model").style.display = "none";
            document.getElementById("entity_list").style.display = "none";
            document.getElementById("entity_list_two").style.display = "none";
            document.getElementById("entity_list_three").style.display = "none";
            this.editMode = false;
            this.isAddNode = false;
            this.manipulationMessage = "";
        },

        cancelNodeEdit(callback) {
            this.clearNodePopUp();
            callback(null);
        },
        resetEditNode() {
            this.editNodeControler = {
                iconSelected: "",
                values: null,
                nodeType: "",
                username_hidden: false,
                attributes: [],
            };
            document.getElementById("icon-required").style.display = "none";
            document.getElementById("type-required").style.display = "none";
            document.getElementById("values-required").style.display = "none";
        },

        highlightRequired() {
            if (this.editNodeControler.iconSelected === "") {
                document.getElementById("icon-required").style.display = "block";
            } else {
                document.getElementById("icon-required").style.display = "none";
            }
            if (this.editNodeControler.nodeType === "") {
                document.getElementById("type-required").style.display = "block";
            } else {
                document.getElementById("type-required").style.display = "none";
            }
            if (this.editNodeControler.values === null) {
                document.getElementById("values-required").style.display = "block";
            } else {
                document.getElementById("values-required").style.display = "none";
            }
        },

        async saveNode(url, payload, isEdit) {
            try {
                let response = await caseApi({
                    method: isEdit ? "put" : "post",
                    url: url,
                    data: payload,
                });
                if (response.data.status) {
                    this.$toast.success("Node Save Successfully.");
                } else {
                    this.$toast.error("Error: " + response.data.message);
                }
                return response.data._id;
            } catch (error) {
                console.error("Error:", error);
            }
            // fetch(url, {
            //   method: isEdit ? "PUT" : "POST",
            //   headers: {
            //     Accept: "application/json",
            //     Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM="
            //   },
            //   body : JSON.stringify(payload)
            // })
            // .then(response => response.json())
            // .then(data => {

            //   return data._id;
            // })
            // .catch(error => {
            //   console.error("Error:", error);
            // });
        },
        async updateEdge(id, isDelete) {
            let connectedEdges = this.edges.filter((edge) => {
                return edge.to === id || edge.from === id;
            });

            for (const edge of connectedEdges) {
                edge.deleted = isDelete;
                let payload = {
                    case_id: this.$store.getters.getCaseId,
                    deleted: isDelete,
                    id: edge.id,
                };
                await axios.delete("/add-relationship", {data: payload});
            }
        },

        async saveUpdatedNode(id, isDelete) {
            let prevData = this.graphData[id];
            let url = null;
            let payload = {
                ...prevData,
                case_id: this.$store.getters.getCaseId,
                show_on_graph: !isDelete,
                add_graph: !isDelete,
                id: id,
            };
            delete payload._id;

            if (prevData.data_type == "social") {
                url = `/add-social-profile`;
                payload["username"] = payload["entity"];
            } else {
                if (prevData.iconType === "extra" || prevData.icon_type === "extra") {
                    url = `/icon-node/${this.$store.getters.getCaseId}`;
                    payload["_id"] = payload.id;
                    if (!payload["key"]) payload["key"] = payload["entity_identifier"];
                    // } else if (prevData.entity_identifier === "website") {
                    //     url = `/add-website`;
                } else if (["volunteering_roles", "wider_evidence_skills", "charitable_work"].includes(prevData.entity_identifier)) {
                    url = `/add-green-content`;
                } else if (["passive_media", "active_media", "search", "url"].includes(prevData.entity_identifier)) {
                    url = `/active-passive-media`;
                    payload["_id"] = payload.id;
                    delete payload.id;
                } else if (prevData.data_type == "icon" && prevData.icon_type === "extra") {
                    url = `/icon-node/${this.$store.getters.getCaseId}`;
                    payload["_id"] = payload["id"];
                } else {
                    url = `/entity-identifier`;
                    payload.entity_type = prevData["entity_identifier"];
                    if ("key_facts" in payload) {
                        payload.key_facts = payload.key_facts.map((ele) => ({key_fact_id: ele._id}));
                    }
                }
            }

            try {
                let response = await axios.put(url, payload);
                this.hoverNodeId = null;
                this.$toast.success(`Node ${isDelete ? "deleted" : "added"} Successfully.`);
            } catch (error) {
                this.$toast.error("Network error");
            }
        },

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        async saveEditEdge() {
            let edgeId = this.selectedEdgeId[0];
            let edgeDetails = this.edges.find((edge) => edge.id === edgeId);
            let payload = {
                id: edgeId,
                case_id: this.$store.getters.getCaseId,
                from_node: this.editEdgeControler.from,
                to_node: this.editEdgeControler.to,
                label: this.editEdgeControler.label,
                dashed: this.editEdgeControler.type === "dashed" ? true : this.editEdgeControler.type === "dotted" ? this.dottedEdgeConf : false,
                line_type: this.editEdgeControler.type,
                bidirectional: this.editEdgeControler.bidirectional,
                color: this.editEdgeControler.color,
                arrow_type: this.editEdgeControler.bidirectional ? "arrow_bi" : edgeDetails.arrow_type,
            };
            await this.saveUpdatedEdge(payload, edgeId);
            window.network?.body.data.edges.update([
                {
                    id: edgeId,
                    arrows: payload.bidirectional ? "to:from" : "to",
                    dashes: payload.dashed,
                    smooth: {type: "continuous"},
                    label: payload.label,
                    color: {
                        color: payload.color,
                        hover: payload.color,
                        highlight: payload.color,
                    },
                },
            ]);
            this.clearEdgePopUp();
            this.editMode = false;
            this.isEditEdge = false;
        },
        async saveUpdatedEdge(payload, edgeId) {
            try {
                let response = await axios.put("/add-relationship", {...payload, dashed: false});
                for (let ed of this.edges) {
                    if (ed.id === edgeId) {
                        ed.arrows = payload.bidirectional ? EDGE_BIDIRECTIONAL_KEY : "to";
                        ed.dashes = payload.dashed;
                        ed.label = payload.label;
                        ed.bidirectional = payload.bidirectional;
                        ed.color = {
                            color: payload.color,
                            hover: payload.color,
                            highlight: payload.color,
                        };
                        ed.exportColor = payload.color;
                    }
                }
            } catch (error) {
                this.$toast.error(`Error: ${error}`);
            }
        },
        editEdgeWithoutDrag(data, callback) {
            let edgeId = this.selectedEdgeId[0];
            let edge_details;
            for (let ed of this.edges) {
                if (ed.id === edgeId) {
                    edge_details = ed;
                }
            }

            this.editEdgeControler.label = data.label ? data.label : "";
            this.editEdgeControler.type = edge_details.dashes ? (edge_details.dashes.length ? "dotted" : "dashed") : "solid";
            this.editEdgeControler.bidirectional = edge_details.arrows === EDGE_BIDIRECTIONAL_KEY ? true : false;
            this.editEdgeControler.from = data.from;
            this.editEdgeControler.to = data.to;
            this.editEdgeControler.color = edge_details.color.color;
            document.getElementById("edge-popup-model").style.display = "block";
        },
        async createEdge(data, callback) {
            data.arrows = "to";
            data.smooth = {type: "continuous"};
            data.color = {color: "#0d69d5", highlight: "#0d69d5", hover: "#0d69d5"};
            data.relationship_type = "visual";
            data.show_on_graph = true;
            data.dashes = false;
            let payload = {
                id: "",
                case_id: this.$store.getters.getCaseId,
                from_node: data.from,
                to_node: data.to,
                label: "",
                dashed: false,
                bidirectional: false,
                relationship_type: "visual",
                show_on_graph: true,
            };
            await this.saveEdge(payload);
            this.edges.push(data);
            callback(data);
            this.isAddEdge = false;
            this.editMode = false;
            this.manipulationMessage = "";
            this.saveGraph();
        },

        async saveEdge(payload) {
            let url = `/add-relationship`;
            try {
                let response = await axios.post(url, payload);
                this.$toast.success("Connection Save Successfully.");
            } catch (error) {
                console.error("Error:", error);
            }
        },
        cancelEdgeEdit: function () {
            this.clearEdgePopUp();
            this.editMode = false;
            this.isEditEdge = false;
            this.manipulationMessage = "";
        },
        clearEdgePopUp: function () {
            document.getElementById("edge-saveButton").onclick = null;
            document.getElementById("edge-cancelButton").onclick = null;
            document.getElementById("edge-popup-model").style.display = "none";
        },

        getfilteredNode(nodes) {
            let filteredNodes = [];
            let connectedNode = [];
            this.entityFilterOptions = [];
            let nodesToShow = [];

            nodes.forEach((node) => {
                if (node.show_on_graph) {
                    nodesToShow.push(node);
                } else {
                    filteredNodes.push(node);
                }
            });

            nodesToShow.forEach((node) => {
                let match = false;
                for (let edge of this.getEdges) {
                    const showEdge = "show_on_graph" in edge ? edge.show_on_graph : true;
                    if ((node["id"] == edge["from"] || node["id"] == edge["to"]) && showEdge) {
                        match = true;
                        break;
                    }
                    if (node.is_center == true) {
                        match = true;
                        break;
                    }
                }
                if (node.id === this.centerNode.id) {
                    connectedNode.push(node);
                } else if (match) connectedNode.push(node);
                else filteredNodes.push(node);
            });
            for (let node of connectedNode) {
                if (node.associatedType === "entity") {
                    this.entityFilterOptions.push({id: node.id, name: node.nodeEntity});
                }
            }
            if (this.centerNode.entity)
                this.entityFilterOptions.push({
                    id: this.centerNode._id,
                    name: this.centerNode.entity,
                });
            return [filteredNodes, connectedNode];
        },

        checkDisplayOptions(hoverNodeId) {
            if (this.getReadOnlyMode) {
                return false;
            }
            if (this.stabilizing) {
                return false;
            }
            if (this.centerNode.id === hoverNodeId) {
                return false;
            }

            if (this.editMode) {
                return false;
            }

            return true;
        },
        initGraph: function (graphArea) {
            let that = this;
            this.destroy();
            let [filteredNodes, connectedNode] = that.getfilteredNode(this.nodes);
            that.singleNodes = filteredNodes;
            var nodes = new DataSet(connectedNode);
            var edges = new DataSet(that.getEdges);
            var data = {
                nodes: nodes,
                edges: edges,
            };
            var options = {
                physics: {
                    enabled: true,
                    barnesHut: {
                        theta: 0.5,
                        gravitationalConstant: -50000,
                        centralGravity: 0.3,
                        springLength: 180,
                        springConstant: 0.015,
                        damping: 0.8,
                        avoidOverlap: 1,
                    },
                },
                nodes: {
                    shapeProperties: {
                        useImageSize: true,
                    },
                    //0 4px 8px 0 rgba(0,0,0,0.2);
                },
                edges: that.edgesConf,
                manipulation: {
                    enabled: false,
                    addNode: function (data, callback) {
                        document.getElementById("node-type").disabled = false;
                        document.getElementById("node-operation").innerHTML = "Add Node";
                        that.editNode(data, that.clearNodePopUp, callback);
                    },
                    addEdge: function (data, callback) {
                        document.getElementById("node-type").disabled = false;
                        if (data.from == data.to) {
                            var r = alert("Can't connect egde to a same node.");
                            // if (r != true) {
                            //     callback(null);
                            //     return;
                            // }
                        } else {
                            document.getElementById("edge-operation").innerHTML = "Add Edge";
                            that.createEdge(data, callback);
                        }
                    },
                    editNode: function (data, callback) {
                        // that.editMode = true;
                        let prevData = that.graphData[that.hoverNodeId];
                        // that.editNode(data, that.clearNodePopUp, callback, prevData);
                        that.toggleEdit(data, callback, prevData);
                    },
                    editEdge: {
                        editWithoutDrag: function (data, callback) {
                            that.editMode = true;
                            document.getElementById("edge-operation").innerHTML = "Edit Edge";
                            that.editEdgeWithoutDrag(data, callback);
                        },
                    },
                },
                interaction: {
                    //   navigationButtons: true,
                    hover: true,
                    multiselect: true,
                    // tooltipDelay: 30,
                },
                // layout: {
                //   randomSeed: 200,
                // },
            };
            window.network = new Network(graphArea, data, options);
            let pos = window.network.getViewPosition();
            window.network.on("selectNode", async function (data) {
                that.disableEditMode();
                if (data.nodes.includes(that.centerNode.id)) return;
                let selectedNodeId = data.nodes[data.nodes.length - 1];
                if (that.optionsNode.includes(selectedNodeId)) {
                    return;
                }
                if (that.selectedNodes.includes(selectedNodeId)) {
                } else {
                    const img = await that.getNodeImage(that.graphData[selectedNodeId], true);
                    nodes.update({
                        id: selectedNodeId,
                        image: img,
                    });
                    that.selectedNodes.push(selectedNodeId);
                }
            });
            window.network.on("deselectNode", async function (data) {
                that.editMode = false;
                let deselecedNode = [];
                for (let nd of that.selectedNodes) {
                    if (!data.nodes.includes(nd)) {
                        const img = await that.getNodeImage(that.graphData[nd]);
                        nodes.update({
                            id: nd,
                            image: img,
                        });
                        deselecedNode.push(nd);
                    }
                }
                for (let nd of deselecedNode) {
                    that.selectedNodes.splice(that.selectedNodes.indexOf(nd), 1);
                }
            });
            window.network.on("selectEdge", function (data) {
                data.edges.forEach((edge) => {
                    window.network?.body.data.edges.update({
                        id: edge,
                        font: {
                            size: that.edgeLabelSize,
                        },
                    });
                });
                that.selectedEdgeId = data["edges"];
            });
            window.network.on("deselectEdge", function (data) {
                // data.previousSelection.edges.forEach((edge) => {
                //     const index = that.selectedEdgeId.indexOf(edge.id);
                //     if (index > -1) that.selectedEdgeId.splice(index, 1);
                // });
                that.selectedEdgeId = data.edges;
            });
            window.network.on("click", async function (params) {
                //open link in new tab

                if (that.editMode || that.centerNode.id === params.nodes[0]) {
                    return;
                }
                const nodeId = params.nodes[0];
                if (nodeId === "link") {
                    that.nodes.forEach((n) => {
                        if (
                            n.id == that.hoverNodeId
                            // (n.type == "website" || n.type == "social_profile") &&
                            // n.url != ""
                        ) {
                            //open the link
                            var win = window.open(that.graphData[that.hoverNodeId]["entity"], "_blank");
                            // win.focus();
                        }
                    });
                }
                if (nodeId === "edit") {
                    window.network.editNode();
                }
                // if (nodeId === "expand") {
                //     let expandedNodes = that.expandNodeConnection(that.hoverNodeId);
                //     that.connectionCollapsedNodes.splice(that.connectionCollapsedNodes.indexOf(that.hoverNodeId), 1);
                //     for (let exNodeId of expandedNodes) {
                //         that.connectionCollapsedNodes.splice(that.connectionCollapsedNodes.indexOf(exNodeId), 1);
                //     }
                //     let expandNodePos = window.network.getPosition("expand");
                //     window.network?.body.data.nodes.remove("expand");
                //     that.optionsNode.splice(that.optionsNode.indexOf("expand"), 1);
                //     that.optionsNode.push("collapse");
                //     that.addOptionNodeToCanvas("collapse", expandNodePos.x, expandNodePos.y);
                // }
                // if (nodeId === "collapse") {
                //     let collapsedNodes = that.collapseConnections(that.hoverNodeId);
                //     that.connectionCollapsedNodes.push(that.hoverNodeId);
                //     collapsedNodes = collapsedNodes.filter((el) => !that.connectionCollapsedNodes.includes(el));
                //     that.connectionCollapsedNodes = [...that.connectionCollapsedNodes, ...collapsedNodes];
                //     let collapseNodePos = window.network.getPosition("collapse");
                //     window.network?.body.data.nodes.remove("collapse");
                //     that.optionsNode.splice(that.optionsNode.indexOf("collapse"), 1);
                //     that.optionsNode.push("expand");
                //     that.addOptionNodeToCanvas("expand", collapseNodePos.x, collapseNodePos.y);
                // }
                if (nodeId === "delete") {
                    const hoverNodeId = cloneDeep(that.hoverNodeId);
                    that.hoverNodeId = null;

                    that.removeOptionNode();
                    // that.updateEdge(that.hoverNodeId, true);
                    await that.saveUpdatedNode(hoverNodeId, true);
                    nodes.remove(hoverNodeId);
                    let idx = that.nodes.findIndex((el) => el.id === hoverNodeId);
                    that.singleNodes.push(that.nodes[idx]);
                    that.nodes.splice(idx, 1);
                    // for (let nd of that.nodes) {
                    //     if (nd.id === that.hoverNodeId) {

                    //     }
                    // }

                    const connections = that.entity_identifier_data.connections.filter((connection) => connection.to === hoverNodeId);
                    const connectionIds = new Set(connections.map((connection) => connection._id));
                    that.selectedEdgeId = that.selectedEdgeId.filter((connectionId) => !connectionIds.has(connectionId));
                    that.selectedNodes.splice(that.selectedNodes.indexOf(hoverNodeId), 1);
                }
            });

            let xPositionLeft = document.body.offsetWidth / 2;
            let stabilizedCount = 0;
            if (connectedNode.length === 1) {
                that.loader.hide();
            }
            window.network.on("stabilized", function (params) {
                that.fitToCanvas();
                let x = 0;
                let y = -120;
                stabilizedCount = stabilizedCount + 1;
                if (stabilizedCount == 2) {
                    that.loader.hide();
                    stabilizedCount = 0;
                    that.stabilizing = false;
                }
                window.network.setOptions({physics: {enabled: false}});
                connectedNode.forEach(function (node) {
                    if (node.x && node.y) window.network.moveNode(node.id, node.x, node.y);
                });
                that.filterOnRelationshipType();
                that.replotHighlighted(that.getActiveEntity ?? [{entity: "All"}]);
            });
            // let removeOptionNode = () => {
            //     for (let nodeId of that.optionsNode) {
            //         nodes.remove(nodeId);
            //     }
            // };

            window.network.on("hoverNode", function (params) {
                if (!that.checkDisplayOptions(params.node)) {
                    return;
                }
                if (that.optionsNode.includes(params.node)) {
                    clearTimeout(that.optionNodeTimer);
                    return;
                }
                that.hoverNodeId = params.node;
                clearTimeout(that.optionNodeTimer);
                that.removeOptionNode();
                that.drawOptionNodes(that.hoverNodeId);
            });

            window.network.on("blurNode", function (params) {
                if (that.optionsNode.includes(params.node)) {
                    clearTimeout(that.optionNodeTimer);
                }
                that.optionNodeTimer = setTimeout(() => {
                    that.removeOptionNode();
                    that.hoverNodeId = null;
                }, 700);
            });
            window.network.on("dragStart", function (params) {
                if (params.nodes.length > 0) {
                    let dragedNodesPosition = {};
                    params.nodes.forEach((nodeId) => {
                        dragedNodesPosition[nodeId] = that.getNodeLastPosition(nodeId);
                    });
                    that.dragedNodes.push(dragedNodesPosition);
                    for (let nodeId of that.optionsNode) {
                        nodes.update([{id: nodeId, hidden: true}]);
                    }
                }
            });
            window.network.on("dragEnd", function (params) {
                if (params.nodes.length > 0) {
                    if (that.editMode) {
                        return;
                    }
                    let parentNodeId = params.nodes[0];
                    if (that.centerNode.id === parentNodeId) {
                        return;
                    }
                    let updatedNodes = [];
                    let nodeCords = that.getOptionNodeCord(parentNodeId, that.optionsNode);
                    for (let nId of that.optionsNode) {
                        updatedNodes.push({id: nId, hidden: false, x: nodeCords[nId].x, y: nodeCords[nId].y});
                    }
                    nodes.update(updatedNodes);
                }
                if (that.hoverNodeId) {
                    that.saveGraph();
                }
            });
        },
        removeOptionNode() {
            for (let nodeId of this.optionsNode) {
                window.network?.body.data.nodes.remove(nodeId);
            }
        },
        getNodeLastPosition(nodeId) {
            let changedPosition;
            this.dragedNodes.forEach((drNodes) => {
                if (Object.keys(drNodes).includes(nodeId)) {
                    changedPosition = window.network.getPosition(nodeId);
                }
            });
            if (!changedPosition) {
                this.nodes.forEach((node) => {
                    if (node.id === nodeId) {
                        changedPosition = {x: node.x, y: node.y};
                    }
                });
            }
            return changedPosition;
        },
        getOptionNodeCord(parentNodeId, optionNodes) {
            let node_pos = window.network.getPositions([parentNodeId]);
            let ygutter = node_pos[parentNodeId].y - this.hoverNodeDimension.height / 2 - 15;
            let xgutter = null;
            let nodeCords = {};

            xgutter = node_pos[parentNodeId].x + this.hoverNodeDimension.width / 2 - 15;

            for (let nodeId of optionNodes) {
                nodeCords[nodeId] = {x: xgutter, y: ygutter};
                xgutter = xgutter - 40;
            }
            ``;
            return nodeCords;
        },

        drawOptionNodes(parentNodeId) {
            let nodeImage = this.nodes.find((n) => {
                return n.id == parentNodeId;
            }).image;
            let optionNodes = [];
            let img = new Image();
            img.src = nodeImage;
            this.hoverNodeDimension.width = img.width;
            this.hoverNodeDimension.height = img.height;
            let node_pos = window.network.getPosition(parentNodeId);
            let ygutter = node_pos.y - img.height / 2 - 15;

            optionNodes.push("delete");
            optionNodes.push("edit");

            //link
            let linkNodeId = "link";
            let nodeLink = this.nodes.find((n) => {
                return n.id == parentNodeId && this.checkUrl(this.graphData[parentNodeId]["entity"]) && this.getNodeTitle(this.graphData[parentNodeId]).key != "email";
            });
            if (nodeLink) {
                optionNodes.push("link");
            }
            let nodeCords = this.getOptionNodeCord(parentNodeId, optionNodes);
            for (let optionNodeId of optionNodes) {
                this.addOptionNodeToCanvas(optionNodeId, nodeCords[optionNodeId].x, nodeCords[optionNodeId].y);
            }
            this.optionsNode = optionNodes;
        },

        addOptionNodeToCanvas(optionNodeId, x, y) {
            window.network?.body.data.nodes.add({
                id: optionNodeId,
                shape: "image",
                image: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(optionIcons[optionNodeId]),
                x: x,
                y: y,
            });
        },
        getAllConnectedNode(nodeId, edges) {
            let connectedNodes = [];
            let nodesQueue = [nodeId];

            while (nodesQueue.length > 0) {
                let currentConnectedNode = [];
                for (let quNodeId of nodesQueue) {
                    for (let edge of edges) {
                        let cnNode;
                        if (edge.from === quNodeId) {
                            cnNode = edge.to;
                        }
                        if (edge.to === quNodeId) {
                            cnNode = edge.from;
                        }

                        if (cnNode) {
                            if (!connectedNodes.includes(cnNode)) {
                                currentConnectedNode.push(cnNode);
                            }
                        }
                    }
                }
                connectedNodes = [...connectedNodes, ...nodesQueue];
                nodesQueue = currentConnectedNode;
            }

            connectedNodes.splice(connectedNodes.indexOf(nodeId), 1);

            let nodesOnCanvas = this.getfilteredNode(this.nodes)[0];
            connectedNodes = connectedNodes.filter((nd) => {
                for (const ndCnv of nodesOnCanvas) {
                    if (ndCnv.id === nd) {
                        return true;
                    }
                }
            });
            return connectedNodes;
        },
        getDirectConnectedNodes(nodeId, unidiectional) {
            let connectNodes = [];
            for (let edge of this.edges) {
                if (edge.from === nodeId) {
                    connectNodes.push(edge.to);
                }
                if (unidiectional) {
                    if (edge.to === nodeId) {
                        connectNodes.push(edge.from);
                    }
                }
            }
            return connectNodes;
        },

        expandNodeConnection(nodeId) {
            let connectedNode = this.getAllConnectedNode(nodeId, this.edges);
            let nodesUpdate = [];
            for (let cnNodeId of connectedNode) {
                nodesUpdate.push({
                    id: cnNodeId,
                    hidden: false,
                });
            }
            window.network?.body.data.nodes.update(nodesUpdate);
            return connectedNode;
        },

        collapseConnections(nodeId) {
            let connectedNode = this.getAllConnectedNode(nodeId, this.edges);
            let nodesUpdate = [];
            for (let cnNodeId of connectedNode) {
                nodesUpdate.push({
                    id: cnNodeId,
                    hidden: true,
                });
            }
            window.network?.body.data.nodes.update(nodesUpdate);
            return connectedNode;
        },
        checkUrl(text) {
            let expression = `[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?`;
            let regex = new RegExp(expression);
            if (text.match(regex)) {
                return true;
            } else {
                return false;
            }
        },
        updateNodeImage(nodeId, img) {
            window.network?.body.data.nodes.update({
                id: nodeId,
                image: img,
            });
        },
        saveUpdateNode(node) {
            let nodeData = this.graphData[node.id];
            let payload = {
                id: node.id,
                case_id: this.$store.getters.getCaseId,
                is_collapse: node.collapsed,
            };
            let url = "";
            let title = this.getNodeTitle(nodeData);
            if (nodeData.data_type === "social") {
                url = `/add-social-profile`;
                payload.platform = node.nodeKey;
                payload.username = node.nodeEntity;
            } else {
                if (node.nodeKey === "website") {
                    url = `/add-website`;
                    payload.url = nodeData.entity;
                }
                if (["phone", "email", "location", "name"].includes(node.nodeKey)) {
                    url = `/placeholder-data`;
                    payload = {...payload, ...nodeData};
                    payload.is_collapse = node.collapsed;
                    payload.attributes = node.attributes ? node.attributes : [null];
                }
                if (["volunteering_roles", "wider_evidence_skills", "charitable_work"].includes(node.nodeKey)) {
                    url = `/add-green-content`;
                    payload.url = node.nodeEntity;
                    payload.data_type = node.nodeKey;
                }
                if (["passive_media", "active_media", "search"].includes(title.key)) {
                    payload = {...nodeData, ...payload};
                    delete payload.id;
                    payload.media_type = payload.entity_identifier;
                    url = `/active-passive-media`;
                }
            }
            caseApi
                .put(url, payload)
                .then((response) => response.json())
                .then((data) => {})
                .catch((error) => {
                    console.error("Error:", error);
                });
        },

        getCenterNodeSvg: function (text, subjectType) {
            let icon = "";
            if (subjectType === "company") {
                icon = CENTER_ICON.company;
            } else {
                icon = CENTER_ICON.person;
            }

            var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="200px" height="67px">
        <style>
        .myBox{
          background-color:#000;
          // -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
          // -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
          // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
          border-radius : 10px;
          border : 1px solid #CCC
        }
        .myBox img{
          width : 50px;
          height:50px;
          margin-top:5px;
          margin-left:5px;
        }
        .myBox tr td p{
          margin : 0
        }
        .myBox tr td .title{
          color : #FFF;
          font-weight : bold
        }
        .myBox tr td .sub{
          color:#999
        }

        </style>svg
        </foreignObject>
        </svg>`;
            var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
            return url;
        },
        getTextHeight: function (text) {
            let textElem = document.getElementById("textRenderer");
            textElem.innerText = text;
            return textElem.offsetHeight < 67 ? 67 : textElem.offsetHeight + 67;
        },

        getBreakedText(text) {
            let textElem = document.getElementById("node-bottom-text");
            textElem.innerText = escape(desc);
        },

        getCollapsedNodeSvg(img, desc, highlight, highlightColor) {
            let width = 195;
            let cherHeight = 20;
            let strokeWidth = 3;
            let rectPosition = {x: 0, y: 0};
            let textIconSpacing = 5;
            let nodeIconSize = {width: 50, height: 50};
            let textLines = breakText(`${desc}`, 10, width - 4 - strokeWidth * 2);
            // let textLines = breakText(`${escape(desc)}`, 9, width - 4 - strokeWidth * 2); // 6 line left-rigth padding
            let height = textLines.length * cherHeight + (strokeWidth + rectPosition.y + textIconSpacing + 6) + 50; // 50 icon height

            let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
            svg.setAttribute("height", `${height}`);
            svg.setAttribute("width", `${width}`);

            // create rect for background
            let rectOptios = {
                x: `${rectPosition.x}`,
                y: `${rectPosition.y}`,
                width: `${width}`,
                height: `${height}`,
                fill: `white`,
            };

            if (highlight) {
                rectOptios.stroke = `red`;
                rectOptios.strokeWidth = `${strokeWidth}`;
                rectOptios.strokeLinejoin = "round";
            }
            let rect = this.getSvgNode("rect", rectOptios);
            svg.append(rect);

            // insert node icon
            let groupTag = this.getSvgNode("g", {});

            let iconOption = {
                x: `${(width - nodeIconSize.width) / 2}`,
                y: `${strokeWidth + rectPosition.y}`,
                height: `${nodeIconSize.height}`,
                width: `${nodeIconSize.width}`,
            };
            if (img) {
                // check for png base64
                try {
                    // convert svg base64 to svg dom
                    let iconSvg;
                    let spl = img.split(",");
                    let base64Str = spl[spl.length - 1];
                    iconSvg = this.base64ToSvgDom(base64Str);
                    for (const [k, v] of Object.entries(iconOption)) {
                        iconSvg.setAttribute(k, v);
                    }
                    groupTag.appendChild(iconSvg);
                } catch (error) {
                    console.error("not an svg base64", img);
                    let pngOption = {...iconOption, href: img};
                    let svgImage = this.getSvgNode("image", pngOption);
                    groupTag.appendChild(svgImage);
                }

                // if (img.includes("base64")) {
                //     let pngOption = { ...iconOption, href: img };
                //     let svgImage = this.getSvgNode("image", pngOption);
                //     groupTag.appendChild(svgImage);
                // } else {
                //     // let base64Str = await this.getIconFormUrl(img)
                //     // let pngOption = { ...iconOption, href: base64Str };
                //     // let svgImage = this.getSvgNode("image", pngOption);
                //     // groupTag.appendChild(svgImage);

                //     // convert svg base64 to svg dom
                //     let iconSvg;
                //     let spl = img.split(",");
                //     let base64Str = spl[spl.length - 1];
                //     iconSvg = this.base64ToSvgDom(base64Str);
                //     for (const [k, v] of Object.entries(iconOption)) {
                //         iconSvg.setAttribute(k, v);
                //     }
                //     groupTag.appendChild(iconSvg);
                // }
            } else {
                // image not available, create dummy svg;
                let iconSvg;
                iconSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                for (const [k, v] of Object.entries(iconOption)) {
                    iconSvg.setAttribute(k, v);
                }
                groupTag.appendChild(iconSvg);
            }
            svg.appendChild(groupTag);

            // insert node text
            let svgText = this.getSvgNode("text", {
                x: "0",
                y: `${cherHeight}`,
                style: "font-size: 20px; font-family: arial;",
                fill: "black",
                letterSpacing: "0.4",
            });

            let currentLinePosition = cherHeight + 50 + strokeWidth + textIconSpacing; // 50 height of icon, 3 stroke width
            for (const txLine of textLines) {
                let tspan = this.getSvgNode("tspan", {x: "50%", y: `${currentLinePosition}`, textAnchor: "middle"});
                let myText = document.createTextNode(`${txLine}`);
                // let myText = document.createTextNode(`${escape(txLine)}`);
                tspan.appendChild(myText);
                svgText.appendChild(tspan);
                currentLinePosition = currentLinePosition + cherHeight;
            }

            svg.append(svgText);

            // Serialize the svg to string
            var svgString = new XMLSerializer().serializeToString(svg);
            var url = "data:image/svg+xml;base64," + Buffer.from(svgString, "utf8").toString("base64");
            return url;
        },
        getSvgNode(n, v) {
            n = document.createElementNS("http://www.w3.org/2000/svg", n);
            for (var p in v)
                n.setAttributeNS(
                    null,
                    p.replace(/[A-Z]/g, function (m, p, o, s) {
                        return "-" + m.toLowerCase();
                    }),
                    v[p]
                );
            return n;
        },

        base64ToSvgDom(base64String) {
            try {
                let svgStr = window.atob(base64String);
                let parser = new DOMParser();
                let doc = parser.parseFromString(svgStr, "image/svg+xml");
                let svg = doc.getElementsByTagName("svg")[0];
                return svg;
            } catch (error) {
                let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                return svg;
            }
        },

        getSocialNodeSvg(img, text, desc, fixed_height, highlight, highlightColor) {
            let height = 80;
            let _attrs = "";
            let max_attr_length = 0;
            let max_attr = null;

            if (desc.attrs.length >= 1) {
                max_attr = desc.attrs.sort((a, b) => b.length - a.length)[0];
                max_attr_length = max_attr.length;
                _attrs = desc.attrs.reduce((str, e) => str + `<div xmlns="http://www.w3.org/1999/xhtml">${e}</div>`, "");
                // _attrs + "<br>Thisfwerfwefffffwefwefwefweffwefwefwefwefwefwefi</br>"
            }

            let titleElement = document.getElementById("titleRenderer");

            if (text.length >= max_attr_length) titleElement.innerText = text;
            else titleElement.innerText = max_attr;

            let width = titleElement.offsetWidth < 102 ? 240 : titleElement.offsetWidth + (107 + 34);

            let desc_class = "node__description__fixed__height";

            let description = desc.desc + _attrs;

            if (!fixed_height) {
                let textElem = document.getElementById("textRenderer");
                textElem.innerHTML = description;
                textElem.style.width = `${width - (70 + 25)}px`; // 50 width of icon in node, 25 right padding
                height = textElem.offsetHeight < 50 ? 80 : textElem.offsetHeight + 50;
                if (this.checkUrl(desc.desc)) {
                    desc_class = "node__description__url";
                } else {
                    desc_class = "node__description__dynamic__height";
                }
            }

            if (highlight) {
                // height = height + 10;
            }
            if (!highlightColor) {
                highlightColor = "#0d69d5";
            }
            // if (description.toLowerCase().includes("makemytrip")){

            //   this.graphNodeControler.image = img;
            //   this.graphNodeControler.title = text;
            //   this.graphNodeControler.desc = description;

            //   this.graphNodeSet = true
            //   height = this.$refs.dummynodebox.$el.offsetHeight;
            // }
            var svg =
                `<svg xmlns="http://www.w3.org/2000/svg" width="${width}px" height="${height}px">
        <defs>
           <style type="text/css">@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans|Oswald|Raleway|Roboto|Indie+Flower|Gamja+Flower');</style>
        </defs>

          <style>
          @font-face {
            font-family: "My Font";
            src: url("data:application/octet-stream;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCIgPgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+CiAgPGZvbnQgaWQ9Ik15Rm9udEVsZW1lbnQiPgogICAgPGZvbnQtZmFjZSBmb250LWZhbWlseT0iTXlGb250IiAvPgogICAgPGdseXBoIHVuaWNvZGU9IkEiIGhvcml6LWFkdi14PSI5NTAiIGQ9Ik0wLDBMMCwxMDBMMTAwLDEwMEwxMDAsMjAwTDAsMjAwTDAsMzAwTDEwMCwzMDBMMTAwLDQwMEwyMDAsNDAwTDIwMCw1MDBMMzAwLDUwMEwzMDAsNjAwTDQwMCw2MDBMNjAwLDYwMEw2MDAsNTAwTDcwMCw1MDBMNzAwLDQwMEw4MDAsNDAwTDgwMCwzMDBMOTAwLDMwMEw5MDAsMjAwTDgwMCwyMDBMODAwLDEwMEw5MDAsMTAwTDkwMCwwTDYwMCwwTDYwMCwxMDBMNzAwLDEwMEw3MDAsMjAwTDgwMCwyMDBMODAwLDMwMEwxMDAsMzAwTDEwMCwyMDBMMjAwLDIwMEwyMDAsMTAwTDMwMCwxMDBMMzAwLDBMMCwwTTMwMCw0MDBMNjAwLDQwMEw2MDAsNTAwTDMwMCw1MDBMMzAwLDQwMFoiIC8+ICAgIAogIDwvZm9udD4KPC9kZWZzPjwvc3ZnPgo=") format("svg");
          }
          .myBox{
            background-color: #fff;
            border-radius : 10px;
            height:100%;
            width: 100%;
            display:flex;
            justify-content:center;
            align-items:center;
          }
          .node__main{
            //background-color: green;
            width:${width - 10}px;
            border-radius : 10px;
            height: ${height - 10}px;
            display:flex;
            align-items:center;
            justify-content:center;
            padding: 2px;
          }
          .outer__border{
            border:3px solid ${highlightColor};
          }
          .node__image{
            width: 70px;
            height: 70px;
            margin-left:5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .soc-image{
            width: 35px;
            height: 35px;
          }
          .node__tex--content{
            width:100%;
            margin-left:8px;
          }
          .node__title{
            // background:orange;
            width:100%;
            font-family: "My Font";
            font-size: 18px;
           text-transform: capitalize;

          }
          .node__description{
            width: ${width - (50 + 25)}px;
            margin-top:2px;
            color: #889CAA;
            font-family: "My Font";
            font-size: 15px;
          }
          .node__description__fixed__height{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
          }
          .node__description__dynamic__height{
            word-break: break-word;
          }
          .node__description__url{
            word-break: break-all;
          }
          </style>
          <foreignObject x="0" y="0" width="100%" height="100%">

            <div xmlns="http://www.w3.org/1999/xhtml" class="myBox">

            <div class="node__main ${highlight ? "outer__border" : ""}">

              <div class="node__image">
                <img class="soc-image" xmlns="http://www.w3.org/1999/xhtml" src="` +
                img +
                `"/>
              </div>
              <div class="node__tex--content">
                <div class="node__title">${text}</div>
                <div xmlns="http://www.w3.org/1999/xhtml" class="node__description ${desc_class}">${description}</div>
              </div>
            </div>

            </div>

          </foreignObject>
          </svg>`;
            var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
            return url;
        },
        getImageNodeSvg: function (img, text, desc, border) {
            var svg =
                `<svg xmlns="http://www.w3.org/2000/svg" width="200px" height="162px">
        <style>
        .myBox{
          background-color:#FFF;
          // -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
          // -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
          // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
          border-radius : 10px;
          border : 1px solid #CCC

        }
        .myBox .thumbnail{
          width : 194px;
          height : 100px;
          background-size : cover;
          background-image : url(` +
                img +
                `)
        }
        .myBox tr td p{
          margin : 0
        }
        .myBox tr td .title{
          color : #000;
          font-weight : bold,
          font-size : 12px
        }
        .myBox tr td .sub{
          color:#999;
          font-size:10px
        }
        .myBox:hover{
          background : ;
        }
        </style>
        <foreignObject x="0" y="0" width="100%" height="100%">
          <div xmlns="http://www.w3.org/1999/xhtml" class="myBox">
            <table  xmlns="http://www.w3.org/1999/xhtml">
              <tr>
                <td><div class="thumbnail"></div></td>
              </tr>
              <tr>
                <td style="padding:10px"> <p class="title" xmlns="http://www.w3.org/1999/xhtml">` +
                text +
                `</p><p class="sub" xmlns="http://www.w3.org/1999/xhtml">Source : ` +
                desc +
                `</p></td>
              </tr>
            </table>
          </div>
        </foreignObject>
        </svg>`;
            var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg);
            return url;
        },

        loadGraph() {
            var container = document.querySelectorAll("#canvas-area-container");
            let graphArea = container[0];
            this.initGraph(graphArea);
        },
        // async getIcons() {
        //     let url = `${baseBlackboxUrl}/api/v1/social_platforms`;
        //     let response = await fetch(url, {
        //         method: "GET",
        //         headers: {
        //             Accept: "application/json",
        //             Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
        //         },
        //     });
        //     let data = await response.json();
        //     this.icons = data;
        //     await this.getOtherIcons();
        // },

        // async getOtherIcons() {
        //     let url = `${baseBlackboxUrl}/api/v1/icons-data`;
        //     let response = await fetch(url, {
        //         method: "GET",
        //         headers: {
        //             Accept: "application/json",
        //             Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
        //         },
        //     });

        //     let data = await response.json();
        //     this.otherIcon = data;
        //     await this.getGraphData();
        // },

        async getGraphData(graphdata) {
            this.otherIcon = cloneDeep(this.getIconsData);
            let iconsUrl = `/icon-node/${this.$store.getters.getCaseId}`;
            let data = cloneDeep(graphdata);
            const iconsResponse = await caseApi.get(iconsUrl);

            const edgeData = await this.getEdgeData();
            if (edgeData.length > 0) {
                this.allEdges = edgeData;
            }
            // let data = await response.json();
            this.entity_identifier_data = data;
            let extraIcons = [];
            extraIcons = iconsResponse.data;

            if (data.entities && data.entities.length) {
                extraIcons.forEach((icon) => {
                    icon.icon_type = "extra";
                    icon.entity = icon.graph_label;
                    data.entities.push(icon);
                });
            }

            for (let gd of data.entities) {
                this.graphData[gd["_id"]] = gd;
            }
            await this.setupGraphData(data, this.allEdges);
        },
        async getIconFormUrl(url) {
            const headers = {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, HEAD,",
                "Access-Control-Allow-Headers": "Content-Type",
            };
            let response = await rawAaxios.get(url, {responseType: "arraybuffer", headers: headers});
            let base64Str = "data:image/svg+xml;base64," + Buffer.from(response.data, "binary").toString("base64");
            return base64Str;
        },
        async getEdgeData() {
            try {
                let response = await caseApi.get(`/add-relationship?case_id=${this.$store.getters.getCaseId}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        buildIconImage: function (title, data_type, icon_type) {
            let img = "";
            if (data_type == "other" || (data_type == "icon" && icon_type === "extra")) {
                img = this.getOtherIcons["icons_data"].find((v) => {
                    return v.key == title;
                });
                img = img ? img["icon_data"] : null;
            } else {
                img = this.getSocialPlatforms.find((v) => {
                    return v.key == title;
                })["icon_data"];
            }
            return img;
        },

        getNodeTitle: function (elem) {
            if (elem.data_type == "icon" && elem.icon_type === "extra") {
                return {title: elem.graph_label, key: elem.key || elem.entity_identifier};
            } else {
                return {
                    title: elem.title,
                    key: elem.entity_identifier == "social_profile" ? elem.platform : elem.entity_identifier,
                };
            }
        },

        getNodeDesc(node) {
            let desc = "";

            if (node.graph_label) {
                desc = node.graph_label;
            } else {
                desc = node["entity"];
            }
            // if (typeof node.entity_type === "object"){
            //   if ("graph_label" in node.entity_type){
            //     desc = node.entity_type.graph_label
            //   } else {

            //   }
            // } else {
            //   desc =  node["entity"];
            // }

            let attrs = [];
            if (node["data_type"] === "social" && node.username_hidden) {
                desc = "—";
            } else {
                if (node.attributes) {
                    attrs = node.attributes.filter((e) => e != null && e.show_graph === true).map((e) => e.value);
                }
            }

            return {desc: desc, attrs: attrs};
        },

        checkFixedHeight(node) {
            let fixed_height = true;
            if (node["data_type"] === "other") {
                if (["name", "email"].includes(node["entity_identifier"])) {
                    fixed_height = false;
                }
            } else if (node["data_type"] === "social") {
                fixed_height = false;
            }

            let has_attrs = node?.attributes ?? false;
            if (has_attrs) fixed_height = false;

            return fixed_height;
        },
        isValidHttpUrl(string) {
            let url;

            try {
                url = new URL(string);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        },

        async getNodeImage(node, highlight) {
            let title = this.getNodeTitle(node);
            let img = this.buildIconImage(title.key, node["data_type"], node["icon_type"]);

            let nodeImg = "";
            let desc;
            let fixed_height = this.checkFixedHeight(node);
            let highlight_color = null;
            if (Array.isArray(node["is_adverse"] && node["is_adverse"][0]) || node["is_adverse"] === true) {
                highlight = true;
                highlight_color = "#d20f23";
            }

            let description = this.getNodeDesc(node);
            if (this.isValidHttpUrl(img)) {
                if (img in this.downloadedIcons) {
                    img = this.downloadedIcons[img];
                } else {
                    const imgUrl = img;
                    img = await this.getIconFormUrl(img);
                    this.downloadedIcons[imgUrl] = img;
                }
            }

            if (this.collapsedNodes.includes(node._id)) {
                nodeImg = this.getCollapsedNodeSvg(img, description.desc, highlight, highlight_color);
            } else {
                nodeImg = this.getSocialNodeSvg(img, title.title, description, fixed_height, highlight, highlight_color);
            }
            return nodeImg;
        },
        async setupGraphData(data, edgeData) {
            let x = 10;
            let y = 10;
            for (let elem of data.entities) {
                if (elem.entity_identifier && elem.entity_identifier === "search") {
                    continue;
                }

                if (!elem.show_on_graph) {
                    edgeData.forEach((edge) => {
                        if (edge.to === elem._id || edge.from === elem._id) {
                            edge["deleted"] = true;
                        }
                    });
                }
                if (elem.status !== "CONFIRMED") continue;

                this.collapsedNodes.push(elem["_id"]);
                let title = this.getNodeTitle(elem);
                let description = this.getNodeDesc(elem);
                let nodeImg = await this.getNodeImage(elem);
                // if(elem["is_collapse"]){
                //   this.collapsedNodes.push(elem["_id"]);
                // } set

                let nodeObj = {
                    id: elem["_id"] != "" ? elem["_id"] : index,
                    nodeTitle: title.title,
                    nodeKey: title.key,
                    nodeDataType: elem["data_type"],
                    nodeEntity: elem["entity"],
                    collapsed: elem["is_collapse"],
                    value: title.title,
                    shape: "image",
                    type: elem["entity_type"],
                    url: elem["url"],
                    title: description.desc,
                    associatedType: elem["type"],
                    image: nodeImg,
                    x: elem.x ? elem.x : x,
                    y: elem.y ? elem.y : y,
                    textHeigth: this.getTextHeight(elem["entity"]),
                    fixed: this.getReadOnlyMode,
                    // fixed: true,
                    iconType: elem["icon_type"],
                    show_on_graph: elem["show_on_graph"],
                };

                this.nodes.push(nodeObj);
                y = y + 20;
            }

            if (data.main_node.length > 0) {
                let mainNodeIcon = "";
                if (this.getUserDetails.type === "company") {
                    mainNodeIcon = CENTER_ICON.company;
                } else {
                    mainNodeIcon = CENTER_ICON.person;
                }
                let mainNode = data.main_node.length > 0 && data.main_node[0];
                let centerNode = {
                    id: mainNode._id,
                    value: mainNode.entity,
                    shape: "image",
                    is_center: true,
                    image: this.getCollapsedNodeSvg(mainNodeIcon, mainNode.entity),
                    x: mainNode["x"] ? mainNode["x"] : 0,
                    y: mainNode["y"] ? mainNode["y"] : 200,
                    show_on_graph: true,
                    // fixed: true,
                };
                this.centerNode = {...mainNode, ...centerNode};

                this.nodes.push(centerNode);
            }

            // alert(data.main_node[0]._id)
            edgeData.forEach((elem) => {
                let edgeObj = this.getEdgeObject(elem);
                this.edges.push(edgeObj);
            });
            this.loadGraph();
        },

        getEdgeObject(elem) {
            let edgeObj = {
                id: elem["_id"],
                from: elem["from"],
                to: elem["to"],
                arrows: EDGE_DIRECTIONS_KEY[elem.arrow_type],
                bidirectional: elem.arrow_type === "arrow_bi" ? true : false,
                dashes: elem["line_type"] === "dashed" ? true : elem["line_type"] === "dotted" ? this.dottedEdgeConf : false,
                label: elem["value"] && elem["percent_value"] ? elem["value"] + " " + "(" + elem["percent_value"] + "%)" : elem["value"],
                deleted: elem.deleted ? elem.deleted : false,
                color: {
                    color: elem.color ? elem.color : elem.relationship_type === "visual" ? "#0d69d5" : "#000000",
                    hover: elem.color ? elem.color : elem.relationship_type === "visual" ? "#0d69d5" : "#000000",
                    highlight: elem.color ? elem.color : elem.relationship_type === "visual" ? "#0d69d5" : "#000000",
                },
                relationship_type: elem.relationship_type ? elem.relationship_type : "actual",
                show_on_graph: "show_on_graph" in elem ? elem.show_on_graph : true,
                exportColor: elem.color ? elem.color : "#000000",
                font: {size: 14},
            };

            return edgeObj;
        },
        async getEntities() {
            let url = `/entity-parent-mapper`;
            try {
                let response = await axios.get(url);
                this.entityMapper = response.data;
            } catch (error) {
                console.error("error: ", error);
            }
        },

        getEntityid(entityName) {
            for (const [key, value] of Object.entries(this.graphData)) {
                if (value.type === "Entity" && value.entity == entityName) return key;
            }
        },
        getConnectedNodes(fromNodeId) {
            let cn = [];
            this.edges.map((el) => {
                if (el.from === fromNodeId && !el.deleted) cn.push(el.to);
                if (el.to === fromNodeId && !el.deleted) cn.push(el.from);
            });

            return cn;
        },

        replotHighlighted(val) {
            let nodesToUpdate = [];
            if (val.length === 0 || (val.length === 1 && val[0].entity === "All")) {
                let fn = this.getfilteredNode(this.nodes)[1];
                for (const node of fn) {
                    nodesToUpdate.push({id: node.id, hidden: false});
                }
            } else {
                let entittyIds = val.map((el) => {
                    if (el._id !== "all-filters-applied" && this.entityFilterValues.findIndex((item) => item.id === el._id) === -1) {
                        this.entityFilterValues.push({id: el._id, name: el.entity});
                    }
                    return el._id;
                });
                let nodesOnCanvas = this.getfilteredNode(this.nodes)[1];
                entittyIds = entittyIds.filter((el) => (nodesOnCanvas.find((n) => n.id === el) ? true : false));
                let connectedNodes = entittyIds.flatMap((el) => window.network.getConnectedNodes(el));
                connectedNodes = connectedNodes.filter((el) => (nodesOnCanvas.find((n) => n.id === el) ? true : false));
                // Add the ids of the entities themself
                entittyIds.map((el) => connectedNodes.push(el));

                for (const [k, v] of Object.entries(this.graphData)) {
                    if (!connectedNodes.includes(k)) {
                        nodesToUpdate.push({id: k, hidden: true});
                    } else {
                        nodesToUpdate.push({id: k, hidden: false});
                    }
                }
                //handle main center node
                if (!connectedNodes.includes(this.centerNode.id)) {
                    nodesToUpdate.push({id: this.centerNode.id, hidden: true});
                } else {
                    nodesToUpdate.push({id: this.centerNode.id, hidden: false});
                }
            }
            window.network?.body.data.nodes.update(nodesToUpdate);
        },
        initDownload() {
            let nodesOnCanvas = window.network.getPositions();
            this.nodesToDownload = this.nodes.filter((nd) => {
                return nd.id in nodesOnCanvas;
            });
            let filteredEdges = this.getEdges.filter((edge) => edge.from in nodesOnCanvas && edge.to in nodesOnCanvas).map((edge) => edge.id);
            this.edgesToDownload = this.edges.filter((edge) => filteredEdges.includes(edge.id));
            this.edgesToDownload.forEach((res) => {
                if (res.relationship_type.toLowerCase() == "visual" || res.relationship_type.toLowerCase() === "actual") {
                    res.exportColor = "#000000";
                }
            });
            for (let nodeId of this.optionsNode) {
                window.network?.body.data.nodes.remove(nodeId);
            }

            this.convertToSvg = true;

            //This code is used for debugging graph when SVG is opened side by side(for download button to work)
            // setTimeout(() => {
            //     this.convertToSvg = true;
            // }, 2000);
        },
    },
    beforeDestroy() {
        window.network.destroy();
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getFiltersApplied", "getUserDetails", "getEntitiesRelationsAttributesData", "getResearchData", "getEntityMapperData", "getSocialPlatforms", "getNodesInfo", "getIconsData", "getisEmptyConnection"]),
        getActiveEntity() {
            return this.getFiltersApplied?.entityFilter ? [...this.getFiltersApplied?.entityFilter] : [];
        },
        getSingleNodes() {
            return this.singleNodes;
            // return this.singleNodes.filter(node => node.iconType !== "extra")
        },
        getEdges() {
            let edges = this.edges.filter((edge) => {
                if (edge.show_on_graph && edge.from !== edge.to) {
                    let fromFound = this.nodes.find((node) => edge.from === node.id && node.show_on_graph);
                    let toFound = this.nodes.find((node) => edge.to === node.id && node.show_on_graph);
                    if (fromFound && toFound) {
                        return true;
                    }
                }
            });
            return edges;
        },
        getEdgeToDownload() {
            return this.getEdges.filter((edge) => {
                try {
                    window.network.getPosition(edge.from);
                    window.network.getPosition(edge.to);
                    return true;
                } catch (error) {
                    return false;
                }
            });
        },
        getNodesToDownload() {
            let nodesOnCanvas = this.nodes.filter((nd) => {
                try {
                    return window.network.getPosition(nd.id);
                } catch (error) {
                    return false;
                }
            });
            return nodesOnCanvas;
        },
        getOtherIcons() {
            return {icons_data: this.otherIcon};
        },
        checkAllGraphData() {
            if (this.getNodesInfo?.entities?.length > 0 && this.getSocialPlatforms.length > 0 && this.getIconsData.length > 0) {
                // this.setupGraphData(newVal, this.allEdges);
                return true;
            } else return false;
        },
    },
    props: {
        msg: String,
        zoom: Number,
        expanded: Boolean,
        automated: Boolean,
        // highlightedEntity:String,
    },

    watch: {
        getActiveEntity(newVal, oldVal) {
            if (!isEqual(newVal, oldVal)) {
                this.replotHighlighted(newVal);
            }
        },

        async getUserDetails(newVal, oldVal) {
            let mainNodeIcon = "";
            if (newVal?.type === "company") {
                mainNodeIcon = CENTER_ICON.company;
            } else {
                mainNodeIcon = CENTER_ICON.person;
            }
            const img = await this.getCollapsedNodeSvg(mainNodeIcon, this.centerNode.entity);
            window.network?.body.data.nodes.update({
                id: this.centerNode.id,
                image: img,
                // image: this.getCenterNodeSvg(this.centerNode.entity, newVal.type),
            });
        },

        expanded(newVal, oldVal) {
            setTimeout(this.fitToCanvas, 500);
            this.nodeStore = false;
        },
        // getNodesInfo(newVal, oldVal) {
        //     if (getNodesInfo?.entities?.length > 0 && this.getIconsData?.length > 0 && this.getIconsData?.length > 0) {
        //         // this.setupGraphData(newVal, this.allEdges);
        //     }
        // },
        async checkAllGraphData(newVal, oldVal) {
            if (newVal) {
                await this.getGraphData(this.getNodesInfo);
            }
        },
        getisEmptyConnection(newVal, oldVal) {
            if (newVal && this.loader != null) {
                this.loader.hide();
            }
        },
    },
};
