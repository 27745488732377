import axios from "@/axios";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import Tags from "@/components/tags";
import Tag from "@/components/tag";
import Button from "@/components/button";
import Input from "@/components/input";

import {getSocialProfileIcon, getPlatformName, getPlatformURL, getPlatformRedirectURL} from "@/utils/functions";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
    name: "modal-add-content",
    props: ["heading", "text", "datatype", "card"],
    data() {
        return {
            showAlert: false,
            requireHeading: true,
            alertMessage: "Content saved",
            flags: [],
            finalTags: [],
            myFiles: [],
            savedFile: "",
            imageCaption: "Subject shared post",
            postingDate: "",
            originalFile: null,
            entityStatus: "",
            screenshot: {
                available: false,
            },
            myServer: {
                process: this.process,
                load: this.loadScreenshot,
            },
            imageCaptionOptions: ["Subject's comment on post", "Subject's comments on post", "Subject shared post", "Friend's comment on post", "Friend's comments on post", "Profile username", "Subject tagged in post", "Subject's response to other user's question", "Other user's question to subject", "Subject's profile picture", "Subject's bio", "Liked page"],
        };
    },
    created() {},
    mounted() {},
    methods: {
        getSocialProfileIcon,
        getPlatformName,
        getPlatformURL,
        getPlatformRedirectURL,
        handleFilePondInit: function () {},
        onTagChanges(event) {
            this.flags = event;
            this.finalTags = event.map((e) => e.tag);
            this.$emit("tagChange", this.finalTags);
        },
        onRagChange(ragStatus) {
            this.entityStatus = ragStatus;
        },
        addCaption(newCaption) {
            this.imageCaptionOptions.push(newCaption);
            this.imageCaption = newCaption;
        },
        async saveData() {
            this.screenshot = {
                available: false,
            };
            if (this.savedFile != "") {
                this.screenshot.available = true;
                this.screenshot.location = this.savedFile;
                this.screenshot.file_name = this.originalFile.name;
                this.screenshot.file_mime_type = this.originalFile.type;
                this.screenshot.size = this.originalFile.size;
            }
            let final_data = {
                platform: this.matchingProfile.platform,
                processing: false,
                username: this.matchingProfile.username,
                posting_date: this.postingDate,
                hidden: false,
                deleted: false,
                content: "",
                tweet_id: "",
                language: {},
                metadata: {
                    retweet: false,
                },
                nlp_analysis: {
                    watson: {},
                    nlp_model: {},
                },
                screenshot: this.screenshot,
                image_caption: this.imageCaption,
                categories: this.finalTags,
                editMode: false,
            };
            if (this.finalTags.length == 0 || this.savedFile === "") {
                this.$toast.error("Please select a risk category and screenshot");
            } else {
                // this.$store.dispatch("addExtraContent", final_data)
                try {
                    let response = await axios.post(`/extra-content-analysis/${this.caseId}`, final_data);
                    this.$toast.success("Extra content saved");
                    this.$modal.hideAll();
                    final_data._id = response?.data?.id;
                    this.$store.dispatch("addExtraContent", final_data);
                } catch (error) {
                    console.error(error);
                    this.$toast.error("Can't update data");
                }
            }
        },
        removeFile() {
            this.savedFile = "";
        },
        process(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            axios
                .post(`/uploads/reports/images`, formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                    onUploadProgress: (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                })
                .then((response) => {
                    load(response.data);
                    this.savedFile = response.data.file;
                    this.originalFile = file;
                })
                .catch((error) => {
                    return {
                        abort: () => {
                            abort();
                        },
                    };
                });
        },
        handleError(error, e) {
            switch (e.code) {
                case "storage/canceled":
                    break;
                default:
                    error(e.message);
            }
        },
    },
    computed: {
        matchingProfile() {
            return this.$store.getters.getMatchingCaseData;
        },
        caseId() {
            return this.$store.getters.getCaseId;
        },

        getFlags() {
            return this.finalTags.map((el) => {
                return {tag: el};
            });
        },
    },
    components: {
        FilePond,
        "neo-datepicker": DatePicker,
        "neo-tags": Tags,
        "neo-tag": Tag,
        "neo-button": Button,
        "neo-input": Input,
        multiselect: Multiselect,
    },
};
