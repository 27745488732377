import axios from "@/axios";
const state = {};
const mutations = {};
const getters = {};
const actions = {
    async addComment({commit}, payload) {
        const resp = await axios.post("/comments", payload);
        return resp;
    },
    async getComment({commit}, payload) {
        const resp = await axios.get("/comments/" + payload.case_id + "/" + payload.comment_id);
        return resp;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
