import Vue from "vue";

const state = Vue.observable({
    user_email: "",
    user_name: "user",
    required_attributes: [],
});

export const mutations = {
    setUserName(val) {
        state.user_name = val;
    },
    setUserEmail(val) {
        state.user_email = val;
    },
    setRequiredAttr(val) {
        state.required_attributes = val;
    },
};

export default state;
