<template>
    <div class="container" ref="container">
        <h2 class="tw-mb-4">Associated Risk</h2>
        <div ref="clipboardTableContainer" class="tw-absolute tw-h-full tw-w-full tw-bg-white tw-hidden">
            <clipboard-table ref="clipboardTable" :data="getProfile_for_report"></clipboard-table>
        </div>

        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
            <vue-multiselect
                class="riskcategory__filter research__multiselect tw-bg-white"
                v-model="selected_risk_categories"
                :options="riskCategories"
                :multiple="true"
                :checkboxes="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="true"
                placeholder="Select Categories"
                :limit="1"
                :searchable="false"
                group-values="categories"
                group-label="group"
                :group-select="true"
                track-by="category"
                label="category"
                @input="handleRagStatusUpdate"
                @close="
                    handleUpdateSource(
                        $event.map((ele) => ele.category),
                        'risk_categories'
                    )
                "
            >
                <template slot="selection" slot-scope="{values, search, isOpen}">
                    <span class="multiselect__single" v-if="selected_risk_categories.length > 1"> {{ selected_risk_categories.length }} categories selected </span>
                </template>
            </vue-multiselect>
            <div class="tw-flex tw-flex-wrap tw-w-full">
                <neo-tag v-for="(tag, index) in selected_risk_categories" :tag="tag.category" :key="`tag_${tag}_${index}`" :bg="getTagColor(tag.category).background" :textColor="getTagColor(tag.category).textColor" :iconBg="getTagColor(tag.category).iconBg" @remove="onRemoveTag(tag.category)" :interactive="true" style="margin: 0 0.5rem 0.5rem 0" class="tw-self-center" />
            </div>

            <div class="tw-flex tw-space-x-4 tw-items-center" v-if="ragStatusCheck">
                <label for="#" class="tw-text-sm tw-text-gray-600 tw-inline-block">RAG Status:</label>
                <div class="tw-flex">
                    <div class="tw-flex tw-items-center tw-mr-6" v-for="radio in radioOptions" :key="radio">
                        <input :id="radio" type="radio" name="radio" class="tw-hidden" :value="radio" v-model="entity_status" :disabled="getReadOnlyMode" />
                        <label :for="radio" class="tw-flex tw-items-center tw-cursor-pointer">
                            <span class="tw-w-4 tw-h-4 tw-inline-block tw-mr-1 tw-rounded-full tw-border tw-border-gray-400 tw-border-solid"></span>
                            {{ radio }}
                        </label>
                    </div>
                </div>
            </div>

            <div v-if="entityData.raw_page_text" class="html__text__container">
                <div class="html__text-title">Media Content</div>
                <div class="html__text-rawtext" v-html="getHtmlText"></div>
            </div>

            <div v-if="currentTab == 'socialProfiles'">
                <div class="media">
                    <div class="tw-flex tw-items-center tw-gap-4 tw-pb-4">
                        <div class="tw-text-lg tw-font-bold">Adverse Content</div>
                        <button class="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-solid tw-border-blue-600 tw-bg-white tw-text-brand tw-font-bold tw-text-lg tw-pb-1 tw-cursor-pointer" v-if="!getReadOnlyMode" @click="onAddContent">
                            +
                        </button>
                    </div>
                    <div class="posts" :class="getReadOnlyMode && 'tw-mb-0'" ref="media">
                        <neo-media v-for="data in extra_content" :key="`${data._id}_${entityData.entity_status}`" cardType="socialImageContent" :content="data" :sidepanel="true"></neo-media>
                    </div>
                    <div class="tw-flex tw-items-center tw-justify-between">
                        <div v-if="entityData.platform === 'twitter' && !getReadOnlyMode" class="tw-flex tw-w-1/4">
                            <neo-input placeholder="Tweet Id" :border="'1rem 0 0 1rem'" bg="white" v-model="searchTweetId"> </neo-input>
                            <neo-button label="Fetch" bg="var(--brand-color)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" style="display: flex; justify-content: center; width: 5.5rem" borderRadius="0 1rem 1rem 0" @click="onFetchTweet"></neo-button>
                        </div>
                        <div class="tw-flex tw-items-center tw-ml-auto" v-if="profile && profile.data.length > 0">
                            <div class="tw-mr-5">
                                <button class="tw-flex tw-items-center tw-text-sm tw-appearance-none tw-bg-transparent tw-px-4 tw-py-2 tw-h-10 tw-border tw-border-brand tw-border-solid tw-rounded-full tw-outline-none tw-cursor-pointer tw-font-medium tw-text-brand hover:tw-text-white hover:tw-bg-brand hover:tw-border-white" @click="onCopyToClipBoard">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                                    </svg>
                                    Copy to Clipboard
                                </button>
                            </div>
                            <div class="tw-flex tw-my-8 tw-justify-end">
                                <neo-toggle v-model="translate" label="Translate Posts"></neo-toggle>
                            </div>
                            <div class="tw-flex tw-my-8 tw-ml-5 tw-justify-end">
                                <neo-toggle v-model="expandAll" label="Expand All"></neo-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="posts" v-if="profile" ref="profile">
                        <!-- social links -->
                        <!-- {{profile.data}} -->
                        <neo-social-post v-for="data in profile.data" v-if="socialPostCheck(data)" :content="data" :profile="profile" :type="data && data.screenshot && data.screenshot.available ? 'image' : 'normal'" :translate="translate" :isExpanded="expandAll" class="tw-pb-4" :ragstatus="entityData.entity_status" :key="`${data._id}_${entityData.entity_status}`"></neo-social-post>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
const VueMultiselect = () => import("@/components/vue-multiselect");
const ClipboardTable = () => import("@/components/clipboard-table");
import ModalAddContent from "@/components/modal-add-content";
const Input = () => import("@/components/input");
const Button = () => import("@/components/button");
const NeoToggle = () => import("@/components/toggle");
const SocialPost = () => import("@/containers/social-post");
const Media = () => import("@/containers/cards/media");
import Tag from "@/components/tag";
import axios from "axios";

import {isEqual} from "lodash";

export default {
    name: "Associated-Risk",
    components: {
        VueMultiselect,
        "clipboard-table": ClipboardTable,
        "neo-input": Input,
        "neo-button": Button,
        "neo-social-post": SocialPost,
        "neo-media": Media,
        "neo-tag": Tag,
        NeoToggle,
    },
    props: {
        entity: Object,
        currentTab: String,
        // interactive: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    data() {
        return {
            searchTweetId: "",
            translate: false,
            expandAll: false,
            extraContentCount: 3,
            extraContentIncrementCounter: 3,
            radioOptions: ["Red", "Amber"],
        };
    },
    methods: {
        handleRagStatusUpdate() {
            if (this.selected_risk_categories.length === 0) {
                this.entityData.entity_status = "";
            } else if (this.selected_risk_categories.length != 0 && this.entity_status === "") {
                this.entityData.entity_status = "Red";
            }
            // this.entityData.all_risk_categories = this.selected_risk_categories.map((ele) => ele.category);
        },

        handleUpdateSource(value, key) {
            const cardCheck = isEqual(this.entityData[key], value);
            this.entityData.entity_status = this.ragStatusCheck ? this.entityData?.entity_status || "Red" : "";
        },

        onRemoveTag(tag) {
            const index = this.entityData.all_risk_categories.findIndex((item) => item === tag);
            const id = this.entityData.risk_categories.findIndex((item) => item === tag);
            this.entityData.all_risk_categories.splice(index, 1);
            this.entityData.risk_categories.splice(id, 1);
        },

        socialPostCheck(data) {
            return this.getReadOnlyMode ? data.include_in_report && data.flagged : data.flagged;
        },
        async onCopyToClipBoard() {
            let el = this.$refs.clipboardTableContainer;
            el.style.display = "block";
            let range, sel;
            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(el);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(el);
                    sel.addRange(range);
                }
                document.execCommand("copy");
            } else if (body.createTextRange) {
                range = body.createTextRange();
                range.moveToElementText(el);
                range.select();
                range.execCommand("Copy");
            }

            el.style.display = "none";
            this.$toast.success("Copied tweet table to clipboard.");
        },

        onAddContent() {
            this.$store.dispatch("matchingProfile", {
                username: this.entityData.username,
                platform: this.entityData.platform,
            });
            this.$modal.show(
                ModalAddContent,
                {
                    text: "This text is passed as a property",
                    heading: "Add Content Analysis",
                    datatype: "missing",
                    card: this.entityData,
                },
                {
                    height: "auto",
                    width: "40%",
                    styles: {
                        overflow: "inherit",
                    },
                },
                {
                    "before-close": (event) => {},
                    tagChange: (tagChange) => {},
                    ragChange: (ragStatus) => {},
                }
            );
        },

        async onFetchTweet() {
            let url = "/portal/orca/api/get/tweet/by/tweetid";
            if (process.env.NODE_ENV == "development") {
                url = `https://development.saas.neotas.com${url}`;
            }
            try {
                let response = await axios.post(url, {
                    case_id: this.$store.getters.getCaseId,
                    tweet_id: this.searchTweetId,
                    username: this.card.username,
                });
                let data = [];
                data.push(response.data);
                // this.$store.dispatch("updateMatchingProfile", response);
                this.$store.commit("UPDATE_MATCHING_PROFILE", data);
            } catch (error) {
                console.error(error);
            }
        },

        handleScroll() {
            let el = this.$refs.container;
            if (el.scrollTop > el.scrollHeight - el.offsetHeight - 100) {
                this.extraContentCount = this.extraContentCount + this.extraContentIncrementCounter;
            }
        },

        getTagColor(tag) {
            const groupName = this.riskCategories.find((catList) => catList.categories.some((elem) => elem.category === tag));
            if (groupName?.group === "Risk Categories") {
                if (this.entityData.entity_status && this.entityData.entity_status.toLowerCase() === "amber") {
                    return {background: "#FFF6DE", textColor: "#D19D00", iconBg: "#FFDC86"};
                } else {
                    this.entityData.entity_status = "Red";
                    return {background: "var(--color-danger-bg)", textColor: "var(--color-danger)", iconBg: "#faccd4"};
                }
            } else if (groupName?.group === "Non-Risk Categories") {
                return {background: "rgb(220 252 231)", textColor: "rgb(21 128 61)", iconBg: "rgb(134 239 172)"};
            } else {
                return {};
            }
        },
    },
    mounted() {
        let media = this.$refs.media?.children[0];
        let container = this.$refs.container;
        if (media?.offsetWidth) this.extraContentIncrementCounter = parseInt(1 / (media.offsetWidth / container.offsetWidth), 10);
        this.handleScroll();
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getExtraContent"]),

        entityData: {
            get() {
                return this.entity;
            },
            set(val) {
                this.entity = val;
            },
        },

        riskCategories() {
            return this.$store.getters.getRiskCategories;
        },

        tags() {
            // return this.$store.getters.getMatchingProfileConsolidatedFlags?.categories || [];
            return this.selected_risk_categories || [];
        },

        ragStatusCheck() {
            let categoriesList = this.riskCategories.find((cat) => cat.group === "Risk Categories");
            let radio = false;
            if (categoriesList?.categories) {
                radio = categoriesList?.categories?.some((elem) => this.tags.some((cat) => cat.category === elem.category));
            }
            return this.tags.length > 0 && !this.getReadOnlyMode && radio;
        },
        getProfile_for_report() {
            if (this.$store.getters.getMatchingProfile) return this.$store.getters.getMatchingProfile.data.filter((item) => item.include_in_report);
            else return [];
        },
        extra_content() {
            return this.$store.getters.getMatchingExtraContent?.slice(0, this.extraContentCount)?.map((content) => ({...content, entity_status: this.entityData.entity_status})) ?? [];
        },
        profile() {
            return this.$store.getters.getMatchingProfile;
        },

        searchedText() {
            return this.$store.getters.search?.["research-automated"];
        },
        getHtmlText() {
            let newText = this.entityData.raw_page_text;
            // let re = new RegExp(this.searchedText, "gi");
            // this.entityData.raw_page_text.match(re)?.forEach((matchedText) => {
            //     if (!modifiedText.includes(matchedText)) {
            //         let matchedRe = new RegExp(matchedText, "g");
            //         newText = newText.replace(matchedRe, `<mark>${matchedText}</mark>`);
            //         modifiedText.push(matchedText);
            //     }
            // });
            // let newText = this.card.raw_page_text.replace(re, `<mark>${this.searchedText}</mark>`);
            return newText;
        },

        selected_risk_categories: {
            get() {
                return this.entityData.all_risk_categories?.map((ele) => ({category: ele})) || [];
            },
            set(val) {
                this.entityData.all_risk_categories = val.map((ele) => ele.category);
                this.entityData.risk_categories = val.map((ele) => ele.category);
            },
        },

        entity_status: {
            get() {
                return this.entityData?.entity_status && this.entityData?.all_risk_categories?.length > 0 ? this.entityData?.entity_status : "Red";
            },
            set(val) {
                this.entityData.entity_status = val;
            },
        },
    },
    watch: {},
};
</script>
<style lang="scss" scoped>
.media {
    // margin: 0 1rem;
    span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            cursor: pointer;
            color: var(--brand-color);
            transition: all 150ms ease-in;
            margin: 0;
            padding: 0.5rem 0.8rem;
            border-radius: 0.5rem;
            &:hover {
                background-color: var(--brand-accent-neutral-hover-on);
            }
        }
    }

    .posts {
        margin-bottom: 5rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
        gap: 2rem;
        &.tw-mb-0 {
            margin-bottom: 0;
        }
    }
    .adverse-content {
        padding-bottom: 100px;
    }
    .add-content {
        display: flex;
        align-items: center;
        border: 1px solid var(--brand-color);
        border-radius: 50%;
        padding: 0.1rem;
        transition: var(--transition-mnml);
        cursor: pointer;
        outline: none;
        background-color: transparent;

        &:hover {
            background-color: var(--brand-transparent);
        }

        p {
            margin: 0 0.6rem;
            color: var(--brand-color);
            font-size: 14px;
        }
        img {
            background-color: #bed4fc;
            padding: 0.3rem;
            border-radius: 100%;
            margin: 0.1rem;
        }
    }
}

.html__text__container {
    display: flex;
    flex-direction: column;
    background-color: white;

    .html__text-title {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .html__text-rawtext {
        padding-top: 0.75rem;
        white-space: pre-line;
    }
}

input[type="radio"] + label span {
    transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
    transform: scale(1.2);
}

input[type="radio"]:checked + label span {
    background-color: var(--brand-color); //bg-blue
    box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
    color: var(--brand-color); //text-blue
}
</style>
