import {dashedCase} from "@/utils/functions";
import {startCase} from "lodash";

export default {
    sentenceCase: (value) => (!value ? "" : startCase(value.toString())),

    dashedCase: (string) => dashedCase(string),

    sliceCollection: (collection, from, to) => collection.slice(from ? from : 0, to ? to : collection.length),
};
