import {mapActions, mapGetters} from "vuex";
//import { v4 as uuidv4 } from "uuid";
import smartAnalyser from "@/components/smart-analyser";
import {EventBus} from "@/main.js";

export default {
    name: "analyse",
    components: {
        smartAnalyser,
    },
    props: {
        //     source: {
        //         type: String,
        //     },
        //     value: {
        //         type: String,
        // //     },
        //     fromGraph: {
        //         type: Boolean,
        //         default: false,
        //     },
        //     name: {
        //         type:String,
        //     }
    },
    data() {
        return {
            selectedTools: [],
            isOpen: false,
            source: "",
            value: "",
            fromGraph: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getAllToolNames", "getEntitiesRelationsAttributesData"]),
        // tippyName() {
        //     return `${uuidv4()}`;
        // },
        allToolsCopy() {
            return _.cloneDeep(this.getAllToolNames);
        },
    },
    mounted() {
        const bg = this.$refs.bgLayer;
    },
    methods: {
        ...mapActions(["handleAnalyseQuery"]),
        // async handleAnalyse(e) {
        //     // if (e.ctrlKey) {
        //     //     const resolved = this.$router.resolve({
        //     //         fullPath: this.$route.fullPath,
        //     //     });
        //     //     window.open(resolved.href, "_blank");
        //     // }
        //     this.$toast.success(`Analysing ${this.value}...`);
        //     EventBus.$emit('topprogress', 'start');
        //     await this.handleAnalyseQuery({ source: this.source, value: this.value, windowOpen: true });
        //     EventBus.$emit('topprogress', 'done');
        // },
        async handleSelectedAnalyse(analyser) {
            this.$toast.success(`Analysing ${analyser.value}...`);
            EventBus.$emit("topprogress", "start");
            console.log(this.source);
            await this.handleAnalyseQuery({source: this.source, value: analyser.value, windowOpen: true, selectedTools: analyser.tools});
            EventBus.$emit("topprogress", "done");
        },

        // this.getEntitiesRelationsAttributesData.entities

        clearAllTools() {
            this.isOpen = false;
            const bgCopy = document.getElementById("bg-copy");
            bgCopy.remove();
            if (this.fromGraph) this.$refs.smartanal.clearAllTools();
            return true;
        },
        // showBgLayer() {
        //     this.isOpen = true;
        //     // const background = this.$refs.bgLayer
        //     const bgLayer = document.getElementById("bgLayer").cloneNode();
        //     const bgCopy = document.body.appendChild(bgLayer);
        //     bgCopy.classList = "bg-layer tw-fixed tw-inset-0 tw-bg-black tw-opacity-30 tw-z-50";
        //     bgCopy.id = "bg-copy";
        //     bgCopy.style.zIndex = " 590";
        //     this.$refs["smartanal"].openAllToolGraph();
        //     // document.body.appendChild(background)
        // },
        beforeOpen({params}) {
            this.source = params.src;
            this.value = params.value;
            this.fromGraph = params.fromGraph;
        },
        handleQuickSave() {
            let source = this.source;
            if (source == "url") {
                source = "website";
            }
            let entityData = null;
            if (source === "company_name") {
                entityData = this.getEntitiesRelationsAttributesData?.entities?.find((n) => n.entity == "New Company");
            } else if (source === "name") {
                entityData = this.getEntitiesRelationsAttributesData?.entities?.find((n) => n.entity == "New Person");
            } else {
                entityData = this.getEntitiesRelationsAttributesData?.entities?.find((n) => n.entity_identifier == source);
            }
            if (entityData) {
                // const payload = {
                //     msg: "popupSidepanel",
                //     value: this.value,
                //     datatype: "entity",
                //     item: entityData,
                //     userDefined: true,
                //     text: this.value,
                //     edit: false,
                // };
                this.$store.dispatch("setSelectedEntityData", {
                    all_data: {entity: this.value, add_graph: true},
                    datatype: "entity",
                    editEntity: true,
                    entity: entityData,
                    text: this.value,
                    userDefined: true,
                });

                this.$store.dispatch("showAddEntityPanel");
                // if (value.doc_type === "relation") payload.relationType = this.getEntitiesRelationsAttributesData.relations.find((n) => n._id == value.mapper[0].map_id);
                // this.openExtension(payload);
            }
        },
        openExtension(payload) {
            if (window.chrome) {
                chrome?.runtime?.sendMessage(this.$store.getters.getExtensionID, payload, (response) => {
                    setTimeout(this.onClose, 1000);
                });
            }
        },
    },
};
