import {mapGetters} from "vuex";
import {dragscroll} from "vue-dragscroll";
import Checkbox from "@/components/checkbox";
import {TippyComponent} from "vue-tippy";
import {orderBy} from "lodash";

export default {
    name: "entity-selector",
    components: {
        "neo-checkbox": Checkbox,
        tippy: TippyComponent,
    },
    directives: {
        dragscroll,
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedEntities: [],
            dragging: false,
            select_all: false,
            showOnlyMain: true,
            show_associated: false,
            scrollerVisible: false,
        };
    },
    computed: {
        ...mapGetters(["getIconsData", "getAllAssociatedEntities", "getUserDetails"]),
        selectedEntityIds() {
            return this.selectedEntities.map((e) => e._id);
        },
        validEntities() {
            // .filter((e) => typeof e.entity_type == "object")
            let entityData = this.getAllAssociatedEntities.map((e) => ({
                ...e,
                main_entity: e.main_entity ? e.main_entity : e.attributes.filter((f) => f.attribute_id == "ffe23e7b-6477-49d6-b12b-793d4212c31a" && f.value === true).length > 0,
            }));
            if (!this.showOnlyMain) {
                entityData = entityData.filter((e) => e.main_entity === true);
            }
            return orderBy(entityData, ["main_entity"], ["desc"]);
        },
    },
    mounted() {},
    methods: {
        openExtension(entity_key) {
            let item = this.$store.getters.getEntitiesRelationsAttributesData.entities.find((e) => e.entity_key == entity_key);
            let payload = {
                msg: "popupSidepanel",
                value: "",
                datatype: "entity",
                edit: false,
                item,
            };
            if (window.chrome) chrome?.runtime?.sendMessage(this.$store.getters.getExtensionID, payload, (_response) => {});
        },
        openEntityMenu(entityKey) {
            let entity = this.$store.getters.getEntitiesRelationsAttributesData.entities.find((e) => e.entity_key == entityKey);
            this.$store.dispatch("setSelectedEntityData", {
                datatype: "entity",
                editEntity: false,
                entity: entity,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");
            // here need to write code for left side menu
            // maybe from vues
        },
        switchSelect() {
            if (this.select_all) {
                this.selectedEntities = [...this.validEntities];
            } else {
                this.selectedEntities = [];
            }
            this.$emit("entitySelected", this.selectedEntities);
        },
        switchAssociated() {
            // TODO: Need to add code here.
        },
        dragStart() {
            this.dragging = true;
        },
        dragEnd() {
            this.dragging = false;
        },
        editEntity(event, all_data) {
            event.stopPropagation();

            let entity = this.$store.getters.getEntitiesRelationsAttributesData.entities.find((e) => e.entity_key === all_data.entity_type.entity_key);
            this.$store.dispatch("setSelectedEntityData", {
                datatype: "entity",
                all_data,
                editEntity: true,
                entity,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");
        },
        clickScroll(direction) {
            let currentScroll = this.$refs.entityScrollContainer.scrollLeft;
            let targetScroll = direction == "left" ? currentScroll - 100 : currentScroll + 100;
            if (targetScroll < 0) targetScroll = 0;
            this.$refs.entityScrollContainer.scrollTo({
                left: targetScroll,
                behavior: "smooth",
            });
        },
        selectEntity(entity) {
            if (!this.dragging) {
                let index = this.selectedEntities.indexOf(entity);
                if (index < 0) {
                    this.selectedEntities.push(entity);
                } else {
                    this.selectedEntities.splice(index, 1);
                }
                this.select_all = this.selectedEntities.length == this.validEntities.length;
                this.$emit("entitySelected", this.selectedEntities);
            }
        },
        getIcon(key) {
            key = key ? key : this.getUserDetails.type == "person" ? "name" : "company_name";
            return this.getIconsData.find((e) => e.key == key)?.icon_data ?? "";
        },
    },
};
