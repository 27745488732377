export default {
    data() {
        return {
            refreshing: false,
            worker: null,
            updateExists: false,
        };
    },
    methods: {
        showRefreshUI(e) {
            if (e.detail) {
                this.worker = e.detail;
                this.updateExists = true;
            } else {
                console.warn("No worker data found!");
            }
        },
        refreshApp() {
            this.updateExists = false;
            console.log(this.worker);
            if (!this.worker || !this.worker.waiting) {
                console.warn("No worker data found when trying to refresh!");
                return;
            }
            this.worker.waiting.postMessage({type: "SKIP_WAITING"});
        },
    },
    created() {
        document.addEventListener("swUpdated", this.showRefreshUI, {once: true});
        navigator.serviceWorker &&
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
    },
};
