import Input from "@/components/input";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

import {GREEN_FLAG_FILTER_OPTIONS, ASSOCIATED_ENTITIES_FILTER_OPTIONS, MEDIA_ENTITIES_FILTER_OPTIONS} from "@/utils/constants";

export default {
    name: "nodes-container",
    components: {
        "neo-input": Input,
        Multiselect,
    },
    props: ["nodes", "icons", "otherIcon"],
    data() {
        return {
            hoveredNodeId: null,
            filter: "",
            searchFilter: "",
            selectedNodeType: null,
            nodeTypeOptions: [
                {
                    displayName: "Social Profile",
                    key: "social",
                    childs: [],
                },
                {
                    displayName: "Associated Entities",
                    key: "associatedEntities",
                    childs: ASSOCIATED_ENTITIES_FILTER_OPTIONS,
                },
                // {
                //     "displayName": "Green Content",
                //     "key": "greenContent",
                //     "childs": ["Volunteering Role", "Wider Evidence of Skills", "Charitable work"]
                // },
                // {
                //     "displayName": "Websites",
                //     "key": "website",
                //     "childs": []
                // },
                {
                    displayName: "Media",
                    key: "media",
                    childs: MEDIA_ENTITIES_FILTER_OPTIONS,
                },
            ],
        };
    },
    computed: {
        getfilterChilds() {
            let childs = [];
            if (this.selectedNodeType) {
                if (this.selectedNodeType.key === "social") {
                    for (let i of this.icons) {
                        childs.push(i.name.toLowerCase());
                    }
                } else {
                    childs = this.selectedNodeType.childs.map((e) =>
                        e
                            .split(" ")
                            .map((el) => (el === "of" ? "of" : el[0].toUpperCase() + el.substr(1).toLowerCase()))
                            .join(" ")
                    );
                }
            }
            return childs;
        },
        checkNodeTitel(title) {
            if (this.filter) {
                if (title.includes(this.filter.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        filteredNodes() {
            let nds = this.nodes.filter((node) => {
                if (this.selectedNodeType) {
                    if (this.selectedNodeType.key === "social" && node.nodeDataType === "social") {
                        return this.checkOnNodeTitle(node);
                    } else if (this.selectedNodeType.key === "associatedEntities" && this.getfilterChilds.some((el) => el.toLowerCase() === node.nodeKey)) {
                        return this.checkOnNodeTitle(node);
                    } else if (this.selectedNodeType.key === "greenContent" && this.getfilterChilds.includes(node.nodeTitle)) {
                        return this.checkOnNodeTitle(node);
                    } else if (this.selectedNodeType.key === "website" && node.nodeTitle.toLowerCase() === "website") {
                        return true;
                    } else if (this.selectedNodeType.key === "media" && this.getfilterChilds.includes(node.nodeTitle)) {
                        return this.checkOnNodeTitle(node);
                    }
                } else {
                    return true;
                }
            });

            return nds;
        },
    },
    mounted() {},
    methods: {
        checkOnNodeTitle(node) {
            if (this.filter) {
                return node.nodeTitle.toLowerCase().includes(this.filter.toLowerCase());
            } else {
                return true;
            }
        },
        onNodeHover(nodeId) {
            this.hoveredNodeId = nodeId;
        },

        getNodeIcon(node) {
            let img = "";
            if (node.nodeDataType == "other" || (node.nodeDataType == "icon" && node.iconType === "extra")) {
                img = this.otherIcon["icons_data"].find((v) => {
                    return v.key == node.nodeKey;
                });
                img = img ? img["icon_data"] : null;
            } else {
                img = this.icons.find((v) => {
                    return v.key == node.nodeKey;
                });
                img = img ? img["icon_data"] : null;
            }

            return img;
        },
        checkSearchFilter(node) {},
        onNodeTypeFilterChange(nodeType) {
            this.filter = "";
        },
        onNodeTypeFilterRemove() {
            this.selectedNodeType = null;
            this.filter = "";
        },
        onFilterRemove() {
            this.filter = "";
        },
        onFilterSelect(option) {
            this.filter = option.toLowerCase();
        },
        onAddNode(node) {
            this.$emit("onadd", node);
        },
        onDeleteNode(node) {
            this.$emit("ondelete", node);
        },
    },
};
