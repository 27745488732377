import {SET_CASE_LIST_PIE_CHART, SET_CASE_STATUS_PIE_CHART, SET_DOMAINS_PIE_CHART, SET_REG_STATUS_PIE_CHART, SET_RISK_CATEGORIES_PIE_CHART, SET_SOURCE_CATEGORIES_PIE_CHART, SET_TIME_LINE} from "./mutations-types";

export default {
    [SET_CASE_LIST_PIE_CHART](state, data) {
        state.caseListPieChart = data;
    },
    [SET_CASE_STATUS_PIE_CHART](state, data) {
        state.caseStatusPieChart = data;
    },
    [SET_DOMAINS_PIE_CHART](state, data) {
        state.caseListPieChart = data;
    },
    [SET_REG_STATUS_PIE_CHART](state, data) {
        state.ragStatusPieChart = data;
    },
    [SET_RISK_CATEGORIES_PIE_CHART](state, data) {
        state.riskCategoriesPieChart = data;
    },
    [SET_SOURCE_CATEGORIES_PIE_CHART](state, data) {
        state.caseListPieChart = data;
    },
    [SET_TIME_LINE](state, data) {
        state.caseListPieChart = data;
    },
    SET_ASSIGNED_DASHBOARDS(state, data) {
        state.assignedDashboards = data;
    },
};
