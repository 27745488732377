import {escapeRegExp} from "@/utils/functions";
import {DATA_TYPES} from "@/utils/content-scripts/mark.decorator";

/**
 * Search word Entity properties
 *  @param {String} source - string with Search word Entity
 *
 *   @return {Object};
 */

function SearchWordToMarkEntityAdapter(source) {
    return {
        getEntity() {
            const elementForRender = {
                word: source,
            };
            return {
                element: elementForRender,
                dataType: DATA_TYPES.searchWords,
                pattern: new RegExp(`${escapeRegExp(source.trim())}`, "gmi"),
            };
        },
        getClasses() {
            const fixedClass = "user-search-word";
            return `${fixedClass}`;
        },
        getAttributes() {
            const attributes = [];
            attributes.push({key: "title", value: `${DATA_TYPES.searchWords} - ${source}`});
            return attributes;
        },
    };
}

export default SearchWordToMarkEntityAdapter;
