import axios from "@/axios";

export default {
    state: {
        sections: [],
        reportTemplates: [],
    },
    getters: {
        sections: (state) => state.sections,
        reportTemplates: (state) => state.reportTemplates,
    },
    mutations: {
        SET_SECTIONS: (state, payload) => (state.sections = payload),
        SET_REPORT_TEMPLATE: (state, payload) => (state.reportTemplates = payload),
    },
    actions: {
        fetchAllSections: async ({commit}) => {
            commit("SET_SECTIONS", []);
            let {data} = await axios.get("/reports");
            commit("SET_SECTIONS", data ? data : []);
        },
        fetchAllReportTemplates: async ({commit, dispatch, state}) => {
            commit("SET_REPORT_TEMPLATE", []);
            if (state.sections.length <= 0) {
                await dispatch("fetchAllSections");
            }
            let {data} = await axios.get("/template/all");
            let allTemplates = [];
            data?.data.forEach((temp) => {
                let reportSections = temp.body.map((val) => {
                    return state.sections.find((sec) => {
                        return sec.id == val;
                    });
                });
                let templatePage = {
                    id: temp._id,
                    name: temp.name ? temp.name : "Untitled Document",
                    html: "",
                    body: temp.body,
                };
                reportSections.forEach((repo) => {
                    if (repo) templatePage.html = templatePage.html + repo.html;
                });
                if (templatePage.html) allTemplates.push(templatePage);
            });
            commit("SET_REPORT_TEMPLATE", allTemplates);
        },
    },
};
