import App from "./App.vue";
import Filters from "./filters";
import Rx from "rxjs/Rx";
import Vue from "vue";
import VueAxios from "vue-axios";
import VueRx from "vue-rx";
import axios from "axios";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";

import {fpjsPlugin} from "@fingerprintjs/fingerprintjs-pro-vue-v2";

// import vuetify from './plugins/vuetify';
import {directive as onClickOutside} from "./directives/clickoutside.js";
import vueTopprogress from "vue-top-progress";

// import { datadogRum } from "@datadog/browser-rum";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// import 'bootstrap/dist/css/bootstrap.css';

import "./styles.scss";

import "vue-select/dist/vue-select.css";
import VueClipboard from "vue-clipboard2";
import vSelect from "vue-select";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import PortalVue from "portal-vue";

Vue.use(PortalVue);
Vue.component("v-select", vSelect);
Vue.use(CKEditor);
Vue.use(VueClipboard);
Vue.directive("on-click-outside", onClickOutside);

Vue.use(VueCookies);
Vue.use(vueTopprogress);

Vue.use(VueRx, Rx);
Vue.use(VueAxios, axios);

Vue.use(fpjsPlugin, {
    loadOptions: {
        apiKey: "eADnuFv5BRv47CoYKwoC",
        endpoint: "https://metrics-fp.neotas.com",
    },
});

import VModal from "vue-js-modal";
import Loading from "vue-loading-overlay";
import Toast from "vue-toastification";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// This is needed for toast
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

import {BootstrapVue, BSpinner, BootstrapVueIcons, BButton, BImg, BSidebar, VBToggle, DropdownPlugin, BDropdown, ModalPlugin, VBModal, PopoverPlugin} from "bootstrap-vue";
import Vuelidate from "vuelidate";
// import titleMixin from '@/mixins/titleMixin'
// import highcharts plugin
import "./plugins/highcharts";
// import "./plugins/histogramSlider";
import "./plugins/formulate";

import Editor from "vue-editor-js/src/index";

import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

Vue.use(VueGoodTablePlugin);
Vue.use(Editor);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.use(PopoverPlugin);
Vue.use(VTooltip);

// Vue.mixin(titleMixin)

Vue.component("b-button", BButton);
Vue.component("b-spinner", BSpinner);
Vue.component("b-img", BImg);
Vue.component("b-sidebar", BSidebar);
Vue.component("b-dropdown", BDropdown);

Vue.directive("b-modal", VBModal);
Vue.directive("b-toggle", VBToggle);

// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading);

const options = {
    // You can set your default options here
    position: "top-right",
    newestOnTop: true,
    maxToasts: 20,
    timeout: 5000,
    // icon: false
    hideProgressBar: true,
};

Vue.use(Toast, options);

Vue.use(VModal, {dialog: true});
import Toasted from "vue-toasted";

import "tailwindcss/tailwind.css";
const html_option = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: ["tailwindcss/tailwind.css", "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css", "https://unpkg.com/kidlat-css/css/kidlat.css"],
};

// or, using the defaults with no stylesheet
Vue.use(Toasted, {
    theme: "toasted-primary",
    position: "top-right",
    duration: 3000,
});
Vue.use(VueRx, Rx);
Vue.use(VueAxios, axios);

Object.entries(Filters).forEach(([k, fn]) => Vue.filter(k, fn));

Vue.config.productionTip = false;
const iamURL = "https://0doku09yt2.execute-api.eu-west-2.amazonaws.com/beta/usermod";

const iamApi = axios.create({
    baseURL: "https://0doku09yt2.execute-api.eu-west-2.amazonaws.com/beta/usermod",
    timeout: 10000,
});

const caseApi = axios.create({
    baseURL: "http://localhost:8000",
    timeout: 1000,
});

const userApi = axios.create({
    baseURL: "https://0doku09yt2.execute-api.eu-west-2.amazonaws.com/beta/usermod",
    // baseURL: 'http://localhost:8009',
    timeout: 1000,
});
const riskApi = axios.create({
    baseURL: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1`,
    // baseURL: `https://saas.neotas.uk/api/v1/`,
    headers: {
        Accept: "application/json",
        Authorization: "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=",
        // Authorization: `Bearer ${VueCookies.get('token')}`
    },
});
const jwtBlackboxApi = axios.create({
    // baseURL: "https://blackbox.neotas.uk/api/v1/",
    baseURL: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1`,
    // baseURL: `https://saas.neotas.uk/api/v1/`,
});

jwtBlackboxApi.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

caseApi.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

userApi.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

iamApi.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faPowerOff,
    faUserSecret,
    faDotCircle,
    faChevronCircleDown,
    faGlobe,
    faTextWidth,
    faCircleNotch,
    faSortAlphaDown,
    faSortNumericDown,
    faGlobeAmericas,
    faCheck,
    faCheckSquare,
    faUserCircle,
    faPlus,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faEdit,
    faSave,
    faTrash,
    faChartLine,
    faSearchPlus,
    faClone,
    faDesktop,
    faClipboard,
    faCog,
    faExpand,
    faUserCog,
    faListAlt,
    faUser,
    faUsers,
    faKeyboard,
    faThList,
    faAngleLeft,
    faAngleDown,
    faAngleUp,
    faBars,
    faAngleRight,
    faExternalLinkAlt,
    faLink,
    faSearch,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faPaintBrush,
    faTint,
    faMapMarkerAlt,
    faCalendarAlt,
    faImage,
    faNewspaper,
    faBirthdayCake,
    faEnvelope,
    faPhoneAlt,
    faBuilding,
    faGraduationCap,
    faVenusMars,
    faLanguage,
    faExclamationCircle,
    faAddressBook,
    faIcons,
    faTimes,
    faTachometerAlt,
    faCircle,
    faPlusCircle,
    faMinusCircle,
    faSitemap,
    faProjectDiagram,
    faWindowRestore,
    faDownload,
    faSync,
    faTable,
    faClock,
    faMinus,
    faEye,
    faSpellCheck,
    faCopy,
    faEllipsisV,
    faUserShield,
    faUpload,
    faChartBar,
    faFilter,
    faEyeSlash,
    faInfoCircle,
    faCheckCircle,
    faHistory,
    faQuestion,
    faTimesCircle,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
// import { faFilterSlash, faFilters } from "@fortawesome/pro-solid-svg-icons";
import {faSkype, faLinkedin, faGithub, faGoogle, faTwitter, faGitlab, faFlickr, faInstagram, faFacebook} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(
    // faFilterSlash,
    // faFilters,
    faEyeSlash,
    faTable,
    faPowerOff,
    faTable,
    faUserSecret,
    faFacebook,
    faDotCircle,
    faChevronCircleDown,
    faSync,
    faGlobe,
    faTextWidth,
    faCircleNotch,
    faSortAlphaDown,
    faSortNumericDown,
    faGlobeAmericas,
    faCheck,
    faCheckSquare,
    faUserCircle,
    faPlus,
    faPlusCircle,
    faMinusCircle,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faEdit,
    faSave,
    faTrash,
    faChartLine,
    faSearchPlus,
    faClone,
    faDesktop,
    faClipboard,
    faCog,
    faExpand,
    faUserCog,
    faListAlt,
    faUser,
    faUsers,
    faUserShield,
    faKeyboard,
    faThList,
    faAngleLeft,
    faAngleDown,
    faAngleUp,
    faBars,
    faAngleRight,
    faExternalLinkAlt,
    faLink,
    faSearch,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faSuperscript,
    faSubscript,
    faPaintBrush,
    faTint,
    faMapMarkerAlt,
    faCalendarAlt,
    faImage,
    faNewspaper,
    faBirthdayCake,
    faEnvelope,
    faPhoneAlt,
    faBuilding,
    faGraduationCap,
    faVenusMars,
    faLanguage,
    faExclamationCircle,
    faAddressBook,
    faIcons,
    faTimes,
    faTimesCircle,
    faTachometerAlt,
    faCircle,
    faCheckCircle,
    faDownload,
    faSkype,
    faLinkedin,
    faGithub,
    faGoogle,
    faTwitter,
    faGitlab,
    faSitemap,
    faWindowRestore,
    faFlickr,
    faInstagram,
    faSitemap,
    faProjectDiagram,
    faClock,
    faMinus,
    faEye,
    faSpellCheck,
    faCopy,
    faEllipsisV,
    faUpload,
    faChartBar,
    faInfoCircle,
    faFilter,
    faHistory,
    faQuestion,
    faChevronDown
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";

import VueSimpleContextMenu from "vue-simple-context-menu";

Vue.component("vue-simple-context-menu", VueSimpleContextMenu);

import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import VTooltip from "v-tooltip";

Vue.use(VueTippy, {
    directive: "tippy",
});

// Sentry.init({
//     Vue,
//     dsn: "https://594c85e7bfbf4e6c95c2e94011a29d06@o1007886.ingest.sentry.io/5971041",
//     integrations: [
//         new Integrations.BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             tracingOrigins: ["localhost", "development.saas.neotas.com", "staging.saas.neotas.com", "saas.neotas.com", /^\//],
//         }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     logErrors: true,
//     environment: process.env.VUE_APP_ENVIRONMENT,
// });

// datadogRum.init({
//     applicationId: "4c1b627d-74a1-47e0-ab41-9f34ea8d4baf",
//     clientToken: "pub383c82d7eef353f303f949b92d390a83",
//     site: "datadoghq.eu",
//     service: "saas-frontend",
//     env: process.env.VUE_APP_ENVIRONMENT,
//     sampleRate: 100,
//     trackInteractions: true,
//     defaultPrivacyLevel: "allow",
//     allowedTracingOrigins: [/https:\/\/blackbox\.neotas\.uk/, /https:\/\/.*\.blackbox\.neotas\.uk/, /https:\/\/osint-engine\.neotas\.uk/, /https:\/\/.*\.osint-engine\.neotas\.uk/, /https:\/\/iam\.neotas\.uk/, /https:\/\/.*\.iam\.neotas\.uk/, /https:\/\/saas\.neotas\.com/, /https:\/\/.*\.saas\.neotas\.com/],
// });

// datadogRum.startSessionReplayRecording();

new Vue({
    router,
    store,
    // vuetify,
    render: (h) => h(App),
}).$mount("#app");

// const baseBlackboxUrl = "https://blackbox.neotas.uk"
var baseBlackboxUrl = process.env.VUE_APP_BLACK_BOX_URL;

var lightrayUrl = process.env.VUE_APP_LIGHTRAY_URL;

var downloadGraphUrl = "/images";
// var downloadGraphUrl = "https://neotas-reports-storage.s3.amazonaws.com/reports-templates"

const blackboxApi = axios.create({
    baseURL: baseBlackboxUrl,
    timeout: 10000,
});

const lightrayApi = axios.create({
    baseURL: lightrayUrl,
    timeout: 10000,
});

const osintDataUrl = axios.create({
    baseURL: `https://osint-engine.neotas.uk/api/v1`,
    timeout: 10000,
});

osintDataUrl.interceptors.request.use(
    (config) => {
        config.headers["Accept"] = "application/json";
        config.headers["Authorization"] = "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=";

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

blackboxApi.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        config.headers["Accept"] = "application/json";
        // config.headers["Authorization"] = "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=";
        config.headers["Authorization"] = `Bearer ${token}`;

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

lightrayApi.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("delvium-token");
        config.headers["Accept"] = "application/json";
        // config.headers["Authorization"] = "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=";
        config.headers["Authorization"] = `Bearer ${token}`;

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

riskApi.interceptors.request.use(
    (config) => {
        config.headers["Accept"] = "application/json";
        config.headers["Authorization"] = "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=";
        config.headers["x-user-id"] = "6d385edd-7872-4e94-bdfb-2809316825c0";
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

export const EventBus = new Vue();

// it should be imported after vue instance
import "@/utils/content-scripts/nlp-analysis.facade";

// import "./registerServiceWorker";
export {baseBlackboxUrl, blackboxApi, lightrayApi, riskApi, downloadGraphUrl, iamApi, caseApi, userApi, iamURL, jwtBlackboxApi, osintDataUrl};
