import {distance} from "@/utils/functions";

function CellMap() {
    this.map = new Map();

    this.getMap = function () {
        return this.map;
    };
    this.getKeys = function () {
        return this.map.keys();
    };
    this.getValues = function () {
        return this.map.values();
    };
    this.getSize = function () {
        return this.map.size;
    };

    this.setEntry = function (key, value) {
        return this.map.set(key, value);
    };
    this.getEntry = function (key) {
        return this.map.get(key);
    };
    this.hasEntry = function (key) {
        return this.map.has(key);
    };
    this.deleteEntry = function (key) {
        this.map.delete(key);
    };

    this.setStatus = function (key, status) {
        if (!this.hasEntry(key)) throw new Error("Key not defined");

        this.setEntry(key, {...this.getEntry(key), occupied: status});
    };

    this.getOccupied = function () {
        const res = new CellMap();
        for (let [key, value] of this.map) {
            if (value.occupied) res.setEntry(key, value);
        }

        return res.map;
    };

    this.getUnOccupied = function () {
        const res = new CellMap();
        for (let [key, value] of this.map) {
            if (!value.occupied) res.setEntry(key, value);
        }

        return res.map;
    };

    this.getNearestCell = function (from) {
        const unoccupied = this.getUnOccupied();

        const distances = [];
        unoccupied.forEach((value, key) => {
            distances.push({
                [key]: distance(from.x, from.y, value.position.x, value.position.y).toFixed(3),
            });
        });

        const min = Math.min(...distances.map((distance) => Object.entries(distance).map(([, value]) => value)[0]));

        const shortDistance = distances.find((distance) => {
            const [, value] = Object.entries(distance)[0];
            return +value === min;
        });

        const [key] = Object.entries(shortDistance)[0];

        return this.getEntry(key);
    };

    this.checkOccupiedCells = function (bounding) {
        Object.entries(bounding).map((coords) => this.getNearestCell(coords));
    };
}

export default {
    state: () => ({
        gxCellSize: 50,
        gxCellMap: new CellMap(),
    }),
    mutations: {},
    actions: {},
    modules: {},
    getters: {
        getGxCellSize: (state) => state.gxCellSize,
        getGxCellMap: (state) => state.gxCellMap,
    },
};
