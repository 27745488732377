import {escape, unescape} from "html-escaper";

export default {
    name: "graph-svg",
    props: {
        nodes: Array,
        edges: Array,
        showLabel: Boolean,
    },

    data() {
        return {
            tempImage: null,
            defs: null,
            svgId: "graph-svg",
            scale: 1,
            lineConf: {
                dashed: "6 3",
                dotted: "1.5, 2",
            },
            endArrowKey: "endArrow",
        };
    },
    mounted() {
        this.scale = window.network.getScale();
        this.canvasToSvg();
    },
    methods: {
        intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
            // Check if none of the lines are of length 0
            if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
                return false;
            }

            let denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

            // Lines are parallel
            if (denominator === 0) {
                return false;
            }

            let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
            let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

            // is the intersection along the segments
            if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
                return false;
            }

            // Return a object with the x and y coordinates of the intersection
            let x = x1 + ua * (x2 - x1);
            let y = y1 + ua * (y2 - y1);
            return {x, y};
        },
        getCollidingEdge(lineStart, lineEnd, nodeConfig) {
            let slop = (lineStart.y - lineEnd.y) / (lineStart.x - lineEnd.x);
            let hh = nodeConfig.height / 2;
            let hw = nodeConfig.width / 2;
            let hsw = slop * hw;
            let hsh = hh / slop;

            let TOPLEFT = {x: nodeConfig.x, y: nodeConfig.y};
            let BOTTOMLEFT = {x: nodeConfig.x, y: nodeConfig.y + nodeConfig.height};
            let BOTTOMRIGHT = {x: nodeConfig.x + nodeConfig.width, y: nodeConfig.y + nodeConfig.height};
            let TOPRIGHT = {x: nodeConfig.x + nodeConfig.width, y: nodeConfig.y};

            let collidindEdge;

            if (-hh <= hsw && hsw <= hh) {
                // line intersects
                if (nodeConfig.x + hw < lineEnd.x) {
                    //right edge;
                    collidindEdge = [TOPRIGHT, BOTTOMRIGHT];
                } else if (nodeConfig.x + hw > lineEnd.x) {
                    //left edge
                    collidindEdge = [TOPLEFT, BOTTOMLEFT];
                }
            }
            if (-hw <= hsh && hsh <= hw) {
                if (nodeConfig.y + hh < lineEnd.y) {
                    //top edge
                    collidindEdge = [BOTTOMLEFT, BOTTOMRIGHT];
                } else if (nodeConfig.y + hh > lineEnd.y) {
                    // bottom edge
                    collidindEdge = [TOPLEFT, TOPRIGHT];
                }
            }
            return collidindEdge;
        },
        getDomLine(x1, y1, x2, y2, color, thickness, lineType) {
            let mid = this.getEdgeMid({x: x1, y: y1}, {x: x2, y: y2});

            let options = {
                points: `${x1} ${y1}, ${mid.x} ${mid.y}, ${x2} ${y2}`,
                stroke: color,
                strokeWidth: thickness,
            };
            if (lineType === "dashed") {
                options.strokeDasharray = this.lineConf.dashed;
            } else if (lineType === "dotted") {
                options.strokeDasharray = this.lineConf.dotted;
            }

            let line = this.getNode("polyline", options);
            return line;
        },

        getOverlapedLength(lineStart, lineEnd, collidingNode) {
            let collidingEdge = this.getCollidingEdge(lineStart, lineEnd, collidingNode);
            let intersectionPoint = this.intersect(lineStart.x, lineStart.y, lineEnd.x, lineEnd.y, collidingEdge[0].x, collidingEdge[0].y, collidingEdge[1].x, collidingEdge[1].y);

            let a = lineStart.x - intersectionPoint.x;
            let b = lineStart.y - intersectionPoint.y;
            let r = Math.sqrt(a * a + b * b);
            return r;
        },
        getEdgeLength(start, end) {
            return Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
        },
        getEdgeSlope(start, end) {
            return (start.y - end.y) / (start.x - end.x);
        },
        getEdgeMid(start, end) {
            return {
                x: (start.x + end.x) / 2,
                y: (start.y + end.y) / 2,
            };
        },
        getEdgeAngle(start, end) {
            return Math.atan2(start.y - end.y, start.x - end.x) * (180 / Math.PI);
        },

        getDomEdgeAndLabel(edgeId, formNodeConfig, toNodeConfig, bidirectional, lineType, color, label) {
            color = color ? color : "black";
            const thickness = this.scale + 0.5;
            let x1, y1, x2, y2;

            x1 = Math.round(formNodeConfig.x + formNodeConfig.width / 2);
            y1 = Math.round(formNodeConfig.y + formNodeConfig.height / 2);
            x2 = Math.round(toNodeConfig.x + toNodeConfig.width / 2);
            y2 = Math.round(toNodeConfig.y + toNodeConfig.height / 2);
            let lineStart = {x: x2, y: y2};
            let lineEnd = {x: x1, y: y1};

            let edgeLabelId = null;

            let line = this.getDomLine(x2, y2, x1, y1, color, thickness, lineType);
            if (label) {
                edgeLabelId = `edgeLabel-${edgeId}`;
                let edgeAngle = this.getEdgeAngle(lineStart, lineEnd);
                let edgeLabel = this.getEdgeLabel(edgeAngle, label, edgeLabelId, color);
                this.defs.appendChild(edgeLabel);
                line.setAttribute("marker-mid", `url(#${edgeLabelId})`);
            }

            let overlapedLength = this.getOverlapedLength(lineStart, lineEnd, toNodeConfig);
            let arrow = this.getArrow(edgeId, overlapedLength, color);
            this.defs.appendChild(arrow);
            line.setAttribute("marker-start", `url(#traingle-${edgeId})`);

            if (bidirectional) {
                overlapedLength = this.getOverlapedLength(lineEnd, lineStart, formNodeConfig);
                arrow = this.getArrow(edgeId, overlapedLength, color, true);
                this.defs.appendChild(arrow);
                line.setAttribute("marker-end", `url(#traingle-${this.endArrowKey}-${edgeId})`);
            }
            return {edge: line};
        },

        getMinMaxX(scale) {
            let minX = Infinity;
            let maxX = 0;
            let maxY = 0;
            let minY = Infinity;
            let leftMostNode, rightMostNode, topMostNode, downMostNode;

            for (const [nodeId, nodeCords] of Object.entries(window.network.getPositions())) {
                nodeCords = window.network.canvasToDOM(nodeCords);
                if (nodeCords.x > maxX) {
                    maxX = nodeCords.x;
                    rightMostNode = nodeId;
                }
                if (nodeCords.x < minX) {
                    minX = nodeCords.x;
                    leftMostNode = nodeId;
                }

                if (nodeCords.y > maxY) {
                    maxY = nodeCords.y;
                    downMostNode = nodeId;
                }
                if (nodeCords.y < minY) {
                    minY = nodeCords.y;
                    topMostNode = nodeId;
                }
            }
            let rightNodeWidthHeight = this.getNodeWidthHeight(rightMostNode, scale);
            let downNodeWidthHeight = this.getNodeWidthHeight(downMostNode, scale);
            let topMostNodeWidthHeight = this.getNodeWidthHeight(topMostNode, scale);
            let leftMostNodeWidthHeight = this.getNodeWidthHeight(leftMostNode, scale);
            maxX = maxX + rightNodeWidthHeight.nodeWidth + 20;
            maxY = maxY + downNodeWidthHeight.nodeHeight + 20;
            minY = minY - topMostNodeWidthHeight.nodeHeight / 2 - 20;
            minX = minX - leftMostNodeWidthHeight.nodeWidth / 2 - 20;
            return {minX, maxX, minY, maxY};
        },
        getNodeWidthHeight(nodeId, scale) {
            const nodeBoundingBox = window.network.getBoundingBox(nodeId);
            let nodeHeight = nodeBoundingBox.bottom - nodeBoundingBox.top;
            let nodeWidth = nodeBoundingBox.right - nodeBoundingBox.left;
            let aspectRatio = nodeHeight / nodeWidth;

            nodeWidth = Math.round(nodeWidth - (((1 - scale) * 100) / 100) * nodeWidth);
            nodeHeight = Math.round(aspectRatio * nodeWidth);
            return {nodeWidth, nodeHeight};
        },
        canvasToSvg() {
            const scale = this.scale;
            let minXmaxX = this.getMinMaxX(scale);
            const container = document.querySelector("#canvas-area-container");
            const height = minXmaxX.maxY - minXmaxX.minY;
            const width = minXmaxX.maxX - minXmaxX.minX;

            // create container svg
            var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            // svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
            svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
            svg.setAttribute("id", this.svgId);
            svg.setAttribute("width", `${width}`);
            svg.setAttribute("height", `${height}`);

            this.defs = this.getNode("defs");

            let domNodeDetails = {};
            let nodeDivs = "";
            let createdEdges = [];

            // create all nodes
            for (const [nodeId, nodeCords] of Object.entries(window.network.getPositions())) {
                nodeCords = window.network.canvasToDOM(nodeCords);
                const img = this.nodes.find((item) => item.id === nodeId)?.image;
                let nodeWidth, nodeHeight;
                ({nodeWidth, nodeHeight} = this.getNodeWidthHeight(nodeId, scale));

                const node_x = nodeCords.x - nodeWidth / 2 - minXmaxX.minX;
                const node_y = nodeCords.y - nodeHeight / 2 - minXmaxX.minY;

                let groupNode = this.getNode("g", {});

                let svgNode = this.base64ToSvgDom(img.replace("data:image/svg+xml;base64,", ""));
                svgNode.setAttribute("x", `${node_x}`);
                svgNode.setAttribute("y", `${node_y}`);
                svgNode.setAttribute("height", `${nodeHeight}`);
                svgNode.setAttribute("width", `${nodeWidth}`);
                svgNode.setAttribute("iid", `${nodeId}`);
                groupNode.appendChild(svgNode);
                // let svgNode = this.getNode('image', {x: node_x, y: node_y, "href": img, height: nodeHeight, width: nodeWidth})
                domNodeDetails[nodeId] = {x: node_x, y: node_y, height: nodeHeight, width: nodeWidth, el: groupNode};
            }

            // draw all edges
            let lineType;
            for (const [nodeId, nodeConfig] of Object.entries(domNodeDetails)) {
                for (const connectedEdge of this.getConnectedEdges(nodeId)) {
                    if (!createdEdges.includes(connectedEdge.id)) {
                        if (connectedEdge.dashes === true) {
                            lineType = "dashed";
                        } else if (connectedEdge.dashes === false) {
                            lineType = "plane";
                        } else {
                            lineType = "dotted";
                        }
                        const label = this.showLabel ? connectedEdge.label : null;
                        const {edge, edgeLabel} = this.getDomEdgeAndLabel(connectedEdge.id, domNodeDetails[connectedEdge["from"]], domNodeDetails[connectedEdge["to"]], connectedEdge.bidirectional, lineType, connectedEdge.exportColor, label);

                        svg.append(edge);
                        createdEdges.push(connectedEdge.id);
                        if (edgeLabel) {
                            svg.append(edgeLabel);
                        }
                    }
                }
            }

            // push all created nodes to svg
            for (const [nodeId, nodeDetails] of Object.entries(domNodeDetails)) {
                svg.append(nodeDetails.el);
            }

            svg.append(this.defs);
            let el = this.$refs.graphtosvgcontainer;
            el.appendChild(svg);

            let svgEl = document.getElementById(this.svgId);
            let svgData = new XMLSerializer().serializeToString(svgEl);

            let svgBlob = new Blob([svgData], {type: "image/svg+xml;charset=utf-8"});
            this.$emit("complete", svgBlob);

            // NOTE: Uncomment this for debugging svg generation of graph.
            // svg.classList.add("yolo");
            // document.querySelector(".yolo").parentNode.removeChild(document.querySelector(".yolo"));
            // var dx = document.querySelector(".canvas__area__wrapper");
            // svg.style.border = "solid 1px black";
            // dx.appendChild(svg);
        },

        getEdgeLabel(textAngle, text, id, color) {
            // let
            let marker = document.createElementNS("http://www.w3.org/2000/svg", "marker");
            let markerWidth = 20;
            let markerHeight = 14;
            let fontSize = Math.round(16 * this.scale);
            markerWidth = Math.round(markerWidth - (((1 - this.scale) * 100) / 100) * markerWidth);
            markerHeight = Math.round(markerHeight - (((1 - this.scale) * 100) / 100) * markerHeight);

            let textScale = 1;
            let refY = markerHeight / 2;
            if (textAngle < 90 && textAngle > -90) {
                textScale = -1;
                refY = Math.round(18 * this.scale);
            } else {
                refY = Math.round(-18 * this.scale);
            }

            marker.setAttribute("id", id);
            marker.setAttribute("markerWidth", `${markerWidth}`);
            marker.setAttribute("markerHeight", `${markerHeight}`);
            // marker.setAttribute("refX", `${refX}`);
            marker.setAttribute("refY", `${refY}`);
            marker.setAttribute("orient", "auto");
            marker.setAttribute("markerUnits", "userSpaceOnUse");
            marker.setAttribute("style", "overflow: visible;");

            let textNode = this.getNode("text", {id: id, fontSize: `${fontSize}px`, transform: `translate(${0}, ${0}) scale(${textScale})`, fontFamily: "arial", textAnchor: "middle"});
            let myText = document.createTextNode(escape(text));
            textNode.appendChild(myText);
            marker.appendChild(textNode);
            return marker;
        },

        getArrow(id, refX, color, atEndOfLine) {
            let marker = document.createElementNS("http://www.w3.org/2000/svg", "marker");
            let markerWidth = 20;
            let markerHeight = 14;
            markerWidth = Math.round(markerWidth - (((1 - this.scale) * 100) / 100) * markerWidth);
            markerHeight = Math.round(markerHeight - (((1 - this.scale) * 100) / 100) * markerHeight);

            if (atEndOfLine) {
                id = `traingle-${this.endArrowKey}-${id}`;
                refX = `${refX + markerWidth}`;
            } else {
                id = `traingle-${id}`;
                refX = `-${refX - refX * 0.01}`;
            }

            marker.setAttribute("id", id);
            marker.setAttribute("markerWidth", `${markerWidth}`);
            marker.setAttribute("markerHeight", `${markerHeight}`);
            marker.setAttribute("refX", `${refX}`);
            marker.setAttribute("refY", `${markerHeight / 2}`);
            marker.setAttribute("orient", "auto");
            marker.setAttribute("markerUnits", "userSpaceOnUse");

            let polygonPoints;

            if (atEndOfLine) {
                polygonPoints = `0 0, ${markerWidth} ${markerHeight / 2}, 0 ${markerHeight}`;
            } else {
                polygonPoints = `${markerWidth} 0, ${markerWidth} ${markerHeight}, 0 ${markerHeight / 2}`;
            }

            let polygon = this.getNode("polygon", {
                points: polygonPoints,
                fill: `${color}`,
            });
            marker.appendChild(polygon);
            return marker;
        },

        getConnectedEdges(nodeId) {
            let connectedEdge = this.edges.filter((edge) => edge.from === nodeId || edge.to === nodeId);
            return connectedEdge;
        },
        base64ToSvgDom(base64String) {
            try {
                let svgStr = decodeURIComponent(
                    window
                        .atob(base64String)
                        .split("")
                        .map(function (c) {
                            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join("")
                );
                // let svgStr = window.atob(base64String);
                let parser = new DOMParser();
                let doc = parser.parseFromString(svgStr, "image/svg+xml");
                let svg = doc.getElementsByTagName("svg")[0];
                return svg;
            } catch (error) {
                console.error("invalid base64", base64String);
                let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                return svg;
            }
        },
        getNode(n, v) {
            n = document.createElementNS("http://www.w3.org/2000/svg", n);
            for (var p in v)
                n.setAttributeNS(
                    null,
                    p.replace(/[A-Z]/g, function (m, p, o, s) {
                        return "-" + m.toLowerCase();
                    }),
                    v[p]
                );
            return n;
        },
    },
};
