import Grid from "./grid";
import {SAMPLE_NODES} from "@/test/nodes";

export default {
    state: () => ({
        entities: [...SAMPLE_NODES.entities],
        connections: [...SAMPLE_NODES.connections],
    }),
    mutations: {},
    actions: {},
    modules: {
        grid: Grid,
    },
    getters: {
        getConnectionMap: (state) =>
            state.connections.reduce((map, connection) => {
                const pair = {
                    out: () => connection.to,
                    in: () => connection.from,
                    connections: () => connection,
                };

                Object.keys(pair).forEach((key) => ((map[connection.from] = map[connection.from] || {})[key] = (map[connection.from] = map[connection.from] || {})[key] || []).push(pair[key]()));
                return map;
            }, {}),
    },
};
