import createStorageStateHelper from "@/utils/create-storage-state.helper";
import {cloneDeep} from "lodash";

const nlpStorage = createStorageStateHelper("nlp-analysis-config");
const defaultState = {
    badWordsLanguages: ["All Languages"],
    highlightedEntities: [],
    caseIdentifiersChecked: false,
    badWordsChecked: false,
    productKeywordsChecked: false,
    analyzerOptions: [
        {name: "Topic Classification", selected: false, key: "analyzer-topic-classification", payload_name: "topic_classification"},
        {name: "Entity Recognition", selected: false, key: "analyzer-entity-recognition", payload_name: "ner"},
        {name: "ESG Categorisation", selected: false, key: "analyzer-esg-categorisation", payload_name: "esg_categorisation"},
        {name: "Core Risk Categorisation", selected: false, key: "analyzer-risk-categorisation", payload_name: "risk_categorisation"},
    ],
    autoAnalyze: false,
};

const storedState = nlpStorage.getState();
const state = {...defaultState, ...storedState};

export default {
    state,
    mutations: {
        SET_BAD_WORDS_LANGUAGES(state, badWordsLanguages) {
            state.badWordsLanguages = badWordsLanguages;
        },
        SET_HIGHLIGHTED_ENTITIES(state, highlightedEntities) {
            state.highlightedEntities = highlightedEntities;
        },
        SET_CASE_IDENTIFIERS_CHECKED(state, caseIdentifiersChecked) {
            state.caseIdentifiersChecked = caseIdentifiersChecked;
        },
        SET_BAD_WORDS_CHECKED(state, badWordsChecked) {
            state.badWordsChecked = badWordsChecked;
        },
        SET_PRODUCT_KEYWORDS_CHECKED(state, productKeywordsChecked) {
            state.productKeywordsChecked = productKeywordsChecked;
        },
        SET_ANALYZER_OPTIONS(state, payload) {
            state.analyzerOptions = payload;
        },
        SET_AUTO_ANALYZE(state, payload) {
            state.autoAnalyze = payload;
        },
    },
    actions: {
        setBadWordsLanguages({commit}, payload) {
            commit("SET_BAD_WORDS_LANGUAGES", payload);
            nlpStorage.unionState({badWordsLanguages: payload});
        },
        toggleBadWordsLanguage({commit, dispatch, state}, payload) {
            let badWordsLanguages = state.badWordsLanguages;
            const index = badWordsLanguages.indexOf(payload);

            if (index === -1) {
                badWordsLanguages.push(payload);
            } else {
                badWordsLanguages.splice(index, 1);

                if (badWordsLanguages.length === 0) {
                    badWordsLanguages = ["All Languages"];
                }
            }

            dispatch("setBadWordsLanguages", badWordsLanguages);
        },
        addHighlightedEntity({commit, state}, payload) {
            const highlightedEntities = state.highlightedEntities.filter((highlightedEntity) => highlightedEntity.id !== payload.id);

            commit("SET_HIGHLIGHTED_ENTITIES", [...highlightedEntities, payload]);
        },
        addHighlightedEntities({commit, state}, payload) {
            const highlightedEntities = state.highlightedEntities.filter((highlightedEntity) => {
                return payload.some((entityToSave) => entityToSave.id === highlightedEntity.id);
            });

            commit("SET_HIGHLIGHTED_ENTITIES", [...highlightedEntities, ...payload]);
        },
        setHighlightedEntities({commit}, payload) {
            commit("SET_HIGHLIGHTED_ENTITIES", payload);
        },
        removeHighlightedEntity({commit, state}, payload) {
            const highlightedEntities = state.highlightedEntities.filter((highlightedEntity) => highlightedEntity.id !== payload);

            commit("SET_HIGHLIGHTED_ENTITIES", highlightedEntities);
        },
        setCaseIdentifiersChecked({commit}, payload) {
            commit("SET_CASE_IDENTIFIERS_CHECKED", payload);
            nlpStorage.unionState({caseIdentifiersChecked: payload});
        },
        setBadWordsChecked({commit}, payload) {
            commit("SET_BAD_WORDS_CHECKED", payload);
            nlpStorage.unionState({badWordsChecked: payload});
        },
        setProductKeywordsChecked({commit}, payload) {
            commit("SET_PRODUCT_KEYWORDS_CHECKED", payload);
            nlpStorage.unionState({productKeywordsChecked: payload});
        },
        setAnalayzerOptions({commit}, payload) {
            commit("SET_ANALYZER_OPTIONS", payload);
        },
        setAutoAnalyze({commit}, payload) {
            commit("SET_AUTO_ANALYZE", payload);
        },
    },
    getters: {
        getBadWordsLanguages: (state) => state.badWordsLanguages,
        getCombinedHighlightedEntities: (state) => {
            const result = {};
            const highlightedEntities = state.highlightedEntities.map((highlightedEntity) => cloneDeep(highlightedEntity.count));

            for (let countOfEntities of highlightedEntities) {
                for (let dataType in countOfEntities) {
                    const resultDataTypeItem = result[dataType];
                    const entityDataTypeItem = countOfEntities[dataType];
                    if (resultDataTypeItem === undefined) {
                        result[dataType] = entityDataTypeItem;
                        continue;
                    }

                    for (let entityName in entityDataTypeItem.entities) {
                        const resultItem = resultDataTypeItem.entities[entityName];
                        const entityItem = entityDataTypeItem.entities[entityName];
                        if (resultItem === undefined) {
                            resultDataTypeItem.entities[entityName] = entityItem;
                        } else {
                            resultItem.count += entityItem.count;
                        }

                        resultDataTypeItem.count += entityItem.count;
                    }
                }
            }

            return result;
        },
        getCaseIdentifiersChecked: (state) => state.caseIdentifiersChecked,
        getBadWordsChecked: (state) => state.badWordsChecked,
        getProductKeywordsChecked: (state) => state.productKeywordsChecked,
        getAnalyzerOptions: (state) => state.analyzerOptions,
        getAutoAnalyze: (state) => state.autoAnalyze,
    },
};
