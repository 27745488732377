import Chart from "chart.js";

export default {
    name: "neo-chart-donut",
    components: {},
    props: ["id", "value", "bgColor", "width", "height", "cWidth", "cHeight", "cLabel", "label", "fontSize", "margin"],
    data() {
        return {
            chartData: {
                type: "doughnut",

                data: {
                    datasets: [
                        {
                            data: [+this.value, 100 - +this.value],
                            backgroundColor: [this.bgColor, "#eef2f9"],
                            borderWidth: 0,
                        },
                    ],

                    labels: [this.label || this.cLabel, "Complete"],
                },
                options: {
                    tooltips: {
                        enabled: false,
                    },
                    elements: {
                        center: {
                            text: `${this.value}%`,
                            color: this.bgColor,
                            fontStyle: "var(--brand-font)",
                            sidePadding: 20,
                            minFontSize: 20,
                            lineHeight: 14,
                        },
                    },
                    legend: {
                        display: false,
                    },
                    responsive: false,
                    lineTension: 1,
                    cutoutPercentage: "85",
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    display: false,
                                },
                                gridLines: {
                                    display: false,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                ticks: {
                                    display: false,
                                },
                                gridLines: {
                                    display: false,
                                },
                            },
                        ],
                    },
                },
            },
        };
    },
    computed: {},
    mounted() {
        this.createChart(this.id, this.chartData);
    },
    methods: {
        createChart(chartId, chartData) {
            const ctx = document.getElementById(chartId);

            new Chart(ctx, {
                type: chartData.type,
                data: chartData.data,
                options: chartData.options,
            });

            Chart.pluginService.register({
                beforeDraw: function (chart) {
                    if (chart.config.options.elements.center) {
                        const ctx = chart.chart.ctx;

                        const centerConfig = chart.config.options.elements.center;
                        const fontStyle = centerConfig.fontStyle || "var(--brand-font)";
                        const txt = centerConfig.text;
                        const color = centerConfig.color || "#000";
                        const maxFontSize = centerConfig.maxFontSize || 20;
                        const sidePadding = centerConfig.sidePadding || 20;
                        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);

                        ctx.font = "14px " + fontStyle;

                        const stringWidth = ctx.measureText(txt).width;
                        const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

                        const widthRatio = elementWidth / stringWidth;
                        const newFontSize = Math.floor(30 * widthRatio);
                        const elementHeight = chart.innerRadius * 2;

                        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                        let minFontSize = centerConfig.minFontSize;
                        const lineHeight = centerConfig.lineHeight || 14;
                        let wrapText = false;

                        if (minFontSize === undefined) {
                            minFontSize = 14;
                        }

                        if (minFontSize && fontSizeToUse < minFontSize) {
                            fontSizeToUse = minFontSize;
                            wrapText = true;
                        }

                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                        let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                        ctx.font = fontSizeToUse + "px " + fontStyle;
                        ctx.fillStyle = color;

                        if (!wrapText) {
                            ctx.fillText(txt, centerX, centerY);
                            return;
                        }

                        const words = txt.split(" ");
                        let line = "";
                        const lines = [];

                        for (let n = 0; n < words.length; n++) {
                            let testLine = line + words[n] + " ";
                            let metrics = ctx.measureText(testLine);
                            let testWidth = metrics.width;
                            if (testWidth > elementWidth && n > 0) {
                                lines.push(line);
                                line = words[n] + " ";
                            } else {
                                line = testLine;
                            }
                        }

                        centerY -= (lines.length / 2) * lineHeight;

                        for (let n = 0; n < lines.length; n++) {
                            ctx.fillText(lines[n], centerX, centerY);
                            centerY += lineHeight;
                        }
                        ctx.fillText(line, centerX, centerY);
                    }
                },
            });
        },
    },
};
