import Button from "@/components/button";

// Dynamic imports for components that are not used on first load
const Panel = () => import("@/containers/add-entity-panel/panel");

export default {
    name: "neo-entity-panel",
    data() {
        return {
            parentEntityImagePath: null,
            parentEntityName: null,
        };
    },
    components: {
        "neo-button": Button,
        panel: Panel,
    },
    computed: {
        getCaseId() {
            return this.$store.getters.getCaseId;
        },
        getIsAddEntityPanelOpen() {
            return this.$store.getters.isAddEntityPanelOpen;
        },
        getSelectedEntityData() {
            return this.$store.getters.getSelectedEntityData;
        },
        getEntitiesRelationsAttributesData() {
            return this.$store.getters.getEntitiesRelationsAttributesData.entities;
        },
    },
    beforeDestroy() {
        this.$store.dispatch("setSelectedEntityData", null);
    },
    mounted() {
        this.getEntityImagePath();
        this.getEntityName();
    },
    watch: {
        getSelectedEntityData() {
            this.getEntityImagePath();
            this.getEntityName();
        },
    },
    methods: {
        hideEntityPanel() {
            let reloadType;
            if (this.getSelectedEntityData?.datatype == "entity" && this.getSelectedEntityData?.all_data) {
                if (this.getSelectedEntityData.all_data?.entity_type?._id === "012da181-d04c-4ff3-ad01-a52fe9187686" || this.getSelectedEntityData?.all_data?.entity_type?._id === "584b2f53-2694-439c-bef3-0390e43bc4d0") {
                    reloadType = ["associatedPeople", "map"];
                } else {
                    reloadType = ["personalDetails", "map"];
                }
                this.reloadContent(reloadType);
            }
            this.$store.dispatch("hideAddEntityPanel");
        },
        reloadContent(contentType) {
            contentType.forEach((e) => {
                let data = {currentTab: e === "map" ? "personalDetails" : e};
                this.$store.dispatch("fetchAllResearchScreenData", data);
            });
        },
        getParentEntity() {
            if (this.getSelectedEntityData == null || this.getSelectedEntityData.entity == null) {
                throw new Error(`No selected entity`);
            }

            const parentEntity = this.getEntitiesRelationsAttributesData.find((e) => e._id === this.getSelectedEntityData.entity.parent_id);
            if (parentEntity === undefined) {
                throw new Error(`Can't find parentEntity`);
            }

            return parentEntity;
        },
        getEntityImagePath() {
            try {
                const parentEntity = this.getParentEntity();
                this.parentEntityImagePath = require(`@/assets/tooltip-icons/${parentEntity.icon}`);
            } catch (e) {
                console.warn(e);
            }
        },
        getEntityName() {
            try {
                const parentEntity = this.getParentEntity();
                this.parentEntityName = parentEntity.entity;
            } catch (e) {
                console.warn(e);
            }
        },
    },
};
