import axios from "axios";
import VueCookies from "vue-cookies";
import refreshTokeninterceptor from "./utils.js";

const iamApi = axios.create({
    baseURL: process.env.VUE_APP_USER_API_URL,
});

// Add a request interceptor
iamApi.interceptors.request.use(
    function(config) {
        let token = VueCookies.get("token");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

iamApi.interceptors.response.use(undefined, refreshTokeninterceptor(iamApi));
export default iamApi;
