import KeywordDataProvider from "@/services/end-points/keywords.data-provider.js";
import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast);

export default {
    state: {
        page: 1,
        perPage: 10,
        order: {
            ordering_key: "keyword_set_name",
            ordering_value: "ASC",
        },
        filters: {},
        totalKeywordsCount: 0,
        languages: [],
        entities: [],
        productMapEntities: [],
        keywordErrorMessage: "",
    },
    mutations: {
        setKeywordPage(state, page) {
            state.page = page;
        },
        setKeywordPerPage(state, perPage) {
            state.perPage = perPage;
        },
        setKeywordLanguages(state, languages) {
            state.languages = languages;
        },
        setKeywordEntities(state, entities) {
            state.entities = entities;
        },
        setProductMapEntities(state, entities) {
            state.productMapEntities = entities;
        },
        setKeywordTotalCount(state, totalKeywordsCount) {
            state.totalKeywordsCount = totalKeywordsCount;
        },
        setKeywordOrder(state, order) {
            state.order = order;
        },
        setKeywordFilters(state, filters) {
            state.filters = filters;
        },
        setErrorMessage(state, errorMessage) {
            state.keywordErrorMessage = errorMessage;
        },
    },
    actions: {
        async loadKeywordLanguages({commit}) {
            commit(`setKeywordLanguages`, await KeywordDataProvider.getLanguages());
        },
        async loadKeywordEntities({commit, state}) {
            const offset = (state.page - 1) * state.perPage;
            const [entities, totalKeywordsCount] = await KeywordDataProvider.filterEntities(state.filters, state.perPage, offset, state.order);
            // const entities = await KeywordDataProvider.getEntities(state.perPage, offset);

            commit(`setKeywordTotalCount`, totalKeywordsCount);
            commit(`setKeywordEntities`, entities);
        },
        async loadKeywordEntitiesByProduct({commit}, productId) {
            commit(`setProductMapEntities`, await KeywordDataProvider.getKeywordProductMap(productId));
        },
        async setKeywordSetsToProduct({commit}, payload) {
            // { productId: string, keywordIds: string[] } - payload
            try {
                const returnedKeywords = await KeywordDataProvider.setKeywordSetsToProduct(payload);
            } catch (e) {
                commit(`setErrorMessage`, e);
            }
            commit(`loadKeywordEntitiesByProduct`, payload.productId);
        },
        async removeKeywordSetsFromProduct({commit}, payload) {
            // { productId: string, keywordIds: string[] } - payload
            try {
                const deletedKeywords = await KeywordDataProvider.removeKeywordSetsFromProduct(payload);
            } catch (e) {
                commit(`setErrorMessage`, e);
            }
            commit(`loadKeywordEntitiesByProduct`, payload.productId);
        },
        setKeywordPage({commit}, page) {
            commit(`setKeywordPage`, page);
        },
        setKeywordPerPage({commit}, perPage) {
            commit(`setKeywordPerPage`, perPage);
        },
        setKeywordOrder({commit}, order) {
            commit(`setKeywordOrder`, order);
        },
        setKeywordFilters({commit}, filters) {
            commit(`setKeywordFilters`, filters);
        },
        async updateKeywordEntity({commit, state, dispatch}, payload) {
            try {
                let response = await KeywordDataProvider.updateEntity(payload.id, payload.entity);
                if (response.status) {
                    Vue.$toast.success("Keyword updated successfully !");
                }
            } catch (e) {
                commit(`setErrorMessage`, e);
            }

            dispatch(`loadKeywordEntities`);
        },
        async createKeywordEntity({commit, dispatch}, keywordEntity) {
            try {
                await KeywordDataProvider.createEntity(keywordEntity);
            } catch (e) {
                commit(`setErrorMessage`, e);
            }

            dispatch(`loadKeywordEntities`);
        },
        async deleteKeywordEntity({commit, dispatch}, keywordId) {
            try {
                await KeywordDataProvider.deleteEntity(keywordId);
            } catch (e) {
                commit(`setErrorMessage`, e);
            }

            dispatch(`loadKeywordEntities`);
        },
    },
    getters: {
        getKeywordLanguages: (state) => state.languages,
        getKeywordEntities: (state) => state.entities,
        getKeywordProductMapEntities: (state) => state.productMapEntities,
        getTotalKeywordsCount: (state) => state.totalKeywordsCount,
        getKeywordPage: (state) => state.page,
        getKeywordPerPage: (state) => state.perPage,
        getKeywordSort: (state) => state.sort,
        getKeywordFilters: (state) => state.filters,
        getErrorMessage: (state) => state.keywordErrorMessage,
    },
};
