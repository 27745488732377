import { render, staticRenderFns } from "./tab.html?vue&type=template&id=75af9513&scoped=true&"
import script from "./tab.js?vue&type=script&lang=js&"
export * from "./tab.js?vue&type=script&lang=js&"
import style0 from "./tab.scss?vue&type=style&index=0&id=75af9513&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75af9513",
  null
  
)

export default component.exports